import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DynamicForm from '@components/common/DynamicForm';
import { userFormGeneralItems } from './UserFormConfig';
import { useCreateUserMutation, useGetRolesQuery } from '@api/userSlice';
import cloneDeep from 'lodash.clonedeep';
import { CommonButton } from '@components/common/CommonButton';
import { TEAMS } from '@constants/index';
import { getSessionUserFromAccessToken } from '@utils/utils';

export default function AdminCreateUserComponent({
  onAdminCreateUserModalClose,
}: {
  onAdminCreateUserModalClose: (createdUser: boolean) => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sessionUser, setSessionUser] = useState<any>({
    teams: [],
  });

  const [createUser] = useCreateUserMutation();
  const [createFormItems, setFormItems] = useState(cloneDeep(userFormGeneralItems));

  const { data: roles, isSuccess: isGetRolesSuccess } = useGetRolesQuery({});

  useEffect(() => {
    const getUserData = async () => {
      const sessionUser = await getSessionUserFromAccessToken();
      setSessionUser(sessionUser);
    };
    getUserData();
  }, []);

  useEffect(() => {
    let formattedRoles: any[] = [];
    if (isGetRolesSuccess) {
      for (let role of roles) {
        let formattedRole = {
          id: role.roleId,
          label: role.roleName,
        };

        formattedRoles.push(formattedRole);
      }
    }

    let formattedTeams: any[] = [];
    for (let team of TEAMS) {
      if (
        !sessionUser.teams ||
        sessionUser.teams.length <= 0 ||
        sessionUser.teams.includes('gasp') ||
        sessionUser.teams.includes(team.id)
      ) {
        let formattedTeam = {
          id: team.id,
          label: team.label,
        };

        formattedTeams.push(formattedTeam);
      }
    }

    let formattedFormItems = cloneDeep(userFormGeneralItems);
    let roleField = formattedFormItems.find((item) => item.id === 'role');
    if (roleField) {
      roleField.extras.dropdownData = formattedRoles;
    }
    let teamsField = formattedFormItems.find((item) => item.id === 'teams');
    if (teamsField) {
      teamsField.extras.dropdownData = formattedTeams;
    }

    setFormItems(formattedFormItems);
  }, [roles, isGetRolesSuccess, sessionUser]);

  // user
  const formMethods = useForm({
    mode: 'onSubmit',
  });

  const onSubmit = async (event: any) => {
    setIsSubmitting(true);
    const formValues: any = formMethods.getValues();
    let params = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      contactNumber: formValues.contactNumber,
      designation: formValues.designation,
      role: formValues.role?.map((role: any) => role.id),
      teams: formValues.teams?.map((team: any) => team.id),
    };

    let response: any = await createUser(params);

    setIsSubmitting(false);

    if (!response.error) {
      onAdminCreateUserModalClose(true);
    }
  };

  return (
    <div className="overflow-auto max-h-screen pb-20">
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="flex flex-col">
          <DynamicForm formFields={createFormItems} formMethods={formMethods}></DynamicForm>
        </div>
        <div className="flex flex-col pt-4 text-left">
          <CommonButton
            className="ml-0 w-full flex justify-center"
            variant="primary"
            type="submit"
            title={isSubmitting ? 'Submitting...' : 'Submit'}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </div>
  );
}
