import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import { getSessionUserFromAccessToken } from '@utils/utils';

import { ReactComponent as Report } from '@assets/icons/report_management.svg';
import { ReactComponent as Portfolios } from '@assets/icons/portfolios.svg';
import { ReactComponent as PoQueue } from '@assets/icons/po_queue.svg';
import { ReactComponent as UserManagement } from '@assets/icons/user_management.svg';
// import { ReactComponent as Analytics } from '@assets/icons/analytics.svg';

import { ReactComponent as ReportWhite } from '@assets/icons/report_management_white.svg';
import { ReactComponent as PortfoliosWhite } from '@assets/icons/portfolios_white.svg';
import { ReactComponent as PoQueueWhite } from '@assets/icons/po_queue_white.svg';
import { ReactComponent as UserManagementWhite } from '@assets/icons/user_management_white.svg';
import { Tooltip } from '@mui/material';
// import { ReactComponent as AnalyticsWhite } from '@assets/icons/analytics_white.svg';

// Map of links to display in the side navigation.
// Depending on the size of the application, th is would be stored in a database.
// const navlinks = [
//   {
//     name: 'Reports',
//     href: '/admin/reports',
//     icon: Report,
//     iconWhite: ReportWhite,
//   },
//   {
//     name: 'Portfolios',
//     href: '/admin/portfolios',
//     icon: Portfolios,
//     iconWhite: PortfoliosWhite,
//   },
//   {
//     name: 'Directions Queue',
//     href: '/admin/directions-queue',
//     icon: PoQueue,
//     iconWhite: PoQueueWhite,
//   },
//   {
//     name: 'User Management',
//     href: '/admin/users',
//     icon: UserManagement,
//     iconWhite: UserManagementWhite,
//   },
//   // {
//   //   name: "Analyics",
//   //   href: "/admin/analytics",
//   //   icon: Analytics,
//   //   iconWhite: AnalyticsWhite,
//   // },
// ];

interface NavLinksProps {
  isOpen: boolean;
}

export default function NavLinks({ isOpen }: NavLinksProps) {
  const location = useLocation();
  const { pathname } = location;
  const [links, setLinks] = useState<any>([]);

  useEffect(() => {
    const getSessionData = async () => {
      try {
        const sessionUser = await getSessionUserFromAccessToken();

        let navLinks = [];
        if (
          sessionUser.permissions.includes('reports.scamenablers.view') ||
          sessionUser.permissions.includes('reports.banks.view') ||
          sessionUser.permissions.includes('reports.paynows.view')
        ) {
          navLinks.push({
            name: 'Reports',
            href: '/admin/reports',
            icon: Report,
            iconWhite: ReportWhite,
          });
        }
        if (
          sessionUser.permissions.includes('portfolios.proactivescamenablers.view') &&
          !sessionUser.permissions.includes('portfolios.scamenablers.view')
        ) {
          navLinks.push({
            name: 'Portfolios',
            href: '/admin/portfolios/proactive',
            icon: Portfolios,
            iconWhite: PortfoliosWhite,
          });
        } else if (
          sessionUser.permissions.includes('portfolios.banks.view') &&
          !sessionUser.permissions.includes('portfolios.scamenablers.view')
        ) {
          navLinks.push({
            name: 'Portfolios',
            href: '/admin/portfolios/banks',
            icon: Portfolios,
            iconWhite: PortfoliosWhite,
          });
        } else if (
          sessionUser.permissions.includes('portfolios.paynows.view') &&
          !sessionUser.permissions.includes('portfolios.scamenablers.view')
        ) {
          navLinks.push({
            name: 'Portfolios',
            href: '/admin/portfolios/paynow',
            icon: Portfolios,
            iconWhite: PortfoliosWhite,
          });
        } else if (
          sessionUser.permissions.includes('portfolios.scamenablers.view') ||
          sessionUser.permissions.includes('portfolios.banks.view') ||
          sessionUser.permissions.includes('portfolios.paynows.view')
        ) {
          navLinks.push({
            name: 'Portfolios',
            href: '/admin/portfolios',
            icon: Portfolios,
            iconWhite: PortfoliosWhite,
          });
        }
        if (sessionUser.permissions.includes('directionsQueue.productionorders.view')) {
          navLinks.push({
            name: 'Directions Queue',
            href: '/admin/directions-queue',
            icon: PoQueue,
            iconWhite: PoQueueWhite,
          });
        }
        if (sessionUser.permissions.includes('userManagement.users.view')) {
          navLinks.push({
            name: 'User Management',
            href: '/admin/users',
            icon: UserManagement,
            iconWhite: UserManagementWhite,
          });
        }

        setLinks(navLinks);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    getSessionData();
  }, []);

  return (
    <>
      {links.map((link: any, index: number) => {
        const LinkIcon = link.icon;
        const LinkIconWhite = link.iconWhite;

        return (
          <Tooltip title={link.name} key={index}>
            <Link
              key={link.name}
              to={link.href}
              className={clsx(
                'flex h-[48px] grow items-center rounded-md justify-center gap-2 md:flex-none md:justify-start md:p-2 md:px-3',
                {
                  'rounded-md bg-blue-500 text-blue-500 hover:opacity-80': pathname.includes(
                    link.href,
                  ),
                  'hover:bg-blue-300 hover:text-blue-500': !pathname.includes(link.href),
                  'p-0 md:justify-center': !isOpen,
                },
              )}
            >
              <div className="w-[24px] h-[24px]  flex items-center justify-center">
                {!pathname.includes(link.href) && <LinkIcon />}
                {pathname.includes(link.href) && <LinkIconWhite />}
              </div>
              <p
                className={clsx('font-bold text-blue-500 ', {
                  'hidden md:block ': isOpen,
                  'text-white': pathname.includes(link.href),
                  hidden: !isOpen,
                })}
              >
                {link.name}
              </p>
            </Link>
          </Tooltip>
        );
      })}
    </>
  );
}
