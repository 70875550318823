import {
  ochaDirections,
  offenceList,
  platform,
  scamEnablerOutcomes,
  scamType,
  useOfScamEnabler,
} from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { validateIdNotNil } from '@utils/utils';
import validator from 'validator';

export const generalInformationItems: Array<FormItemInterface> = [
  {
    label: 'DA1 Assigned',
    id: 'da1',
    type: 'labelEditDropdownUser',
    extras: { updateAPI: 'assignScamEnablerV2', userGroup: 'scamEnablersDA1' },
  },
  {
    label: 'DA2 Assigned',
    id: 'da2',
    type: 'labelEditDropdownUser',
    extras: { updateAPI: 'assignScamEnablerV2', userGroup: 'scamEnablersDA2' },
  },
  {
    label: 'Moniker Status',
    type: 'labelEditDropdown',
    id: 'outcome',
    extras: {
      updateAPI: 'updateScamEnablerV2',
      dropdownData: scamEnablerOutcomes,
    },
  },
  {
    label: 'Production Order Sent Date',
    type: 'labelEditDateTime',
    id: 'productionOrderSentDate',
    extras: { updateAPI: 'updateScamEnablerV2' },
    rules: {
      required: 'Date is required',
    },
  },
  {
    label: 'Platform Reference Number',
    type: 'label',
    id: 'platformReferenceNo',
  },
  {
    label: 'Date of Reply',
    type: 'labelEditDateTime',
    id: 'closedAt',
    extras: { updateAPI: 'updateScamEnablerV2' },
    rules: {
      required: 'Date is required',
    },
  },
  { label: 'Review TAT', type: 'label', id: 'reviewTAT' },
  { label: 'Return TAT', type: 'label', id: 'returnTAT' },
];

export const generalUpdateInformationItems: Array<FormItemInterface> = [
  {
    label: 'DA1 Assigned',
    id: 'da1',
    type: 'dropdownUser',
    extras: { userGroup: 'scamEnablersDA1' },
  },
  {
    label: 'DA2 Assigned',
    id: 'da2',
    type: 'dropdownUser',
    extras: { userGroup: 'scamEnablersDA2' },
  },
  {
    label: 'Moniker Status',
    type: 'dropdown',
    id: 'outcome',
    extras: {
      dropdownData: scamEnablerOutcomes,
    },
  },
  {
    label: 'Platform Reference Number',
    type: 'textInput',
    id: 'platformReferenceNo',
  },
  {
    label: 'Production Order Sent Date',
    type: 'dateTime',
    id: 'productionOrderSentDate',
  },
  {
    label: 'Date of Reply',
    type: 'dateTime',
    id: 'closedAt',
  },
  { label: 'Review TAT', type: 'label', id: 'reviewTAT' },
  { label: 'Return TAT', type: 'label', id: 'returnTAT' },
];

export const monikerDetailsItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'label',
    id: 'scamEnablerUserId',
  },
  {
    label: 'URL',
    type: 'link',
    id: 'url',
    extras: {
      isDirectLink: true,
    },
  },
  { label: 'Platform', type: 'label', id: 'platform' },
  {
    label: 'Profile Name',
    type: 'label',
    id: 'profileName',
  },
  {
    label: 'Platform Reference Number',
    type: 'label',
    id: 'platformReferenceNo',
  },
  {
    label: 'Amount of Losses',
    id: 'amountLoss',
    type: 'label',
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'scamEnablerId',
    extras: {
      updateAPI: 'uploadScamEnablerAttachmentV2',
      attachmentPath: '/v1/report/attachment/',
    },
  },
  {
    label: 'Scam Type',
    type: 'label',
    id: 'scamType',
  },
  {
    label: 'Offence',
    type: 'label',
    id: 'offence',
  },
  {
    label: 'Justification',
    type: 'label',
    id: 'useOfScamEnabler',
  },
  {
    label: 'Victim report',
    type: 'label',
    id: 'victimReported',
  },
  {
    label: 'Brief facts of case',
    type: 'label',
    id: 'explanation',
  },
  // {
  //   label: 'Interaction to any post/ads',
  //   type: 'labelEditDropdown',
  //   extras: {
  //     updateAPI: 'updateScamEnablerV2',
  //     dropdownData: ['Yes', 'No'],
  //   },
  //   id: 'interactionPostAds',
  // },
  {
    label: 'No. of victims',
    type: 'label',
    id: 'numberOfVictims',
  },
  {
    label: "Scammer's Contact No.",
    type: 'label',
    id: 'scammerContactNumber',
  },
];

export const monikerUpdateDetailsItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'textInput',
    id: 'scamEnablerUserId',
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    rules: {
      validate: {
        isURL: (value: string) => {
          if (value && !validator.isURL(value)) {
            return 'Invalid website URL. Please provide URL in the correct format e.g. https://website.com or http://website.com';
          }
        },
      },
    },
  },
  {
    label: 'Platform',
    type: 'dropdown',
    id: 'platform',
    extras: { dropdownData: platform },
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
  },
  {
    label: 'Platform Reference Number',
    type: 'textInput',
    id: 'platformReferenceNo',
  },
  {
    label: 'Amount of Losses',
    type: 'textInput',
    id: 'amountLoss',
    placeholder: 'e.g 1000',
    extras: {
      textInputType: 'number',
    },
    rules: {
      required: 'Please enter a number',
      pattern: {
        value: /^[0-9]+$/,
        message: 'Please enter a number',
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'scamEnablerId',
    extras: {
      updateAPI: 'uploadScamEnablerAttachmentV2',
      attachmentPath: '/v1/report/attachment/',
    },
  },
  {
    label: 'Scam Type',
    type: 'dropdown',
    id: 'scamType',
    extras: { dropdownData: scamType },
  },
  {
    label: 'Offence',
    type: 'dropdown',
    id: 'offence',
    extras: { dropdownData: offenceList },
  },
  {
    label: 'Justification',
    type: 'dropdown',
    id: 'useOfScamEnabler',
    extras: { dropdownData: useOfScamEnabler },
  },
  {
    label: 'Victim report',
    type: 'dropdown',
    id: 'victimReported',
    extras: { dropdownData: ['Yes', 'No'] },
  },
  {
    label: 'Brief facts of case',
    type: 'textInput',
    id: 'explanation',
    extras: {
      rows: 4,
    },
  },
  // {
  //   label: 'Interaction to any post/ads',
  //   type: 'dropdown',
  //   id: 'interactionPostAds',
  //   extras: { dropdownData: ['Yes', 'No'] },
  // },
  {
    label: 'No. of victims',
    type: 'textInput',
    id: 'numberOfVictims',
    extras: {
      textInputType: 'number',
    },
  },
  {
    label: "Scammer's Contact Number",
    type: 'telephoneInput',
    id: 'scammerContactNumber',
    placeholder: 'e.g. +6591234567',
    rules: {
      pattern: {
        value: /^\+[0-9]+$/,
        message: 'Please enter a valid phone number. e.g. For Spain, +3512345678',
      },
    },
  },
];

export const monikerfilterGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Platform',
    type: 'dropdownMulti',
    id: 'platforms',
    placeholder: 'Please Select',
    extras: { dropdownData: platform },
    alignment: 'row',
  },
  {
    label: 'Scam Type',
    type: 'dropdownMulti',
    id: 'scam_type',
    placeholder: 'Please Select',
    extras: { dropdownData: scamType },
    alignment: 'row',
  },
  {
    label: 'Date Received',
    type: 'calendarRange',
    id: 'created_date_from,created_date_to',
    placeholder: 'dd/mm/yyyy',
    alignment: 'row',
  },
  {
    label: 'Date of PO Sent',
    type: 'calendarRange',
    id: 'po_sent_date_from,po_sent_date_to',
    placeholder: 'dd/mm/yyyy',
    alignment: 'row',
  },
  {
    label: 'DA1 Assigned',
    type: 'dropdownUser',
    id: 'da1',
    extras: { userGroup: 'scamEnablersDA1' },
    alignment: 'row',
  },
  {
    label: 'DA2 Assigned',
    type: 'dropdownUser',
    id: 'da2',
    extras: { userGroup: 'scamEnablersDA2' },
    alignment: 'row',
  },
  {
    label: 'Moniker Status',
    type: 'dropdownMulti',
    id: 'outcome',
    extras: { dropdownData: scamEnablerOutcomes },
    placeholder: 'Plese select',
    alignment: 'row',
  },
  {
    label: 'Direction',
    type: 'dropdownMulti',
    id: 'direction',
    extras: { dropdownData: ochaDirections },
    placeholder: 'Plese select',
    alignment: 'row',
  },
  {
    label: 'Channel',
    type: 'dropdownMulti',
    id: 'channel',
    extras: { dropdownData: ['Community Guideline', 'OCHA'] },
    placeholder: 'Plese select',
    alignment: 'row',
  },
];

export const monikerDetailsKeyInformation: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'label',
    id: 'scamEnablerUserId',
  },
  {
    label: 'URL',
    type: 'link',
    id: 'url',
    extras: {
      isDirectLink: true,
    },
  },
  { label: 'Platform', type: 'label', id: 'platform' },
  {
    label: 'Profile Name',
    type: 'label',
    id: 'profileName',
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'scamEnablerId',
    extras: {
      updateAPI: 'uploadScamEnablerAttachmentV2',
      attachmentPath: '/v1/report/attachment/',
    },
  },
  {
    label: 'Scam Type',
    type: 'label',
    id: 'scamType',
  },
  {
    label: 'Offence',
    type: 'label',
    id: 'offence',
  },
  {
    label: 'Brief facts of case',
    type: 'label',
    id: 'explanation',
  },
];

export const monikerUpdateDetailsKeyInformation: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'textInput',
    id: 'scamEnablerUserId',
    rules: {
      validate: {
        isNotNil: (value: string) => {
          return validateIdNotNil(value);
        },
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    rules: {
      validate: {
        isURL: (value: string) => {
          if (value && !validator.isURL(value)) {
            return 'Invalid website URL. Please provide URL in the correct format e.g. https://website.com or http://website.com';
          }
        },
      },
    },
  },
  {
    label: 'Platform',
    type: 'dropdown',
    id: 'platform',
    extras: { dropdownData: platform },
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'scamEnablerId',
    extras: {
      updateAPI: 'uploadScamEnablerAttachmentV2',
      attachmentPath: '/v1/report/attachment/',
    },
  },
  {
    label: 'Scam Type',
    type: 'dropdown',
    id: 'scamType',
    extras: { dropdownData: scamType },
  },
  {
    label: 'Offence',
    type: 'dropdown',
    id: 'offence',
    extras: { dropdownData: offenceList },
  },
  {
    label: 'Brief facts of case',
    type: 'textInput',
    id: 'explanation',
    extras: {
      rows: 4,
    },
  },
];

export const monikerDetailsAdditionalInformation: Array<FormItemInterface> = [
  {
    label: 'Amount of Losses',
    id: 'amountLoss',
    type: 'label',
  },
  {
    label: 'Victim report',
    type: 'label',
    id: 'victimReported',
  },
  {
    label: 'No. of victims',
    type: 'label',
    id: 'numberOfVictims',
  },
  {
    label: "Scammer's Contact No.",
    type: 'label',
    id: 'scammerContactNumber',
  },
];

export const monikerUpdateDetailsAdditionalInformation: Array<FormItemInterface> = [
  {
    label: 'Amount of Losses',
    type: 'textInput',
    id: 'amountLoss',
    placeholder: 'e.g 1000',
    extras: {
      textInputType: 'number',
    },
    rules: {
      required: 'Please enter a number',
      pattern: {
        value: /^[0-9]+$/,
        message: 'Please enter a number',
      },
    },
  },
  {
    label: 'Victim report',
    type: 'dropdown',
    id: 'victimReported',
    extras: { dropdownData: ['Yes', 'No'] },
  },
  {
    label: 'No. of victims',
    type: 'textInput',
    id: 'numberOfVictims',
    extras: {
      textInputType: 'number',
    },
  },
  {
    label: "Scammer's Contact Number",
    type: 'telephoneInput',
    id: 'scammerContactNumber',
    placeholder: 'e.g. +6591234567',
    rules: {
      pattern: {
        value: /^\+[0-9]+$/,
        message: 'Please enter a valid phone number. e.g. For Spain, +3512345678',
      },
    },
  },
];
