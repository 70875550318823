import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import DynamicForm from '@components/common/DynamicForm';
import { userFormGeneralItems } from './UserFormConfig';
import {
  useGetRolesQuery,
  useAdminUpdateUserMutation,
  useAdminResetPassword,
  useAdminResetTotpMutation,
} from '@api/userSlice';
import cloneDeep from 'lodash.clonedeep';
import LinearProgress from '@components/common/LinearProgress';
import { User } from '@interface/UsersInterface';
import ConfirmResetPasswordModal from './ConfirmResetPasswordModal';
import ConfirmResetTotpModal from './ConfirmResetTotpModal';
import { CommonButton } from '@components/common/CommonButton';
import { TEAMS } from '@constants/index';
import { getSessionUserFromAccessToken } from '@utils/utils';

export default function AdminUpdateUserComponent({
  user,
  onAdminUpdateUserModalClose,
}: {
  user: User;
  onAdminUpdateUserModalClose: any;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isResettingTotp, setIsResettingTotp] = useState(false);
  const [confirmResetPasswordModalOpen, setConfirmResetPasswordModalOpen] = useState(false);
  const [confirmResetTotpModalOpen, setConfirmResetTotpModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [sessionUser, setSessionUser] = useState<any>({
    teams: [],
  });

  const [adminUpdateUser] = useAdminUpdateUserMutation();
  const adminResetPassword = useAdminResetPassword();
  const [adminResetTotp] = useAdminResetTotpMutation();
  const [createFormItems, setFormItems] = useState(cloneDeep(userFormGeneralItems));
  let { data: roles, isSuccess: isGetRolesSuccess } = useGetRolesQuery({});

  useEffect(() => {
    const getUserData = async () => {
      const sessionUser = await getSessionUserFromAccessToken();
      setSessionUser(sessionUser);
    };
    getUserData();
  }, []);

  const defaultValues = useMemo(() => {
    let formattedRoles: any[] = [];
    if (isGetRolesSuccess) {
      for (let role of roles) {
        let formattedRole = {
          id: role.roleId,
          label: role.roleName,
        };

        formattedRoles.push(formattedRole);
      }
    }

    let formattedTeams: any[] = [];
    for (let team of TEAMS) {
      if (
        !sessionUser.teams ||
        sessionUser.teams.length <= 0 ||
        sessionUser.teams.includes('gasp') ||
        sessionUser.teams.includes(team.id)
      ) {
        let formattedTeam = {
          id: team.id,
          label: team.label,
        };

        formattedTeams.push(formattedTeam);
      }
    }

    let formattedFormItems = cloneDeep(userFormGeneralItems);
    let roleField = formattedFormItems.find((item) => item.id === 'role');
    if (roleField) {
      roleField.extras.dropdownData = formattedRoles;
    }

    let userRole = user.role?.map((item: any) => item.roleId || item) || [];
    user.role = userRole;
    let filteredRoles = roles?.filter((role: any) => user.role.includes(role.roleId));
    filteredRoles = filteredRoles?.map((role: any) => {
      return { id: role.roleId, label: role.roleName };
    });

    let teamsField = formattedFormItems.find((item) => item.id === 'teams');
    if (teamsField) {
      teamsField.extras.dropdownData = formattedTeams;
    }

    let userTeams = user.teams?.map((item: any) => item.teamId || item) || [];
    user.teams = userTeams;
    let filteredTeams = TEAMS?.filter((team: any) => user.teams?.includes(team.id));
    filteredTeams = filteredTeams?.map((team: any) => {
      return { id: team.id, label: team.label };
    });

    let formattedUser = structuredClone(user);
    formattedUser.role = filteredRoles;
    formattedUser.teams = filteredTeams;

    setFormItems(formattedFormItems);

    if (isGetRolesSuccess) {
      setDataLoaded(true);
    }
    return formattedUser;
  }, [roles, isGetRolesSuccess, user, sessionUser]);

  // user
  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  const onSubmit = async (event: any) => {
    setIsSubmitting(true);
    const formValues: any = formMethods.getValues();
    let response: any;
    let formValueRole = formValues.role?.map((role: any) => role.id).sort();
    let formValueTeams = formValues.teams?.map((team: any) => team.id).sort();
    if (
      user.firstName !== formValues.firstName ||
      user.lastName !== formValues.lastName ||
      user.contactNumber !== formValues.contactNumber ||
      user.designation !== formValues.designation ||
      user.role.toString() !== formValueRole.toString() ||
      user.teams.toString() !== formValueTeams.toString() ||
      user.active !== formValues.active ||
      user.rank !== formValues.rank
    ) {
      let params = {
        pathParameters: { user_id: user.userId },
        body: {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          contactNumber: formValues.contactNumber,
          designation: formValues.designation,
          role: formValueRole,
          teams: formValueTeams,
          active: formValues.active,
          rank: formValues.rank,
        },
      };

      response = await adminUpdateUser(params);
    } else {
      console.log('No changes, skip update');
    }

    setIsSubmitting(false);

    if (!response?.error) {
      onAdminUpdateUserModalClose(true);
    }
  };

  const handleResetPassword = async (event: any) => {
    event.preventDefault();
    setConfirmResetPasswordModalOpen(true);
  };

  const handleConfirmResetPasswordModalClose = async (confirm: boolean) => {
    setConfirmResetPasswordModalOpen(false);
    if (confirm) {
      setIsResettingPassword(true);
      let params = {
        pathParameters: { user_id: user.userId },
        body: {},
      };
      await adminResetPassword(params);
      setIsResettingPassword(false);
    }
  };

  const handleResetTotp = async (event: any) => {
    event.preventDefault();
    setConfirmResetTotpModalOpen(true);
  };

  const handleConfirmResetTotpModalClose = async (confirm: boolean) => {
    setConfirmResetTotpModalOpen(false);
    if (confirm) {
      setIsResettingTotp(true);
      let params = {
        pathParameters: { user_id: user.userId },
        body: {},
      };
      await adminResetTotp(params);
      setIsResettingTotp(false);
    }
  };

  return (
    <div className="overflow-auto max-h-screen pb-20">
      {dataLoaded ? (
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <DynamicForm formFields={createFormItems} formMethods={formMethods}></DynamicForm>
          </div>
          <div className="flex flex-col pt-4 text-left">
            <CommonButton
              className="ml-0 w-full flex justify-center"
              variant="primary"
              type="submit"
              title={isSubmitting ? 'Submitting...' : 'Submit'}
              isLoading={isSubmitting}
            />
            <CommonButton
              className="ml-0 mt-5 w-full flex justify-center"
              variant="primary"
              type="submit"
              title={isResettingPassword ? 'Resetting password...' : 'Reset Password'}
              isLoading={isResettingPassword}
              onClick={handleResetPassword}
            />
            <CommonButton
              className="ml-0 mt-5 w-full flex justify-center"
              variant="primary"
              type="submit"
              title={isResettingTotp ? 'Resetting TOTP...' : 'Reset TOTP'}
              isLoading={isResettingTotp}
              onClick={handleResetTotp}
            />
          </div>
        </form>
      ) : (
        <LinearProgress />
      )}
      <ConfirmResetPasswordModal
        user={user}
        isOpen={confirmResetPasswordModalOpen}
        onConfirmResetPasswordModalClose={(confirm: boolean) =>
          handleConfirmResetPasswordModalClose(confirm)
        }
      />
      <ConfirmResetTotpModal
        user={user}
        isOpen={confirmResetTotpModalOpen}
        onConfirmResetTotpModalClose={(confirm: boolean) =>
          handleConfirmResetTotpModalClose(confirm)
        }
      />
    </div>
  );
}
