import DynamicForm from '@components/common/DynamicForm';
import { convertUnixToSingaporeTime, convertYYYYMMDDToUnixTimestamp } from '@utils/utils';
import { useEffect, useState } from 'react';
import { CommonButton } from '@components/common/CommonButton';
import { useForm } from 'react-hook-form';
import { overviewEditForm, overviewItems } from '../ReportFormConfig';
import { useUpdateReportInfoMutation } from '@api/reportsSlice';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';

function formatDataForForm(data: any) {
  return {
    ...data.report,
    // To render submissions
    submissions: {
      reportHistory: data.report.reportHistory,
      reportNumber: data.report.reportNumber,
    },
    createdAt: convertUnixToSingaporeTime(data.report.createdAt),
    referralDate: convertUnixToSingaporeTime(data.report.referralDate),
    divisionAssignedDate: convertUnixToSingaporeTime(data.report.divisionAssignedDate || ''),
  };
}

export default function ReportOverview({ data, refetch }: { data: any; refetch: any }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updateReport] = useUpdateReportInfoMutation();
  const dispatch = useDispatch();

  const formMethods = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (data && data.report) {
      formMethods.reset(data.report);
    }
  }, [data, formMethods]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let dirtyValues = formMethods.formState.dirtyFields;
    console.log('dirtyValues', dirtyValues);
    if (Object.keys(dirtyValues).length === 0) {
      dispatch(
        showAlert({
          type: 'error',
          message: `No Fields to update`,
        }),
      );
    } else {
      let params: any = {};
      for (let key in dirtyValues) {
        if (key === 'divisionAssignedDate') {
          params[key] = convertYYYYMMDDToUnixTimestamp(formMethods.getValues(key));
        } else {
          params[key] = formMethods.getValues(key);
        }
      }
      console.log('params', params);
      let r: any = await updateReport([
        {
          ...params,
          reportId: data.report._id,
        },
      ]);

      if (!r.error) {
        await refetch();
        dispatch(
          showAlert({
            type: 'success',
            title: 'Success',
            message: `Overview updated successfully.`,
          }),
        );
      }
    }

    setIsEditing(false);
    setIsSubmitting(false);
  };

  return (
    <div className="h-full flex flex-col">
      <div className="px-4 pt-4 flex align-middle items-center justify-between text-center top-0 bg-inherit">
        <h1 className="font-bold text-lg text-blue-500 mb-0">Overview</h1>
        <div className="flex flex-row justify-between align-middle items-center h-1/2">
          {isEditing && (
            <div className="flex gap-4 items-center align-middle">
              <CommonButton
                isDisabled={false}
                variant="text"
                title={`Cancel`}
                onClick={() => setIsEditing(false)}
              />
              <CommonButton
                isDisabled={!formMethods.formState.isDirty}
                variant="secondary"
                title={`Save`}
                isLoading={isSubmitting}
                onClick={handleSubmit}
              />
            </div>
          )}
          {!isEditing && (
            <CommonButton
              isDisabled={false}
              variant="secondary"
              title={`Edit`}
              isLoading={isSubmitting}
              onClick={() => setIsEditing(true)}
            />
          )}
        </div>
      </div>

      <div className="px-4 overflow-auto">
        <>
          {data && (
            <>
              {isEditing ? (
                <form>
                  <DynamicForm
                    formFields={overviewEditForm}
                    data={formatDataForForm(data)}
                    // callback={refetch}
                    isEditable={true}
                    formMethods={formMethods}
                  />
                </form>
              ) : (
                <DynamicForm
                  formFields={overviewItems}
                  data={formatDataForForm(data)}
                  isEditable={false}
                  callback={refetch}
                />
              )}
            </>
          )}
          {!data && <DynamicForm formFields={overviewItems} isLoading={true} />}
        </>
      </div>
    </div>
  );
}
