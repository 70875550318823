import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetPaynowByIdQuery } from '@api/paynowSlice';
import { getSessionUserFromAccessToken } from '@utils/utils';

// REFER TO useMonikersDetailedPage TO POPULATE
export const usePaynowDetailedPage = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const reportId = searchParams.get('reportId');
  const [myUser, setMyUser] = useState({
    userId: '',
    firstName: '',
    lastName: '',
  });

  const { refetch, data, isSuccess, isError, isLoading, isFetching } = useGetPaynowByIdQuery({
    id: params.paynowId,
  });

  useEffect(() => {
    getSessionUserFromAccessToken().then((userSession: any) => {
      setMyUser(userSession);
    });
  }, []);

  return {
    params,
    reportId,
    refetch,
    data,
    isSuccess,
    isError,
    isLoading,
    isFetching,
    myUser,
  };
};
