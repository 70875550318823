import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { RelatedReportInterface } from '@interface/ScamEnablerInterface';
import ModalLayout from '@components/layouts/ModalLayout';
import DynamicForm from '@components/common/DynamicForm';
import { overviewItems, reportItemsGeneral, assessmentFindingsItems } from '../ReportFormConfig';
import { useScamEnablers } from '@api/scamEnablerSlice';

interface MonikerRelatedReportModalProps {
  relatedReports: Array<RelatedReportInterface>;
  userId: string;
  platform: string;
}

const MonikerRelatedReportModal: React.FC<MonikerRelatedReportModalProps> = ({
  relatedReports,
  userId,
  platform,
}) => {
  const [open, setOpen] = useState(false);
  const [showRelatedReport, setShowRelatedReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseRelatedReport = () => setShowRelatedReport(false);
  const handleOpenRelatedReport = (id: string) => {
    setSelectedReport(id);
    setShowRelatedReport(true);
  };

  const { relatedScamEnablerData, relatedScamEnablerFields, data } = useScamEnablers(
    selectedReport,
    userId,
    platform,
  );

  return (
    <>
      <div className="w-2/3 flex flex-row items-center gap-2">
        <p>{relatedReports.length}</p>
        {relatedReports.length > 0 && (
          <button className="underline color-blue" onClick={handleOpen}>
            View Details
          </button>
        )}
      </div>
      <Modal open={open} onClose={handleClose}>
        <ModalLayout
          title={`Related Report`}
          handleCloseModal={handleClose}
          alignment="right"
          size="full"
        >
          <p className="font-bold pb-2">{`${platform} (${userId})`}</p>
          <ul>
            {relatedReports.map((field, index) => (
              <li key={index}>
                <button
                  key={index}
                  className="underline text-blue-500 py-1"
                  onClick={() => handleOpenRelatedReport(field.reportId)}
                >
                  {`${field.reportNumber}`}
                </button>
              </li>
            ))}
          </ul>
        </ModalLayout>
      </Modal>
      {data && (
        <Modal open={showRelatedReport} onClose={handleCloseRelatedReport}>
          <ModalLayout
            title={data?.report.reportNumber}
            handleCloseModal={() => {
              handleCloseRelatedReport();
              handleClose();
            }}
            alignment="right"
            size="full"
            link={`/admin/reports/${data.report.reportId}`}
            linkText={'View Report'}
            isNestedLayout={true}
          >
            <h2 className="font-bold pb-2">{`${userId}`}</h2>
            <h3 className="text-blue-500 font-bold pt-4">Overview</h3>
            <DynamicForm formFields={overviewItems} data={data} key="overview" />
            <h3 className="text-blue-500 font-bold pt-4 flex flex-row align-bottom gap-2">
              Moniker Information
            </h3>
            <DynamicForm
              formFields={relatedScamEnablerFields}
              data={relatedScamEnablerData}
              key={userId}
            />

            <h3 className="text-blue-500 font-bold pt-4">Report Information</h3>
            <DynamicForm
              formFields={reportItemsGeneral}
              data={data.report.reportHistory[0]}
              key="reportInformation"
            />
            <h3 className="text-blue-500 font-bold pt-4">Assessment Findings</h3>
            {
              <DynamicForm
                formFields={assessmentFindingsItems}
                data={data}
                key={'assessmentFindings'}
              />
            }
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default MonikerRelatedReportModal;
