import { FormItemInterface } from '@interface/dynamicFormInterface';
import { designations } from '@interface/constant';

export const userFormGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Email',
    type: 'textInput',
    id: 'email',
    placeholder: 'user@spf.gov.sg',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid SPF email',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@(spf|gt\.tech|tech).gov.sg$/i,
        message: 'A valid SPF email address is required eg. user@spf.gov.sg.',
      },
    },
  },
  {
    label: 'First Name',
    type: 'textInput',
    id: 'firstName',
    placeholder: 'Daniel',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid First Name',
      // pattern: {
      //   value: /^[a-zA-Z0-9._%+-]+@tech\.gov\.sg$/,
      //   message: "A valid SPF email address is required eg. user@spf.gov.sg.",
      // },
    },
  },
  {
    label: 'Last Name',
    type: 'textInput',
    id: 'lastName',
    placeholder: 'Tan',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid First Name',
      // pattern: {
      //   value: /^\d{6}$/,
      //   message: "Invalid verification code. Please enter verification code in the correct format eg. 123456",
      // },
    },
  },
  {
    label: 'Phone No.',
    id: 'contactNumber',
    type: 'telephoneInput',
    alignment: 'column',
  },
  {
    label: 'Rank',
    type: 'textInput',
    id: 'rank',
    alignment: 'column',
  },
  {
    label: 'Designation',
    id: 'designation',
    type: 'dropdown',
    placeholder: 'Please select designation',
    extras: { dropdownData: designations },
    alignment: 'column',
    rules: {
      required: 'Please select a designation',
    },
  },
  {
    label: 'Role',
    type: 'dropdownMulti',
    id: 'role',
    placeholder: 'Please select role',
    extras: { dropdownData: [] },
    alignment: 'column',
  },
  {
    label: 'Teams',
    type: 'dropdownMulti',
    id: 'teams',
    placeholder: 'Please select teams',
    extras: { dropdownData: [] },
    alignment: 'column',
  },
];
