import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormLogoutMutation } from '@api/sessionSlice';
import { CommonButtonGroup } from './CommonButton';

const FormHeaderComponent = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  const [formLogout] = useFormLogoutMutation();
  const [isSignoutLoading, setIsSignoutLoading] = useState(false);

  function clearForm() {
    window.location.reload();
  }

  async function logout() {
    try {
      setIsSignoutLoading(true);
      let response: any = await formLogout({});
      setIsSignoutLoading(false);

      if (!response.error) {
        navigate('/form-login');
      }
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <>
      <div className="w-full h-16 flex justify-between bg-grey-200 items-center rounded-t-lg">
        <div>
          <h1 className={`font-bold text-grey-500 p-6 text-lg`}>{title}</h1>
        </div>
        <div className="flex justify-end items-center mr-10">
          <CommonButtonGroup
            dropdownOptions={[
              {
                key: 'clearForm',
                title: 'Clear form',
                disabled: false,
                onClick: () => {
                  clearForm();
                },
              },
              {
                key: 'logout',
                title: 'Logout',
                disabled: false,
                isLoading: isSignoutLoading,
                onClick: () => {
                  logout();
                },
              },
            ]}
            isDisabled={false}
            className='bg-white hover:bg-blue-200 active:bg-blue-300 hover:text-blue-500'
            variant="secondary"
            icon="hamburger"
            title=""
          />
        </div>
      </div>
    </>
  );
};

export default FormHeaderComponent;
