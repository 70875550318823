import { IconButton } from './CommonButton';
import { useForm } from 'react-hook-form';
import { useState, useCallback, useEffect } from 'react';
import { assignUserParameters, useScamEnablerAssign } from '@api/scamEnablerSlice';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import DropdownUser from './DropdownUser';

type EditLabelDropdownProps = {
  field: FormItemInterface;
  data: any;
  callback?: () => void;
  isEditable?: boolean;
};

const EditLabelDropdownUser: React.FC<EditLabelDropdownProps> = ({
  field,
  data,
  callback,
  isEditable,
}) => {
  const [selectedOption, setSelectedOption] = useState(field.value);
  const { control, formState, setValue, getValues } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const assignUser = useScamEnablerAssign();

  const handleSubmit = useCallback(async () => {
    let currentOutcome = getValues(field.id);
    let response: any;
    // if (currentOutcome !== selectedOption) {
    //   setIsEditing(false);
    //   return true;
    // }
    setIsLoading(true);
    if (field.extras.updateAPI === 'assignScamEnablerV2') {
      let newAssignParams: Array<assignUserParameters> = [];

      newAssignParams.push({
        scamEnablerId: data._id,
        userId: currentOutcome.id,
        role: field.id,
      });
      response = await assignUser(newAssignParams);
    }

    if (response && !response.error) {
      setIsEditing(false);
      if (callback) {
        callback();
      }
    }
    setIsLoading(false);
  }, [field, selectedOption, getValues, data, callback]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setSelectedOption(field.value);
  }, [field.value]);

  const handleEdit = useCallback(() => setIsEditing(true), []);

  useEffect(() => {
    setSelectedOption(field.value);
  }, [field.value]);
  return (
    <div className="flex flex-row flex-grow gap-2 items-center justify-start ">
      {isEditing ? (
        <form className="flex flex-row flex-grow gap-2 items-center justify-center">
          <DropdownUser
            id={field.id}
            control={control}
            setValue={setValue}
            userGroup={field.extras.userGroup}
          >
            {' '}
            <IconButton
              isDisabled={false}
              icon="tick"
              onClick={handleSubmit}
              isLoading={isLoading}
              type="button"
            />
          </DropdownUser>

          <IconButton
            isDisabled={false}
            icon="cross"
            onClick={handleCancel}
            isLoading={false}
            type="button"
          />
        </form>
      ) : (
        <div className="flex flex-row gap-2 items-center justify-center">
          <p>{selectedOption}</p>

          {isEditable && (
            <IconButton isDisabled={false} icon="edit" onClick={handleEdit} isLoading={false} />
          )}
        </div>
      )}
    </div>
  );
};

export default EditLabelDropdownUser;
