import { ReportAccordion } from './components/ReportAccordion';

function sortReportBreakdown(reportsDataArr: any, mainReportId?: string) {
  if (reportsDataArr.filter((reportData: any) => reportData.reportId === mainReportId).length > 0) {
    let mainReport = reportsDataArr.filter((report: any) => report.reportId === mainReportId)[0];
    let otherReports = reportsDataArr.filter((report: any) => report.reportId !== mainReportId);

    return [mainReport, ...otherReports];
  }

  return reportsDataArr;
}

export default function TransactionDetails({
  data,
  mainReportId,
  refetch,
  isAssignedToMe,
}: {
  data: any;
  mainReportId?: string;
  refetch: any;
  isAssignedToMe: boolean;
}) {
  // let transactions = useMemo(() => {
  //   return deconflictTransactions(data);
  // }, [data]);

  return (
    <div className="max-h-[60vh] overflow-auto">
      {/* Breakdown by reports */}
      <h3 className="text-blue-400 text-sm font-bold mb-2">Transactions by Reports</h3>

      {data?.financialInstitute?.updatedTransactions &&
        sortReportBreakdown(data.financialInstitute.updatedTransactions, mainReportId).map(
          (reportData: any, index: number) => (
            <ReportAccordion
              key={index}
              reportData={reportData}
              isMain={reportData.reportId === mainReportId}
              financialInstitute={data.financialInstitute}
              refetch={refetch}
              isAssignedToMe={isAssignedToMe}
            />
          ),
        )}
      {!data && <div className="animate-pulse bg-grey-300 w-full h-40 rounded-lg"></div>}
    </div>
  );
}
