export const whatsappColumns = [
  {
    title: 'Whatsapp Number',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    render: (text: string, record: any, index: number) => {
      return <p>{record.scamEnablerUserId},</p>;
    },
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
  },
  {
    title: 'Brief facts of case',
    dataIndex: 'explanation',
    key: 'explanation',
  },
];
