import RelatedReportModal from '@pages/portfolios/monikers/modals/RelatedReportModal';
import { convertUnixToSingaporeTime } from '@utils/utils';
import cloneDeep from 'lodash.clonedeep';
import { useState } from 'react';

export function PaynowDetailsRelatedReports({
  data,
  isLoading,
}: {
  data: any;
  isLoading: boolean;
}) {
  const [selectedReport, setSelectedReport] = useState('');
  const [relatedReportOpen, setRelatedReportOpen] = useState(false);

  return (
    <div>
      <div className="flex flex-row justify-between align-middle mb-4">
        <div className="w-100 flex flex-row items-center align-middle gap-4">
          <h1 className="font-bold text-lg text-blue-500">Related Reports</h1>
        </div>
      </div>

      {/* <div className="max-h-[30vh] overflow-auto mt-5 flex flex-col gap-2">
        {data &&
          data.map((report: { createdAt: number; reportId: string; reportNumber: string }) => (
            <div className="bg-white rounded-lg flex flex-row items-center justify-between p-4 w-full overflow-hidden">
              <p className="text-blue-500 font-bold text-sm">{report.reportNumber}</p>
              <p className="text-grey-400 text-xs">
                {convertUnixToSingaporeTime(report.createdAt)}
              </p>
            </div>
          ))}
        {data &&
          data.map((report: { createdAt: number; reportId: string; reportNumber: string }) => (
            <div className="bg-white rounded-lg flex flex-row items-center justify-between p-4 w-full overflow-hidden">
              <p className="text-blue-500 font-bold text-sm">{report.reportNumber}</p>
              <p className="text-grey-400 text-xs">
                {convertUnixToSingaporeTime(report.createdAt)}
              </p>
            </div>
          ))}
        {data &&
          data.map((report: { createdAt: number; reportId: string; reportNumber: string }) => (
            <div className="bg-white rounded-lg flex flex-row items-center justify-between p-4 w-full overflow-hidden">
              <p className="text-blue-500 font-bold text-sm">{report.reportNumber}</p>
              <p className="text-grey-400 text-xs">
                {convertUnixToSingaporeTime(report.createdAt)}
              </p>
            </div>
          ))}
        {data &&
          data.map((report: { createdAt: number; reportId: string; reportNumber: string }) => (
            <div className="bg-white rounded-lg flex flex-row items-center justify-between p-4 w-full overflow-hidden">
              <p className="text-blue-500 font-bold text-sm">{report.reportNumber}</p>
              <p className="text-grey-400 text-xs">
                {convertUnixToSingaporeTime(report.createdAt)}
              </p>
            </div>
          ))}
        {data &&
          data.map((report: { createdAt: number; reportId: string; reportNumber: string }) => (
            <div className="bg-white rounded-lg flex flex-row items-center justify-between p-4 w-full overflow-hidden">
              <p className="text-blue-500 font-bold text-sm">{report.reportNumber}</p>
              <p className="text-grey-400 text-xs">
                {convertUnixToSingaporeTime(report.createdAt)}
              </p>
            </div>
          ))}
        {data &&
          data.map((report: { createdAt: number; reportId: string; reportNumber: string }) => (
            <div className="bg-white rounded-lg flex flex-row items-center justify-between p-4 w-full overflow-hidden">
              <p className="text-blue-500 font-bold text-sm">{report.reportNumber}</p>
              <p className="text-grey-400 text-xs">
                {convertUnixToSingaporeTime(report.createdAt)}
              </p>
            </div>
          ))}
      </div> */}

      {data?.reports.length > 0 && !isLoading && (
        <div className="overflow-auto max-h-[30vh]">
          {cloneDeep(data.reports)
            .sort((a: any, b: any) => b.createdAt - a.createdAt)
            .map((report: any, index: number) => {
              return (
                <div className="relative pb-2 overflow-hidden" key={index}>
                  <button
                    key={index}
                    className="bg-white rounded-lg flex flex-row items-center justify-between p-4 w-full overflow-hidden"
                    onClick={() => {
                      setRelatedReportOpen(true);
                      setSelectedReport(report.reportId);
                    }}
                  >
                    <p className="text-blue-500 font-bold text-sm">{report.reportNumber}</p>
                    <p className="text-grey-400 text-xs">
                      {convertUnixToSingaporeTime(report.createdAt)}
                    </p>
                  </button>
                </div>
              );
            })}
          <RelatedReportModal
            isOpen={relatedReportOpen}
            handleClose={() => setRelatedReportOpen(false)}
            reportId={selectedReport}
          />
        </div>
      )}
      <div>
        {isLoading && <div className="animate-pulse bg-grey-300 w-full h-64 rounded-lg"></div>}
      </div>
    </div>
  );
}
