import Modal from '@mui/material/Modal';
import ModalLayout from '@components/layouts/ModalLayout';
import {
  useAssignProactiveScamEnablersV2Mutation,
  useGetProactiveScamEnablersByIdV2Query,
  useUpdateProactiveScamEnablersV2Mutation,
} from '@api/scamEnablerSlice';
import DynamicForm from '@components/common/DynamicForm';
import { convertMsToHoursMinutes, extractUserIdFromUrl } from '@utils/utils';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CommonButton } from '@components/common/CommonButton';
import dayjs from 'dayjs';
import {
  monikerEditItems,
  monikerItems,
  overviewEditItems,
  overviewItems,
} from '../ProactiveMonikersFormConfig';
import ViewProactiveActivityLogsComponent from '../ViewProactiveActivityLogsComponent';
import { any, delay } from 'bluebird';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';

interface ProactiveDetailedViewModalProps {
  record: any;
  assignOpen: boolean;
  onAssignClose: () => void;
  callback: () => void;
}

const ProactiveDetailedViewModal: React.FC<ProactiveDetailedViewModalProps> = ({
  record,
  assignOpen,
  onAssignClose,
  callback,
}) => {
  const { data, error, isLoading, isSuccess, refetch } = useGetProactiveScamEnablersByIdV2Query(
    {
      id: record._id,
    },
    {
      refetchOnFocus: true,
      skip: !record,
    },
  );
  const [updateProactiveScamEnablers] = useUpdateProactiveScamEnablersV2Mutation();
  const [assignUser] = useAssignProactiveScamEnablersV2Mutation();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showActivityLogs, setShowActivityLogs] = useState(true);
  const defaultValues = useMemo(() => {
    const initialValues: any = {};

    if (data?.proactiveScamEnabler) {
      [...overviewEditItems, ...monikerEditItems].forEach((item) => {
        if (
          item.type === 'dateTime' &&
          (item?.extras?.showTime || item?.extras?.showTime === undefined) &&
          data.proactiveScamEnabler[item.id]
        ) {
          initialValues[item.id] = dayjs(data.proactiveScamEnabler[item.id]).format(
            'YYYY-MM-DDTHH:mm',
          );
        } else if (
          item.type === 'calendar' ||
          (item.type === 'dateTime' &&
            !item?.extras?.showTime &&
            data.proactiveScamEnabler[item.id])
        ) {
          initialValues[item.id] = dayjs(data.proactiveScamEnabler[item.id]).format('YYYY-MM-DD');
        } else if (
          item.type === 'multiTelephoneInput' &&
          data.proactiveScamEnabler[item.id] &&
          typeof data.proactiveScamEnabler[item.id] === 'string'
        ) {
          initialValues[item.id] = [data.proactiveScamEnabler[item.id]];
        } else if (item.id === 'assigned' && data.proactiveScamEnabler?.assignment?.length > 0) {
          initialValues[item.id] = { id: data.proactiveScamEnabler.assignment[0].userId };
        } else if (item.id === 'verifiedProfile') {
          initialValues[item.id] = data.proactiveScamEnabler?.verifiedProfile ? 'true' : 'false';
        } else if (
          item.type === 'dynamicTable' &&
          data.proactiveScamEnabler?.[item.id]?.length > 0
        ) {
          console.log('data.proactiveScamEnabler[item.id]', data.proactiveScamEnabler[item.id]);
          initialValues[item.id] = data.proactiveScamEnabler[item.id];
        } else {
          initialValues[item.id] = data.proactiveScamEnabler[item.id] || ''; // Fallback to empty string if data[item.id] is undefined
        }
      });
    }
    return initialValues;
  }, [data]);

  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    formMethods.reset(defaultValues); // This will reset the form with new default values
  }, [defaultValues, formMethods, data]);

  const processReport = (data: any) => {
    if (!data) return {};
    let tempData = cloneDeep(data);
    let submittedAt = data.submittedAt;
    if (submittedAt) {
      let reviewTATObject = convertMsToHoursMinutes(submittedAt - tempData.createdAt);
      tempData.reviewTAT = reviewTATObject.hours + ' hours ' + reviewTATObject.minutes + ' minutes';

      if (tempData.closedAt) {
        let returnTATObject = convertMsToHoursMinutes(tempData.closedAt - submittedAt);
        tempData.returnTAT =
          returnTATObject.hours + ' hours ' + returnTATObject.minutes + ' minutes';
      }
    }
    if (tempData.scammerContactNumber && typeof tempData.scammerContactNumber === 'string') {
      tempData.scammerContactNumber = [tempData.scammerContactNumber];
    }
    if (tempData?.assignment) {
      tempData.assigned = tempData.assignment[0].userName;
    }

    return tempData;
  };

  const checkisDisabled = () => {
    let isDisabled = isEditing ? !formMethods.formState.isDirty : false;
    // console.log('formMethods.formState.isValid', formMethods.formState.isValid);

    return isDisabled;
  };
  const handleSubmit = async () => {
    try {
      if (isEditing) {
        setIsSubmitting(true);
        let values = formMethods.getValues();
        let dirtyFields = formMethods.formState.dirtyFields;
        let tempParams: any = {};
        let isUpdated = false;
        for (const key in dirtyFields) {
          if (!dirtyFields[key]) continue;
          tempParams[key] = values[key];

          if (key === 'url' && extractUserIdFromUrl(values[key], 'Facebook').isValidUrl) {
            tempParams['scamEnablerUserId'] = extractUserIdFromUrl(values[key], 'Facebook').userId;
          } else if (key === 'submittedAt' || key === 'closedAt') {
            tempParams[key] = new Date(values[key]).getTime();
          } else if (key === 'facebookCreatedAt') {
            tempParams[key] = new Date(values[key]).setHours(0, 0, 0, 0);
          } else if (key === 'numberOfAds') {
            tempParams[key] = parseInt(values[key]);
          } else if (key === 'assigned') {
            let response: any = await assignUser([
              { proactiveScamEnablerId: data.proactiveScamEnabler._id, userId: values[key].id },
            ]);
            if (!response.error) {
              isUpdated = true;
            }
          } else if (key === 'attachmentFileNames' && tempParams[key]?.length > 0) {
            tempParams[key] = tempParams[key]
              .map((row: string) => (row ? row.trim() : ''))
              .filter((row: string) => row !== '');

            if (tempParams[key].length === 0) {
              delete tempParams[key];
            }
          } else if (key === 'scammerContactNumber' && tempParams[key]?.length > 0) {
            tempParams[key] = tempParams[key]
              .map((row: string) => (row ? row.trim() : '+65'))
              .filter((row: string) => row && row !== '+65');

            if (tempParams[key].length === 0) {
              delete tempParams[key];
            }
          } else if (key === 'redirectionUrls' && tempParams[key]?.length > 0) {
            tempParams[key] = tempParams[key].filter((item: any) => {
              return item.url || item.attachments?.length > 0;
            });
            tempParams[key].forEach((item: any) => {
              if (item.url.trim() === '' && item.attachments.length > 0) {
                dispatch(
                  showAlert({
                    type: 'error',
                    message: 'URL is required if attachments are present',
                  }),
                );
                throw false;
              }
            });
            tempParams[key] = tempParams[key].map((redirectionUrl: any) => {
              redirectionUrl.attachments = redirectionUrl.attachments.map((attachment: any) => {
                if (typeof attachment === 'string') {
                  attachment = { fileName: attachment };
                }
                return attachment;
              });
              return redirectionUrl;
            });
          }
        }

        //if tempParams only has key assigned, no need to update
        if (!(Object.keys(tempParams).length === 1 && tempParams.assigned)) {
          tempParams['_id'] = data.proactiveScamEnabler._id;

          let response: any = await updateProactiveScamEnablers([tempParams]);

          isUpdated = true;
        }
        if (isUpdated) {
          await refetch();
          formMethods.reset(defaultValues);
          setShowActivityLogs(!showActivityLogs);
          if (callback) callback();
        }
        setIsSubmitting(false);
      }
      setIsEditing(!isEditing);
    } catch (error) {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      {data && (
        <Modal open={assignOpen} onClose={onAssignClose}>
          <ModalLayout
            title={''}
            handleCloseModal={() => {
              onAssignClose();
            }}
            alignment="right-large"
            size="full"
            overflowClass="none"
          >
            <div className="flex justify-between pb-4 -mt-20 max-h-[80vh] mr-10">
              <h1 className="text-blue-500 bold">{data?.proactiveScamEnabler.profilename}</h1>
              <div className="flex flex-end gap-4">
                {isEditing && (
                  <CommonButton
                    isDisabled={false}
                    variant="secondary"
                    title={'Cancel'}
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  />
                )}

                <CommonButton
                  isLoading={isSubmitting}
                  isDisabled={checkisDisabled()}
                  variant="primary"
                  title={isEditing ? 'Save' : 'Edit'}
                  onClick={handleSubmit}
                  allowedPermissions={['portfolios.proactivescamenablers.update']}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="w-[35%]">
                <h3 className="text-blue-400 font-bold">Activity Logs</h3>
                <div className="pl-6 pt-4 mt-2 overflow-y-scroll overflow-x-hidden h-[75vh] ">
                  <ViewProactiveActivityLogsComponent
                    proactiveScamEnablerId={record._id}
                    activityLogsModalOpen={showActivityLogs}
                  />
                </div>
              </div>
              <span className="w-0.5 h-[75vh] flex align-middle bg-grey-300"></span>
              <div className="w-[60%]">
                <div className="overflow-auto max-h-[80vh]">
                  <h3 className="text-blue-400 font-bold">General Information</h3>
                  {data?.proactiveScamEnabler && !isEditing && (
                    <DynamicForm
                      formFields={overviewItems}
                      data={processReport(data.proactiveScamEnabler)}
                      callback={refetch}
                    />
                  )}
                  {data?.proactiveScamEnabler && isEditing && (
                    <DynamicForm
                      formFields={overviewEditItems}
                      data={processReport(data.proactiveScamEnabler)}
                      callback={refetch}
                      formMethods={formMethods}
                    />
                  )}
                  <h3 className="text-blue-400 font-bold pt-4 flex flex-row align-bottom gap-2">
                    Moniker Details
                  </h3>
                  {data?.proactiveScamEnabler && !isEditing && (
                    <DynamicForm
                      formFields={monikerItems}
                      data={processReport(data.proactiveScamEnabler)}
                      isEditable={isEditing}
                      formMethods={formMethods}
                      callback={refetch}
                    />
                  )}
                  {data?.proactiveScamEnabler && isEditing && (
                    <DynamicForm
                      formFields={monikerEditItems}
                      data={processReport(data.proactiveScamEnabler)}
                      formMethods={formMethods}
                      isEditable={isEditing}
                      callback={refetch}
                    />
                  )}
                  <h3 className="text-blue-400 font-bold pt-4 flex flex-row align-bottom gap-2">
                    Related Reports
                  </h3>
                </div>
                {data.reports &&
                  data?.reports.length > 0 &&
                  data.reports.map((report: any, index: number) => (
                    <div key={index}>
                      <p className="text-black pt-4 flex flex-row align-bottom gap-2">
                        {report.reportNumber}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default ProactiveDetailedViewModal;
