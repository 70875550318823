import { CommonButton, IconButton } from '@components/common/CommonButton';
import { useEffect, useMemo, useState } from 'react';
import ActivityLog from '@pages/portfolios/monikers/MonikersDetailedPage/ActivityLog/ActivityLog';
import DynamicForm from '@components/common/DynamicForm';
import {
  overviewFormItemsDates,
  overviewEditFormItemsFir,
  overviewFormItemsGeneral,
  overviewInformationItemsDates,
  overviewInformationItemsFir,
  overviewInformationItemsGeneral,
} from '../BankFormConfig';
import { useForm } from 'react-hook-form';
import { useUpdateFinancialInstituteMutation } from '@api/financialInstituteSlice';
import { showAlert } from '@store/alertSlice';
import { useDispatch } from 'react-redux';
import { convertMsToHoursMinutes, convertUnixToDynamicFormDateTime } from '@utils/utils';
import cloneDeep from 'lodash.clonedeep';
import { useLazyGetReportInfoQuery } from '@api/reportsSlice';

function transformData(data: any) {
  let newObj = cloneDeep(data);

  newObj.fir = {
    link: `/admin/Reports/${data?.fir?.reportId}`,
    display: data?.fir?.reportNumber || '-',
  };
  newObj.personOfInterestCentric = data?.fir?.personOfInterestCentric || false;
  // newObj['firDO'] = (data?.firDO?.firstName || '') + ' ' + (data?.firDO?.lastName || '');

  let productionOrderSentDate =
    newObj?.productionOrderSentDate || newObj?.generateProductionOrderAt;

  if (productionOrderSentDate) {
    newObj.productionOrderSentDate = productionOrderSentDate;
    if (newObj.referralDate) {
      let reviewTATObject = convertMsToHoursMinutes(productionOrderSentDate - newObj.referralDate);
      let days = Math.round(reviewTATObject.hours / 24);
      newObj.reviewTAT =
        days +
        ' days ' +
        (reviewTATObject.hours - days * 24) +
        ' hours ' +
        reviewTATObject.minutes +
        ' minutes';
    }

    if (newObj.bankAccountFrozenDate) {
      let returnTATFrozen = convertMsToHoursMinutes(
        newObj.bankAccountFrozenDate - productionOrderSentDate,
      );
      let days = Math.round(returnTATFrozen.hours / 24);
      newObj.returnTATFrozen =
        days +
        ' days ' +
        (returnTATFrozen.hours - days * 24) +
        ' hours ' +
        returnTATFrozen.minutes +
        ' minutes';
    }

    if (newObj.bankDetailsRetrievedDate) {
      let returnTATDetails = convertMsToHoursMinutes(
        newObj.bankDetailsRetrievedDate - productionOrderSentDate,
      );
      let days = Math.round(returnTATDetails.hours / 24);
      newObj.returnTATDetails =
        days +
        ' days ' +
        (returnTATDetails.hours - days * 24) +
        ' hours ' +
        returnTATDetails.minutes +
        ' minutes';
    }
  }

  return newObj;
}

export function BankDetailedOverview({
  data,
  refetch,
  isAssignedToMe,
}: {
  data: any;
  refetch: any;
  isAssignedToMe: boolean;
}) {
  const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updateFinancialInstitute] = useUpdateFinancialInstituteMutation();
  const [getReports] = useLazyGetReportInfoQuery();

  const dispatch = useDispatch();
  const [FIRFields, setFIRFields] = useState<any>();

  const defaultValues = useMemo(() => {
    if (data) {
      let newData = cloneDeep(data);
      newData.bankAccountFrozenDate = convertUnixToDynamicFormDateTime(
        newData.bankAccountFrozenDate,
      );
      newData.bankDetailsRetrievedDate = convertUnixToDynamicFormDateTime(
        newData.bankDetailsRetrievedDate,
      );
      newData.createdAt = convertUnixToDynamicFormDateTime(newData.createdAt);
      newData.productionOrderSentDate = convertUnixToDynamicFormDateTime(
        newData.productionOrderSentDate,
      );
      newData.referralDate = convertUnixToDynamicFormDateTime(newData.referralDate);
      newData.personOfInterestCentric =
        newData?.fir?.personOfInterestCentric === true ? true : false;

      newData.fir = {
        id: newData?.fir?.reportId || '',
        label: newData?.fir?.reportNumber || '',
      };

      return newData;
    }
  }, [data]);

  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const personOfInterestCentric = formMethods.watch('personOfInterestCentric');

  useEffect(() => {
    if (data?.updatedTransactions) {
      let currentReportId = formMethods.getValues('fir')?.id;
      if (personOfInterestCentric === false) {
        const reportExists = data.updatedTransactions.some(
          (item: any) => item.reportId === currentReportId,
        );

        if (!reportExists) {
          formMethods.setValue('fir', { id: '', label: '' });
        }
      }

      const updatedFormFields = cloneDeep(
        overviewEditFormItemsFir(
          data.updatedTransactions.map((item: any) => {
            return {
              id: item.reportId,
              label: item.reportNumber,
            };
          }),
          getReports,
          personOfInterestCentric === true,
        ),
      );
      setFIRFields(updatedFormFields);
    }
  }, [personOfInterestCentric, data]);

  useEffect(() => {
    if (data) {
      formMethods.reset(defaultValues);
    }
  }, [data, defaultValues, formMethods]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let dirtyFields = formMethods.formState.dirtyFields;
    let values = formMethods.getValues();
    let params: any = { financialInstituteId: data._id };
    for (let key in dirtyFields) {
      if (
        key === 'productionOrderSentDate' ||
        key === 'bankDetailsRetrievedDate' ||
        key === 'bankAccountFrozenDate' ||
        key === 'referralDate'
      ) {
        params[key] = new Date(values[key]).getTime();
      } else if (key === 'fir' || key === 'personOfInterestCentric') {
        params['fir'] = {
          reportId: values['fir']?.id || '',
          reportNumber: values['fir']?.label || '',
          personOfInterestCentric: values['personOfInterestCentric'] === true ? true : false,
        };
      } else {
        if (typeof values[key] === 'string') {
          params[key] = values[key].trim();
        } else {
          params[key] = values[key];
        }
        params[key] = values[key];
      }
    }
    let r: any = await updateFinancialInstitute(params);

    if (!r.error) {
      await refetch();

      dispatch(
        showAlert({
          type: 'success',
          title: 'Success',
          message: `Overview updated successfully.`,
        }),
      );
    }
    setIsEditing(false);
    setIsSubmitting(false);
  };

  return (
    <div className="flex-row box-border h-full bg-grey-100 rounded-lg p-6">
      <div className="flex flex-row justify-between align-middle">
        <div className="w-100 flex flex-row items-center align-middle gap-4">
          <h1 className="font-bold text-lg text-blue-500">Overview</h1>

          {isAssignedToMe && (
            <>
              {/* <IconButton
                isDisabled={false}
                icon="activity_log"
                type="button"
                onClick={() => setIsActivityLogModalOpen(true)}
                isLoading={false}
              /> */}
              {data?.accountNumber && isActivityLogModalOpen && (
                <ActivityLog
                  isOpen={isActivityLogModalOpen}
                  handleClose={() => setIsActivityLogModalOpen(false)}
                  id={data.account?.accountNum}
                />
              )}
            </>
          )}
        </div>
        {isAssignedToMe && isEditing && (
          <div className="flex gap-4">
            <CommonButton
              isDisabled={false}
              variant="text"
              title={`Cancel`}
              onClick={() => setIsEditing(false)}
            />
            <CommonButton
              isDisabled={!formMethods.formState.isDirty}
              variant="secondary"
              title={`Save`}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            />
          </div>
        )}
        {isAssignedToMe && !isEditing && (
          <CommonButton
            isDisabled={false}
            variant="secondary"
            title={`Edit`}
            isLoading={isSubmitting}
            onClick={() => setIsEditing(true)}
          />
        )}
      </div>

      <div className="max-h-[75vh] pb-4 overflow-auto mt-5">
        <h3 className="font-bold text-blue-400 text-sm">General Information</h3>
        {data && (
          <>
            {isEditing ? (
              <DynamicForm
                formFields={overviewFormItemsGeneral}
                isLoading={false}
                isEditable={true}
                formMethods={formMethods}
              />
            ) : (
              <>
                <DynamicForm
                  formFields={overviewInformationItemsGeneral}
                  data={{
                    ...transformData(data),
                    dutyOfficer: `${(data.dutyOfficer && data.dutyOfficer.firstName) || ''} ${(data.dutyOfficer && data.dutyOfficer.lastName) || ''}`,
                  }}
                  isEditable={false}
                />
              </>
            )}
          </>
        )}
        {!data && (
          <DynamicForm
            formFields={overviewInformationItemsGeneral}
            isLoading={true}
            isEditable={false}
          />
        )}
        <h3 className="font-bold text-blue-400 text-sm">Dates and Turn Around Time (TAT)</h3>
        {data && (
          <>
            {isEditing ? (
              <DynamicForm
                formFields={overviewFormItemsDates}
                isEditable={true}
                callback={refetch}
                formMethods={formMethods}
                data={{
                  ...transformData(data),
                }}
              />
            ) : (
              <>
                <DynamicForm
                  formFields={overviewInformationItemsDates}
                  data={{
                    ...transformData(data),
                  }}
                  formMethods={formMethods}
                  isEditable={false}
                  callback={refetch}
                />
              </>
            )}
          </>
        )}
        {!data && (
          <DynamicForm
            formFields={overviewInformationItemsDates}
            isLoading={true}
            isEditable={false}
          />
        )}
        <h3 className="font-bold text-blue-400 text-sm">
          First Incident Report (FIR) Information{' '}
        </h3>
        <p className="text-grey-400 italic text-xs py-1">
          If POIC is selected, a broader list of reports is available to select. Key in at least 5
          characters to start searching in the dropdown
        </p>
        {data && (
          <>
            {isEditing ? (
              <DynamicForm
                formFields={FIRFields}
                isLoading={false}
                isEditable={true}
                callback={() => {}}
                formMethods={formMethods}
              />
            ) : (
              <>
                <DynamicForm
                  formFields={overviewInformationItemsFir}
                  data={{
                    ...transformData(data),
                  }}
                  formMethods={formMethods}
                  isEditable={false}
                />
              </>
            )}
          </>
        )}
        {!data && (
          <DynamicForm
            formFields={overviewInformationItemsFir}
            isLoading={true}
            isEditable={false}
          />
        )}
      </div>
    </div>
  );
}
