// import { SessionInterface } from "../interface/SessionInterface";
import { apiSlice } from "./apiSlice";

export const sessionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          body: params,
          url: `/v1/login`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    loginTotp: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          body: params,
          url: `/v1/logintotp`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    logout: builder.mutation({
      query: () => {
        return {
          method: "POST",
          url: `/v1/logout`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    formLogin: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          body: params,
          url: `/v1/formlogin`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    formLoginOtp: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          body: params,
          url: `/v1/formloginotp`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    formLogout: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          body: params,
          url: `/v1/formlogout`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const { useLoginMutation, useLoginTotpMutation, useLogoutMutation, useFormLoginMutation, useFormLoginOtpMutation, useFormLogoutMutation } = sessionApiSlice;
