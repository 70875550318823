import { useState } from 'react';
import {
  LazadaItems,
  ShopeeItems,
  carousellItems,
  defaultItems,
  emailItems,
  faceBookItems,
  googlePlaystoreApplicationItems,
  iMessageItems,
  instagramItems,
  phoneNumberItems,
  phoneNumberUserIdItems,
  rcsItems,
  telegramItems,
  twitterItems,
  urlItemsMainTable,
  whatsappItems,
} from './CreateReportFormConfig';
import TextInput from '@components/common/TextInput';
import TableInput from '@components/common/TableInput';
import { platform, financialExchangePlatform, useOfScamEnabler } from '@interface/constant';
import Dropdown from '@components/common/dropdown/Dropdown';
import DynamicForm from '@components/common/DynamicForm';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import cloneDeep from 'lodash.clonedeep';
import { CommonButton } from '@components/common/CommonButton';
import { ScamEnablerPaymentChannelForm } from '../ReportFormConfig';
import { getCountries } from 'libphonenumber-js';
import { getCountryNameAndFlag } from '@components/common/telephoneInput/countryData';

const CreateReportMonikers = ({
  formMethods,
  update,
  scamEnablerFormState,
  setScamEnablerFormState,
  setCurrScamEnabler,
  setShowDeleteModal,
  field,
  index,
  sectionRefs,
  isShowPaymentChannel,
  isFormCreateReport,
}: {
  formMethods: any;
  update: any;
  scamEnablerFormState: Array<Array<FormItemInterface>>;
  setScamEnablerFormState: any;
  setCurrScamEnabler: any;
  setShowDeleteModal: any;
  field: any;
  index: number;
  sectionRefs: any;
  isShowPaymentChannel: boolean;
  isFormCreateReport: boolean;
}) => {
  const handleOpenDeleteModal = (index: number) => {
    setCurrScamEnabler(index);
    setShowDeleteModal(true);
  };

  let remarksRules = {
    pattern: {},
    validate: {
      doesNotContainPII: (value: string) => {
        const NRICRegex = /[STFG]\d{7}[A-Z]/gi;
        if (NRICRegex.test(value)) {
          console.log('PII detected');
          return 'Please indicate any additional information but do not include any personal identifiable information (PII) such as NRIC and names.';
        }
      },
    },
  };

  const [websiteItemState, setWebsiteItemState] = useState<Array<Array<FormItemInterface>>>([]);

  const onPlatformUpdate = (platform: string, index: number) => {
    let tempItems = cloneDeep(defaultItems);
    switch (platform) {
      case 'Facebook':
        tempItems = cloneDeep(faceBookItems);
        break;
      case 'Instagram':
        tempItems = cloneDeep(instagramItems);
        break;
      case 'Telegram':
        tempItems = cloneDeep(telegramItems);
        break;
      case 'X':
        tempItems = cloneDeep(twitterItems);
        break;
      case 'Carousell':
        tempItems = cloneDeep(carousellItems);
        break;
      case 'Lazada':
        tempItems = cloneDeep(LazadaItems);
        break;
      case 'Shopee':
        tempItems = cloneDeep(ShopeeItems);
        break;
      case 'Website':
        tempItems = cloneDeep(urlItemsMainTable);
        break;
      case 'Whatsapp':
        tempItems = cloneDeep(whatsappItems);
        break;
      case 'Email':
        tempItems = cloneDeep(emailItems);
        break;
      case 'LINE App':
      case 'WeChat':
        tempItems = cloneDeep(phoneNumberUserIdItems);
        break;
      // case 'On a call':
      case 'SMS':
        tempItems = cloneDeep(phoneNumberItems);
        break;
      case 'RCS':
        tempItems = cloneDeep(rcsItems);
        break;
      case 'iMessage':
        tempItems = cloneDeep(iMessageItems);
        break;
      case 'Google Playstore Application':
        tempItems = cloneDeep(googlePlaystoreApplicationItems);
        break;
      case '':
        tempItems = [];
        break;
      default:
        break;
    }

    if (formMethods.getValues(`scamEnablers.[${index}].type`) === 'Payment') {
      if (platform === '') {
        tempItems = [];
      } else {
        tempItems = cloneDeep(ScamEnablerPaymentChannelForm(platform));
      }
    }

    tempItems.forEach((item) => {
      formMethods.setValue(`scamEnablers[${index}].${item.id}`, '');
      item.id = `scamEnablers[${index}].${item.id}`;
      if (item.rules && !item.rules.validate) {
        item.rules.validate = null;
      }
      if (item.type === 'radioToggle') {
        item.extras.nestedFieldTypes.forEach((nestedField: any) => {
          nestedField.id = `scamEnablers[${index}].${nestedField.id}`;
        });
      }
    });

    let newDynamicFormState = cloneDeep(scamEnablerFormState);
    if (newDynamicFormState[index]) {
      newDynamicFormState[index] = tempItems;
    } else {
      newDynamicFormState.push(tempItems);
    }

    setScamEnablerFormState(newDynamicFormState);
  };

  const resetFormImputs = (index: number) => {
    let newDynamicFormState = cloneDeep(scamEnablerFormState);
    if (newDynamicFormState[index]) {
      newDynamicFormState[index] = [];
    } else {
      newDynamicFormState.push([]);
    }

    setScamEnablerFormState(newDynamicFormState);
  };

  const onTypeUpdate = (type: string, index: number) => {
    if (type === 'Moniker') {
      update(index, {
        useOfScamEnabler: formMethods.getValues(`scamEnablers.${index}.useOfScamEnabler`),
        platform: '',
        type: 'Moniker',
        scamEnablerType: type,
      });
      resetFormImputs(index);
    } else {
      update(index, {
        useOfScamEnabler: formMethods.getValues(`scamEnablers.${index}.useOfScamEnabler`),
        platform: '',
        type: 'Payment',
        scamEnablerType: type,
      });
      resetFormImputs(index);
    }
  };

  const getCountryNames = () => {
    let countries = getCountries();
    let countryList: any = [];
    countries.forEach((country: any) => {
      countryList.push(getCountryNameAndFlag(country).name);
    });
    return countryList;
  };
  return (
    <div key={field.id} ref={(el) => (sectionRefs.current[index] = el)}>
      <div className="flex justify-between">
        <p className="font-bold py-2">{`Scam Enabler ${index + 1}`}</p>
        {index > 0 && (
          <CommonButton
            title="Delete Enabler"
            variant={'text-blue'}
            type="button"
            onClick={() => handleOpenDeleteModal(index)}
          />
        )}
      </div>

      <div className={'flex flex-col p-1 w-full'}>
        <label className=" font-bold mr-4 pb-1" htmlFor={`scamEnablers[${index}].useOfScamEnabler`}>
          {'How the scam enabler is used'}
          {!isFormCreateReport && <span className="pl-2 text-grey-400 italic">Optional</span>}
        </label>
        <Dropdown
          control={formMethods.control}
          id={`scamEnablers.${index}.useOfScamEnabler`}
          rules={isFormCreateReport ? { required: 'Please select an option' } : {}}
          formState={formMethods.formState}
          dropdownData={useOfScamEnabler}
          setValue={formMethods.setValue}
          watch={formMethods.watch}
        ></Dropdown>
      </div>
      {isShowPaymentChannel && (
        <div className={'flex flex-col p-1 w-full'}>
          <label
            className="w-1/3 font-bold mr-4 pb-1"
            htmlFor={`scamEnablers[${index}].scamEnablerType`}
          >
            {'Type'}
          </label>
          <Dropdown
            control={formMethods.control}
            id={`scamEnablers[${index}].scamEnablerType`}
            dropdownData={['Banks/Fintech/Crypto', 'Moniker']}
            rules={{ required: 'Please select an option' }}
            formState={formMethods.formState}
            onChange={(e: any) => {
              onTypeUpdate(e, index);
              formMethods.trigger();
            }}
            setValue={formMethods.setValue}
            watch={formMethods.watch}
          ></Dropdown>
        </div>
      )}
      <div className={'flex flex-col p-1 w-full'}>
        <label className="w-1/3 font-bold mr-4 pb-1" htmlFor={`scamEnablers[${index}].platform`}>
          {'Platform'}
        </label>
        <Dropdown
          control={formMethods.control}
          id={`scamEnablers[${index}].platform`}
          dropdownData={
            formMethods.getValues(`scamEnablers.[${index}].type`) === 'Payment'
              ? financialExchangePlatform
              : platform
          }
          rules={{ required: 'Please select an option' }}
          formState={formMethods.formState}
          onChange={(e: any) => {
            onPlatformUpdate(e, index);
            formMethods.trigger();
          }}
          othersCustomComponent={
            formMethods.getValues(`scamEnablers.[${index}].type`) === 'Payment'
              ? {
                  inputType: 'dropdown',
                  dropdownData: getCountryNames(),
                  label: 'Country',
                }
              : undefined
          }
          setValue={formMethods.setValue}
          watch={formMethods.watch}
        ></Dropdown>
      </div>
      {scamEnablerFormState[index] &&
        (formMethods.watch(`scamEnablers[${index}].platform`) === 'Website' ? (
          <div className="pt-3">
            <TableInput
              index={`scamEnablers[${index}].websites`}
              formFields={urlItemsMainTable}
              formMethods={formMethods}
              websiteItemState={websiteItemState}
              setWebsiteItemState={setWebsiteItemState}
            />
            <label className="w-1/3 font-bold mr-4 pb-1 pl-1">
              {'Additional Comments'}
              {<span className="pl-2 text-grey-400 italic">- Optional</span>}
            </label>
            <TextInput
              id={`scamEnablers[${index}].remarks`}
              control={formMethods.control}
              rules={remarksRules}
              placeholder="Please indicate any additional information..."
              formState={formMethods.formState}
              rows={8}
            />
          </div>
        ) : (
          <DynamicForm
            formFields={scamEnablerFormState[index]}
            formMethods={formMethods}
            isEditable={true}
          />
        ))}
    </div>
  );
};

export default CreateReportMonikers;
