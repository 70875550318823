import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton } from '@components/common/CommonButton';

import { useDispatch } from 'react-redux';
import Dropdown from '@components/common/dropdown/Dropdown';
import { useForm } from 'react-hook-form';
import DynamicForm from '@components/common/DynamicForm';
import { ReactComponent as Info } from '@assets/icons/info_blue.svg';

const BankProductionOrderModal = ({
  financialInstituteData,
  type,
  poOpen,
  isLoading,
  onPOClose,
  handleSubmit,
}: {
  financialInstituteData: any;
  type: string;
  poOpen: boolean;
  isLoading: boolean;
  onPOClose: () => void;
  handleSubmit: (reportNumber: string, type: string) => void;
}) => {
  const formMethods = useForm();

  const dispatch = useDispatch();
  const onDropdownChange = (e: any) => {
    console.log(e);
  };

  const getDropdownData = () => {
    if (financialInstituteData?.financialInstitute?.updatedTransactions?.length > 0) {
      const reportNumbers = financialInstituteData.financialInstitute.updatedTransactions.map(
        (transaction: any) => transaction.reportNumber,
      );
      if (type === 'freeze') {
        reportNumbers.push('others');
      }
      return reportNumbers;
    } else {
      if (type === 'freeze') {
        return ['others'];
      } else {
        return [];
      }
    }
  };

  const onSubmit = async (data: any) => {
    let values = formMethods.getValues();
    let reportNumber = values.reportNumber;
    if (values.reportNumber === 'others' && values.referenceNumber) {
      reportNumber = values.referenceNumber;
    }
    handleSubmit(reportNumber, type);
  };
  return (
    <>
      <Modal open={poOpen} onClose={onPOClose}>
        <ModalLayout
          title={`Generate Production Order(${type === 'freeze' ? 'Freezing' : 'Earmark'})`}
          handleCloseModal={onPOClose}
          alignment="center"
          size="medium"
        >
          {type === 'earmark' && (
            <div className="bg-blue-200 rounded-lg flex flex-row border-blue-400 p-4 mb-4">
              <div className="pr-4">
                <Info className="w-[20px] h-[20px]" fill="#FFF" />
              </div>
              <p>
                Production order for earmark does not have an ‘Others’ options available in the
                dropdown below to indicate a reference number. An existing report number needs to be
                selected to tag the earmark amount from the report to the production order.
              </p>
            </div>
          )}
          <p className="pb-2">
            Please indicate the report no. you would like this production order to tag to.
          </p>
          <p className="font-bold mr-4 text-grey-500 flex items-center pb-2">Report No.</p>
          <Dropdown
            id={'reportNumber'}
            control={formMethods.control}
            formState={formMethods.formState}
            dropdownData={getDropdownData()}
            setValue={formMethods.setValue}
            watch={formMethods.watch}
            onChange={onDropdownChange}
            othersCustomComponent={
              <DynamicForm
                formFields={[
                  {
                    label: 'Reference No.',
                    id: 'referenceNumber',
                    type: 'textInput',
                    alignment: 'column',
                    rules: {
                      required: 'Reference number is required',
                    },
                  },
                ]}
                formMethods={formMethods}
              ></DynamicForm>
            }
          ></Dropdown>
          <div className="flex gap-4 mr-0 ml-auto mt-10">
            <CommonButton title="Cancel" variant="text" onClick={onPOClose} isLoading={isLoading} />
            <CommonButton
              title="Generate"
              variant="primary"
              onClick={onSubmit}
              isDisabled={
                !formMethods.getValues().reportNumber ||
                (formMethods.getValues().reportNumber == 'others' &&
                  formMethods.getValues().referenceNumber !== '')
              }
              isLoading={isLoading}
            />
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default BankProductionOrderModal;
