export function AmountMoneyCard({
  title,
  amount,
  isLoading,
}: {
  title: string;
  amount: number;
  isLoading: boolean;
}) {
  return (
    <div className="min-w-32 flex-1">
      <div className={`bg-gray-200 p-4 rounded-lg ${isLoading ? 'animate-pulse' : ''}`}>
        <h3 className={`font-bold text-sm text-gray-500 mb-2 `}>{title}</h3>
        {isLoading && (
          <h3 className={`font-bold text-red text-md ${isLoading ? 'animate-pulse' : ''}`}> </h3>
        )}
        {!isLoading && <h3 className={`font-bold text-red text-md`}>${amount.toFixed(2)}</h3>}
      </div>
    </div>
  );
}
