import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '@api/sessionSlice';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { IconButton } from './CommonButton';
import UpdateUserModal from '../../pages/users/UpdateUser/UpdateUserModal';

const HeaderComponent = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const [user, setUser] = useState<any>({});
  const [intials, setInitials] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isSignoutLoading, setIsSignoutLoading] = useState(false);
  useEffect(() => {
    const getSessionData = async () => {
      try {
        const response = await getSessionUserFromAccessToken();
        let initial = response.firstName[0].toUpperCase() + response.lastName[0].toUpperCase();
        setInitials(initial);

        setUser(response);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    getSessionData();
  }, []);

  // let session = await getUserFromAccessToken();

  const handleNotification = () => {};

  async function handleSignOut() {
    try {
      setIsSignoutLoading(true);
      let response: any = await logout(null);
      setIsSignoutLoading(false);

      if (!response.error) {
        navigate('/login');
      }
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  function handleClickUserName() {
    setOpenModal(true);
  }

  async function handleUpdateUserModalClose() {
    const response = await getSessionUserFromAccessToken();
    let initial = response.firstName[0].toUpperCase() + response.lastName[0].toUpperCase();
    setInitials(initial);

    setUser(response);

    setOpenModal(false);
  }

  return (
    <>
      <div className="w-full h-16 flex justify-between bg-grey-200 items-center">
        <div>
          <h1 className={`font-bold  text-grey-500 p-6`}>{title}</h1>
        </div>
        <div className="flex justify-end items-center">
          {/* <IconButton onClick={handleNotification} icon="notification" isDisabled={false} /> */}
          <IconButton
            onClick={handleSignOut}
            icon="logout"
            isDisabled={false}
            isLoading={isSignoutLoading}
          />
          <div
            className="flex flex justify-end items-center gap-4 px-8"
            onClick={handleClickUserName}
            style={{ cursor: 'pointer' }}
          >
            <div className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
              {intials || 'DO'}
            </div>
            <p>{`${user.firstName} ${user.lastName}` || 'Duty Officer'}</p>
          </div>
        </div>
      </div>
      <UpdateUserModal isOpen={openModal} onModalClose={handleUpdateUserModalClose} />
    </>
  );
};

export default HeaderComponent;
