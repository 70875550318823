import { Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton } from '@components/common/CommonButton';
import {
  useCreateProactiveScamEnablersV2Mutation,
  useGetProactiveScamEnablersExistV2Mutation,
} from '@api/scamEnablerSlice';
import DynamicForm from '@components/common/DynamicForm';

import { debounce } from 'lodash';
import { createProactiveItems } from '../ProactiveMonikersFormConfig';
import { extractUserIdFromUrl, validateIdNotNil } from '@utils/utils';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';

const CreateProactiveMonikerModal = ({
  isOpen,
  handleClose,
  callback,
}: {
  isOpen: boolean;
  handleClose: () => void;
  callback: () => void;
}) => {
  const formMethods = useForm({
    defaultValues: {
      platform: 'Facebook',
      scamEnablerUserId: '',
      scammerContactNumber: ['+65'],
      url: '',
      verifiedProfile: 'false',
    },
    mode: 'onChange',
  });

  const [parentExtras, setParentExtras] = useState({} as any);

  const urlField = formMethods.watch('url');
  const userIdField = formMethods.watch('scamEnablerUserId');

  const platformField = formMethods.watch('platform');

  const [checkUserId] = useGetProactiveScamEnablersExistV2Mutation();
  const [createProactiveScamEnabler] = useCreateProactiveScamEnablersV2Mutation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  // Function to update loading and verification state
  const updateUserIdState = (isLoading: boolean, isVerified: boolean) => {
    setParentExtras({
      scamEnablerUserId: {
        isLoading,
        isVerified,
      },
    });
  };

  const checkUserIdExists = async (userId: string) => {
    try {
      updateUserIdState(true, false); // Start loading

      let response = await checkUserId({
        proactiveScamEnablers: [
          {
            scamEnablerUserId: userId,
            platform: platformField,
          },
        ],
      }).unwrap();

      const user = response?.result.find((item: any) => {
        return item.scamEnablerUserId === userId;
      });
      if (user && user.exists === false) {
        formMethods.clearErrors('scamEnablerUserId');
        updateUserIdState(false, true); // Success
        return; // Exit early on success
      } else {
        formMethods.setError('scamEnablerUserId', {
          type: 'manual',
          message: 'Moniker ID already exists',
        });
      }
    } catch (error) {
      console.error('Error retrieving profile', error);
      formMethods.setError('scamEnablerUserId', {
        type: 'manual',
        message: 'Error retrieving profile',
      });
    }
    updateUserIdState(false, false); // End loading with error or user exists
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const data: any = formMethods.getValues();
      // let userId = data.url.replace('https://www.facebook.com/profile.php?id=', '');
      // data.scamEnablerUserId = userId;
      // data.platform = 'Facebook';
      if (data.numberOfAds || data.numberOfAds === 0) data.numberOfAds = parseInt(data.numberOfAds);
      else if (!data.numberOfAds) {
        delete data.numberOfAds;
      }
      if (data.facebookCreatedAt) {
        data.facebookCreatedAt = new Date(data.facebookCreatedAt).setHours(0, 0, 0, 0);
        if (data.attachments && data.attachments.length > 0) {
          data.attachments.forEach((attachment: any) => {
            let splitFileName = attachment.fileName.split('.');
            attachment.fileName = `${data.scamEnablerUserId}.${splitFileName[splitFileName.length - 1]}`;
          });
        }
      }
      if (data.scammerContactNumber && data.scammerContactNumber.length > 0) {
        // if (data.attachmentFileNames && data.attachmentFileNames.length > 0) {
        //   data.attachmentFileNames = data.attachmentFileNames
        //     .map((row: string) => (row ? row.trim() : ''))
        //     .filter((row: string) => row !== '');

        //   if (data.attachmentFileNames.length === 0) {
        //     delete data.attachmentFileNames;
        //   }
        // }
        data.scammerContactNumber = data.scammerContactNumber
          .map((row: string) => (row ? row.trim() : '+65'))
          .filter((row: string) => row !== '+65');

        if (data.scammerContactNumber.length === 0) {
          delete data.scammerContactNumber;
        }
      }
      if (data.redirectionUrls && data.redirectionUrls.length > 0) {
        data.redirectionUrls = data.redirectionUrls.filter((item: any) => {
          return item.url || item.attachments?.length > 0;
        });
        data.redirectionUrls.forEach((item: any) => {
          if (item.url.trim() === '' && item.attachments.length > 0) {
            dispatch(
              showAlert({
                type: 'error',
                message: 'URL is required if attachments are present',
              }),
            );
            throw false;
          }
        });
      }

      let response: any = await createProactiveScamEnabler([data]);
      if (!response.error) {
        if (callback) callback();
        handleClose();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (platformField === 'Facebook' && !urlField) {
      formMethods.setValue('url', 'https://www.facebook.com/profile.php?id=');
    }
  }, [platformField, formMethods]);

  useEffect(() => {
    const debouncedCheckURL = debounce(async () => {
      if (urlField && platformField === 'Facebook') {
        const { isValidUrl, userId } = extractUserIdFromUrl(urlField, 'Facebook');
        if (isValidUrl && userId.length >= 2) {
          formMethods.setValue('scamEnablerUserId', userId);
        }
      }
    }, 500); // 500ms debounce time
    debouncedCheckURL();

    return () => {
      debouncedCheckURL.cancel(); // Cancel any pending executions on component unmount
    };
  }, [urlField, checkUserId, formMethods]);

  useEffect(() => {
    const debouncedCheckUserId = debounce(async () => {
      if (
        platformField &&
        userIdField &&
        validateIdNotNil(userIdField) != 'Please enter a valid userId'
      ) {
        await checkUserIdExists(userIdField);
      }
    }, 500); // 500ms debounce time
    debouncedCheckUserId();

    return () => {
      debouncedCheckUserId.cancel(); // Cancel any pending executions on component unmount
    };
  }, [userIdField, checkUserId, formMethods]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalLayout
        title={'Add Report - Manual Entry'}
        handleCloseModal={handleClose}
        alignment="center"
        size="large"
        overflowClass="none"
      >
        <>
          <p className="text-sm text-grey-400 -mt-8">
            All Fields are required unless marked as optional
          </p>
          <div className="overflow-auto max-h-[75vh] mt-2">
            <p className="text-grey-400 pb-4 -mt-4">
              All fields are required unless marked as optional
            </p>

            <DynamicForm
              formFields={createProactiveItems}
              formMethods={formMethods}
              parentExtras={parentExtras}
              isEditable={true}
            />
          </div>
          <div className="flex justify-end gap-4 pt-4">
            <CommonButton
              isDisabled={false}
              variant="text"
              title={`Cancel`}
              onClick={() => {
                handleClose();
              }}
            />
            <CommonButton
              isDisabled={!formMethods.formState.isValid}
              variant="primary"
              title={`Add`}
              onClick={handleSubmit}
              isLoading={isLoading}
            />
          </div>
        </>
      </ModalLayout>
    </Modal>
  );
};

export default CreateProactiveMonikerModal;
