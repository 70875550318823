import { ReactComponent as AddIcon } from '@assets/icons/add_circle.svg';
import { ReactComponent as RemoveIcon } from '@assets/icons/remove_icon.svg';
import { ReactComponent as AttachmentIcon } from '@assets/icons/attachment.svg';
import { useState } from 'react';
import { Box } from '@mui/material';

const AddImageField = ({
  formMethods,
  id,
  setShowModal,
}: {
  formMethods: any;
  id: string;
  setShowModal?: any;
}) => {
  const [hoverIndex, setHoverIndex] = useState(-1);

  const removeFile = (attachmentIndex: number) => {
    const newAttachments = [...formMethods.getValues(`${id}.attachments`)];
    newAttachments.splice(attachmentIndex, 1);
    formMethods.setValue(`${id}.attachments`, newAttachments);
  };

  return (
    <div className="flex gap-3">
      {formMethods.getValues(id) &&
      formMethods.getValues(id).attachments &&
      formMethods.getValues(id).attachments.length > 0 ? (
        <div>
          {formMethods
            .getValues(id)
            .attachments.map((attachmentItem: any, attachmentIndex: number) => (
              <div
                key={attachmentIndex}
                className="flex gap-2 pb-1 items-center"
                onMouseEnter={() => setHoverIndex(attachmentIndex)}
                onMouseLeave={() => setHoverIndex(-1)}
              >
                <AttachmentIcon />
                <p>{attachmentItem.fileName}</p>
                {hoverIndex === attachmentIndex ? (
                  <RemoveIcon
                    className="cursor-pointer"
                    onClick={() => removeFile(attachmentIndex)}
                  />
                ) : (
                  <div className="w-3"></div>
                )}
              </div>
            ))}
          <div className="flex gap-2 pb-1">
            <AttachmentIcon className="invisible" />
            <AddIcon className="cursor-pointer" onClick={() => setShowModal(true)} />
          </div>
        </div>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <p>No uploads</p>
          <AddIcon className="self-center cursor-pointer" onClick={() => setShowModal(true)} />
        </Box>
      )}
    </div>
  );
};

export default AddImageField;
