import { FormItemInterface } from '@interface/dynamicFormInterface';
import { BankStatus, MonikerOutcomeStatus, ReportStatus } from './StatusComponent';
import cloneDeep from 'lodash.clonedeep';
import MultiTextInput from './MultiTextInput';
import TextInput from './TextInput';
import Password from './Password';
import DatePickerComponent from './DatePicker';
import clsx from 'clsx';
import ScreenshotsModal from './ScreenshotModal';
import MonikerRelatedReportModal from '@pages/reports/modals/MonikerRelatedReportModal';
import FileUpload from './FileUpload';
import Radio from './Radio';
import Toggle from './Toggle';
import { convertUnixToSingaporeTime, getAmountLoss } from '@utils/utils';
import { Link } from 'react-router-dom';
import EditlabelDropdown from './EditLabelDropdown';
import TextInputSelect from './TextInputSelect';
import { EditLabelDateTime } from './EditLabelDateTime';
import DropdownMulti from './DropdownMulti';
import DropdownUser from './DropdownUser';
import CustomDateRangePicker from './DateRangePicker';
import Dropdown from './dropdown/Dropdown';
import TableChecklist from './TableChecklist';
import { EditLabelText } from './EditLabelText';
import EditLabelDropdownUser from './EditLabelDropdownUser';
import TooltipComponent from './TooltipComponent';
import TelephoneInput from './telephoneInput/TelephoneInput';
import MultiTelephoneInput from './telephoneInput/MultiTelephoneInput';
import { GetSubmissionButton } from '@pages/reports/Buttons';

import DynamicTable from './DynamicTable';
import DynamicTableView from './DynamicTableView';

import RadioToggleField from './RadioToggleField';
import CheckboxTable from './CheckboxTable';

import TableInputView from './TableInputView';
import TableInputEdit from './TableInputEdit';

export const getField = (
  field: FormItemInterface,
  data: any,
  callback?: any,
  isEditable?: boolean,
  formMethods?: any,
  setBadgeText?: any,
  parentExtras?: any,
) => {
  if (field.value !== undefined) {
    switch (field.type) {
      case 'reportStatus':
        return getReportStatusField(field);
      case 'bankStatus':
        return getBankStatusField(field);
      case 'monikerOutcomeStatus':
        return getMonikerOutcomeStatusField(field);
      case 'transactionLink':
        return getTransactionLinkField(field, data);
      case 'textInput':
        return getTextInputField(field, formMethods.control, formMethods.formState, parentExtras);
      case 'multiTextInput':
        return getMultiTextInputField(
          field,
          formMethods.control,
          formMethods.formState,
          parentExtras,
        );
      case 'telephoneInput':
        return getTelephoneInputField(field, formMethods.control, formMethods.formState);
      case 'multiTelephoneInput':
        return getMultiTelephoneInputField(field, formMethods.control, formMethods.formState);
      case 'password':
        return getPasswordField(field, formMethods.control, formMethods.formState);
      case 'calendar':
        return getCalendarField(field, formMethods.control, formMethods.formState);
      case 'calendarRange':
        return getCalendarRangeField(
          field,
          formMethods.control,
          formMethods.formState,
          formMethods.setValue,
        );
      case 'dateTime':
        return getDateTimeField(field, formMethods.control, formMethods.formState);
      case 'dropdown':
        return getDropdownField(
          field,
          formMethods.control,
          formMethods.formState,
          formMethods.setValue,
          setBadgeText,
          formMethods.watch,
        );
      case 'dropdownUser':
        return getDropdownUserField(field, formMethods.control, formMethods.setValue);
      case 'dropdownMulti':
        return getDropdownMultiField(
          field,
          formMethods.control,
          formMethods.formState,
          formMethods.setValue,
        );
      case 'fileUpload':
        return getFileUploadField(
          field,
          formMethods.control,
          formMethods.setValue,
          formMethods.getValues,
        );
      case 'boolean':
        return getBooleanField(field, formMethods.control, formMethods.formState, isEditable);
      case 'toggle':
        return getToggleField(field, formMethods.control, formMethods.formState);
      case 'screenshot':
        return getScreenshotField(field, data, isEditable, callback, formMethods, parentExtras);
      case 'reports':
        return getReportsField(field, data);
      case 'date':
        return getDateField(field);
      case 'label':
        return getLabelField(field);
      case 'tableChecklist':
        return getTableChecklist(
          field,
          formMethods.control,
          formMethods.getValues,
          formMethods.watch,
          formMethods.setValue,
          isEditable,
        );
      // case 'urlTableEdit':
      //   return getTableInputEdit(field, formMethods);
      // case 'urlTableView':
      //   return getTableInputView(field, data);
      case 'transactionAmountLabel':
        return getTransactionAmountLabelField(data);
      case 'link':
        return getLinkField(field, data);
      case 'fileLabel':
        return getFileLabelField(field);
      case 'labelEditDropdown':
        return getLabelEditDropdownField(field, data, callback, isEditable);
      case 'labelEditDropdownUser':
        return getLabelEditDropdownUserField(field, data, callback, isEditable);
      case 'labelEditDateTime':
        return getLabelEditDateTimeField(field, data, callback, isEditable);
      case 'labelEditText':
        return getLabelEditTextField(field, data, callback, isEditable);
      case 'textInputSelect':
        return getTextInputSelectField(field, formMethods.control, formMethods.formState);
      // case 'transactionTable':
      //   return getEditableTransactionField(
      //     field,
      //     formMethods.control,
      //     formMethods.setValue,
      //     formMethods.resetField,
      //     formMethods.getValues,
      //     data,
      //     isEditable,
      //   );
      case 'dynamicTable':
        return getDynamicTable(field, formMethods, isEditable || false, data);
      case 'submissions':
        return getSubmissions(field);
      case 'radioToggle':
        return getRadioToggleField(field, formMethods);

      case 'checkbox':
        return getCheckBox(field, formMethods.control, isEditable);
      case 'component':
        return getComponent(field);
      default:
        return getDefaultField(field);
    }
  }
  return <div></div>;
};

const getDynamicTable = (
  field: FormItemInterface,
  formMethods: any,
  isEditable: boolean,
  data: any,
) => {
  return (
    <>
      {formMethods && (
        <DynamicTable
          tableId={field.id}
          formFields={field.extras?.tableFields}
          isEditable={isEditable}
          formMethods={formMethods}
          data={data}
          defaultRow={field.extras?.defaultRow}
          showCounter={field.extras?.showCounter}
        />
      )}
      {!formMethods && (
        <DynamicTableView
          tableId={field.id}
          formFields={field.extras?.tableFields}
          data={data}
          defaultRow={field.extras?.defaultRow}
          // showCounter={field.extras?.showCounter}
        />
      )}
    </>
  );
};
const getReportStatusField = (field: FormItemInterface) => {
  return (
    <div
      className={clsx({
        'opacity-60': field.disabled,
      })}
    >
      <ReportStatus reportStatus={typeof field.value === 'string' ? field.value : ''} />
    </div>
  );
};

const getBankStatusField = (field: FormItemInterface) => {
  return (
    <div
      className={clsx({
        'opacity-60': field.disabled,
      })}
    >
      <BankStatus outcome={typeof field.value === 'string' ? field.value : ''} />
    </div>
  );
};

const getMonikerOutcomeStatusField = (field: FormItemInterface) => {
  return <MonikerOutcomeStatus outcome={typeof field.value === 'string' ? field.value : ''} />;
};

const getTransactionLinkField = (field: FormItemInterface, data: any) => {
  return (
    <div className="flex text-sm overflow-auto items-center gap-4">
      <p> {getAmountLoss(data)}</p>
    </div>
  );
};

const getTextInputField = (
  field: FormItemInterface,
  control: any,
  formState: any,
  parentExtras: any,
) => {
  return (
    <TextInput
      id={field.id}
      control={control}
      rules={field.rules}
      placeholder={field.placeholder}
      formState={formState}
      prefix={field?.extras?.prefix ? field.extras.prefix : ''}
      disabled={field.disabled}
      rows={field?.extras?.rows}
      prefill={field?.extras?.prefill}
      keyup={field?.extras?.keyup}
      isLoading={parentExtras && parentExtras[field.id]?.isLoading}
      isVerified={parentExtras && parentExtras[field.id]?.isVerified}
      textInputType={field?.extras?.textInputType}
      autocomplete={field?.extras?.autocomplete}
    ></TextInput>
  );
};
const getMultiTextInputField = (
  field: FormItemInterface,
  control: any,
  formState: any,
  parentExtras: any,
) => {
  return (
    <MultiTextInput
      fieldConfig={field}
      control={control}
      formState={formState}
      parentExtras={parentExtras}
    ></MultiTextInput>
  );
};

const getTelephoneInputField = (field: FormItemInterface, control: any, formState: any) => {
  return (
    <TelephoneInput
      id={field.id}
      control={control}
      rules={field.rules}
      formState={formState}
      alignment={field.alignment}
    ></TelephoneInput>
    // <CustomTelInput value={'1231'} onChange={() => {}} />
  );
};

const getMultiTelephoneInputField = (field: FormItemInterface, control: any, formState: any) => {
  return (
    <MultiTelephoneInput
      id={field.id}
      control={control}
      rules={field.rules}
      formState={formState}
      alignment={field.alignment}
    ></MultiTelephoneInput>
    // <CustomTelInput value={'1231'} onChange={() => {}} />
  );
};

const getPasswordField = (field: FormItemInterface, control: any, formState: any) => {
  return (
    <Password
      id={field.id}
      control={control}
      rules={field.rules}
      placeholder={field.placeholder}
      formState={formState}
      displayPasswordRequirements={field.displayPasswordRequirements}
      prefix={field?.extras?.prefix ? field.extras.prefix : ''}
    ></Password>
  );
};

const getCalendarField = (field: FormItemInterface, control: any, formState: any) => {
  return (
    <DatePickerComponent
      id={field.id}
      control={control}
      rules={field.rules}
      placeholder={field.placeholder}
      formState={formState}
      removePopupContainer={field?.extras?.removePopupContainer}
    ></DatePickerComponent>
  );
};

const getCalendarRangeField = (
  field: FormItemInterface,
  control: any,
  formState: any,
  setValue: any,
) => {
  return (
    <CustomDateRangePicker
      id={field.id}
      control={control}
      rules={field.rules}
      placeholder={field.placeholder}
      formState={formState}
      setValue={setValue}
      showTime={field?.extras?.showTime}
    ></CustomDateRangePicker>
  );
};

const getDateTimeField = (field: FormItemInterface, control: any, formState: any) => {
  return (
    <DatePickerComponent
      id={field.id}
      control={control}
      rules={field.rules}
      placeholder={field.placeholder}
      formState={formState}
      showTime={field.extras?.showTime || field.extras?.showTime === undefined}
      format={field.extras?.format}
      removePopupContainer={field?.extras?.removePopupContainer}
    ></DatePickerComponent>
  );
};

const getDropdownUserField = (field: FormItemInterface, control: any, setValue: any) => {
  return (
    <DropdownUser
      id={field.id}
      userGroup={field.extras.userGroup}
      control={control}
      setValue={setValue}
    />
  );
};

const getDropdownField = (
  field: FormItemInterface,
  control: any,
  formState: any,
  setValue: any,
  setBadgeText: any,
  watch: any,
) => {
  return (
    <>
      <Dropdown
        id={field.id}
        control={control}
        rules={field.rules}
        placeholder={field.placeholder}
        formState={formState}
        dropdownData={field.extras?.dropdownData}
        originalValue={field.extras?.defaultValue}
        searchable={field.searchable}
        setValue={setValue}
        setBadgeText={setBadgeText}
        watch={watch}
        onInputChange={field.extras?.onInputChange}
        onChange={field.extras?.onChange}
      ></Dropdown>
    </>
  );
};

const getDropdownMultiField = (
  field: FormItemInterface,
  control: any,
  formState: any,
  setValue: any,
) => {
  return (
    <DropdownMulti
      id={field.id}
      control={control}
      rules={field.rules}
      placeholder={field.placeholder}
      formState={formState}
      dropdownData={field.extras.dropdownData}
      setValue={setValue}
      allowFreeSolo={field?.extras?.allowFreeSolo}
      isUpperCase={field?.extras?.isUpperCase}
    ></DropdownMulti>
  );
};

const getFileUploadField = (
  field: FormItemInterface,
  control: any,
  setValue: any,
  getValues: any,
) => {
  return (
    <FileUpload
      control={control}
      id={field.id}
      setValue={setValue}
      getValues={getValues}
      fileType={field?.extras?.fileType ? field.extras.fileType : ''}
      allowPaste={field?.extras?.allowPaste}
      required={field?.rules?.required || false}
    ></FileUpload>
  );
};

const getBooleanField = (
  field: FormItemInterface,
  control: any,
  formState: any,
  isEditable?: boolean | true,
) => {
  return (
    <Radio
      control={control}
      id={field.id}
      rules={field.rules}
      formState={formState}
      radioItems={field.extras?.radioItems}
      isEditable={isEditable}
      handleTrueClick={field.extras?.handleTrueClick}
      handleFalseClick={field.extras?.handleFalseClick}
    ></Radio>
  );
};

const getToggleField = (field: FormItemInterface, control: any, formState: any) => {
  return (
    <Toggle control={control} id={field.id} rules={field.rules} formState={formState}></Toggle>
  );
};

const getScreenshotField = (
  field: FormItemInterface,
  data: any,
  isEditable?: boolean | false,
  callback?: any,
  formMethods?: any,
  parentExtras?: any,
) => {
  return (
    <ScreenshotsModal
      attachments={data?.attachments || data || []}
      scamEnablerId={data?._id || data?.scamEnablerId || parentExtras?._id}
      fieldId={field.id}
      isEditable={isEditable}
      callback={callback}
      updateAPI={field?.extras?.updateAPI}
      attachmentPath={field?.extras?.attachmentPath}
      data={data}
      allowPaste={field.extras.allowPaste}
      formMethods={formMethods}
    ></ScreenshotsModal>
  );
};

const getReportsField = (field: FormItemInterface, data: any) => {
  return (
    <MonikerRelatedReportModal
      relatedReports={data.relatedReports}
      userId={data.userId}
      platform={data.platform}
    ></MonikerRelatedReportModal>
  );
};

const getDateField = (field: FormItemInterface) => {
  return (
    <label className="text-wrap overflow-auto break-words">
      {field.value !== '-' && convertUnixToSingaporeTime(field.value, field?.extras?.format)}
      {field.value === '-' && field.value}
    </label>
  );
};

const getLabelField = (field: FormItemInterface) => {
  if (field?.extras?.format?.type === 'currency' && typeof field.value === 'number') {
    return (
      <label
        className={clsx('text-wrap overflow-auto break-words', {
          'opacity-60': field.disabled,
        })}
      >
        {field.value.toFixed(2)}
      </label>
    );
  }
  return (
    <label
      className={clsx('text-wrap overflow-auto break-words', {
        'opacity-60': field.disabled,
      })}
    >
      {typeof field.value === 'object' && field.value !== null
        ? Object.values(field.value).join(', ')
        : field.value === true
          ? 'Yes'
          : field.value === false
            ? 'No'
            : (field.value as string)}
    </label>
  );
};

const getTableChecklist = (
  field: FormItemInterface,
  control: any,
  getValues: any,
  watch: any,
  setValue: any,
  isEditable?: boolean,
) => {
  return (
    <TableChecklist
      control={control}
      getValues={getValues}
      id={field.id}
      isEditable={isEditable}
      rules={field.rules}
      rows={field.extras.checkboxData}
      setValue={setValue}
      watch={watch}
    />
  );
};

const getTableInputEdit = (field: FormItemInterface, formMethods: any, isEditable?: boolean) => {
  return <TableInputEdit id={field.id} formMethods={formMethods} formFields={field} />;
};

const getTableInputView = (field: FormItemInterface, data: any) => {
  return <TableInputView id={field.id} formFields={field} data={data} />;
};

const getTransactionAmountLabelField = (data: any) => {
  let totalAmount = 0;
  if (data.transactions && data.transactions.length > 0) {
    data.transactions.forEach((transaction: any) => {
      totalAmount += transaction.amount;
    });
  }
  return <label className="text-wrap  overflow-auto break-words">{totalAmount}</label>;
};

const getLinkField = (field: FormItemInterface, data: any) => {
  return (
    <>
      {field?.extras?.isDirectLink && (
        <a href={field.value} className="underline" target="_blank" rel="noopener noreferrer">
          {field.value}
        </a>
      )}
      {!field?.extras?.isDirectLink && (
        <Link
          to={`${field.value.link || field.value}`}
          className="underline text-sm"
          target="_blank"
          rel="noopener noreferrer"
        >
          {field.value.display || field.value}
        </Link>
      )}
    </>
  );
};

const getFileLabelField = (field: FormItemInterface) => {
  return (
    <label className="text-wrap  overflow-auto break-words">
      {Array.isArray(field.value)
        ? field.value.map((file) => file.fileName).join(', ')
        : field.value}
    </label>
  );
};

const getLabelEditDropdownField = (
  field: FormItemInterface,
  data: any,
  callback: any,
  isEditable?: boolean,
) => {
  return (
    <EditlabelDropdown field={field} data={data} callback={callback} isEditable={isEditable} />
  );
};

const getLabelEditDropdownUserField = (
  field: FormItemInterface,
  data: any,
  callback: any,
  isEditable?: boolean,
) => {
  return (
    <EditLabelDropdownUser field={field} data={data} callback={callback} isEditable={isEditable} />
  );
};

const getLabelEditDateTimeField = (
  field: FormItemInterface,
  data: any,
  callback: any,
  isEditable?: boolean,
) => {
  return (
    <EditLabelDateTime field={field} data={data} callback={callback} isEditable={isEditable} />
  );
};

const getLabelEditTextField = (
  field: FormItemInterface,
  data: any,
  callback: any,
  isEditable?: boolean,
) => {
  return <EditLabelText field={field} data={data} callback={callback} isEditable={isEditable} />;
};

const getTextInputSelectField = (field: FormItemInterface, control: any, formState: any) => {
  return (
    <TextInputSelect
      id={field.id}
      idCurrency={field.extras.idCurrency}
      control={control}
      rules={field.rules}
      placeholder={field.placeholder}
      formState={formState}
    />
  );
};

const getRadioToggleField = (field: FormItemInterface, formMethods: any) => {
  return (
    <RadioToggleField
      field={field}
      formMethods={formMethods}
      nestedFields={field.extras.nestedFieldTypes}
    />
  );
};

const getDefaultField = (field: FormItemInterface) => {
  return (
    <label className="text-wrap  overflow-auto break-words">
      {Array.isArray(field.value)
        ? field.value.map((file) => file.fileName).join(', ')
        : field.value}
    </label>
  );
};

// const getEditableTransactionField = (
//   field: FormItemInterface,
//   control: any,
//   setValue: any,
//   resetField: any,
//   getValues: any,
//   data: any,
//   isEditable?: boolean,
// ) => {
//   return (
//     <EditableTransactionTable
//       control={control}
//       data={{
//         ...data,
//       }}
//       setValue={setValue}
//       resetField={resetField}
//       isEditing={isEditable}
//       getValues={getValues}
//       transactions={field.extras?.initialTransactions || field.value}
//     />
//   );
// };

const getSubmissions = (field: FormItemInterface) => {
  console.log('submission', field.value);
  return (
    <div className="flex gap-2 text-center align-middle">
      {field.value.reportHistory.length}{' '}
      <GetSubmissionButton
        reportHistoryData={field.value.reportHistory}
        reportNumber={field.value.reportNumber}
      />
    </div>
  );
};

const getCheckBox = (field: FormItemInterface, control: any, isEditable?: boolean) => {
  return <CheckboxTable control={control} id={field.id} disabled={!isEditable} />;
};

const getComponent = (field: FormItemInterface) => {
  return (
    <div
      className={clsx({
        'opacity-60': field.disabled,
      })}
    >
      {field.value}
    </div>
  );
};

const getNestedValue = (data: any, nestedField: string[]): any => {
  let value = data;

  for (let i = 0; i < nestedField.length; i++) {
    if (value[nestedField[i]] !== undefined) {
      value = value[nestedField[i]];
    } else {
      return undefined;
    }
  }
  return value;
};

export const processData = (formItems: Array<FormItemInterface>, data: any) => {
  const processedForm = cloneDeep(formItems);

  processedForm &&
    processedForm.map((item) => {
      if (item.nestedField) {
        const nestedValue = getNestedValue(data, item.nestedField)[item.id];
        item.value = nestedValue !== undefined ? nestedValue : '-';
      } else {
        item.value =
          data?.[item.id] || data?.[item.id] === true || data?.[item.id] === false
            ? data[item.id]
            : '-';
      }
    });
  return processedForm;
};

const DynamicForm = ({
  formFields,
  data,
  children,
  formMethods,
  setBadgeText,
  callback,
  isEditable,
  isLoading,
  parentExtras,
  formClass,
}: {
  formFields: Array<FormItemInterface>;
  formMethods?: any;
  data?: any;
  children?: any;
  setBadgeText?: any;
  callback?: any;
  isEditable?: boolean;
  isLoading?: boolean;
  parentExtras?: any;
  formClass?: any;
}) => {
  const processedForm = processData(formFields, data);
  const getFormClass = (field: any) => {
    let gap = formClass?.columnGap || (field.alignment === 'column' ? 'gap-3' : 'gap-1');
    let className = `p-2 pl-0 w-full ${field.className} ${field.alignment === 'column' ? 'flex flex-col' : 'flex flex-row'} ${gap}`;
    return className;
  };
  return (
    <>
      {processedForm &&
        processedForm.map((field) => (
          <div key={field.id} className={getFormClass(field)}>
            {!field.extras?.removeLabel && (
              <label
                className={clsx('font-bold mr-4 text-grey-500 flex items-start', {
                  'w-1/2': field.alignment === 'half-row',
                  'w-1/3 ': field.alignment !== 'column' && field.alignment !== 'half-row',
                  'opacity-60': field.disabled,
                })}
              >
                {field.label}
                {field.extras?.tooltip && (
                  <TooltipComponent content={field.extras.tooltip.content} />
                )}
                : {field.optional && <span className="pl-2 text-grey-400 italic">Optional</span>}
              </label>
            )}
            {field.helperText && field.alignment === 'column' && (
              <p className="text-grey-400">{field.helperText}</p>
            )}
            <div
              className={clsx('', {
                'flex flex-col gap-2': field.alignment === 'column',
                'w-1/2 flex items-center pb-1': field.alignment === 'half-row',
                'w-2/3 flex items-center pb-1':
                  field.alignment !== 'column' && field.alignment !== 'half-row',
              })}
            >
              {isLoading && (
                <div className="animate-pulse bg-grey-300 w-full h-full rounded-lg"></div>
              )}
              {!isLoading &&
                formMethods &&
                getField(
                  field,
                  data,
                  callback,
                  isEditable,
                  formMethods,
                  setBadgeText,
                  parentExtras,
                )}
              {!isLoading && !formMethods && getField(field, data, callback, isEditable)}
              {field.helperText && field.alignment !== 'column' && (
                <p className="text-grey-400">{field.helperText}</p>
              )}
            </div>
            {field.helperTextBottom && field.alignment === 'column' && (
              <p className="text-grey-400 text-xs">{field.helperTextBottom}</p>
            )}
          </div>
        ))}
      {children}
      {/* <button type="submit">Submit</button> */}
    </>
  );
};

export default DynamicForm;
