import { useDownloadProductionOrders } from '@api/productionOrderSlice';
import { DownloadProductionOrderButton } from '@pages/reports/Buttons';
import { convertUnixToSingaporeTime } from '@utils/utils';

export function ProductionOrdersIssued({
  orders,
}: {
  orders: Array<{
    productionOrderId: string;
    poReferenceNo: string;
    createdAt: number;
    createdBy: any;
  }>;
}) {
  return (
    <div className="w-full">
      {(!orders || (orders && orders.length == 0)) && (
        <p className="italic text-xs text-gray-500">No production orders issued</p>
      )}

      {orders &&
        orders.map((order) => (
          <div className="w-1/2 min-w-56 mb-6">
            <div className="flex mb-2 justify-between">
              <p className="font-bold text-md">{order.poReferenceNo}</p>

              <DownloadProductionOrderButton order={order} requireAdditionalFiles={true} />
            </div>

            <div className="w-full flex justify-between">
              {/* <p className="min-w-32 text-sm text-gray-400">
                Issued by: {order?.createdBy?.firstName || ''} {order?.createdBy?.lastName || ''}
              </p> */}
              <p className="text-sm text-gray-400">{convertUnixToSingaporeTime(order.createdAt)}</p>
            </div>
          </div>
        ))}
    </div>
  );
}
