import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton } from '@components/common/CommonButton';
import { useState } from 'react';
import { useDownloadProductionOrders } from '@api/productionOrderSlice';

const DownloadBulkProductionOrderModal = ({
  isOpen,
  invalidScamEnablers,
  productionOrderIds,
  onClose,
}: {
  isOpen: boolean;
  invalidScamEnablers: string[];
  productionOrderIds: any[];
  onClose: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const downloadProductionOrders = useDownloadProductionOrders();

  const handleSubmit = async () => {
    setIsLoading(true);
    let response = await downloadProductionOrders(productionOrderIds, 'v1', true);
    setIsLoading(false);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        sx={{
          zIndex: 100,
          '& .MuiBackdrop-root': {
            zIndex: -1,
          },
        }}
      >
        <ModalLayout
          title={'Confirmation'}
          handleCloseModal={onClose}
          alignment="center"
          size="small"
          overflowClass="overflow-hidden"
        >
          <>
            {invalidScamEnablers && (
              <>
                <p>The following monikers do not have existing production orders:</p>
                <div className="overflow-auto bg-grey-200 p-4 my-4">
                  {invalidScamEnablers.map((scamEnabler, index) => (
                    <div key={index} className="grid grid-cols-4">
                      <p className="text-sm">{scamEnabler}</p>
                    </div>
                  ))}
                </div>
                <p>
                  Click the ‘Download’ button to download the production orders for the rest of the
                  selected monikers.
                </p>
              </>
            )}
            <div className="flex justify-end gap-4 pt-4">
              <CommonButton isDisabled={false} variant="text" title={`Cancel`} onClick={onClose} />
              <CommonButton
                isLoading={isLoading}
                variant="primary"
                title="Download"
                onClick={handleSubmit}
                isDisabled={false}
              />
            </div>
          </>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default DownloadBulkProductionOrderModal;
