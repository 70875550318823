import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

interface Breadcrumb {
  label: string;
  href: string;
  active?: boolean;
}

export default function Breadcrumbs({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) {
  return (
    <nav aria-label="Breadcrumb" className="block">
      <ol className={clsx('flex text-md md:text-lg')}>
        {breadcrumbs.map((breadcrumb, index) => (
          <li
            key={breadcrumb.href}
            aria-current={breadcrumb.active}
            className={clsx('font-bold', breadcrumb.active ? 'text-grey-500' : 'text-blue-400')}
          >
            {!breadcrumb.label?.includes('undefined') && (
              <Link to={breadcrumb.href}>{breadcrumb.label}</Link>
            )}
            {index < breadcrumbs.length - 1 ? <span className="mx-3 inline-block">/</span> : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}
