import logoImageName from '@assets/icons/satis_plus_logo_graphic_name.png';

const LoginHeader = () => {
  return (
    <div className="flex flex-row gap-6 pb-6">
      <img src={logoImageName} alt="Logo" className="h-30 w-full" />
    </div>
  );
};

export default LoginHeader;
