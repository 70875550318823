import { MonikerOutcomeStatus } from '@components/common/StatusComponent';
import {
  accountTypes,
  classifications,
  countries,
  divisions,
  financialExchangePlatform,
  platform,
  reportStatus,
  scamEnablerOutcomes,
  scamType,
  transactionTypes,
} from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { getFileMimeTypes, validateIdNotNil } from '@utils/utils';
import validator from 'validator';
import {
  carousellItems,
  defaultItems,
  emailItems,
  googlePlaystoreApplicationItems,
  instagramItems,
  LazadaItems,
  phoneNumberUserIdItems,
  ShopeeItems,
  telegramItems,
  twitterItems,
  urlItems,
  urlItemsMainTable,
  whatsappItems,
} from './CreateReportPage/CreateReportFormConfig';

export const faceBookItems: Array<FormItemInterface> = [{ label: 'URL', type: 'label', id: 'url' }];

export const monikerGenericItems: Array<FormItemInterface> = [
  { label: 'User ID', type: 'label', id: 'userId' },
  // { label: "Scam Type", type: "label", id: "amountTransferred" },
  { label: 'Victim report', type: 'label', id: 'victimReported' },
  { label: 'Brief facts of case', type: 'label', id: 'explanation' },
  {
    label: 'Interactions to any posts/ads',
    type: 'label',
    id: 'interactionPostAds',
  },
  { label: 'No. of Victims', type: 'label', id: 'numberOfVictims' },
  {
    label: 'Moniker Status',
    type: 'labelEditDropdown',
    id: 'outcome',
    extras: {
      updateAPI: 'updateScamEnablerOutcomeV1',
      dropdownData: scamEnablerOutcomes,
    },
  },
  { label: 'Previously terminated', type: 'label', id: 'relatedTerminated' },
  { label: "Scammer's Contact No.", type: 'label', id: 'scammerContactNumber' },
  {
    label: 'Date Replied',
    type: 'labelEditDateTime',
    id: 'closedAt',
    extras: {
      updateAPI: 'updateScamEnablerV1',
    },
  },
  { label: 'Review TAT', type: 'label', id: 'reviewTAT' },
  { label: 'Return TAT', type: 'label', id: 'returnTAT' },
  // { label: "Report Reference ID", type: "label", id: "reportReferenceId" },
  { label: 'Related Reports', type: 'reports', id: 'relatedReports' },
];

export const POQueuemonikerGenericItems: Array<FormItemInterface> = [
  { label: 'Platform', type: 'label', id: 'platform' },
  { label: 'User ID', type: 'label', id: 'userId' },
  // { label: "Scam Type", type: "label", id: "amountTransferred" },
  { label: 'Victim report', type: 'label', id: 'victimReported' },
  { label: 'Brief facts of case', type: 'label', id: 'explanation' },
  {
    label: 'Interactions to any posts/ads',
    type: 'label',
    id: 'interactionPostAds',
  },
  { label: 'No. of Victims', type: 'label', id: 'numberOfVictims' },
  {
    label: 'Amount of Losses',
    type: 'transactionAmountLabel',
    id: 'amountTransferred',
  },
  {
    label: 'Moniker Status',
    type: 'labelEditDropdown',
    id: 'outcome',
    extras: {
      updateAPI: 'updateScamEnablerOutcomeV2',
      dropdownData: scamEnablerOutcomes,
    },
  },
  {
    label: 'Date Replied',
    type: 'labelEditDateTime',
    id: 'closedAt',
    extras: {
      updateAPI: 'updateScamEnablerV1',
      extras: {
        updateAPI: 'updateScamEnablerV1',
      },
    },
  },
  {
    label: 'Report No.',
    type: 'link',
    id: 'reportNumber',
    extras: {
      link: '/admin/reports',
      pathParam: 'reportId',
    },
  },
];

export const overviewItems: Array<FormItemInterface> = [
  {
    label: 'Report No.',
    id: 'reportNumber',
    type: 'label',
  },
  {
    label: 'Report Status',
    id: 'reportStatus',
    type: 'labelEditDropdown',
    extras: {
      dropdownData: reportStatus,
      updateAPI: 'updateReportStatusV1',
    },
  },
  {
    label: 'Date of Creation (Latest)',
    id: 'createdAt',
    type: 'label',
  },
  {
    label: 'Date of Referral',
    id: 'referralDate',
    type: 'label',
    extras: {
      tooltip: {
        content: `This refers to the date when the report was first referred to ASC. It will follow Date of Creation by default but it can be edited accordingly if required.`,
      },
    },
  },
  {
    label: 'Classification',
    id: 'classification',
    type: 'label',
  },
  {
    label: 'Scam Type',
    id: 'scamType',
    type: 'label',
  },
  {
    label: 'Scam Sub-Type',
    id: 'scamSubType',
    type: 'label',
  },
  {
    label: 'Division Assigned',
    id: 'divisionAssigned',
    type: 'label',
  },
  {
    label: 'Date Assigned to Division',
    id: 'divisionAssignedDate',
    type: 'label',
  },
  {
    label: 'Submissions',
    id: 'submissions',
    type: 'submissions',
  },
];

export const overviewEditForm: Array<FormItemInterface> = [
  {
    label: 'Report No.',
    id: 'reportNumber',
    type: 'label',
    disabled: true,
  },
  {
    label: 'Report Status',
    id: 'reportStatus',
    type: 'dropdown',
    extras: {
      dropdownData: reportStatus,
    },
    disabled: true,
  },
  {
    label: 'Date of Creation (Latest)',
    id: 'createdAt',
    type: 'label',
    disabled: true,
  },
  {
    label: 'Date of Referral',
    id: 'referralDate',
    type: 'label',
    disabled: true,
  },
  {
    label: 'Classification',
    id: 'classification',
    type: 'dropdown',
    extras: {
      dropdownData: classifications,
    },
  },
  {
    label: 'Scam Type',
    id: 'scamType',
    type: 'dropdown',
    extras: {
      dropdownData: scamType,
    },
  },
  {
    label: 'Scam Sub-Type',
    id: 'scamSubType',
    type: 'textInput',
  },
  {
    label: 'Division Assigned',
    id: 'divisionAssigned',
    type: 'dropdown',
    extras: {
      dropdownData: divisions,
    },
  },
  {
    label: 'Date Assigned to Division',
    id: 'divisionAssignedDate',
    type: 'calendar',
  },
  {
    label: 'Submissions',
    id: 'submissions',
    type: 'submissions',
    disabled: true,
  },
];

export const reportItemsGeneral: Array<FormItemInterface> = [
  {
    label: 'Date Received',
    type: 'date',
    id: 'createdAt',
  },
  {
    label: 'Police Station',
    type: 'label',
    id: 'policeStation',
  },
  {
    label: 'Submitted By',
    type: 'label',
    id: 'spfEmail',
  },
  {
    label: 'Scam Type',
    type: 'label',
    id: 'scamType',
  },
];

export const assessmentFindingsItems: Array<FormItemInterface> = [
  {
    label: 'Proposer (DA1)',
    type: 'label',
    id: 'da1',
  },
  {
    label: 'Reviewer (DA2',
    type: 'label',
    id: 'da2',
  },
];

export const scamItemsEnablersGeneralItems: Array<FormItemInterface> = [
  { label: 'User ID', type: 'label', id: 'userId' },
  { label: 'Use of Scam Enabler', type: 'label', id: 'useOfScamEnabler' },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'scamEnablerId',
    extras: {
      updateAPI: 'uploadScamEnablerAttachmentV2',
      attachmentPath: '/v1/report/attachment/',
      fileType: [
        'image/png',
        'image/jpeg',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ],
    },
  },
];

export const transactionsGeneralItems: Array<FormItemInterface> = [
  { label: 'Date of Transfer', type: 'label', id: 'dateOfTransfer' },
  { label: 'Receiving banks/Paynow', type: 'label', id: 'platform' },
  { label: 'Account No.', type: 'label', id: 'transactionReference' },
  { label: 'Amount of Losses', type: 'label', id: 'amount' },
];

export const reportFilterGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Date Received',
    type: 'calendarRange',
    id: 'created_datetime_from,created_datetime_to',
    placeholder: 'dd/mm/yyyy HH:MM',
    alignment: 'row',
    extras: {
      showTime: true,
    },
  },
  {
    label: 'Classification',
    type: 'dropdownMulti',
    id: 'classification',
    placeholder: 'Please Select',
    extras: { dropdownData: classifications },
    alignment: 'row',
  },
  {
    label: 'Scam Type',
    type: 'dropdownMulti',
    id: 'scam_type',
    placeholder: 'Please Select',
    extras: { dropdownData: scamType },
    alignment: 'row',
  },
  {
    label: 'Report Status',
    type: 'dropdownMulti',
    id: 'report_status',
    extras: { dropdownData: reportStatus },
    placeholder: 'Please select',
    alignment: 'row',
  },
];

export const monikerScamEnablerDetailForm: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'label',
    id: 'scamEnablerUserId',
  },
  {
    label: 'Profile Name',
    type: 'label',
    id: 'profileName',
  },
  {
    label: 'URL',
    type: 'label',
    id: 'url',
  },
  {
    label: 'Moniker Status',
    type: 'labelEditDropdown',
    id: 'outcome',
    extras: {
      dropdownData: MonikerOutcomeStatus,
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      attachmentPath: '/v1/report/attachment/',
      fileType: [
        'image/png',
        'image/jpeg',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ],
    },
  },
];

export const submissionDetailsGeneralInfo: Array<FormItemInterface> = [
  {
    label: 'Date Received',
    id: 'createdAt',
    type: 'date',
  },
  {
    label: 'Submitted by',
    id: 'spfEmail',
    type: 'label',
  },
  {
    label: 'Police Station/Unit',
    id: 'policeStation',
    type: 'label',
  },
  {
    label: 'Report No.',
    id: 'reportNumber',
    type: 'label',
  },
  {
    label: 'Classification',
    id: 'classification',
    type: 'label',
  },
  {
    label: 'Scam Type',
    id: 'scamType',
    type: 'label',
  },
  {
    label: 'Scam Sub-Type',
    id: 'scamSubType',
    type: 'label',
  },
];

export const submissionDetailsScamEnablerMoniker: Array<FormItemInterface> = [
  {
    label: 'How was this scam enabler used',
    id: 'useOfScamEnabler',
    type: 'label',
  },
  {
    label: 'Platform',
    id: 'platform',
    type: 'label',
  },
  {
    label: 'User ID',
    id: 'scamEnablerUserId',
    type: 'label',
  },
  {
    label: 'URL',
    id: 'url',
    type: 'label',
  },
  {
    label: 'Profile Name',
    id: 'profileName',
    type: 'label',
  },
  {
    label: 'Screenshots',
    id: 'attachments',
    type: 'screenshot',
    extras: {
      allowPaste: false,
      attachmentPath: '/v1/report/attachment/',
    },
  },
];

export const submissionDetailsScamEnablerBank: Array<FormItemInterface> = [
  {
    label: 'How was this scam enabler used',
    id: 'useOfScamEnabler',
    type: 'label',
  },
  {
    label: 'Payment Channel',
    id: 'platform',
    type: 'label',
  },
  {
    label: 'Account No.',
    id: 'accountNumber',
    type: 'label',
  },
  {
    label: 'Account Type',
    id: 'accountType',
    type: 'label',
  },
  {
    label: 'Transaction Type',
    id: 'transactionType',
    type: 'label',
  },
  {
    label: 'Transactions',
    id: 'transactions',
    type: 'dynamicTable',
    extras: {
      tableFields: [
        {
          label: 'Date of Transfer',
          type: 'label',
          id: 'dateOfTransfer',
        },
        {
          label: 'Amount Loss',
          type: 'label',
          id: 'amountLoss',
        },
        {
          label: 'Transaction Type',
          id: 'transactionType',
          type: 'label',
        },
      ],
    },
    optional: true,
    alignment: 'column',
  },
  {
    label: 'Additional Info for Email PO',
    id: 'additionalInformation',
    type: 'label',
  },
];

export const submissionDetailsScamEnablerPaynow: Array<FormItemInterface> = [
  {
    label: 'How was this scam enabler used',
    id: 'useOfScamEnabler',
    type: 'label',
  },
  {
    label: 'Payment Channel',
    id: 'platform',
    type: 'label',
  },
  {
    label: 'HP/NRIC/FIN/UEN',
    id: 'paynowNumber',
    type: 'label',
  },
  {
    label: 'Bank Display Name',
    id: 'bankDisplayName',
    type: 'label',
  },
  {
    label: 'Transactions',
    id: 'transactions',
    type: 'dynamicTable',
    extras: {
      defaultRow: {
        dateOfTransfer: '',
        amountLoss: '',
        currency: 'SGD',
        transactionType: 'L-L',
      },
      tableFields: [
        {
          label: 'Date of Transfer',
          type: 'date',
          id: 'dateOfTransfer',
        },
        {
          label: 'Amount Loss',
          type: 'label',
          id: 'amountLoss',
        },
        {
          label: 'Transaction Type',
          id: 'transactionType',
          type: 'label',
        },
      ],
    },
    optional: true,
    alignment: 'column',
  },
];
let platformFields: any = {
  '': [],
  Facebook: [
    {
      label: 'URL',
      type: 'textInput',
      id: 'url',
      alignment: 'column',
      extras: {
        keyup: (value: string) => {
          if (value.toLowerCase().startsWith('facebook.com/')) {
            return `https://www.${value}`;
          } else if (value.toLowerCase().startsWith('www.facebook.com/')) {
            return `https://${value}`;
          } else {
            return value;
          }
        },
      },
      rules: {
        required: 'Facebook URL is required',
        validate: {
          startsWithFacebook: (value: string) => {
            if (!value.toLowerCase().startsWith('https://www.facebook.com/'))
              return 'URL must start with https://www.facebook.com/';
          },
        },
      },
    },
    {
      label: 'Facebook User ID',
      type: 'textInput',
      id: 'userId',
      alignment: 'column',
      // extras: { prefix: "FBID#" },
      rules: {
        required: 'Facebook User Id is required',
        pattern: {},
        validate: {
          isURL: (value: string) => {
            if (validator.isURL(value)) {
              return 'Please do not include "www.facebook.com" in the Facebook handle';
            }
          },
          isNotNil: (value: string) => {
            return validateIdNotNil(value);
          },
        },
      },
    },
    {
      label: 'Screenshot(s)',
      type: 'fileUpload',
      id: 'attachments',
      extras: {
        fileType: getFileMimeTypes(['image', 'pdf', 'word']),
        maxFiles: 3,
      },
      alignment: 'column',
      optional: true,
    },
  ],
  Instagram: instagramItems,
  'Google Playstore Application': googlePlaystoreApplicationItems,
  Carousell: carousellItems,
  Lazada: LazadaItems,
  Shopee: ShopeeItems,
  Telegram: telegramItems,
  X: twitterItems,
  Whatsapp: whatsappItems,
  Email: emailItems,
  'LINE App': phoneNumberUserIdItems,
  WeChat: phoneNumberUserIdItems,
  Website: [],
};

export const ScamEnablerMonikerForm = (platformStr: string) => [
  {
    label: 'Platform',
    id: 'platform',
    type: 'dropdown',
    alignment: 'column',
    extras: {
      dropdownData: platform,
    },
  },
  ...(platformFields[platformStr] ? platformFields[platformStr] : defaultItems),
];

const createReportPaynowFields = [
  {
    label: 'HP/NRIC/FIN/UEN',
    id: 'paynowNumber',
    type: 'textInput',
    alignment: 'column',
    rules: {
      required: 'HP/NRIC/FIN/UEN is required',
    },
  },
  {
    label: 'Bank Display Name',
    id: 'bankDisplayName',
    type: 'textInput',
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Transactions',
    id: 'transactions',
    type: 'dynamicTable',
    extras: {
      defaultRow: {
        dateOfTransfer: '',
        amountLoss: '',
        currency: 'SGD',
        transactionType: 'L-L',
      },
      tableFields: [
        {
          label: 'Date of Transfer',
          type: 'calendar',
          id: 'dateOfTransfer',
          extras: {
            removePopupContainer: true,
          },
        },
        {
          label: 'Amount Loss',
          type: 'textInputSelect',
          id: 'amountLoss',
          extras: {
            idCurrency: 'currency',
          },
        },
        {
          label: 'Transaction Type',
          id: 'transactionType',
          type: 'dropdown',
          alignment: 'column',
          optional: true,
          extras: {
            dropdownData: transactionTypes,
            // defaultValue: 'Local - Local',
          },
        },
      ],
    },
    optional: true,
    alignment: 'column',
  },
];

const createrReportBankFields = [
  {
    label: 'Account No.',
    id: 'accountNumber',
    type: 'textInput',
    alignment: 'column',
    rules: {
      required: 'Account No is required',
    },
  },
  {
    label: 'Account Type',
    id: 'accountType',
    type: 'dropdown',
    alignment: 'column',
    optional: true,
    extras: {
      dropdownData: accountTypes,
      // defaultValue: 'Personal',
    },
  },

  {
    label: 'Transactions',
    id: 'transactions',
    type: 'dynamicTable',
    extras: {
      defaultRow: {
        dateOfTransfer: '',
        amountLoss: '',
        currency: 'SGD',
        transactionType: 'L-L',
      },
      tableFields: [
        {
          label: 'Date of Transfer',
          type: 'calendar',
          id: 'dateOfTransfer',
          extras: {
            removePopupContainer: true,
          },
        },
        {
          label: 'Amount Loss',
          type: 'textInputSelect',
          id: 'amountLoss',
          extras: {
            idCurrency: 'currency',
          },
        },
        {
          label: 'Transaction Type',
          id: 'transactionType',
          type: 'dropdown',
          alignment: 'column',
          optional: true,
          extras: {
            dropdownData: transactionTypes,
            // defaultValue: 'Local - Local',
          },
        },
      ],
    },
    optional: true,
    alignment: 'column',
  },
  {
    label: 'Additional Info for Email PO',
    id: 'additionalInformation',
    type: 'textInput',
    alignment: 'column',
    optional: true,
    extras: {
      rows: 3,
    },
  },
];

export const ScamEnablerPaymentChannelForm = (platform: string) => {
  return platform === 'Paynow' ? createReportPaynowFields : createrReportBankFields;
};
