import { CommonButton } from '@components/common/CommonButton';
import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';

const ReassignmentConfirmationModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  isLoading,
}: {
  isOpen: boolean;
  handleClose: any;
  handleSubmit: any;
  isLoading: boolean;
}) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalLayout
        title="Confirmation"
        handleCloseModal={handleClose}
        alignment="center"
        size="small"
      >
        <p>
          The required boxes for the selected channel/direction is not checked. This means that you
          DO NOT approve the channel/direction proposed by DA1
        </p>
        <p className="py-2">
          By saving this, this moniker will be reassigned back to DA1 for reassessment.
        </p>

        <p>Are you sure you want to proceed?</p>
        <p>You cannot undo this action.</p>
        <div className="flex justify-end gap-4 pt-4">
          <CommonButton isDisabled={false} variant="text" title={`Cancel`} onClick={handleClose} />
          <CommonButton
            isLoading={isLoading}
            variant="warning"
            title="Proceed with Reassessment"
            onClick={handleSubmit}
          />
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default ReassignmentConfirmationModal;
