// DateTimeForm.tsx
import { useForm } from 'react-hook-form';
import { IconButton } from './CommonButton';
import DatePickerComponent from './DatePicker';

interface DateTimeFormProps {
  id: string;
  onSubmit: any;
  onCancel: () => void;
  isLoading: boolean;
  rules: any;
}

export const DateTimeForm: React.FC<DateTimeFormProps> = ({
  id,
  onSubmit,
  onCancel,
  isLoading,
  rules,
}) => {
  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  return (
    <form
      className="flex flex-row gap-2 items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <DatePickerComponent
        showTime={true}
        id={id}
        control={control}
        rules={rules}
        removePopupContainer={true}
      />
      <IconButton isDisabled={false} icon="cross" onClick={onCancel} isLoading={false} />
      {formState.isValid && (
        <IconButton isDisabled={false} icon="tick" type="submit" isLoading={isLoading} />
      )}
    </form>
  );
};
