// alertsSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const AlertSlice = createSlice({
  name: "alerts",
  reducerPath: "alerts",
  initialState: null,
  reducers: {
    showAlert: (state, action) => {
      return action.payload;
    },
    hideAlert: () => null,
  },
});

export const { showAlert, hideAlert } = AlertSlice.actions;
