import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AssessmentFindingsMonikerPresubmitChecklist1 from './AssessmentFindingsMonikerPresubmitChecklist1';
import AssessmentFindingsMonikerPresubmitChecklist2 from './AssessmentFindingsMonikerPresubmitChecklist2';
import cloneDeep from 'lodash.clonedeep';
import CommunityGuidelineConfirmationModal from '../../modals/CommunityGuidelineConfirmationModal';
import { IconButton } from '@components/common/CommonButton';

const AssessmentFindingsDetailsComponent = ({
  data,
  callback,
  currentRole,
  isLoading,
  productionOrder,
  onReturn,
}: {
  data?: any;
  callback?: any;
  currentRole: string;
  currentUser: any;
  isLoading: boolean;
  scamEnablerId: string | undefined;
  productionOrder?: any;
  onReturn: () => void;
}) => {
  const [tabValue, setTabValue] = useState(0);

  const [showOcha, setShowOcha] = useState(true);
  const [showCommunityGuidelineConfirmationModal, setShowCommunityGuidelineConfirmationModal] =
    useState(false);
  const [isDA2Assigned, setIsDA2Assigned] = useState(false);
  const [isDA1FormEditing, setIsDA1FormEditing] = useState(false);
  const [isDA2FormEditing, setIsDA2FormEditing] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [isReassign, setIsReassign] = useState(false);
  const formMethodsDa1 = useForm({
    mode: 'onChange',
  });
  const formMethodsDa2 = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (data?.scamEnabler?.outcome === 'Pending Decision') {
      setTabValue(1);
    }
    if (
      data?.scamEnabler?.outcome === 'Under Review' &&
      data?.scamEnabler?.findings?.monikerPreSubmitChecklist2
    ) {
      setIsReassign(true);
    } else {
      setIsReassign(false);
    }

    let checklist1: any;
    let checklist2: any;
    let channel;

    setShowEdit(true);
    if (productionOrder?.scamEnablers) {
      setShowEdit(false);
      if (productionOrder?.scamEnablers[0]?.findings?.monikerPreSubmitChecklist1?.checklist) {
        checklist1 = productionOrder.scamEnablers[0].findings.monikerPreSubmitChecklist1.checklist;
        channel =
          productionOrder?.scamEnablers[0].findings.monikerPreSubmitChecklist1.checklist.channel;
        if (channel !== 'OCHA') {
          setShowOcha(false);
        } else {
          setShowOcha(true);
        }
      }
      if (productionOrder?.scamEnablers[0]?.findings?.monikerPreSubmitChecklist2?.checklist) {
        checklist2 = productionOrder.scamEnablers[0].findings.monikerPreSubmitChecklist2.checklist;
      }
    }

    if (!checklist1 && data?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.checklist) {
      checklist1 = data.scamEnabler.findings.monikerPreSubmitChecklist1.checklist;
      channel = data.scamEnabler.findings.monikerPreSubmitChecklist1.checklist.channel;
      if (channel !== 'OCHA') {
        setShowOcha(false);
      } else {
        setShowOcha(true);
      }
    }

    if (!checklist2 && data?.scamEnabler?.findings?.monikerPreSubmitChecklist2?.checklist) {
      checklist2 = data.scamEnabler.findings.monikerPreSubmitChecklist2.checklist;
    }

    if (data?.scamEnabler?.assignment) {
      let isAssignedDA2 = data.scamEnabler.assignment.find(
        (assignment: any) => assignment.role === 'da2',
      );
      if (isAssignedDA2) {
        setIsDA2Assigned(true);
      } else {
        setIsDA2Assigned(false);
      }
    }

    if (checklist1) {
      for (let key in checklist1) {
        let value = checklist1[key];
        formMethodsDa1.setValue(key, value, { shouldValidate: true });
      }
    } else {
      formMethodsDa1.setValue('channel', 'OCHA');
      if (currentRole === 'da1' && !productionOrder?.scamEnablers) {
        setIsDA1FormEditing(true);
      }
      setShowOcha(true);
    }

    if (checklist2) {
      for (let key in checklist2) {
        let value = checklist2[key];
        formMethodsDa2.setValue(key, value, { shouldValidate: true });
      }
    } else {
      if (currentRole === 'da2' && !productionOrder?.scamEnablers) {
        setIsDA2FormEditing(true);
      }
    }
  }, [data, formMethodsDa1, formMethodsDa2, currentRole, productionOrder?.scamEnablers]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className="box-border flex-row bg-grey-100 h-full rounded-lg p-6">
      <div onClick={onReturn} className="flex align-middle items-center cursor-pointer pb-4">
        <IconButton icon="left_arrow_with_line" type="button" isDisabled={false} />
        <span className="text-blue-400 font-bold pl-1">Back to Assessment Findings</span>
      </div>
      <div className="flex align-middle items-center">
        <h1 className="font-bold text-lg pb-4 text-blue-500 ">Assessment Findings</h1>
      </div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        sx={{
          '.MuiTabs-flexContainer': {
            justifyContent: 'space-around',
            flexWrap: 'wrap',
          },
          '& .MuiTab-root': {
            color: 'var(--blue-400)',
            flex: 1,
            wordWrap: 'break-word',
          },
          '& .MuiTab-root.Mui-selected': {
            color: 'var(--blue-500)',
          },
          '.MuiTabs-indicator': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Tab
          label="Assessment by DA1"
          sx={{
            textTransform: 'none',
            fontWeight: 700,
            backgroundColor: tabValue === 0 ? 'var(--blue-200)' : 'transparent',
            borderRadius: '5px',
          }}
        />
        <Tab
          label="Decision by DA2"
          sx={{
            textTransform: 'none',
            fontWeight: 700,
            backgroundColor: tabValue === 1 ? 'var(--blue-200)' : 'transparent',
            borderRadius: '5px',
          }}
        />
      </Tabs>
      {tabValue === 0 && (
        <div className="mt-4">
          <AssessmentFindingsMonikerPresubmitChecklist1
            formMethods={formMethodsDa1}
            isReassigned={isReassign}
            showOcha={showOcha}
            setShowOcha={setShowOcha}
            data={cloneDeep(data)}
            currentRole={currentRole}
            callback={callback}
            isDA2Assigned={isDA2Assigned}
            isEditing={isDA1FormEditing}
            setIsEditing={setIsDA1FormEditing}
            isLoading={isLoading}
            showEdit={showEdit}
            onReturn={onReturn}
          />
        </div>
      )}
      {tabValue === 1 && (
        <div className="mt-4">
          <AssessmentFindingsMonikerPresubmitChecklist2
            formMethods={formMethodsDa2}
            data={cloneDeep(data)}
            showOcha={showOcha}
            currentRole={currentRole}
            callback={callback}
            isDA2Assigned={isDA2Assigned}
            isEditing={isDA2FormEditing}
            setIsEditing={setIsDA2FormEditing}
            isLoading={isLoading}
            showEdit={showEdit}
            onReturn={onReturn}
          />
        </div>
      )}
      <CommunityGuidelineConfirmationModal
        isOpen={showCommunityGuidelineConfirmationModal}
        onClose={() => {
          setShowCommunityGuidelineConfirmationModal(false);
        }}
      />
    </div>
  );
};

export default AssessmentFindingsDetailsComponent;
