import { Table, Pagination, ConfigProvider } from 'antd';
import { DebounceInput } from 'react-debounce-input';
import { AddReportButton, DownloadReportsButton } from '../Buttons';
import cloneDeep from 'lodash.clonedeep';
import getColumns from './getColumns';
import useSearch from '@components/common/useSearch';
import useTableParams from '@components/common/useTableParams';
import { useEffect, useRef, useState } from 'react';
import { CommonButton } from '@components/common/CommonButton';
import FilterModal from '@components/common/FilterModal';
import CheckReportsModal from '../modals/CheckReportsModal';
import { useForm } from 'react-hook-form';
import FilterAssets from '@components/common/FilterAssets';
import { reportFilterGeneralItems } from '../ReportFormConfig';
import AccessControl from '@components/common/AccessControl';
import { useGetReportInfoQuery } from '@api/reportsSlice';
import dayjs from 'dayjs';

const reportsTableParamsLocalStorageKey = 'reportsTableParams';

export default function ReportTable({ platforms }: { platforms?: string }) {
  const { searchTerm, handleSearch } = useSearch();
  const today = dayjs(new Date()).format('DD MMM YYYY');

  const setDefaultFilterValues = () => {
    const currentTime = dayjs();
    const today2000 = currentTime.hour(20).minute(0).second(0).millisecond(0);

    let date_from: any = '';
    let date_to: any = '';
    if (currentTime.isBefore(today2000)) {
      date_from = currentTime.hour(8).minute(0).second(0).millisecond(0);
      date_to = date_from.add(12, 'hour');

      date_from = date_from.format('YYYY-MM-DD HH:mm');
      date_to = date_to.format('YYYY-MM-DD HH:mm');
    } else {
      date_from = currentTime.hour(20).minute(0).second(0).millisecond(0);
      date_to = date_from.add(12, 'hour');

      date_from = date_from.format('YYYY-MM-DD HH:mm');
      date_to = date_to.format('YYYY-MM-DD HH:mm');
    }

    let date_from_timestamp = Date.parse(date_from);
    let date_to_timestamp = Date.parse(date_to);

    let defaultValues = {
      report_status: [],
      scam_type: [],
      'created_datetime_from,created_datetime_to': [date_from, date_to],
      created_datetime_from: date_from_timestamp,
      created_datetime_to: date_to_timestamp,
      classification: [],
    };

    if (localStorage.getItem(reportsTableParamsLocalStorageKey)) {
      let localStorageParamsValues = localStorage.getItem(reportsTableParamsLocalStorageKey)
        ? JSON.parse(localStorage.getItem(reportsTableParamsLocalStorageKey) as any)
        : {};

      let localStorageFilterValues = localStorageParamsValues?.filters;

      for (let filterKey in localStorageFilterValues) {
        let formItemType = reportFilterGeneralItems.filter((e) => e.id === filterKey)[0]?.type;
        if (
          formItemType === 'dropdownMulti' &&
          localStorageFilterValues[filterKey] &&
          typeof localStorageFilterValues[filterKey] === 'string'
        ) {
          localStorageFilterValues[filterKey] = localStorageFilterValues[filterKey].split(',');
        }
      }

      defaultValues = Object.assign(defaultValues, localStorageFilterValues);
    }

    return defaultValues;
  };

  const defaultFilterValues = setDefaultFilterValues();

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: defaultFilterValues,
  });

  const initialParams = {
    sort: {
      sortId: 'createdAt',
      sortOrder: 'desc' as const, // Update the sortOrder property to have a value of 'asc' as a string literal
    },
    pagination: {
      current: 1,
      pageSize: 50,
    },
    filters: defaultFilterValues,
  };

  const { tableParams, setTableParamsLocalStorage, handlePageChange, handleTableChange } =
    useTableParams(initialParams, 'reportsTableParams');
  const [filterOpen, setFilterOpen] = useState(false);
  const [checkReportOpen, setCheckReportOpen] = useState(false);

  const handleFiltersChange = (filters: any) => {
    if (filters.classification) {
      filters.classification = filters.classification.join(',');
    }
    if (filters.scam_type) {
      filters.scam_type = filters.scam_type.join(',');
    }
    if (filters.created_datetime_from) {
      filters.created_datetime_from = Date.parse(filters.created_datetime_from);
    }
    if (filters.created_datetime_to) {
      filters.created_datetime_to = Date.parse(filters.created_datetime_to);
    }

    setTableParamsLocalStorage({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
      filters,
    });
  };

  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRows(selectedRows);
    },
  };

  const { refetch, data, isSuccess, isError, isLoading, isFetching } = useGetReportInfoQuery({
    params: {
      // platforms: platforms,
      search: searchTerm,
      sort_by: tableParams.sort.sortId,
      order_by: tableParams.sort.sortOrder,
      page: tableParams.pagination.current,
      size: tableParams.pagination.pageSize,
      filters: tableParams.filters,
    },
  });

  const columns = getColumns(refetch);
  const tableRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
  }, [data]);

  return (
    <>
      <AccessControl
        type="page"
        allowedPermissions={[
          'reports.scamenablers.view',
          'reports.banks.view',
          'reports.paynows.view',
        ]}
      >
        <div className="rounded-lg bg-white p-2 md:pt-0">
          <div className="rounded-lg bg-grey-300 px-4 py-2 mt-5 mb-2 my-4 font-bold flex place-self-start">
            <div className="flex items-center">
              <div>
                <span className="text-grey-400 text-sm">TOTAL REPORTS</span>
                <br />
                <span className="text-blue-400 text-sm">{today}</span>
              </div>
              <div>
                <h2 className="mx-6">{data?.todayReportCount?.total || 0}</h2>
              </div>
            </div>
            <div className="flex items-center rounded-lg bg-grey-100 px-4 mr-4">
              <span className="text-grey-400 text-sm w-40">PENDING REVIEW</span>
              <h2 className="text-red ml-4">{data?.todayReportCount?.pendingReview || 0}</h2>
            </div>
            <div className="flex items-center rounded-lg bg-grey-100 px-4  mr-4">
              <span className="text-grey-400 text-sm w-40">REVIEW COMPLETED</span>
              <h2 className="text-green ml-4">{data?.todayReportCount?.reviewCompleted || 0}</h2>
            </div>
            <div className="flex items-center rounded-lg bg-grey-100 px-4">
              <span className="text-grey-400 text-sm w-40">IN PROGRESS</span>
              <h2 className="text-yellow ml-4">{data?.todayReportCount?.inProgress || 0}</h2>
            </div>
          </div>
          <div className="flex items-center justify-between gap-2 py-3">
            <div className="flex flex-row gap-4 items-center">
              <div className="w-64">
                <DebounceInput
                  className="w-64 h-12 p-4 bg-grey-300 rounded-[8px] border-none text-grey-500 text-sm"
                  minLength={2}
                  debounceTimeout={300}
                  placeholder="Search for reports..."
                  onChange={handleSearch}
                />
              </div>
              <CommonButton
                isDisabled={false}
                variant="secondary"
                title={`Filters`}
                onClick={() => setFilterOpen(true)}
              />
            </div>

            <div className="flex flex-row gap-2 mr-4">
              <DownloadReportsButton />
              <AddReportButton refetch={() => setTimeout(refetch, 2000)} />
              {/* {
                <CommonButton
                  isDisabled={false}
                  variant="primary"
                  title={`Check Reports`}
                  onClick={() => setCheckReportOpen(true)}
                />
              } */}
              {/* <AssignButton
                selectedReports={selectedRows}
                showSelectedReports={true}
                callback={refetch}
                isDisabled={selectedRows.length <= 0}
              /> */}
            </div>
          </div>
          <FilterAssets
            defaultValues={defaultFilterValues}
            formMethods={formMethods}
            handleFiltersChange={handleFiltersChange}
          />
          <div className="max-h-[calc(100vh-320px)] overflow-auto" ref={tableRef}>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    /* here is your component tokens */
                    headerBg: '#D0DEE0',
                  },
                },
              }}
            >
              <Table
                rowSelection={{ ...rowSelection }}
                dataSource={cloneDeep(data?.reports)}
                columns={columns}
                // rowKey="reportId"
                rowKey="_id"
                pagination={false}
                onChange={handleTableChange}
                loading={isLoading || isFetching}
                sticky
                scroll={{ x: 'max-content', y: 'max-content' }}
              />
            </ConfigProvider>
          </div>

          <Pagination
            className="pt-2 flex justify-end"
            defaultCurrent={1}
            current={tableParams.pagination.current}
            total={data?.totalNumberOfRecords}
            onChange={handlePageChange}
            showSizeChanger={true}
            defaultPageSize={50}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          />
        </div>
        <CheckReportsModal
          isOpen={checkReportOpen}
          onModalClose={() => setCheckReportOpen(false)}
        />
        <FilterModal
          filterOpen={filterOpen}
          formItems={reportFilterGeneralItems}
          formMethods={formMethods}
          onClearFilters={() => formMethods.reset(defaultFilterValues)}
          onFilterClose={() => setFilterOpen(false)}
          onSubmitFilter={(filters: any) => {
            handleFiltersChange(filters);
          }}
        />
      </AccessControl>
    </>
  );
}
