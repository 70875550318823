import { FormItemInterface } from '@interface/dynamicFormInterface';

export const overviewForm: Array<FormItemInterface> = [
  {
    label: 'Account No.',
    id: 'accountNumber',
    type: 'label',
  },
  {
    label: 'Name of Bank',
    id: 'platform',
    type: 'label',
  },
  {
    label: 'Account Status',
    id: 'outcome',
    type: 'bankStatus',
  },
  {
    label: 'Account Type',
    id: 'accountType',
    type: 'label',
  },
  {
    label: 'Transaction Type',
    id: 'transactionType',
    type: 'label',
  },
  {
    label: 'First Incident Report (FIR)',
    id: 'fir',
    type: 'link',
  },
  {
    label: 'FIR DO',
    id: 'firDO',
    type: 'label',
  },
  {
    label: 'Additional info for Email PO \n(For this report only)',
    id: 'additionalInformation',
    type: 'label',
  },
];

export const transactionDetailsForm: Array<FormItemInterface> = [
  {
    label: 'Total Amount Scammed (SGD)',
    id: 'totalAmtScammed',
    type: 'label',
  },
  {
    label: 'Transactions',
    id: 'transactions',
    type: 'dynamicTable',
    alignment: 'column',
    extras: {
      tableFields: [
        {
          label: 'Date of Transfer',
          type: 'label',
          id: 'dateOfTransfer',
        },
        {
          label: 'Amount Loss',
          type: 'label',
          id: 'amountLoss',
        },
        {
          label: 'Transaction Type',
          id: 'transactionType',
          type: 'label',
        },
      ],
    },
  },
];
