import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';
import UpdateUserComponent from './UpdateUserComponent';

const UpdateUserModal = ({
  isOpen,
  onModalClose,
}: {
  isOpen: boolean;
  onModalClose: () => void;
}) => {
  return (
    <Modal open={isOpen} onClose={onModalClose}>
      <ModalLayout
        title={`Update User`}
        handleCloseModal={onModalClose}
        alignment="center"
        size="small"
      >
        <UpdateUserComponent onModalClose={onModalClose}/>
      </ModalLayout>
    </Modal>
  );
};

export default UpdateUserModal;
