import { ReactComponent as Warning } from '@assets/icons/warning_yellow.svg';

const CustomBadge = ({ text }: { text: string }) => {
  let badgeClass = 'inline-block rounded px-2 py-1 text-xs font-semibold border border-current';
  switch (text) {
    case 'Submitting a fresh report':
    case 'Community Guideline':
      badgeClass += ' bg-[var(--green-200)] text-[var(--green)]';
      break;
    case 'The previous submission is incorrect':
      badgeClass += ' bg-[var(--orange-200)] text-[var(--orange)]';
      break;
    case 'Adding to the previous submission (new scam enablers found)':
      text = 'Add on to the previous submission';
      badgeClass += ' bg-[var(--yellow-200)] text-[var(--yellow)]';
      break;
    case 'OCHA':
      badgeClass += ' bg-[#EED0DD] text-[var(--pink)]';
      break;
    case 'Previously Not terminated':
      text = 'Previously Not terminated';
      badgeClass += ' bg-[var(--yellow-200)] text-[var(--yellow)] flex flex-row items-center gap-1';
      return (
        <span className={badgeClass}>
          <Warning /> {text}
        </span>
      );
      case 'Previously terminated':
      text = 'Previously terminated';
      badgeClass += ' bg-[var(--yellow-200)] text-[var(--yellow)] flex flex-row items-center gap-1';
      return (
        <span className={badgeClass}>
          <Warning /> {text}
        </span>
      );

    default:
      break;
  }
  return <span className={badgeClass}>{text}</span>;
};

export default CustomBadge;
