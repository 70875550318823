import { CommonButton, CommonButtonGroup, IconButton } from '@components/common/CommonButton';
import { useState } from 'react';
import ProactiveDetailedViewModal from './modals/ViewProactiveDetailedModal';
import CreateProactiveMonikerModal from './modals/CreateProactiveMonikerModal';
import { ReportInterface } from '@interface/ScamEnablerInterface';
import AssignProactiveMonikerModal from './modals/AssignProactiveMonikerModal';
import UpdateProactiveMonikerModal from './modals/UpdateProactiveMonikerModal';
import CreateProactiveMonikerExcelModal from './modals/CreateProactiveMonikerExcelModal';
import DownloadDateRangeModal from '../monikers/modals/DownloadDateRangeModal';
import ViewProactiveActivityLogsModal from './modals/ViewProactiveActivityLogsModal';
import { useDownloadProactiveScamEnablerFileForSubmission } from '@api/scamEnablerSlice';
import { showAlert } from '@store/alertSlice';
import { useDispatch } from 'react-redux';

export function ViewProactiveMonikerButton({
  record,
  refetch,
}: {
  record: any;
  refetch: () => void;
}) {
  const [detailedViewOpen, setDetailedViewOpen] = useState(false);
  const onDetaiedViewClose = () => {
    setDetailedViewOpen(false);
  };

  return (
    <>
      <CommonButton
        isDisabled={false}
        variant="underline-text"
        title={`${record.profilename}`}
        onClick={() => setDetailedViewOpen(true)}
      />
      {detailedViewOpen && (
        <ProactiveDetailedViewModal
          record={record}
          assignOpen={detailedViewOpen}
          onAssignClose={onDetaiedViewClose}
          callback={refetch}
        />
      )}
    </>
  );
}

export function ProactiveMonikerTableGroupButtonTertiary({
  selectedScamEnablers,
}: {
  selectedScamEnablers: Array<ReportInterface>;
}) {
  const dispatch = useDispatch();
  const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false);
  const [downloadType, setDownloadType] = useState('');
  const [isDownloadingFileForSubmission, setIsDownloadingFileForSubmission] = useState(false);

  const downloadProactiveScamEnablerFileForSubmission =
    useDownloadProactiveScamEnablerFileForSubmission();

  const handleDownloadFileForSubmission = async () => {
    if (selectedScamEnablers.length <= 0) {
      dispatch(
        showAlert({
          type: 'error',
          message: `No Proactive Scam Enabler selected`,
        }),
      );
    } else {
      setIsDownloadingFileForSubmission(true);

      let proactiveScamEnablerIds = selectedScamEnablers.map((row: any) => row._id).join(',');

      let response = await downloadProactiveScamEnablerFileForSubmission(proactiveScamEnablerIds);
      setIsDownloadingFileForSubmission(false);
    }
  };

  return (
    <>
      <CommonButtonGroup
        dropdownOptions={[
          {
            key: 'statistics',
            title: 'Statistics',
            disabled: false,
            onClick: async () => {
              setDownloadType('proactiveStatistics');
              setDateRangeModalOpen(true);
            },
          },
          {
            key: 'fileForSubmission',
            title: 'File for Submission',
            disabled: selectedScamEnablers?.length === 0,
            isLoading: isDownloadingFileForSubmission,
            onClick: () => {
              handleDownloadFileForSubmission();
            },
          },
        ]}
        isDisabled={false}
        variant="secondary"
        title={`Download`}
        allowedPermissions={['portfolios.proactivescamenablers.download']}
      />
      {dateRangeModalOpen && (
        <DownloadDateRangeModal
          isOpen={dateRangeModalOpen}
          downloadType={downloadType}
          onClose={() => setDateRangeModalOpen(false)}
        />
      )}
    </>
  );
}

export function ProactiveMonikerTableGroupButtonSecondary({
  selectedScamEnablers,
  showSelectedScamEnablers,
  filters,
  callback,
}: {
  selectedScamEnablers: Array<ReportInterface>;
  showSelectedScamEnablers: boolean;
  filters?: any;
  callback: () => void;
}) {
  const [assignOpen, setAssignOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [isDownloadLoading, setIsDownLoading] = useState(false);
  const [updateCSVOpen, setUpdateCSVOpen] = useState(false);

  return (
    <>
      <CommonButtonGroup
        dropdownOptions={[
          {
            key: 'assign',
            title: 'Assign',
            disabled: selectedScamEnablers.length <= 0,
            onClick: () => {
              setAssignOpen(true);
            },
            allowedPermissions: ['portfolios.proactivescamenablers.assign'],
          },
          {
            key: 'update',
            title: 'Update Details',
            disabled: false,
            onClick: () => {
              setUpdateOpen(true);
            },
            allowedPermissions: ['portfolios.proactivescamenablers.update'],
          },
          // {
          //   key: 'UpdateCSV',
          //   title: 'Update by CSV',
          //   disabled: false,
          //   onClick: () => {
          //     setUpdateCSVOpen(true);
          //   },
          // },
          // {
          //   key: 'download',
          //   title: 'Download',
          //   disabled: false,
          //   isLoading: isDownloadLoading,
          //   onClick: async () => {
          //     setIsDownLoading(true);
          //     await downloadScamEnablerCSV(filters);
          //     setIsDownLoading(false);
          //   },
          // },
        ]}
        isDisabled={false}
        variant="secondary"
        title={`More Actions`}
        allowedPermissions={[
          'portfolios.proactivescamenablers.assign',
          'portfolios.proactivescamenablers.update',
        ]}
      />
      {assignOpen && (
        <AssignProactiveMonikerModal
          selectedScamEnablers={selectedScamEnablers}
          showSelectedScamEnablers={showSelectedScamEnablers}
          assignOpen={assignOpen}
          onAssignClose={() => setAssignOpen(false)}
          callback={callback}
        />
      )}
      {updateOpen && (
        <UpdateProactiveMonikerModal
          isOpen={updateOpen}
          handleClose={() => setUpdateOpen(false)}
          callback={callback}
          selectedScamEnablers={selectedScamEnablers}
        />
      )}
      {/* {updateCSVOpen && (
          <UploadUpdateExcelModal
            id={'fileUploadUpdate'}
            isOpen={updateCSVOpen}
            onClose={() => setUpdateCSVOpen(false)}
            callback={callback}
          />
        )} */}
    </>
  );
}

export function ProactiveMonikerTableGroupButtonPrimary({ callback }: { callback: () => void }) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);

  return (
    <>
      <CommonButtonGroup
        dropdownOptions={[
          {
            key: 'manual',
            title: 'Manual Entry',
            disabled: false,
            onClick: () => {
              setShowCreateModal(true);
            },
          },
          {
            key: 'csv',
            title: 'Upload List',
            disabled: false,
            onClick: () => {
              setDownloadOpen(true);
            },
          },
        ]}
        isDisabled={false}
        variant="primary"
        title={`Add Reports`}
        allowedPermissions={['portfolios.proactivescamenablers.create']}
      />
      {showCreateModal && (
        <CreateProactiveMonikerModal
          isOpen={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
          callback={callback}
        />
      )}
      {downloadOpen && (
        <CreateProactiveMonikerExcelModal
          id={'fileUpload'}
          isOpen={downloadOpen}
          onClose={() => setDownloadOpen(false)}
          callback={callback}
        />
      )}
    </>
  );
}

export function AssignProactiveSingleButton({
  callback,
  showSelectedScamEnablers,
  scamEnabler,
}: {
  callback: () => void;
  showSelectedScamEnablers: boolean;
  scamEnabler: ReportInterface;
}) {
  const [assignOpen, setAssignOpen] = useState(false);
  const onAssignClose = () => {
    setAssignOpen(false);
  };
  return (
    <>
      <IconButton
        onClick={() => {
          setAssignOpen(true);
        }}
        icon="assign"
        isLoading={false}
        isDisabled={false}
        allowedPermissions={['portfolios.proactivescamenablers.assign']}
      />
      {assignOpen && (
        <AssignProactiveMonikerModal
          selectedScamEnablers={[scamEnabler]}
          showSelectedScamEnablers={showSelectedScamEnablers}
          assignOpen={assignOpen}
          onAssignClose={onAssignClose}
          callback={callback}
        />
      )}
    </>
  );
}

export function ActivityLogsButton({ proactiveScamEnablerId }: { proactiveScamEnablerId: string }) {
  const [activityLogsModalOpen, setActivityLogsModalOpen] = useState(false);
  return (
    <>
      <IconButton
        onClick={() => {
          setActivityLogsModalOpen(true);
        }}
        icon="activity_log"
        isLoading={false}
        isDisabled={false}
        allowedPermissions={['portfolios.proactivescamenablers.view']}
      />
      {activityLogsModalOpen && (
        <ViewProactiveActivityLogsModal
          proactiveScamEnablerId={proactiveScamEnablerId}
          activityLogsModalOpen={activityLogsModalOpen}
          onActivityLogsModalClose={() => {
            setActivityLogsModalOpen(false);
          }}
        />
      )}
    </>
  );
}
