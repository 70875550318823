import { useState } from 'react';
import { TablePaginationConfig, TableProps } from 'antd';

export interface sortBy {
  sortId: string;
  sortOrder: 'asc' | 'desc';
}

export interface TableParams {
  pagination: TablePaginationConfig;
  sort: sortBy;
  filters?: any;
}

const useTableParams = (initialParams: TableParams, localStorageKey?: string) => {
  const [tableParams, setTableParams] = useState<TableParams>(initialParams);

  const handlePageChange = (page: number, pageSize: number) => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: page,
        pageSize: pageSize,
      },
    });
  };

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter: any) => {
    setTableParams({
      ...tableParams,
      sort: {
        sortId: sorter.field ? sorter.field : '',
        sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
      },
    });
  };

  const handleFiltersChange = (filters: any) => {
    if (filters.report_status) {
      // let newReportStatusFilters: Array<string> = [];
      // filters.report_status.forEach((report_status: string) => {
      //   let statusCode = Object.keys(reportStatusMapping).find(
      //     (key) => reportStatusMapping[key] === report_status,
      //   );
      //   if (statusCode) newReportStatusFilters.push(statusCode);
      // });
      filters.report_status = filters.report_status.join(',');
    }
    if (filters.scam_type) {
      filters.scam_type = filters.scam_type.join(',');
    }
    if (filters.classification) {
      filters.classification = filters.classification.join(',');
    }
    if (filters.date_from) {
      filters.date_from = Date.parse(filters.date_from);
    }
    if (filters.date_to) {
      filters.date_to = Date.parse(filters.date_to);
    }
    if (filters.platform) {
      filters.platform = filters.platform.join(',');
    }

    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
      filters,
    });
  };

  const setTableParamsLocalStorage = (params: any) => {
    setTableParams(params);
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(params));
    }
  }
  return { tableParams, setTableParamsLocalStorage, setTableParams, handlePageChange, handleTableChange, handleFiltersChange };
};

export default useTableParams;
