import { InputAdornment, TableCell, TableRow, TextField } from '@mui/material';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';
import UploadScreenshotModal from '@pages/reports/modals/UploadScreenshotModal';
import { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { getNestedError } from '@utils/utils';
import AddImageField from './AddImageField';

const TableRowInput = ({
  id,
  index,
  field,
  length,
  handleDelete,
  formMethods,
}: {
  id: any;
  index: any;
  field: any;
  length: number;
  handleDelete: any;
  formMethods: any;
}) => {
  const errors = formMethods.formState?.errors;
  const [showUploadScreenshotModal, setShowUploadScreenshotModal] = useState(false);
  const url = `${id}.url`;

  const validateUniqueUrl = useCallback(
    (currentValue: any) => {
      const allValues = formMethods.getValues(id.split('.')[0]);
      const isDuplicate = allValues.websites.some(
        (item: any, idx: number) => idx !== index && item.url === currentValue,
      );
      return isDuplicate ? 'URL must be unique.' : true;
    },
    [formMethods.getValues, index],
  );

  const rules = {
    ...field.rules,
    validate: {
      ...field.rules?.validate,
      uniqueUrl: validateUniqueUrl,
    },
  };

  return (
    <>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <Controller
          control={formMethods.control}
          name={url}
          rules={rules}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState, formState }) => (
            <TextField
              fullWidth
              inputRef={ref}
              InputProps={{
                endAdornment: errors && getNestedError(errors, id) && (
                  <InputAdornment position="end">
                    <Warning />
                  </InputAdornment>
                ),
              }}
              onBlur={onBlur}
              onChange={onChange}
              placeholder="Please enter..."
              sx={{
                '.MuiInputBase-input': {
                  fontSize: '14px',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: 'var(--blue-400)',
                },
              }}
              value={value ?? ''}
              variant="standard"
            />
          )}
        />
        {errors && getNestedError(errors, url) && (
          <div style={{ color: 'var(--red)' }} role="alert">
            {getNestedError(errors, url)?.message}
          </div>
        )}
      </TableCell>
      <TableCell className="flex">
        <AddImageField
          formMethods={formMethods}
          id={id}
          setShowModal={setShowUploadScreenshotModal}
        />
      </TableCell>
      <TableCell>
        {length > 1 && (
          <DeleteIcon className="cursor-pointer" onClick={() => handleDelete(index)} />
        )}
      </TableCell>

      <UploadScreenshotModal
        id={`${id}.attachments`}
        isOpen={showUploadScreenshotModal}
        onClose={() => setShowUploadScreenshotModal(false)}
        formMethods={formMethods}
        allowPaste={field?.extras?.allowPaste}
      />
    </>
  );
};

export default TableRowInput;
