import { Alert, Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { useEffect, useMemo, useState } from 'react';
import { CommonButton } from '@components/common/CommonButton';

import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import { DropdownContent } from '@components/common/DropdownContent';
import cloneDeep from 'lodash.clonedeep';
import { addToReportForm } from '../PaynowFormConfig';
import DynamicForm from '@components/common/DynamicForm';
import { useForm } from 'react-hook-form';
import { useCreateReportMutation, useLazyGetReportDetailsByIdV2Query } from '@api/reportsSlice';
import { formatEditedForm } from '@pages/reports/modals/AddScamEnablersModal';

const AddToReportConfirmationModal = ({
  selectedBankAccounts,
  data,
  addToReportOpen,
  onAddToReportClose,
}: {
  selectedBankAccounts: Array<any>;
  data: any;
  addToReportOpen: boolean;
  onAddToReportClose: () => void;
}) => {
  const [myUser, setMyUser] = useState({
    userId: '',
    firstName: '',
    lastName: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [getReportById] = useLazyGetReportDetailsByIdV2Query();

  const [createReport] = useCreateReportMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    getSessionUserFromAccessToken().then((userSession: any) => {
      setMyUser(userSession);
    });
  }, []);

  const addToReport = async () => {
    setIsSubmitting(true);

    let values = formMethods.getValues();
    let selectedReport = data?.reports.find((report: any) => {
      if (report.reportNumber == values.reportNumber) {
        return report;
      }
    });
    let result: any = await getReportById({ id: selectedReport.reportId });

    let reportDetails = result?.data?.report;
    let banks: any = [];
    let transactions = data.updatedTransactions.find((updatedTransaction: any) => {
      if (updatedTransaction.reportNumber === values.reportNumber) {
        return updatedTransaction;
      }
    })?.transactions;
    for (let bankAccount of selectedBankAccounts) {
      let temp = {
        accountNumber: bankAccount.bankAccountNumber,
        platform: bankAccount.bank,
        paynowNumber: data.paynowNumber,
        type: 'Payment',
        transactions: transactions,
      };
      banks.push(temp);
    }

    let params = formatEditedForm({ scamEnablers: banks }, reportDetails, myUser);
    let response = await createReport(params);
    if ('error' in response) {
      dispatch(
        showAlert({
          type: 'error',
          message: `${response.error}`,
        }),
      );
    } else {
      dispatch(
        showAlert({
          type: 'success',
          message: `Successfully added bank account to report.`,
        }),
      );
    }
    setIsSubmitting(false);
    onAddToReportClose();
  };

  const formMethods = useForm();

  const getFormFields = () => {
    let newFields = cloneDeep(addToReportForm);
    let dropdown = newFields.find((item: any) => {
      return item.id === 'reportNumber';
    });
    if (dropdown && data?.reports && data?.reports.length > 0)
      dropdown.extras.dropdownData = data?.reports.map((report: any) => {
        return report.reportNumber;
      });
    return newFields;
  };

  return (
    <>
      <Modal open={addToReportOpen} onClose={onAddToReportClose}>
        <ModalLayout
          title={'Add To Report'}
          handleCloseModal={onAddToReportClose}
          alignment="center"
          size="small"
        >
          <Alert severity="info" sx={{ marginBottom: 2, marginTop: -5 }}>
            For bank accounts that are already existing in the selected report, it will be grouped
            with the existing records if it is added to the report again.
          </Alert>
          <DynamicForm formFields={getFormFields()} formMethods={formMethods} isEditable={true} />
          <div className="pb-4"></div>
          <DropdownContent
            title={`Selected Bank Accounts: ${selectedBankAccounts.length}`}
            content={
              selectedBankAccounts &&
              selectedBankAccounts.map((bank: any) => (
                <div className="bg-grey-200 rounded-lg flex flex-row items-center p-4 overflow-hidden">
                  <p className="w-[50%]">{bank.bankAccountNumber}</p>
                  <p className="w-[50%]">{bank.bank}</p>
                </div>
              ))
            }
          />

          <div className="flex gap-4 mr-0 ml-auto mt-10">
            <CommonButton title="Cancel" variant="text" onClick={onAddToReportClose} />
            <CommonButton
              title="Add"
              variant="primary"
              onClick={addToReport}
              isLoading={isSubmitting}
            />
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default AddToReportConfirmationModal;
