import { BankStatus, MonikerOutcomeStatus, PaynowStatus } from '@components/common/StatusComponent';

export function ScamEnablerSelectorCard({
  platform,
  type,
  status,
  monikerId,
  isSelected,
  setIsSelected,
  isNew,
  isPaynowLinked,
}: {
  platform: string;
  type: string;
  status: string;
  monikerId: string;
  isSelected: boolean;
  setIsSelected: () => void;
  isNew?: boolean;
  isPaynowLinked?: boolean;
}) {
  return (
    <div
      className={`relative ${isSelected ? 'bg-blue-300' : 'bg-white'} w-full rounded-lg my-3 px-3 py-5 hover:bg-blue-200 hover:cursor-pointer active:border border-blue-500 bg-blue-200`}
      onClick={setIsSelected}
    >
      {/* New */}
      {isNew && (
        <div>
          <div
            style={{
              zIndex: 0,
              borderWidth: '0px 55px 55px 0',
              borderColor: 'transparent var(--blue-400) transparent transparent',
              position: 'absolute',
              right: 0,
              top: 0,
              borderTopRightRadius: '10px',
            }}
          ></div>
          <div
            className="rotate-45 font-bold text-white z-10 absolute"
            style={{
              top: '8px',
              right: '5px',
            }}
          >
            New
          </div>
        </div>
      )}
      <div className="flex justify-between mb-2">
        <p className="font-bold text-md text-blue-500">{platform}</p>

        {!isNew && (
          <>
            {type === 'moniker' && <MonikerOutcomeStatus outcome={status} textOnly={true} />}
            {type === 'bank' && <BankStatus outcome={status} textOnly={true} />}
            {type === 'paynow' && <PaynowStatus outcome={status} textOnly={true} />}
          </>
        )}
      </div>

      <p className="font-bold text-md text-blue-500 mb-2">
        {monikerId?.length > 30 ? monikerId.substring(0, 30) : monikerId}
      </p>
      {isPaynowLinked && (
        <p className="italic text-sm text-grey-400">Added from PayNow screening results</p>
      )}
    </div>
  );
}
