import { ReactComponent as Error404Icon } from '@assets/icons/error_404.svg';

const ErrorPage = () => {
  return (
    <>
      <div className="overflow-auto h-[78vh] mb-4">
        <div className="flex justify-center items-center h-full">
          <Error404Icon width="300" height="300" />
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
