import DynamicForm from '@components/common/DynamicForm';
import { overviewForm } from './bankSectionFormConfig';

function formatData(data: any) {
  let newData: any = { ...data };
  if (data.fir) {
    newData['fir'] = {
      link: `/admin/Reports/${data.fir?.reportId}`,
      display: data.fir.reportNumber,
    };
    newData['firDO'] = (data?.firDO?.firstName || '') + ' ' + (data?.firDO?.lastName || '')
  }

  return newData;
}

export function Overview({ data }: { data: any }) {
  return (
    <div>
      <DynamicForm formFields={overviewForm} data={formatData(data)} />
    </div>
  );
}
