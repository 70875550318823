import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { BACKEND_URL } from '@environment/AppEnvironment';
import ModalLayout from '../layouts/ModalLayout';
import UploadScreenshotModal from '@pages/reports/modals/UploadScreenshotModal';
import { useForm } from 'react-hook-form';
import { CommonButton, IconButton } from './CommonButton';
import {
  useDeleteProactiveScamEnablersAttachmentV2Mutation,
  useDeleteScamEnablerAttachmentV2Mutation,
  useUploadProactiveScamEnablersAttachmentV2Mutation,
  useUploadScamEnablerAttachmentV2Mutation,
} from '@api/scamEnablerSlice';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import cloneDeep from 'lodash.clonedeep';
import { base64ToBlob } from '@utils/utils';

const ScreenshotsModal = ({
  attachments,
  fieldId,
  scamEnablerId,
  isEditable,
  attachmentPath,
  updateAPI,
  callback,
  data,
  allowPaste,
  formMethods,
}: {
  attachments: Array<string>;
  fieldId: string;
  scamEnablerId: string;
  isEditable?: boolean;
  attachmentPath: string;
  updateAPI: string;
  callback?: any;
  data: any;
  allowPaste?: boolean;
  formMethods?: any;
}) => {
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState('');
  const [displayFileName, setDisplayFileName] = useState('');
  const [displayedAttachment, setDisplayedAttachment] = useState(attachments);

  const formScreenshotMethods = useForm();
  const [showUploadScreenshotModal, setShowUploadScreenshotModal] = useState(false);
  const [uploadScamEnablerAttachment] = useUploadScamEnablerAttachmentV2Mutation();
  const [deleteScamEnablerAttachment] = useDeleteScamEnablerAttachmentV2Mutation();

  const [uploadProactiveScamEnablerAttachment] =
    useUploadProactiveScamEnablersAttachmentV2Mutation();
  const [deleteProactiveScamEnablerAttachment] =
    useDeleteProactiveScamEnablersAttachmentV2Mutation();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<Record<number, boolean>>({});
  const dispatch = useDispatch();

  const handleUploadScreenshot = async () => {
    setIsLoading(true);
    let attachmentData = formScreenshotMethods.getValues();

    let tempAttachments = cloneDeep(attachmentData.attachments);
    tempAttachments.forEach((attachment: any) => {
      if (updateAPI === 'uploadProactiveScamEnablerAttachmentV2') {
        let splitFileName = attachment.fileName.split('.');
        attachment.fileName = `${data.scamEnablerUserId}.${splitFileName[splitFileName.length - 1]}`;
      }
      attachment.fileContent = attachment.fileContent.replace(/^data:.+;base64,/, '');
    });

    let response: any = {};
    if (!updateAPI && formMethods) {
      let newAttachments = displayedAttachment.concat(tempAttachments);
      formMethods.setValue(fieldId, newAttachments, { shouldDirty: true });
      setDisplayedAttachment(newAttachments);
      setIsLoading(false);

      setShowUploadScreenshotModal(false);
      return;
    } else if (updateAPI === 'uploadProactiveScamEnablerAttachmentV2') {
      response = await uploadProactiveScamEnablerAttachment({
        proactiveScamEnablerId: scamEnablerId,
        attachments: tempAttachments,
      });
    } else {
      response = await uploadScamEnablerAttachment({
        scamEnablerId: scamEnablerId,
        attachments: tempAttachments,
      });
    }

    if (response && !response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Upload file Successful`,
        }),
      );
      setShowUploadScreenshotModal(false);
      if (callback) callback();
    }
    setIsLoading(false);
  };

  const handleDeleteScreenshot = async (filename: string, index: number) => {
    let response: any = {};
    setIsLoadingDelete((prevState) => ({ ...prevState, [index]: true }));
    if (!updateAPI && formMethods) {
      console.log('attachments', { attachments, filename });
      let prevAttachments = formMethods.getValues(fieldId);

      let newAttachments = prevAttachments.filter((attachment: any) => {
        if (typeof attachment === 'object') {
          return attachment.fileName !== filename;
        } else {
          return attachment !== filename;
        }
      });

      formMethods.setValue(fieldId, newAttachments, { shouldDirty: true });
      console.log('deleted', formMethods.getValues(fieldId));
      setDisplayedAttachment(newAttachments);
      setIsLoadingDelete((prevState) => ({ ...prevState, [index]: false }));

      setShowUploadScreenshotModal(false);
      return;
    } else if (updateAPI === 'uploadProactiveScamEnablerAttachmentV2') {
      response = await deleteProactiveScamEnablerAttachment({
        proactiveScamEnablerId: scamEnablerId,
        attachment: filename,
      });
    } else {
      response = await deleteScamEnablerAttachment({
        scamEnablerId: scamEnablerId,
        attachment: filename,
      });
    }

    if (response && !response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Delete file Successful`,
        }),
      );
      if (callback) callback();
    }
    setIsLoadingDelete((prevState) => ({ ...prevState, [index]: false }));
  };
  const handleOpen = (file: any) => {
    let filename = file;
    if (typeof file === 'object' && file.fileContent) {
      const fileContent = file.fileContent;
      const blob = base64ToBlob(fileContent, file.contentType);

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.target = '_blank';
      a.rel = 'noopener noreferrer';
      // Append the anchor element to the document
      document.body.appendChild(a);

      // Trigger a click event on the anchor element
      a.click();

      // Remove the anchor element from the document
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else if (filename && filename.length > 0) {
      const fileExtension = filename.split('.').pop()?.toLowerCase() ?? ''; // Provide a default value for fileExtension
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

      let tempFileName = filename;
      let splitFile = filename.split('/');
      let tempDisplayFileName = '';
      if (splitFile.length > 1) {
        tempFileName = splitFile[0] + '/' + encodeURIComponent(splitFile[1]);
        tempDisplayFileName = splitFile[1];
      } else {
        tempFileName = scamEnablerId + '/' + encodeURIComponent(tempFileName);
        tempDisplayFileName = tempFileName;
      }

      if (!imageExtensions.includes(fileExtension)) {
        const a = document.createElement('a');

        a.href = `${BACKEND_URL}${attachmentPath}${tempFileName}`;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        // Append the anchor element to the document
        document.body.appendChild(a);

        // Trigger a click event on the anchor element
        a.click();

        // Remove the anchor element from the document
        document.body.removeChild(a);
      } else {
        setOpen(true);
        setDisplayFileName(tempDisplayFileName);
        setOpenFile(tempFileName);
      }
    }
  };
  const handleClose = () => setOpen(false);

  const getFileName = (file: any) => {
    if (typeof file === 'string') {
      return file;
    } else if (typeof file === 'object') {
      return file.fileName;
    }
  };
  return (
    <div className="flex flex-col">
      {displayedAttachment &&
        displayedAttachment.length > 0 &&
        displayedAttachment.map((file, index) => {
          return (
            <div key={index} className="break-word group">
              <div className="flex flex-row gap-2 py-1">
                <CommonButton
                  isLoading={false}
                  variant="text-blue"
                  title={
                    getFileName(file).split('/').length > 1
                      ? getFileName(file).split('/')[1]
                      : getFileName(file)
                  }
                  onClick={() => handleOpen(file)}
                  className="text-left"
                />
                {isEditable && (
                  <IconButton
                    isDisabled={false}
                    icon="delete"
                    onClick={() => handleDeleteScreenshot(getFileName(file), index)}
                    isLoading={isLoadingDelete[index]}
                    type="button"
                    className="hidden group-hover:inline-flex"
                  />
                )}
              </div>
              <Modal open={open} onClose={handleClose}>
                <ModalLayout
                  title={displayFileName}
                  handleCloseModal={handleClose}
                  alignment="center"
                  size="large"
                >
                  <img src={`${BACKEND_URL}${attachmentPath}${openFile}`} alt={openFile} />
                </ModalLayout>
              </Modal>
            </div>
          );
        })}
      {isEditable && (
        <form>
          <IconButton
            isDisabled={false}
            icon="add"
            onClick={() => setShowUploadScreenshotModal(true)}
            isLoading={false}
            type="button"
          />

          {showUploadScreenshotModal && (
            <UploadScreenshotModal
              id={`attachments`}
              isOpen={showUploadScreenshotModal}
              onClose={() => setShowUploadScreenshotModal(false)}
              formMethods={formScreenshotMethods}
              onUploadScreenshot={handleUploadScreenshot}
              isLoading={isLoading}
              allowPaste={allowPaste}
            />
          )}
        </form>
      )}
    </div>
  );
};

export default ScreenshotsModal;
