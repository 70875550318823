import Breadcrumbs from '@components/common/Breadcrumbs';
import { usePaynowDetailedPage } from './usePaynowDetailedPage';
import { PaynowDetailsOverview } from './PaynowDetailsOverview';
import { useEffect } from 'react';
import { PaynowDetailsRelatedReports } from './PaynowDetailsRelatedReports';
import { PaynowDetailsScreening } from './PaynowDetailsScreening';
import { GenerateProductionOrderPaynowButton } from '@pages/portfolios/Buttons';
import AccessControl from '@components/common/AccessControl';

const PaynowDetailedPage = () => {
  const { params, reportId, refetch, data, myUser, isLoading } = usePaynowDetailedPage();

  useEffect(() => {
    // data.createdAt = convertUnixToDynamicFormDateTime(data.createdAt);
  }, [data]);

  return (
    <div className="h-full">
      <>
        <AccessControl type="page" allowedPermissions={['portfolios.paynows.view']}>
          <div className="w-100 bg-grey-200 p-4 flex flex-row justify-between items-center">
            <Breadcrumbs
              breadcrumbs={[
                { label: 'Paynow', href: '/admin/portfolios/paynow' },
                {
                  label: data?.paynow?.paynowNumber,
                  href: reportId
                    ? `/admin/portfolios/paynows?reportId=${reportId}`
                    : '/admin/portfolios/paynows',
                  active: true,
                },
              ]}
            />
            {/* <GenerateProductionOrderPaynowButton paynowData={data} reportNumber='' callback={refetch} /> */}
          </div>
          <div className="box-border h-full flex flex-row rounded-lg bg-white p-4">
            {/* Left Column */}
            <div className="w-[30%] flex flex-col gap-5">
              <section className="box-border  h-[46%] rounded-lg bg-white ">
                <div className="box-border flex-row h-full bg-grey-100 rounded-lg p-6">
                  <PaynowDetailsOverview data={data?.paynow} refetch={refetch} />
                </div>
              </section>
              <section className="box-border  h-[46%] rounded-lg bg-white  ">
                <div className="box-border flex-row h-full bg-grey-100 rounded-lg p-6">
                  <PaynowDetailsRelatedReports data={data?.paynow} isLoading={isLoading} />
                </div>
              </section>
            </div>
            {/* Right Column */}
            <div className="w-[70%] h-full flex flex-col pl-5">
              <section className="box-border h-[95%] flex flex-col rounded-lg bg-white">
                <div className="flex-row h-full bg-grey-100 rounded-lg p-6">
                  <PaynowDetailsScreening data={data?.paynow} />
                </div>
              </section>
            </div>
          </div>
        </AccessControl>
      </>
    </div>
  );
};

export default PaynowDetailedPage;
