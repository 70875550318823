import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetupTotpMutation } from '@api/userSlice';
import { getSessionUserFromAccessToken } from '../../../utils/utils';
import LoginHeader from '@components/common/LoginHeader';
import Loading from '@components/common/Loading';
import QRCode from 'react-qr-code';
import { CommonButton } from '@components/common/CommonButton';

const SetupTotpPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [setupTotp] = useSetupTotpMutation();
  const [otpAuthUrl, setOtpAuthUrl] = useState('');

  useEffect(() => {
    const getTotpQr = async () => {
      let user = await getSessionUserFromAccessToken();

      if (user?.setupTotpRequired) {
        const response: any = await setupTotp({});

        if (!response.error) {
          setOtpAuthUrl(response.data.otpAuthUrl);
        }
      }
    };

    getTotpQr();
  }, []);

  const handleRedirect = async () => {
    let route = searchParams.get('route') || '';

    if (route) {
      route = `?route=${route}`;
    }

    navigate(`/login-totp${route}`);
  };

  return (
    <div className="md:flex flex-col items-center justify-center min-h-screen bg-white md:bg-gradient-to-b md:from-blue-300 md:to-blue-400">
      <div className="flex flex-col w-full h-full md:w-1/2 xl:w-5/12 gap-2 bg-white py-16 px-10 md:px-16 rounded-lg">
        <LoginHeader />

        <h1 className="mb-4 ml-1 text-center font-bold">Enable two-factor authentication</h1>
        <div className="flex flex-col items-start pb-4">
          <p className="pb-4">
            To complete your login, authenticate your account with these steps:
          </p>
          <ol className="list-decimal px-10">
            <li>Download an authenticator app on your mobile device (E.g. Google, Microsoft)</li>
            <li>
              In the app, add an account by scanning the QR code below: <br />
              <br />
              {!otpAuthUrl ? <Loading /> : null}
              {otpAuthUrl ? (
                <QRCode
                  size={150}
                  style={{ height: 'auto', maxWidth: '100%', margin: '15px' }}
                  value={otpAuthUrl}
                  viewBox={`0 0 256 256`}
                />
              ) : null}
              <br />
            </li>
            <li>
              Your account has been connected. When prompted to enter a 6-digit code upon login,
              open the authenticator app and enter the code shown
            </li>
          </ol>
        </div>
        <CommonButton
          className="ml-0 w-full flex justify-center"
          variant="primary"
          type="submit"
          title={'Back to login'}
          onClick={handleRedirect}
        />
      </div>
    </div>
  );
};

export default SetupTotpPage;
