import { Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton, LoadingButtonComponent } from '@components/common/CommonButton';
import { useState } from 'react';
import { ReactComponent as Warning } from '@assets/icons/warning_yellow.svg';
import { useDeleteFinancialInstituteMutation } from '@api/financialInstituteSlice';
import { useDeletePaynowMutation } from '@api/paynowSlice';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';

const ConfirmDeleteScamEnablerModal = ({
  selectedScamEnabler,
  reportId,
  type,
  confirmDeleteOpen,
  onConfirmDeleteClose,
  callback,
}: {
  selectedScamEnabler: any;
  reportId: string;
  type: string;
  confirmDeleteOpen: boolean;
  onConfirmDeleteClose: () => void;
  callback: () => void;
}) => {
  const formMethods = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [deleteFinancialInstitute] = useDeleteFinancialInstituteMutation();
  const [deletePaynow] = useDeletePaynowMutation();

  const dispatch = useDispatch();

  const handleDelete = async () => {
    if (type == 'bank') {
      setIsLoading(true);
      let result: any = await deleteFinancialInstitute({
        financialInstituteId: selectedScamEnabler._id,
        reportId: reportId,
      });
      setIsLoading(false);
      if (!result.error) {
        dispatch(
          showAlert({
            type: 'success',
            message: `${selectedScamEnabler.platform} ${selectedScamEnabler.accountNumber} deleted successfully`,
          }),
          callback(),
        );
      } else {
        dispatch(
          showAlert({
            type: 'error',
            message: `Unable to delete ${selectedScamEnabler.platform} ${selectedScamEnabler.accountNumber} due to network error. Please try again later.`,
          }),
        );
      }
    } else if (type == 'paynow') {
      setIsLoading(true);
      let result: any = await deletePaynow({
        paynowId: selectedScamEnabler._id,
        reportId: reportId,
      });
      setIsLoading(false);
      if (!result.error) {
        dispatch(
          showAlert({
            type: 'success',
            message: `${selectedScamEnabler.platform} ${selectedScamEnabler.paynowNumber} deleted successfully`,
          }),
          callback(),
        );
      } else {
        dispatch(
          showAlert({
            type: 'error',
            message: `Unable to delete ${selectedScamEnabler.platform} ${selectedScamEnabler.paynowNumber} due to network error. Please try again later.`,
          }),
        );
      }
    }
    setIsLoading(false);
    onConfirmDeleteClose();
  };

  return (
    <>
      <Modal open={confirmDeleteOpen} onClose={onConfirmDeleteClose}>
        <ModalLayout
          title={'Confirmation'}
          handleCloseModal={onConfirmDeleteClose}
          alignment="center"
          size="small"
        >
          <h5 className="font-h4-bold-14 pb-5">
            You are deleting{' '}
            <span className="font-bold">
              {selectedScamEnabler.platform}{' '}
              {selectedScamEnabler.accountNumber || selectedScamEnabler.paynowNumber}
            </span>{' '}
            from this report.
          </h5>
          {selectedScamEnabler.onlyExistInThisReport && (
            <div className="flex flex-row items-center p-4 bg-yellow-200 border border-yellow rounded-lg mb-5">
              <Warning />
              <p className="pl-4">
                This scam enabler only exists in this report. By proceeding with the deletion, it
                will be archived.
              </p>
            </div>
          )}
          <p className="pb-2">Are you sure you want to proceed?</p>
          <p className="pb-2">You cannot undo this action</p>
          <div className="flex justify-end gap-4 pt-4">
            <CommonButton
              isDisabled={false}
              variant="text"
              title={`Cancel`}
              onClick={onConfirmDeleteClose}
            />
            <LoadingButtonComponent
              isLoading={isLoading}
              title="Delete"
              variant="red"
              onClick={handleDelete}
            />
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default ConfirmDeleteScamEnablerModal;
