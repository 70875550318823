import DynamicForm from '@components/common/DynamicForm';
import { useMemo, useState } from 'react';
import {
  earmarkedAmountsItems,
  editEarmarkedAmountsItems,
  editSeizureAmountsItems,
  seizureAmountsItems,
} from '../BankFormConfig';
import { useForm } from 'react-hook-form';
import cloneDeep from 'lodash.clonedeep';
import { convertUnixToDynamicFormDateTime } from '@utils/utils';
import { CommonButton } from '@components/common/CommonButton';
import { useUpdateFinancialInstituteMutation } from '@api/financialInstituteSlice';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import TooltipComponent from '@components/common/TooltipComponent';

export default function SeizureDetails({
  data,
  refetch,
  isAssignedToMe,
}: {
  data: any;
  refetch: any;
  isAssignedToMe: boolean;
}) {
  const [isSeizedEditing, setIsSeizedEditing] = useState(false);
  const [isEarmarkedEditing, setIsEarmarkedEditing] = useState(false);
  const [isSeizedSubmitting, setIsSeizedSubmitting] = useState(false);
  const [isEarmarkedSubmitting, setIsEarmarkedSubmitting] = useState(false);

  const [updateFinancialInstitute] = useUpdateFinancialInstituteMutation();
  const dispatch = useDispatch();

  const seizedValues = useMemo(() => {
    let bankData = cloneDeep(data?.financialInstitute);
    if (bankData.seizedAmount) {
      bankData.seizedAmount.forEach((t: any, i: number) => {
        if (t.bankDetailsRetrievedDate)
          t.bankDetailsRetrievedDate = convertUnixToDynamicFormDateTime(t.bankDetailsRetrievedDate);
        t.amountSeized = `$${t?.amountInSGD.toFixed(2) || t?.amount?.toFixed(2) || 0} ${t?.currency === 'SGD' ? '' : `(${t?.amount?.toFixed(2) + ' ' + t?.currency})`}`;
      });
    }

    return bankData;
  }, [data]);

  const earmarkedValues = useMemo(() => {
    let bankData = cloneDeep(data?.financialInstitute);
    if (bankData.earmarkedAmount) {
      bankData.earmarkedAmount.forEach((t: any, i: number) => {
        if (t.bankDetailsRetrievedDate)
          t.bankDetailsRetrievedDate = convertUnixToDynamicFormDateTime(t.bankDetailsRetrievedDate);
        t.amountSeized = `$${t?.amountInSGD.toFixed(2) || t?.amount?.toFixed(2) || 0} ${t?.currency === 'SGD' ? '' : `(${t?.amount?.toFixed(2) + ' ' + t?.currency})`}`;
      });
    }
    return bankData;
  }, [data]);

  const seizedFormMethods = useForm({
    defaultValues: seizedValues,
    mode: 'onChange',
  });

  const earmarkedFormMethods = useForm({
    defaultValues: earmarkedValues,
    mode: 'onChange',
  });

  const handleSubmitSeized = async () => {
    setIsSeizedSubmitting(true);
    let dirtyFields = seizedFormMethods.formState.dirtyFields;
    let values = seizedFormMethods.getValues();
    let params: any = { financialInstituteId: data.financialInstitute?._id };
    for (let key in dirtyFields) {
      if (key == 'seizedAmount') {
        params[key] = [];
        for (let item of values[key]) {
          let temp = {
            bankDetailsRetrievedDate: new Date(item.bankDetailsRetrievedDate).getTime(),
            amount: item.amount,
            currency: item.currency || 'SGD',
            poReferenceNo: item.poReferenceNo,
          };
          params[key].push(temp);
        }
      }
    }
    let r: any = await updateFinancialInstitute(params);

    if (!r.error) {
      await refetch();

      dispatch(
        showAlert({
          type: 'success',
          title: 'Success',
          message: `Seized Amounts updated successfully.`,
        }),
      );
    }
    setIsSeizedEditing(false);
    setIsEarmarkedEditing(false);
    setIsSeizedSubmitting(false);
  };

  const handleSubmitEarmarked = async () => {
    setIsEarmarkedSubmitting(true);
    let dirtyFields = earmarkedFormMethods.formState.dirtyFields;
    let values = earmarkedFormMethods.getValues();
    let params: any = { financialInstituteId: data.financialInstitute?._id };
    for (let key in dirtyFields) {
      if (key == 'earmarkedAmount') {
        params[key] = [];
        for (let item of values[key]) {
          let temp = {
            bankDetailsRetrievedDate: new Date(item.bankDetailsRetrievedDate).getTime(),
            amount: item.amount,
            currency: item.currency || 'SGD',
            poReferenceNo: item.poReferenceNo,
          };
          params[key].push(temp);
        }
      }
    }

    let r: any = await updateFinancialInstitute(params);

    if (!r.error) {
      await refetch();

      dispatch(
        showAlert({
          type: 'success',
          title: 'Success',
          message: `Earmarked Amounts updated successfully.`,
        }),
      );
    }
    setIsSeizedEditing(false);
    setIsEarmarkedEditing(false);
    setIsEarmarkedSubmitting(false);
  };

  return (
    <div className="max-h-[60vh] overflow-auto">
      {/* Breakdown by reports */}
      <div className="flex flex-row justify-between align-middle items-center p-2 -mb-6">
        <div className="flex flex-row items-center">
          <h3 className="text-blue-400 text-sm font-bold">Seizure Amounts</h3>
          <TooltipComponent content="seizureAmounts"></TooltipComponent>
        </div>
        {isAssignedToMe && isSeizedEditing && (
          <div className="flex gap-4">
            <CommonButton
              isDisabled={false}
              variant="text"
              title={`Cancel`}
              onClick={() => setIsSeizedEditing(false)}
            />
            <CommonButton
              isDisabled={!seizedFormMethods.formState.isDirty}
              variant="secondary"
              title={`Save`}
              isLoading={isSeizedSubmitting}
              onClick={handleSubmitSeized}
            />
          </div>
        )}
        {isAssignedToMe && !isSeizedEditing && (
          <CommonButton
            isDisabled={false}
            variant="secondary"
            title={`Edit`}
            isLoading={isSeizedSubmitting}
            onClick={() => setIsSeizedEditing(true)}
          />
        )}
      </div>
      {isSeizedEditing ? (
        <DynamicForm
          formFields={editSeizureAmountsItems}
          isEditable={true}
          formMethods={seizedFormMethods}
        />
      ) : (
        <DynamicForm
          formFields={seizureAmountsItems}
          isEditable={false}
          formMethods={seizedFormMethods}
          data={seizedValues}
        />
      )}
      <div className="flex flex-row justify-between align-middle items-center p-2 -mb-6 mt-4">
        <h3 className="text-blue-400 text-sm font-bold">Earmarked Amounts</h3>
        {isAssignedToMe && isEarmarkedEditing && (
          <div className="flex gap-4">
            <CommonButton
              isDisabled={false}
              variant="text"
              title={`Cancel`}
              onClick={() => setIsEarmarkedEditing(false)}
            />
            <CommonButton
              isDisabled={!earmarkedFormMethods.formState.isDirty}
              variant="secondary"
              title={`Save`}
              isLoading={isEarmarkedSubmitting}
              onClick={handleSubmitEarmarked}
            />
          </div>
        )}
        {isAssignedToMe && !isEarmarkedEditing && (
          <CommonButton
            isDisabled={false}
            variant="secondary"
            title={`Edit`}
            isLoading={isEarmarkedSubmitting}
            onClick={() => setIsEarmarkedEditing(true)}
          />
        )}
      </div>
      {isEarmarkedEditing ? (
        <DynamicForm
          formFields={editEarmarkedAmountsItems}
          isEditable={true}
          formMethods={earmarkedFormMethods}
        />
      ) : (
        <DynamicForm
          formFields={earmarkedAmountsItems}
          isEditable={false}
          formMethods={earmarkedFormMethods}
          data={earmarkedValues}
        />
      )}
    </div>
  );
}
