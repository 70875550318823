import DynamicForm from '@components/common/DynamicForm';
import RadioInput from '@components/common/Radio';
import { ErrorOutline } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { assessmentFindingsDa2PartItems } from './AssessmentFindingsConfig';
import { useState } from 'react';
import { useUpdateAssessmentFindingsV2Mutation } from '@api/scamEnablerSlice';
import { useDispatch } from 'react-redux';
import { CommonButton } from '@components/common/CommonButton';
import { showAlert } from '@store/alertSlice';
import ReassignmentConfirmationModal from '@pages/portfolios/monikers/modals/ReassignmentConfirmationModal';
import { convertValues } from '@utils/utils';
const preSubmissionChannelGuidelinesItems = {
  label: 'Do you approve the channel submission to be via Community Guideline?',
  type: 'boolean',
  id: 'community_Q1',
  alignment: 'column',
  extras: {
    defaultValue: 'true',
  },
  rules: {
    required: 'Please select an option',
  },
};
const AssessmentFindingsMonikerPresubmitChecklist2 = ({
  formMethods,
  data,
  showOcha,
  currentRole,
  callback,
  isEditing,
  setIsEditing,
  isLoading,
  showEdit,
  onReturn,
}: {
  formMethods: any;
  data: any;
  showOcha: boolean;
  currentRole: string;
  callback: any;
  isEditing: boolean;
  setIsEditing: any;
  isDA2Assigned: boolean;
  isLoading: boolean;
  showEdit: boolean;
  onReturn: () => void;
}) => {
  const [updateAssessmentFindingsV2] = useUpdateAssessmentFindingsV2Mutation();
  const [isAssessmentFindingsAPILoading, setIsAssessmentFindingsAPILoading] = useState(false);
  const isSubmitDisabled = !formMethods.formState.isValid;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleResubmit = async () => {
    let formResults = convertValues(formMethods.getValues());

    setIsAssessmentFindingsAPILoading(true);
    let params = {
      scamEnablerId: data.scamEnabler._id,
      monikerPreSubmitChecklist2: {
        version: '1.00',
        checklist: formResults,
      },

      reassign: true,
    };
    let response: any = await updateAssessmentFindingsV2(params);
    if (response && !response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Update Successful`,
        }),
      );
      setIsEditing(false);
    }
    setIsModalOpen(false);
    if (callback) callback();
    setIsAssessmentFindingsAPILoading(false);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    let formResults = convertValues(formMethods.getValues());
    if (
      (formResults.checklist2_operationalRequirements_Q1 &&
        formResults.checklist2_operationalRequirements_Q2 &&
        formResults.checklist2_legalRequirements_Q1) ||
      formResults.checklist2_community_Q1
    ) {
      setIsAssessmentFindingsAPILoading(true);
      let params = {
        scamEnablerId: data.scamEnabler._id,
        monikerPreSubmitChecklist2: {
          version: '1.00',
          checklist: formResults,
        },
      };
      let response: any = await updateAssessmentFindingsV2(params);
      if (response && !response.error) {
        callback();

        dispatch(
          showAlert({
            type: 'success',
            message: `Update Successful`,
          }),
        );
        // setIsEditing(false);
        onReturn();
      }
      setIsAssessmentFindingsAPILoading(false);
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <h1 className="font-semibold text-sm pb-1 text-blue-500">Assessment by DA2</h1>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className="overflow-auto h-screen pb-80">
        {showOcha ? (
          <DynamicForm
            formFields={assessmentFindingsDa2PartItems}
            formMethods={formMethods}
            isEditable={isEditing}
            isLoading={isLoading}
          ></DynamicForm>
        ) : (
          <>
            <label className="w-1/2 font-bold mr-4">
              {preSubmissionChannelGuidelinesItems.label}
            </label>
            <RadioInput
              control={formMethods.control}
              setValue={formMethods.setValue}
              id="checklist2_community_Q1"
              rules={preSubmissionChannelGuidelinesItems.rules}
              formState={formMethods.formState}
              isEditable={isEditing}
            />
          </>
        )}
        <div className="pt-3">
          {isSubmitDisabled && (
            <div className="flex">
              <ErrorOutline className="mr-2" />
              <span className="italic text-sm">
                All required fields need to be filled before proceeding to the next section.
              </span>
            </div>
          )}
          <div className="flex flex-row justify-end">
            {isEditing && (
              <CommonButton
                isDisabled={isSubmitDisabled}
                variant="primary"
                title={'Save'}
                isLoading={isAssessmentFindingsAPILoading}
                onClick={(e: any) => {
                  onSubmit(e);
                }}
              />
            )}
            {currentRole === 'da2' && !isEditing && showEdit && (
              <CommonButton variant="primary" title={'Edit'} onClick={() => setIsEditing(true)} />
            )}
          </div>
        </div>
      </form>
      <ReassignmentConfirmationModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleSubmit={handleResubmit}
        isLoading={isAssessmentFindingsAPILoading}
      />
    </Stack>
  );
};

export default AssessmentFindingsMonikerPresubmitChecklist2;
