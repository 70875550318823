
const ErrorPage = () => {
  return (
    <>
      Error
    </>
  );
};

export default ErrorPage;
