import { useDispatch } from 'react-redux';
import { apiSlice } from './apiSlice';
import { showAlert } from '@store/alertSlice';

export const activityLogSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActivtiyLog: builder.query({
      query: (request) => {
        return {
          method: 'GET',
          url: `/v1/activitylog/${request.id}`,
          params: {
            by: request.by || 'scamEnablerId',
          },
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const { useGetActivtiyLogQuery } = activityLogSlice;
