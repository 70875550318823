export const iMessageColumns = [
  // {
  //   title: 'Report Number',
  //   dataIndex: 'reportNumber',
  //   key: 'reportNumber',
  //   render: (text: string, record: any, index: number) => {
  //     console.log('record', record);
  //     return <p>123</p>;
  //   },
  // },
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
  },
];
