import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { getNestedError } from '@utils/utils';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import TextInput from '../TextInput';
import { CircularProgress } from '@mui/material';
import OthersSection from './DropdownOtherSection';
import { debounce } from 'lodash';

const Dropdown = ({
  control,
  disabled,
  dropdownData,
  formState,
  id,
  onChange,
  originalValue,
  placeholder,
  revert,
  rules,
  searchable = true,
  setBadgeText,
  setValue,
  watch,
  othersCustomComponent,
  onInputChange,
  children,
}: {
  control: any;
  disabled?: boolean;
  dropdownData: Array<any>;
  formState?: any;
  id: string;
  onChange?: any;
  originalValue?: string;
  placeholder?: string;
  revert?: boolean;
  rules?: any;
  searchable?: boolean;
  setBadgeText?: any;
  setValue?: any;
  watch?: any;
  othersCustomComponent?: any;
  onInputChange?: (inputValue: string) => Promise<any>;
  children?: any;
}) => {
  const [others, setOthers] = useState(false);
  const [othersTextValue, setOthersTextValue] = useState<string>('');
  const [othersOption, setOthersOption] = useState<string>('');
  const [options, setOptions] = useState(dropdownData);

  const [incorrect, setIncorrect] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const errors = formState?.errors;
  const dropdownStyles = {
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: 'white',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--grey-400)',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: disabled ? '' : 'var(--grey-400)',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--blue-400)',
      },
      '& .MuiSelect-select': {
        paddingTop: originalValue && '10px',
        paddingBottom: originalValue && '10px',
        width: originalValue && '100px',
      },
      fontSize: '14px',
    },
    fontSize: '14px',
    width: '100%',
  };

  const MenuProps = {
    PaperProps: {
      sx: {
        '& .MuiMenuItem-root': {
          '& fieldset': {
            border: 'solid 1px var(--grey-400)',
            borderRadius: '6px',
          },
        },
        '& .MuiMenuItem-root.Mui-selected': {
          backgroundColor: 'var(--blue-300)',
        },
        '& .MuiMenuItem-root:hover': {
          backgroundColor: 'var(--blue-200)',
        },
      },
    },
    MenuListProps: {
      subheader: revert && (
        <ListSubheader component="div" className="flex justify-end bg-white pr-2">
          <button
            onClick={() => setValue(id, originalValue)}
            className="m-0 bg-transparent border-none text-right text-[var(--blue-400)] font-bold text-xs pt-2 pb-2"
          >
            Revert
          </button>
        </ListSubheader>
      ),
    },
  };

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& fieldset': {
              border:
                errors && getNestedError(errors, id)
                  ? 'solid 1px var(--red)'
                  : 'solid 1px var(--grey-400)',
              borderRadius: '6px',
            },
          },
        },
      },
    },
  });

  const handleChange = (event: any, value?: any) => {
    const selected = value;
    if (selected && typeof selected === 'string') {
      setInputValue(selected);
      if (selected.toLowerCase().startsWith('other')) {
        setOthers(true);
        setOthersOption(selected);
      } else {
        setOthers(false);
        setOthersOption('');
      }
      if (
        selected.startsWith('Adding to the previous submission') ||
        selected.startsWith('Submitting a fresh report') ||
        selected.startsWith('The previous submission is incorrect')
      ) {
        if (setBadgeText) setBadgeText(selected);
      }

      if (selected.startsWith('The previous submission is incorrect')) {
        setIncorrect(true);
      } else {
        setIncorrect(false);
        setValue('remarks', '');
      }
    }
    if (onChange) {
      if (selected) {
        onChange(selected);
      } else {
        onChange(event.target.value);
      }
    }
  };

  // For options requiring a textfield for further elaboration
  const handleSpecifyChange = (value: string) => {
    setOthersTextValue(value);
    if (others) {
      setValue(id, `${othersOption} - ${value}`, { shouldDirty: true});
    }
  };

  const renderSelected = (selected: string) => {
    let foundOption = dropdownData.find((data: any) => data && data.id === selected);
    if (selected && selected.toLowerCase().startsWith('other')) {
      return selected.split('-')[0];
    } else if (foundOption) {
      return foundOption.label;
    }
    return selected;
  };

  const CustomPopper = function (props: any) {
    return (
      <Popper {...props} transition>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Box>{props.children}</Box>
          </Grow>
        )}
      </Popper>
    );
  };

  const debouncedHandleInputChange = useCallback(
    debounce(async (newInputValue: string) => {
      if (onInputChange) {
        if (newInputValue) {
          setIsLoading(true);
          const newOptions = await onInputChange(newInputValue);
          setIsLoading(false);
          setOptions(newOptions);
        }
      } else {
        setOptions(dropdownData);
      }
    }, 500), // Adjust the debounce delay as needed
    [onInputChange, dropdownData],
  );

  const handleInputChange = (event: any, newInputValue: string) => {
    setInputValue(newInputValue);
    debouncedHandleInputChange(newInputValue);
  };

  useEffect(() => {
    if (watch && watch(id)) {
      let selectedOption = watch(id);
      if (typeof selectedOption !== 'string') {
        return;
      }
      setInputValue(selectedOption);
      if (selectedOption.toLowerCase().startsWith('other')) {
        setOthers(true);
        let option = selectedOption.split(' - ');
        setOthersOption(option[0]);
        setOthersTextValue(option[1]);
      } else if (selectedOption.startsWith('The previous submission is incorrect')) {
        setIncorrect(true);
      } else {
        setOthers(false);
      }
    }
  }, []);

  useEffect(() => {
    if (!onInputChange) {
      setOptions(dropdownData);
    }
  }, [onInputChange]);
  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-col flex-grow">
        <Controller
          name={id}
          control={control}
          rules={rules}
          render={({ field }) =>
            searchable ? (
              <Autocomplete
                {...field}
                disabled={disabled || false}
                id={id}
                inputValue={renderSelected(inputValue)}
                loadingText={<span className="text-sm">Loading...</span>}
                noOptionsText={<span className="text-sm">No options</span>}
                onInputChange={handleInputChange}
                onChange={(event: any, newValue: any) => {
                  field.onChange(newValue);
                  handleChange(event, newValue);
                }}
                options={options}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholder || 'Please select'}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      shrink: false,
                    }}
                    sx={{ fontSize: '14px' }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: children ? (
                        <div className="absolute right-6">{children}</div>
                      ) : (
                        <>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <Box
                    component="li"
                    sx={{
                      '&:hover': {
                        backgroundColor: 'var(--blue-200) !important',
                      },
                      ...(selected && {
                        backgroundColor: 'var(--blue-300) !important',
                      }),
                      fontSize: '14px',
                    }}
                    {...props}
                  >
                    {typeof option === 'object' ? (
                      <>
                        <Typography variant="body2">{option.label}</Typography>
                        {option.subLabel && (
                          <Typography variant="body2" color="#484848" sx={{ paddingLeft: '15px' }}>
                            {option.subLabel}
                          </Typography>
                        )}
                      </>
                    ) : (
                      option
                    )}
                  </Box>
                )}
                sx={dropdownStyles}
                value={
                  typeof field.value === 'string'
                    ? field.value || originalValue || null
                    : typeof field.value === 'object' && field.value !== null
                      ? options.find((item) => item.id === field.value.id) || field.value.label
                      : null
                }
                PopperComponent={CustomPopper}
              />
            ) : (
              <Select
                {...field}
                displayEmpty
                fullWidth
                id={id}
                MenuProps={MenuProps}
                onChange={(e) => {
                  field.onChange(e);
                  handleChange(e, null);
                }}
                renderValue={(selected) =>
                  selected ? (
                    <div>{renderSelected(selected)}</div>
                  ) : (
                    <div className="text-[var(--grey-400)]">
                      {placeholder ? placeholder : 'Please select'}
                    </div>
                  )
                }
                sx={dropdownStyles}
                value={field.value ?? originalValue ?? ''}
              >
                {dropdownData.map((data) =>
                  typeof data === 'object' ? (
                    <MenuItem key={data.id} value={data.id} sx={{ fontSize: '14px' }}>
                      <div className="pt-1 pb-1 flex">
                        <Typography variant="body2">{data.label}</Typography>
                        {data.subLabel && (
                          <Typography variant="body2" color="#484848" sx={{ paddingLeft: '15px' }}>
                            {data.subLabel}
                          </Typography>
                        )}
                      </div>
                    </MenuItem>
                  ) : (
                    <MenuItem key={data} value={data} sx={{ fontSize: '14px' }}>
                      <div className="pt-1 pb-1">
                        <Typography variant="body2">{data}</Typography>
                      </div>
                    </MenuItem>
                  ),
                )}
              </Select>
            )
          }
        />

        {others && (
          <OthersSection
            handleSpecifyChange={handleSpecifyChange}
            othersTextValue={othersTextValue}
            inputType={othersCustomComponent?.inputType || 'text'}
            othersLabel={othersCustomComponent?.label || 'Please specify'}
            dropdownOptions={othersCustomComponent?.dropdownData || undefined}
          />
        )}
        {incorrect && (
          <div className="pt-2">
            <TextInput
              control={control}
              id={'remarks'}
              placeholder="Please indicate what information was submitted incorrectly in the previous submission..."
              formState={formState}
              rows={8}
              rules={{ required: 'Please indicate' }}
            />
          </div>
        )}
        {errors && getNestedError(errors, id) && (
          <span style={{ color: 'var(--red)' }} role="alert">
            {getNestedError(errors, id)?.message}
          </span>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Dropdown;
