import React from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, Box, Grow, Popper, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import Dropdown from './Dropdown';

interface DropdownOtherSectionProps {
  handleSpecifyChange: any;
  othersTextValue: string;
  inputType: string;
  othersLabel: string;
  dropdownOptions?: any[]; // Optional prop for dropdown options
}

const CustomPopper = function (props: any) {
  return (
    <Popper {...props} transition>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Box>{props.children}</Box>
        </Grow>
      )}
    </Popper>
  );
};

const DropdownOtherSection: React.FC<DropdownOtherSectionProps> = ({
  handleSpecifyChange,
  othersTextValue,
  inputType,
  othersLabel,
  dropdownOptions = [], // Default to an empty array if not provided
}) => {
  const formMethods = useForm();

  const handleChange = (event: any, newValue: string) => {};
  return (
    <>
      {inputType === 'text' && (
        <div className="flex">
          <p className="text-xs text-[var(--grey-400)] pt-3">Please specify: </p>

          <TextField
            onChange={(e: any) => handleSpecifyChange(e.target.value)}
            variant="standard"
            value={othersTextValue}
            sx={{
              '.MuiInputBase-input': {
                fontSize: '14px',
              },
              paddingLeft: 1,
              width: '70%',
            }}
            required
          />
        </div>
      )}
      {inputType === 'dropdown' && (
        <div className="flex flex-col mt-4">
          <label className={'font-bold mr-4 text-grey-500 flex items-center mb-2'}>
            {othersLabel}
          </label>
          {/* <Autocomplete
            disabled={false}
            loadingText={<span className="text-sm">Loading...</span>}
            noOptionsText={<span className="text-sm">No options</span>}
            onChange={(event: any, newValue: any) => {
              handleChange(event, newValue);
            }}
            options={dropdownOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={'Please select'}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: false,
                }}
                sx={{ fontSize: '14px' }}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <Box
                component="li"
                sx={{
                  '&:hover': {
                    backgroundColor: 'var(--blue-200) !important',
                  },
                  ...(selected && {
                    backgroundColor: 'var(--blue-300) !important',
                  }),
                  fontSize: '14px',
                }}
                {...props}
              >
                {typeof option === 'object' ? (
                  <>
                    <Typography variant="body2">{option.label}</Typography>
                    {option.subLabel && (
                      <Typography variant="body2" color="#484848" sx={{ paddingLeft: '15px' }}>
                        {option.subLabel}
                      </Typography>
                    )}
                  </>
                ) : (
                  option
                )}
              </Box>
            )}
            // sx={dropdownStyles}
            value={othersTextValue}
            PopperComponent={CustomPopper}
          /> */}
          <Dropdown
            id={'dropdown-others'}
            control={formMethods.control}
            dropdownData={dropdownOptions}
            onChange={handleSpecifyChange}
            setValue={formMethods.setValue}
          ></Dropdown>
        </div>
      )}
    </>
  );
};

export default DropdownOtherSection;
