export function LinkedPaynowBank({ paynowData }: { paynowData: any }) {
  return (
    <>
      {paynowData.length == 0 && (
        <div className=" text-left">
          <p className="italic text-xs text-gray-500">No PayNow numbers linked to this account</p>
        </div>
      )}

      <div className="flex flex-col gap-3">
        {paynowData.map(
          (paynowNumberData: { createdAt: number; paynowId: string; paynowNumber: string }) => (
            <div className="bg-white p-4">
              {/* <Link
                key={paynowNumberData.paynowNumber}
                // TODO: To change this link
                // to={`/admin/portfolios/monikers/${paynowNumberData.paynowId}`}
                to={''}
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: '15px',
                }}
              >
                {paynowNumberData.paynowNumber}
              </Link> */}
              <p>{paynowNumberData.paynowNumber}</p>
            </div>
          ),
        )}
      </div>
    </>
  );
}
