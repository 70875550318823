import HeaderComponent from '@components/common/HeaderComponent';
import UsersTable from './UsersTable';
import AccessControl from '@components/common/AccessControl';

const ListUsersPage = () => {
  return (
    <>
      <HeaderComponent title={'User Management'} />
      <AccessControl type="page" allowedPermissions={['userManagement.users.view']}></AccessControl>
      <div className="inline-block min-w-full align-middle  ">
        <UsersTable />
      </div>
    </>
  );
};

export default ListUsersPage;
