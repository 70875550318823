import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ReactComponent as ExpandIcon } from '@assets/icons/down_arrow.svg';
import DynamicForm from '@components/common/DynamicForm';
import { editReportInformationForAccount, reportInformationForAccount } from '../../BankFormConfig';
import { ReportTransactionActionButton } from '@pages/portfolios/Buttons';
import { useEffect, useMemo, useState } from 'react';
import { CommonButton } from '@components/common/CommonButton';
import { useForm } from 'react-hook-form';
import { useUpdateFinancialInstituteTransactionsMutation } from '@api/financialInstituteSlice';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import { convertUnixToDynamicFormDate, convertYYYYMMDDToUnixTimestamp } from '@utils/utils';
import cloneDeep from 'lodash.clonedeep';
import { idText } from 'typescript';

export function ReportAccordion({
  reportData,
  isMain,
  refetch,
  financialInstitute,
  isAssignedToMe,
}: {
  reportData: any;
  isMain: boolean;
  refetch: any;
  financialInstitute: any;
  isAssignedToMe: boolean;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updateTransactionDetails] = useUpdateFinancialInstituteTransactionsMutation();
  const dispatch = useDispatch();

  const defaultValues = useMemo(() => {
    let processedReport = cloneDeep(reportData);
    if (reportData.transactions) {
      let amountScammed = 0;
      processedReport.transactions.forEach((t: any, i: number) => {
        if (t.dateOfTransfer) t.dateOfTransfer = convertUnixToDynamicFormDate(t.dateOfTransfer);
        amountScammed += t.amountLossInSGD;
        t.amountScammed = `$${t?.amountLossInSGD?.toFixed(2) || t?.amountLoss?.toFixed(2) || 0} ${t?.currency === 'SGD' ? '' : `(${t?.amountLoss?.toFixed(2) + ' ' + t?.currency})`}`;
      });
      processedReport.amountScammed = amountScammed;
    }

    return processedReport;
  }, [reportData]);

  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    formMethods.reset(defaultValues);
  }, [reportData]);

  const handleSave = async () => {
    setIsSubmitting(true);
    let values = formMethods.getValues();
    let params: any = { updatedTransaction: {}, financialInstituteId: financialInstitute._id };

    let updatedTransaction: any = {};
    updatedTransaction['reportNumber'] = values['reportNumber'];
    updatedTransaction['amountEarmarked'] = values['amountEarmarked'];
    updatedTransaction['additionalInformation'] = values['additionalInformation'];

    let processedTransactions: any = [];
    if (values['transactions'].length > 0) {
      values['transactions'].forEach((t: any) => {
        if (
          !t.dateOfTransfer &&
          !t.amountLoss &&
          t.currency === 'SGD' &&
          t.transactionType === 'L-L'
        ) {
        } else {
          processedTransactions.push({
            amountLoss: t.amountLoss,
            currency: t.currency,
            dateOfTransfer: convertYYYYMMDDToUnixTimestamp(t.dateOfTransfer),
            transactionType: t.transactionType,
          });
        }
      });
    }

    updatedTransaction['transactions'] = processedTransactions;

    params.updatedTransaction = updatedTransaction;
    let r: any = await updateTransactionDetails(params);

    if (!r.error) {
      await refetch();
      formMethods.reset(defaultValues);

      dispatch(
        showAlert({
          type: 'success',
          message: 'Overview updated successfully.',
        }),
      );
    }

    setIsSubmitting(false);
    setIsEditing(false);
  };

  return (
    <Accordion defaultExpanded={isMain}>
      <AccordionSummary
        expandIcon={<ExpandIcon height={10} width={15} />}
        style={{
          flexDirection: 'row-reverse',
          marginLeft: -10,
        }}
      >
        <h3 className="font-bold text-blue-500 text-sm underline ml-2">
          {reportData.reportNumber}
        </h3>
      </AccordionSummary>
      <AccordionDetails>
        {isEditing ? (
          <div className="absolute top-6 right-2 flex gap-2">
            <CommonButton
              onClick={() => {
                formMethods.reset(defaultValues);
                setIsEditing(false);
              }}
              variant="secondary"
              title="Cancel"
            />
            <CommonButton
              variant={'secondary'}
              type={'button'}
              title={'Save'}
              onClick={formMethods.handleSubmit(handleSave, () => {
                dispatch(
                  showAlert({
                    type: 'error',
                    message: 'Invalid transaction. Please ensure dates and amounts are not empty.',
                  }),
                );
              })}
              isLoading={isSubmitting}
              isDisabled={!formMethods.formState.isDirty}
            />
          </div>
        ) : (
          <ReportTransactionActionButton
            startEdit={() => {
              setIsEditing(true);
            }}
            reportId={reportData.reportId}
            isAssignedToMe={isAssignedToMe}
          />
        )}

        {isEditing ? (
          <DynamicForm
            formFields={editReportInformationForAccount}
            isEditable={true}
            formMethods={formMethods}
          />
        ) : (
          <DynamicForm
            formFields={reportInformationForAccount}
            isEditable={false}
            formMethods={formMethods}
            data={defaultValues}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
