import { TableCell, TableRow } from '@mui/material';
import CheckboxTable from './CheckboxTable';
import DatePickerTable from './DatePickerTable';
import dayjs from 'dayjs';
import { useWatch } from 'react-hook-form';
import Dropdown from './dropdown/Dropdown';
import TextInput from './TextInput';
const TableChecklistRow = ({
  id,
  row,
  index,
  control,
  getValues,
  watch,
  rules,
  rows,
  setValue,
  isEditable,
}: {
  id?: string;
  row: any;
  index: number;
  control: any;
  getValues?: any;
  watch: any;
  rules?: any;
  rows: any;
  setValue?: any;
  isEditable?: boolean;
}) => {
  const cellStyle = {
    border: '1px solid black',
  };

  let dependencyId = row?.dependency?.id ? row.dependency.id : undefined;
  const watchedDepValue = useWatch({ control, name: dependencyId, defaultValue: '' });
  const watchedValue = useWatch({ control, name: row.id, defaultValue: '' });

  const validate = () => {
    if (rules && rules.minimum) {
      let validCount = 0;
      rows.forEach((row: any) => {
        const fieldValue = getValues(row.id);
        if (fieldValue === true) validCount++;
      });
      if (validCount >= rules.minimum) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const getFieldType = (row: any) => {
    let isDependentValid = true;
    if (row?.dependency?.id && watchedDepValue !== row.dependency.value) {
      isDependentValid = false;
    }
    switch (row.tableFieldType) {
      case 'checkbox':
        return (
          <CheckboxTable
            control={control}
            id={row.id}
            row={row}
            rules={row.rules}
            disabled={!(isEditable && isDependentValid)}
            validate={validate}
          />
        );
      case 'date':
        return (
          <DatePickerTable
            control={control}
            id={row.id}
            row={row}
            disabled={!(isEditable && isDependentValid)}
          />
        );
      case 'dropdown':
        return (
          <Dropdown
            watch={watch}
            control={control}
            id={row.id}
            dropdownData={row.options}
            rules={row.rules}
            setValue={setValue}
            placeholder={row.placeholder}
            disabled={!(isEditable && isDependentValid)}
          />
        );
      case 'textInput':
        return (
          <TextInput
            id={row.id}
            control={control}
            rules={row.rules}
            placeholder={row.placeholder}
            prefix={row?.extras?.prefix ? row.extras.prefix : ''}
            disabled={!(isEditable && isDependentValid)}
            rows={row?.extras?.rows}
          ></TextInput>
        );
      default:
        break;
    }
  };

  const getFormattedDate = (value: string) => {
    return dayjs(value).format('DD MMM YYYY');
  };

  return (
    <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'white' : 'var(--grey-300)' }}>
      {row.tableFieldType === 'dropdown' || row.tableFieldType === 'textInput' ? (
        <TableCell sx={cellStyle} colSpan={2} scope="row">
          <div
            className={`${
              !isEditable || (row?.dependency?.id && watchedDepValue !== row.dependency.value)
                ? 'opacity-40'
                : 'opacity-100'
            }`}
          >
            <p className="pb-1">
              {index + 1}. {row.name}
            </p>
            {getFieldType(row)}
          </div>
        </TableCell>
      ) : (
        <>
          <TableCell sx={cellStyle} component="th" scope="row">
            <p className="italic text-grey-400">{row?.dependency?.label && row.dependency.label}</p>
            <div
              className={`${
                !isEditable || (row?.dependency?.id && watchedDepValue !== row.dependency.value)
                  ? 'opacity-40'
                  : 'opacity-100'
              }`}
            >
              <div className="flex flex-row gap-2">
                <p> {index + 1}.</p>
                <div>
                  <p> {row.name}</p>
                  {row.subText && <p className="italic text-grey-400">{row.subText}</p>}
                </div>
              </div>

              {row.tableFieldType === 'date' && watchedValue && (
                <span className="pl-3 text-[var(--blue-500)] font-bold">
                  {getFormattedDate(watchedValue)}
                </span>
              )}
            </div>
          </TableCell>
          <TableCell
            sx={{
              ...cellStyle,
              width: '10%',
              opacity:
                !isEditable || (row?.dependency?.id && watchedDepValue !== row.dependency.value)
                  ? '0.4'
                  : '1',
            }}
            align="center"
          >
            {getFieldType(row)}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default TableChecklistRow;
