import { ochaDirections, platform, scamEnablerOutcomes, scamType } from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';

export const getDirectionQueueItems = (view: string) => {
  if (view === 'monikerView') {
    return directionQueuefilterMonikerItems;
  } else {
    return directionQueuefilterReportItems;
  }
};
export const directionQueuefilterReportItems: Array<FormItemInterface> = [
  {
    label: 'Platform',
    type: 'dropdownMulti',
    id: 'platform',
    placeholder: 'Please Select',
    extras: { dropdownData: platform },
    alignment: 'row',
  },
  {
    label: 'Scam Type',
    type: 'dropdownMulti',
    id: 'scam_type',
    placeholder: 'Please Select',
    extras: { dropdownData: scamType },
    alignment: 'row',
  },
  {
    label: 'Date received',
    type: 'calendarRange',
    id: 'created_date_from,created_date_to',
    placeholder: 'dd/mm/yyyy',
    alignment: 'row',
  },
  {
    label: 'Outcome Status',
    type: 'dropdownMulti',
    id: 'outcome',
    extras: { dropdownData: scamEnablerOutcomes },
    placeholder: 'Plese select',
    alignment: 'row',
  },
];

export const directionQueuefilterMonikerItems: Array<FormItemInterface> = [
  {
    label: 'Platform',
    type: 'dropdownMulti',
    id: 'platform',
    placeholder: 'Please Select',
    extras: { dropdownData: platform },
    alignment: 'row',
  },
  {
    label: 'Direction',
    type: 'dropdownMulti',
    id: 'direction',
    placeholder: 'Please Select',
    extras: { dropdownData: ochaDirections },
    alignment: 'row',
  },
  {
    label: 'Channel',
    type: 'dropdownMulti',
    id: 'channel',
    placeholder: 'Please Select',
    extras: { dropdownData: ['Community Guideline', 'OCHA'] },
    alignment: 'row',
  },
  {
    label: 'Scam Type',
    type: 'dropdownMulti',
    id: 'scam_type',
    placeholder: 'Please Select',
    extras: { dropdownData: scamType },
    alignment: 'row',
  },
  {
    label: 'Date Sent to Queue',
    type: 'calendarRange',
    id: 'created_date_from,created_date_to',
    placeholder: 'dd/mm/yyyy',
    alignment: 'row',
  },
  {
    label: 'Outcome Status',
    type: 'dropdownMulti',
    id: 'outcome',
    extras: { dropdownData: scamEnablerOutcomes },
    placeholder: 'Plese select',
    alignment: 'row',
  },
];
