import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { FLAGS_SVG } from './flags';

export function getDisplayNames(lang = 'en'): Intl.DisplayNames {
  return new Intl.DisplayNames(lang, {
    type: 'region',
  });
}

export function getCountryNameAndFlag(code: string): { name: string; flag: string } {
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  const flagBaseUrl = 'https://flagcdn.com/';

  let flagUrl = `${flagBaseUrl}${code.toLowerCase()}.svg`;
  if (code === 'TA' || code === 'AC') {
    flagUrl = {
      TA: FLAGS_SVG.TA,
      AC: FLAGS_SVG.AC,
    }[code];
  }
  return {
    name: regionNamesInEnglish.of(code) as string,
    flag: flagUrl,
  };
}

const generateCountryData = () => {
  const countriesData: any = [];
  let countries = getCountries();
  countries.forEach((country) => {
    let tempCountryData = {
      name: getCountryNameAndFlag(country).name,
      dialCode: `+${getCountryCallingCode(country)}`,
      code: country,
      flag: getCountryNameAndFlag(country).flag,
    };
    countriesData.push(tempCountryData);
  });
  const sgIndex = countriesData.findIndex((country: any) => country.code === 'SG');
  if (sgIndex > -1) {
    // Remove the Singapore entry and unshift it to the beginning of the array
    const sgData = countriesData.splice(sgIndex, 1)[0];
    countriesData.unshift(sgData);
  }
  return countriesData;
};

export const countries = generateCountryData();
