export const lazadaColumns = [
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
  },
  {
    title: 'Brief facts of case',
    dataIndex: 'explanation',
    key: 'explanation',
  },
];
