import { useState } from 'react';
import { useListUsersQuery } from '../../../api/userSlice';
import {
  Table,
  Pagination,
  TableProps,
  GetProp,
  TablePaginationConfig,
  ConfigProvider,
} from 'antd';
import { DebounceInput } from 'react-debounce-input';
import cloneDeep from 'lodash.clonedeep';
import { convertUnixToSingaporeTime } from '../../../utils/utils';
import AdminCreateUserModal from '../AdminCreateUser/AdminCreateUserModal';
import AdminUpdateUserModal from '../AdminUpdateUser/AdminUpdateUserModal';
import { CommonButton } from '@components/common/CommonButton';
import AccessControl from '@components/common/AccessControl';

interface sortBy {
  sortId: string;
  sortOrder: 'asc' | 'desc';
}

interface TableParams {
  pagination: TablePaginationConfig;
  sort: sortBy;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

export default function UsersTable() {
  const [searchTerm, setSearchTerm] = useState('');
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const [updateUserModalOpen, setUpdateUserModalOpen] = useState(false);
  const [updateUserModalSelectedUser, setUpdateUserModalSelectedUser] = useState<any>({});

  const [tableParams, setTableParams] = useState<TableParams>({
    sort: {
      sortId: 'userId',
      sortOrder: 'asc',
    },
    pagination: {
      current: 1,
      pageSize: 50,
    },
  });

  const {
    refetch: refetchUsers,
    data: usersData,
    isSuccess: isGetUsersSuccess,
    isError,
    isLoading,
    isFetching,
  } = useListUsersQuery({
    params: {
      search: searchTerm,
      sort_by: tableParams.sort.sortId,
      order_by: tableParams.sort.sortOrder,
      page: tableParams.pagination.current,
      size: tableParams.pagination.pageSize,
      filters: tableParams.filters,
    },
  });

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      width: '20%',
      render: (text: string, record: any, index: number) => (
        <div className="overflow-hidden">
          <CommonButton
            isDisabled={false}
            variant="underline-text"
            title={text}
            allowedPermissions={['userManagement.*', 'userManagement.users.adminUpdate']}
            renderNoAccess={() => <span>{text}</span>}
            onClick={() => {
              setUpdateUserModalOpen(true);
              setUpdateUserModalSelectedUser(record);
            }}
          />
        </div>
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: true,
      key: 'firstName',
      width: '15%',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: true,
      key: 'lastName',
      width: '15%',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: true,
      key: 'role',
      width: '10%',
      render: (text: string, record: any, index: number) => {
        let roles = record.role.map((item: any) => item.roleName);

        return roles.join(', ');
      },
    },
    {
      title: 'Status',
      dataIndex: 'active',
      sorter: true,
      key: 'active',
      width: '10%',
      render: (text: string, record: any, index: number) => (record.active ? 'Active' : 'Inactive'),
    },
    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      sorter: true,
      key: 'createdAt',
      width: '15%',
      render: (text: string, record: any, index: number) =>
        convertUnixToSingaporeTime(record.createdAt),
    },
    {
      title: 'Date Last Modified',
      dataIndex: 'lastModifiedAt',
      sorter: true,
      key: 'lastModifiedAt',
      width: '15%',
      render: (text: string, record: any, index: number) =>
        convertUnixToSingaporeTime(record.lastModifiedAt),
    },
  ];
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // const navigateToCreateUser = () => {
  //   navigate('/admin/users/create');
  // };

  const handlePageChange = (page: number, pageSize: number) => {
    setTableParams({
      ...tableParams,

      pagination: {
        current: page,
        pageSize: pageSize,
      },
    });
    refetchUsers();
  };

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter: any) => {
    setTableParams({
      ...tableParams,
      sort: {
        sortId: sorter.field ? sorter.field : '',
        sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
      },
    });
    refetchUsers();
  };

  const handleCreateUserModalClose = (userCreated: boolean) => {
    setCreateUserModalOpen(false);
    if (userCreated) {
      refetchUsers();
    }
  };

  const handleUpdateUserModalClose = (userUpdated: boolean) => {
    setUpdateUserModalOpen(false);
    if (userUpdated) {
      refetchUsers();
    }
  };

  return (
    <AccessControl
      type="component"
      allowedPermissions={['userManagement.*', 'userManagement.users.view']}
    >
      <div className="inline-block min-w-full align-middle  ">
        <div className="rounded-lg bg-gray-50 p-2 md:pt-0">
          <div className="flex items-center justify-between gap-2 py-4">
            <div className="flex flex-row gap-4 items-center">
              <div className="w-64">
                <DebounceInput
                  className="w-64 h-12 p-4 bg-grey-300 rounded-[8px] border-none text-grey-500 text-sm"
                  minLength={2}
                  debounceTimeout={300}
                  placeholder="Search for users..."
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 min-h-10">
              <div className="flex flex-row gap-2">
                <CommonButton
                  variant="primary"
                  title="Create User"
                  allowedPermissions={['userManagement.*', 'userManagement.users.create']}
                  onClick={() => {
                    setCreateUserModalOpen(true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="max-h-[calc(100vh-250px)] overflow-auto">
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    /* here is your component tokens */
                    headerBg: '#D0DEE0',
                  },
                },
              }}
            >
              <Table
                dataSource={cloneDeep(usersData?.users)}
                columns={columns}
                rowKey="userId"
                pagination={false}
                onChange={handleTableChange}
                loading={isLoading || isFetching}
                sticky
              />
            </ConfigProvider>
          </div>
          <Pagination
            className="pt-2 flex justify-end"
            defaultCurrent={1}
            current={tableParams.pagination.current}
            total={usersData?.totalNumberOfRecords}
            onChange={handlePageChange}
            showSizeChanger={true}
            defaultPageSize={50}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          />
        </div>
      </div>
      <AdminCreateUserModal
        isOpen={createUserModalOpen}
        onAdminCreateUserModalClose={(userCreated) => handleCreateUserModalClose(userCreated)}
      />
      <AdminUpdateUserModal
        user={updateUserModalSelectedUser}
        isOpen={updateUserModalOpen}
        onAdminUpdateUserModalClose={(userUpdated) => handleUpdateUserModalClose(userUpdated)}
      />
    </AccessControl>
  );
}
