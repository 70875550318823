import { useEffect } from 'react';
import { InfoSection } from '../components/InfoSection';
import { RelatedReports } from '../components/RelatedReports';
import DynamicForm from '@components/common/DynamicForm';
import { monikerScamEnablerDetailForm } from '@pages/reports/ReportFormConfig';

export function SocialMediaSection({ data }: { data: any }) {
  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div>
      {/* Account details */}
      <InfoSection
        title="Account Details"
        inner={
          <DynamicForm isEditable={false} formFields={monikerScamEnablerDetailForm} data={data} />
        }
      />

      {/* Related reports */}
      <InfoSection
        title="Related Reports"
        // TODO: related report mapping
        inner={<RelatedReports relatedReports={data.relatedReports} />}
      />
    </div>
  );
}
