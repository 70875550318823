import { useEffect, useState } from 'react';
import { Modal, Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useCreateReportMutation, useFormCreateReportMutation } from '@api/reportsSlice';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { ReactComponent as SuccessImage } from '@assets/icons/success.svg';
import { ReactComponent as ErrorOutline } from '@assets/icons/error_outline.svg';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import DynamicForm from '@components/common/DynamicForm';
import CustomBadge from '@components/common/CustomBadge';
import { createFormGeneralItems } from './CreateReportFormConfig';
import CreateReportScamEnablers from './CreateReportScamEnablers';
// import CreateReportTransactions from './CreateReportTransaction';
import CreateReportConfirmation from './CreateReportConfirmation';
import CustomizedStepperForm from '@components/common/Stepper';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import { cloneDeep } from 'lodash';
import { cleanUpUserId, convertYYYYMMDDToUnixTimestamp, generateReportNumber } from '@utils/utils';
import { CommonButton } from '@components/common/CommonButton';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { clsx } from 'clsx';
import { commonScamType } from '@interface/constant';

export const formatScamEnablers = (scamEnablers: any) => {
  scamEnablers.forEach((scamEnabler: any) => {
    delete scamEnabler['userIdType'];

    if (scamEnabler.platform === 'Website') {
      scamEnabler.websites.forEach((websiteScamEnabler: any) => {
        scamEnabler['attachments'] = [];
        if (!websiteScamEnabler['attachments']) {
          websiteScamEnabler['attachments'] = [];
        } else {
          websiteScamEnabler['attachments'].forEach((attachment: any) => {
            attachment.fileContent = attachment.fileContent.replace(/^data:.+;base64,/, '');
          });
        }
      });
    } else {
      if (!scamEnabler['attachments']) {
        scamEnabler['attachments'] = [];
      } else {
        scamEnabler['attachments'].forEach((attachment: any) => {
          attachment.fileContent = attachment.fileContent.replace(/^data:.+;base64,/, '');
        });
      }
      if (scamEnabler['userId']) {
        scamEnabler['userId'] = cleanUpUserId(scamEnabler['userId'], scamEnabler['platform']);
      }
      if (scamEnabler['transactions']) {
        let transactions: any = [];
        if (scamEnabler.transactions && Array.isArray(scamEnabler.transactions)) {
          scamEnabler.transactions.forEach((transaction: any) => {
            if (
              (!transaction.dateOfTransfer &&
                !transaction.amountLoss &&
                transaction.currency === 'SGD' &&
                transaction.transactionType === 'L-L') ||
              (!transaction.dateOfTransfer &&
                !transaction.amountLoss &&
                !transaction.currency &&
                !transaction.transactionType)
            ) {
            } else {
              let newTransaction: any = cloneDeep(transaction);
              if (transaction.dateOfTransfer) {
                newTransaction.dateOfTransfer = convertYYYYMMDDToUnixTimestamp(
                  transaction.dateOfTransfer,
                );
              }
              if (!transaction.currency) {
                newTransaction.currency = 'SGD';
              }
              if (!transaction.transactionType) {
                newTransaction.currency = 'L-L';
              }
              if (!transaction.amountLoss) {
                newTransaction.amountLoss = 0;
              }
              if (!transaction.dateOfTransfer) {
                newTransaction.dateOfTransfer = '';
              }
              transactions.push(transaction);
            }
          });
        }

        scamEnabler.transactions = transactions;
      }
      delete scamEnabler.scamEnablerType;
    }
  });

  return scamEnablers;
};
const CreateReportComponent = ({
  handleCloseModal,
  showHeader,
  handleSubmitted,
  isFormCreateReport,
}: {
  handleCloseModal?: any;
  showHeader: boolean;
  handleSubmitted?: any;
  isFormCreateReport: boolean;
}) => {
  const [currentUser, setCurrentUser] = useState<any>({});

  useEffect(() => {
    const getSessionUser = async () => {
      let user;
      if (isFormCreateReport) {
        user = await getSessionUserFromAccessToken('SatisPlusFormAccessToken');
      } else {
        user = await getSessionUserFromAccessToken();
      }
      setCurrentUser(user);
    };
    getSessionUser();
    console.log(currentUser);
  }, []);

  const steps = ['General Information', 'Scam Enablers', 'Confirmation'];
  const satisPoliceStations = ['PID', 'CADID', 'X / DIB'];
  const optionalPoliceStations = ['CD-ASC'];
  const [activeStep, setActiveStep] = useState(0);
  const formMethods = useForm({
    values: {
      reportNumber: '',
      scamEnablers: [{ useOfScamEnabler: '', platform: '', type: 'Moniker' }],
      monetaryLossInvolved: 'false',
      policeStation: '',
      spfEmail: currentUser.email,
    },
    mode: 'onChange',
  });
  const [stepCompletionStatus, setStepCompletionStatus] = useState(
    new Array(steps.length).fill(false),
  );
  const [badgeText, setBadgeText] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submissionId, setSubmissionId] = useState('');

  const dispatch = useDispatch();
  const handleNext = () => {
    console.log('formMethods', formMethods.getValues());
    setStepCompletionStatus((prev) => {
      const newStatus = [...prev];
      newStatus[activeStep] = true;
      return newStatus;
    });
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    const allPreviousCompleted = stepCompletionStatus.slice(0, step).every((status) => status);
    if (allPreviousCompleted) {
      setActiveStep(step);
    }
  };

  const [createReport] = useCreateReportMutation();
  const [formCreateReport] = useFormCreateReportMutation();
  const policeStation = formMethods.watch('policeStation');

  const isButtonDisabled = !formMethods.formState.isValid && !stepCompletionStatus[activeStep];

  const handleCreateReportButton = async () => {
    const formValues: any = formMethods.getValues();

    let params = {
      report: {
        reportNumber:
          formValues.reportNumber.length > 0
            ? formValues.reportNumber
            : generateReportNumber('PID'),
        spfEmail: formValues.spfEmail,
        policeStation: formValues.policeStation,
        classification: formValues.classification,
        scamType: formValues.scamType,
        scamSubType: formValues.scamSubType,
        amountLoss:
          formValues.monetaryLossInvolved === 'true' ? parseInt(formValues.amountLoss) : 0,
      },
      scamEnablers: formValues.scamEnablers,
    };
    params.scamEnablers = formatScamEnablers(params.scamEnablers);
    if (satisPoliceStations.includes(params.report.policeStation)) {
      delete params.report.reportNumber;
    }
    let data: any;
    // check if form create report or portal create report
    if (isFormCreateReport) {
      data = await formCreateReport(params);
    } else {
      data = await createReport(params);
    }
    if (!data.error) {
      if (handleSubmitted) {
        handleSubmitted(data.data.submissionId);
      } else {
        setIsModalOpen(true);
        setSubmissionId(data.data.submissionId);
      }
      dispatch(
        showAlert({
          type: 'success',
          message: `New report added successfully.`,
        }),
      );
    }
    setLoading(false);
  };

  const onSubmit = (event: any) => {
    if (activeStep !== steps.length - 1) {
      handleNext();
    } else {
      handleCreateReportButton();
    }
  };

  const [scamEnablerFormState, setScamEnablerFormState] = useState<Array<Array<FormItemInterface>>>(
    [],
  );

  // const [transactionFormState, setTransactionFormState] = useState<Array<Array<FormItemInterface>>>(
  //   [],
  // );
  const getGeneralItems = () => {
    if (isFormCreateReport) {
      let newFormItems = cloneDeep(createFormGeneralItems);
      const updatedFormGeneralItems = newFormItems.filter(
        (item) => item.id !== 'classification' && item.id !== 'scamSubType',
      );
      let scamTypeItem = newFormItems.find((item: any) => item.id === 'scamType');
      if (scamTypeItem) scamTypeItem.extras.dropdownData = commonScamType;

      return updatedFormGeneralItems;
    } else {
      let newFormItems = cloneDeep(createFormGeneralItems);
      let policeStation = newFormItems.find((item) => item.id === 'policeStation');
      if (policeStation) {
        policeStation.optional = true;
        delete policeStation.rules;
      }

      return newFormItems;
    }
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        if (
          optionalPoliceStations.includes(policeStation) ||
          satisPoliceStations.includes(policeStation)
        ) {
          let newFormitem = cloneDeep(getGeneralItems());
          const filteredFormItems = newFormitem.filter(
            (item: FormItemInterface) => item.id !== 'reportNumber',
          );
          return (
            <div className="flex flex-col gap-2">
              <h2 className="bold">General Information</h2>
              <DynamicForm
                formFields={filteredFormItems}
                formMethods={formMethods}
                setBadgeText={setBadgeText}
              ></DynamicForm>
            </div>
          );
        } else {
          return (
            <div className="flex flex-col gap-2">
              <h2 className="bold">General Information</h2>
              <DynamicForm
                formFields={getGeneralItems()}
                formMethods={formMethods}
                setBadgeText={setBadgeText}
              ></DynamicForm>
            </div>
          );
        }
      case 1:
        return (
          <CreateReportScamEnablers
            formMethods={formMethods}
            scamEnablerFormState={scamEnablerFormState}
            setScamEnablerFormState={setScamEnablerFormState}
            isShowPaymentChannel={!isFormCreateReport}
            isFormCreateReport={isFormCreateReport}
          />
        );
      // case 2:
      //   return (
      //     <CreateReportTransactions
      //       formMethods={formMethods}
      //       transactionFormState={transactionFormState}
      //       setTransactionFormState={setTransactionFormState}
      //     />
      //   );
      case 2:
        const formValues: any = formMethods.getValues();
        return (
          <CreateReportConfirmation
            formMethod={formMethods}
            formValues={formValues}
            setActiveStep={setActiveStep}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  const tooltipContent = (
    <div className="flex text-black bg-white">
      <ErrorOutline className="mr-2" />
      <span>All required fields need to be filled before proceeding to the next section.</span>
    </div>
  );

  useEffect(() => {
    if (
      optionalPoliceStations.includes(policeStation) ||
      satisPoliceStations.includes(policeStation)
    ) {
      formMethods.resetField('reportNumber');
    }
  }, [policeStation, formMethods]);

  useEffect(() => {
    if (formMethods.formState.errors && formMethods.formState?.errors?.scamEnablers) {
      setStepCompletionStatus((prev) => {
        const newStatus = [...prev];
        newStatus[1] = false;
        return newStatus;
      });
    }
  }, [formMethods.formState.errors?.scamEnablers]);
  return (
    <div
      className={clsx('py-8 -mx-2 flex flex-col gap-4 bg-white', {
        'min-h-screen': isFormCreateReport,
        'max-h-screen': !isFormCreateReport,
      })}
    >
      {showHeader && (
        <>
          <p>
            Hi Officer, please assist us to collect scam enablers from the victim of scam which has
            lodged the report.
          </p>
        </>
      )}
      <Stack sx={{ width: '100%' }} spacing={4}>
        <CustomizedStepperForm
          steps={steps}
          handleStepClick={handleStep}
          activeStep={activeStep}
          stepCompletionStatus={stepCompletionStatus}
        >
          <div className="flex justify-between items-center">
            <p className="py-2 text-grey-400">All fields are required unless marked as optional.</p>
            {badgeText.length > 0 && <CustomBadge text={badgeText} />}
          </div>
          <Box sx={{ mb: 2 }}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <div className="flex-1 overflow-auto pb-10">{getStepContent(activeStep)}</div>
              <div className="pt-4 flex justify-end sticky gap-5 bottom-0 pb-4 bg-white">
                <CommonButton
                  variant="text"
                  title="Cancel"
                  onClick={handleCloseModal}
                  type="button"
                />
                {activeStep !== 0 && (
                  <CommonButton
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleBack();
                    }}
                    type="button"
                    variant="secondary"
                    title="Previous"
                  />
                )}
                {activeStep !== steps.length - 1 ? (
                  <Tooltip
                    title={tooltipContent}
                    disableHoverListener={!isButtonDisabled}
                    placement="bottom-end"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.white',
                          fontSize: '12px',
                        },
                      },
                    }}
                  >
                    <span>
                      <CommonButton
                        variant="primary"
                        title="Next"
                        type="submit"
                        isDisabled={isButtonDisabled}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <CommonButton
                    isLoading={loading}
                    variant="primary"
                    title="Submit"
                    onClick={() => setLoading(true)}
                    type="submit"
                  />
                )}
              </div>
            </form>
          </Box>
        </CustomizedStepperForm>
      </Stack>
      <Modal open={isModalOpen} className="fixed inset-0 flex items-center justify-center">
        <div className="bg-white p-4 rounded shadow-lg text-center w-screen h-screen flex flex-col items-center justify-center gap-4">
          <SuccessImage className="h-24 w-24" />
          <h1 className="mb-4 text-blue-400">Your Form has been succesfully submitted</h1>
          <p>{`Submission Id : ${submissionId}`}</p>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={() => window.location.reload()}
          >
            Submit Another Form
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CreateReportComponent;
