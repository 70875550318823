import { BACKEND_STAGE_URL } from "./stg";
import { BACKEND_PRODUCTION_URL } from "./prd";
import { BACKEND_DEV_URL } from "./dev";
import { BACKEND_LOCAL_URL } from "./Local";

let url;

if (process.env.REACT_APP_DEPLOYMENT_MODE === "local") {
  url = BACKEND_LOCAL_URL;
} else if (process.env.REACT_APP_DEPLOYMENT_MODE === "dev") {
  url = BACKEND_DEV_URL;
} else if (process.env.REACT_APP_DEPLOYMENT_MODE === "stg") {
  url = BACKEND_STAGE_URL;
} else if (process.env.REACT_APP_DEPLOYMENT_MODE === "prd") {
  url = BACKEND_PRODUCTION_URL;
} else {
  url = BACKEND_DEV_URL;
}

export const BACKEND_URL = url;
