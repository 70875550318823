import { CommonButton } from '@components/common/CommonButton';
import FileUpload from '@components/common/FileUpload';
import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';

const UploadScreenshotModal = ({
  id,
  isOpen,
  onClose,
  formMethods,
  onUploadScreenshot,
  isLoading,
  allowPaste,
}: {
  id: any;
  isOpen: boolean;
  onClose: () => void;
  formMethods: any;
  onUploadScreenshot?: any;
  isLoading?: boolean;
  allowPaste?: boolean;
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalLayout
        title="Upload screenshot"
        handleCloseModal={onClose}
        alignment="center"
        size="medium"
      >
        {isOpen && (
          <FileUpload
            control={formMethods.control}
            id={id}
            setValue={formMethods.setValue}
            getValues={formMethods.getValues}
            onClose={onClose}
            allowPaste={allowPaste}
          />
        )}
        {onUploadScreenshot && (
          <div className="flex justify-end gap-4 pt-4">
            <CommonButton isDisabled={false} variant="text" title={`Cancel`} onClick={onClose} />
            <CommonButton
              isLoading={isLoading}
              variant="primary"
              title="Save"
              onClick={onUploadScreenshot}
            />
          </div>
        )}
      </ModalLayout>
    </Modal>
  );
};

export default UploadScreenshotModal;
