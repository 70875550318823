import RelatedReportModal from '@pages/portfolios/monikers/modals/RelatedReportModal';
import { convertUnixToSingaporeTime } from '@utils/utils';
import cloneDeep from 'lodash.clonedeep';
import { useState } from 'react';

const PortfoliosMonikerRelatedReports = ({
  data,
  isLoading,
}: {
  data: any;
  isLoading: boolean;
}) => {
  const [selectedReport, setSelectedReport] = useState('');
  const [relatedReportOpen, setRelatedReportOpen] = useState(false);

  return (
    <div>
      {/* <h1 className="font-semibold text-sm pt-4 text-blue-400">Moniker Details</h1>
        <p className="italic text-xs pb-4 text-grey-400">
          {isDirectionQueueMonikerModal
            ? 'When PO is issued, details are populated based on the time when PO is generated'
            : 'Details populated based on latest submission'}
        </p>
        {data && data.scamEnabler && Object.keys(data.scamEnabler).length > 0 ? (
          <DynamicForm
            formFields={monikerDetailsItems}
            data={processReport(data.scamEnabler)}
            callback={refetch}
            isEditable={isEditable}
          />
        ) : (
          <DynamicForm formFields={monikerDetailsItems} isLoading={true} />
        )} */}
      <h1 className="font-semibold text-sm pb-1 text-blue-400">Related Reports</h1>

      {data?.reports?.length > 0 && !isLoading && (
        <div className="overflow-auto max-h-[50vh]">
          {cloneDeep(data.reports)
            .sort((a: any, b: any) => b.createdAt - a.createdAt)
            .map((report: any, index: number) => {
              return (
                <div className="relative pb-2 overflow-hidden" key={index}>
                  <button
                    key={index}
                    className="bg-white rounded-lg flex flex-row items-center justify-between p-4 w-full overflow-hidden"
                    onClick={() => {
                      setRelatedReportOpen(true);
                      setSelectedReport(report.reportId);
                    }}
                  >
                    <p className="text-blue-500 font-bold text-sm">{report.reportNumber}</p>
                    <p className="text-grey-400 text-xs">
                      {convertUnixToSingaporeTime(report.createdAt)}
                    </p>
                    {report.createdAt > data.scamEnabler.lastModifiedAt && (
                      <div className="absolute top-0 right-0 h-[75%] w-0 py-0 px-[88%] flex items-end justify-center rotate-45 translate-x-1/2 -translate-y-1/2 bg-blue-400">
                        <p className="text-white text-xs font-bold">New</p>
                      </div>
                    )}
                  </button>
                </div>
              );
            })}
          <RelatedReportModal
            isOpen={relatedReportOpen}
            handleClose={() => setRelatedReportOpen(false)}
            reportId={selectedReport}
            scamEnablerId={data.scamEnabler.scamEnablerUserId}
            platform={data.scamEnabler.platform}
          />
        </div>
      )}
      <div>
        {isLoading && <div className="animate-pulse bg-grey-300 w-full h-64 rounded-lg"></div>}
      </div>
    </div>
  );
};

export default PortfoliosMonikerRelatedReports;
