import { Controller } from 'react-hook-form';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const RadioGroupComponent = ({
  radioItems,
  onChange,
  control,
  defaultValue,
}: {
  control?: any;
  radioItems: Array<{ value: string; label: string; disabled?: boolean }>;
  onChange?: any;
  defaultValue?: any;
}) => {
  if (control) {
    return (
      <Controller
        name="radioGroup"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <RadioGroup>
            {radioItems.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    );
  } else {
    return (
      <RadioGroup
        onChange={onChange}
        defaultValue={defaultValue ? defaultValue : radioItems[0].value}
      >
        {radioItems.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.value}
            control={<Radio color="secondary" />}
            label={item.label}
            disabled={item?.disabled}
          />
        ))}
      </RadioGroup>
    );
  }
};

export default RadioGroupComponent;
