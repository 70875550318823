import { Controller } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import { Button, ConfigProvider, DatePicker, Flex } from 'antd';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import { useState } from 'react';

const createInputPicker = (PickerComponent: any) => styled(PickerComponent)`
  width: 100%;
  padding: 14px 14px;
  border: solid 1px var(--grey-400);
  font-size: 14px;

  &:hover {
    border-color: var(--grey-400); // Replace #yourHoverBorderColor with your preferred color
    cursor: pointer;
  }

  .ant-picker-input {
    &:hover .ant-picker-input > input {
      border-color: none; // Hover state for the input border
    }
  }

  .ant-picker-active-bar {
    display: none;
  }

  &.ant-picker-focused {
    border: solid 2px var(--blue-400); // Focus state for the range picker itself
    box-shadow: none;
  }

  .ant-picker-input input::placeholder {
    color: var(--grey-400);
    letter-spacing: 0;
  }
`;

const DatePickerTable = ({
  control,
  id,
  rules,
  row,
  disabled,
}: {
  control?: any;
  id: string;
  rules?: any;
  row: any;
  disabled?: boolean;
}) => {
  const StyledInputPicker = createInputPicker(DatePicker);
  const [open, setOpen] = useState(false);

  const isValidDateFormat = (value: string) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(value);
  };

  const getCompatibleValue = (value: string) => {
    if (isValidDateFormat(value)) {
      return dayjs(value, 'YYYY-MM-DD');
    }
    return null;
  };

  const disabledDate = (current: any) => {
    return current && current > dayjs().endOf('day');
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#6FB8C9',
        },
      }}
    >
      <Controller
        name={id}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div>
            <IconButton onClick={() => setOpen(!open)}>
              <CalendarIcon />
            </IconButton>
            <StyledInputPicker
              id={id}
              key={id}
              open={open}
              disabled={disabled}
              disabledDate={disabledDate}
              format="DD/MM/YYYY"
              getPopupContainer={(triggerNode: { parentNode: any }) => {
                return triggerNode.parentNode;
              }}
              onChange={(newValue: string | number | Date | dayjs.Dayjs | null | undefined) => {
                const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';
                onChange(formattedDate);
                setOpen(false);
              }}
              style={{
                opacity: 0,
                width: '1px',
                height: '1px',
                zIndex: -1,
              }}
              // placeholder={placeholder}
              placement={'bottomLeft'}
              onOpenChange={(open: boolean | ((prevState: boolean) => boolean)) => setOpen(open)}
              suffixIcon={<CalendarIcon />}
              showNow={false}
              value={getCompatibleValue(value)}
              renderExtraFooter={() => (
                <Flex justify="space-between" style={{ paddingBlock: 7 }}>
                  <Button size="small" type="link" onClick={() => onChange(null)}>
                    Clear
                  </Button>
                  <Button
                    size="small"
                    type="link"
                    onClick={() => {
                      onChange(dayjs().format('YYYY-MM-DD'));
                      // Value is not updated if we close the picker immediately
                      setTimeout(() => setOpen(false));
                    }}
                  >
                    Today
                  </Button>
                </Flex>
              )}
            />
          </div>
        )}
      />
    </ConfigProvider>
  );
};

export default DatePickerTable;
