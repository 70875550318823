import { useState } from 'react';
import { LoadingButtonComponent } from '@components/common/CommonButton';
import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import { ScamEnablerInterface } from '@interface/ScamEnablerInterface';
import { useDeleteProductionOrderQueueMutation } from '@api/productionOrderSlice';
import { useDispatch } from 'react-redux';
import { deleteProductionOrder } from '../DirectionsQueueMainPage/deleteProductionOrder';

const ProductionOrderDeleteQueueModal = ({
  scamEnablers,
  isOpen,
  onClose,
}: {
  scamEnablers: Array<ScamEnablerInterface>;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [deleteProductionOrderQueue] = useDeleteProductionOrderQueueMutation();
  const dispatch = useDispatch();

  const handleClick = async () => {
    setIsLoadingDelete(true);
    let poQueueIds = scamEnablers.map((scamEnabler) => scamEnabler.poQueueId);
    await deleteProductionOrder(poQueueIds, dispatch, deleteProductionOrderQueue);
    onClose();
    setIsLoadingDelete(false);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalLayout
        title={'Delete from Queue'}
        handleCloseModal={onClose}
        alignment="center"
        size="medium"
      >
        <p className="py-4 font-h4-bold-14 ">
          You are deleting the following moniker IDs from the PO Queue:
        </p>
        <div className="overflow-auto bg-grey-200 p-4 mb-4">
          {scamEnablers.map((scamEnabler, index) => (
            <div key={index} className="grid grid-cols-3">
              <p className="text-sm">
                {scamEnabler.userId ? scamEnabler.userId : scamEnabler.scamEnablerUserId}
              </p>
            </div>
          ))}
        </div>
        <p>Are you sure you want to proceed?</p>
        <p>You cannot undo this action.</p>
        <div className="flex justify-end gap-4 pt-4">
          <button>Cancel</button>
          <LoadingButtonComponent
            isLoading={isLoadingDelete}
            title="Delete"
            variant="red"
            onClick={handleClick}
          />
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default ProductionOrderDeleteQueueModal;
