import { FormItemInterface } from "@interface/dynamicFormInterface";

export const ChangePasswordFormGeneralItems: Array<FormItemInterface> = [
  {
    label: "Current Password",
    type: "password",
    id: "currentPassword",
    placeholder: "Current Password",
    alignment: "column",
    rules: {
      required: "Please enter a valid Password",
      minLength: {
        value: 8,
        message: "Please enter at least 8 characters"
      }
    },
  },
  {
    label: "New Password",
    type: "password",
    id: "newPassword",
    placeholder: "New Password",
    alignment: "column",
    displayPasswordRequirements: true,
    rules: {
      required: "Please enter a valid Password",
      minLength: {
        value: 8
      }
    },
  },
  {
    label: "Confirm Password",
    type: "password",
    id: "confirmPassword",
    placeholder: "Confirm Password",
    alignment: "column",
    rules: {
      required: "Please enter a valid Password",
      minLength: {
        value: 8,
        message: "Please enter at least 8 characters"
      }
    },
  }
];