import type { UploadFile, UploadProps } from 'antd';
import { Upload } from 'antd';
import { ReactComponent as ImageUpload } from '@assets/icons/file_upload.svg';
import { Controller } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';
import { read, utils } from 'xlsx';
import validator from 'validator';
import { CommonButton } from './CommonButton';

const { Dragger } = Upload;

const FileUpload = ({
  control,
  id,
  setValue,
  getValues,
  onClose,
  append,
  setError,
}: {
  control: any;
  id: string;
  setValue: any;
  getValues: any;
  onClose?: any;
  append?: any;
  setError?: any;
}) => {
  const [errorMsg, setErrorMsg] = useState('');

  const props: UploadProps = {
    name: 'file',
    beforeUpload: async (file: UploadFile) => {
      const allowedType = validateFileType(file, [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ]);
      if (!allowedType) {
        setErrorMsg('Incompatible file type.');
        return Upload.LIST_IGNORE;
      } else if (file.size && file.size > 8000000) {
        setErrorMsg('File size exceeds 8MB.');
        return Upload.LIST_IGNORE;
      }
      setErrorMsg('');
      readFile(file);
      return false;
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const validateFileType = ({ type, name }: UploadFile, allowedTypes?: string[]) => {
    if (!allowedTypes) {
      return true;
    }
    if (type) {
      return allowedTypes.includes(type);
    }
  };

  const validateUniqueUrl = useCallback(
    (currentValue: any, index: number) => {
      const allValues = getValues(id.split('.')[0]);
      const isDuplicate = allValues.websites.some(
        (item: any, idx: number) => idx !== index && item.url === currentValue,
      );
      return isDuplicate ? false : true;
    },
    [getValues],
  );

  const validateValidUrl = useCallback(
    (currentValue: any) => {
      const isInvalidUrl = !validator.isURL(currentValue);
      return isInvalidUrl ? false : true;
    },
    [getValues],
  );

  const readFile = (file: any) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet, { header: 1 });

      json.forEach((item: any, index: number) => {
        if (index > 0) {
          // skip the first row
          if (item[0] && item[0].length > 0) {
            append();
            const url = item[0];
            const category = item[1] ?? '';
            const brand = item[2] ?? '';
            setValue(`${id}[${index - 1}]`, {
              url: url,
              category: category,
              brand: brand,
              attachments: [],
            });
            if (!validateUniqueUrl(url, index - 1)) {
              setError(`${id}[${index - 1}].url`, {
                type: 'custom',
                message: 'URL must be unique.',
              });
            } else if (!validateValidUrl(url)) {
              setError(`${id}[${index - 1}].url`, {
                type: 'custom',
                message:
                  'Invalid website URL. Please provide URL in the correct format e.g. https://website.com or http://website.com',
              });
            }
          }
        }
      });
    };
    reader.readAsArrayBuffer(file);
    onClose();
  };

  return (
    <div className="h-full">
      <Controller
        name={id}
        control={control}
        render={({ field }) => (
          <Dragger
            {...props}
            {...field}
            showUploadList={false}
            className="items-center justify-center h-full"
            style={{ backgroundColor: 'white', border: 'solid 1px black' }}
          >
            {errorMsg.length === 0 ? (
              <div className="text-center items-center">
                <div className="ant-upload-drag-icon inline-block mb-3">
                  <ImageUpload />
                </div>
                <p className="ant-upload-text font-bold">Drag and Drop your file here</p>
                <p className="ant-upload-hint mt-3 mb-3 text-[var(--grey-400)]">
                  XLS only - 8MB max
                </p>
                <div className="flex justify-center">
                  <CommonButton
                    title="Choose File"
                    variant="primary"
                    type="button"
                    onClick={undefined}
                  />
                </div>
              </div>
            ) : (
              <div className="inline-flex text-center items-center">
                <Warning className="d-flex" />
                <p className="text-[var(--red)] text-base font-bold pl-1">
                  {errorMsg}
                  <span className="pointer-events-auto text-base underline text-[var(--blue-500)] pl-1">
                    {errorMsg === 'Error in uploading.' ? 'Try again' : 'Choose another'}
                  </span>
                </p>
              </div>
            )}
          </Dragger>
        )}
      />
    </div>
  );
};

export default FileUpload;
