import { IconButton } from './CommonButton';
import Dropdown from './dropdown/Dropdown';
import { useForm } from 'react-hook-form';
import { useState, useCallback, useEffect } from 'react';
import { useUpdateScamEnablerV2Mutation } from '@api/scamEnablerSlice';
import {
  BankStatus,
  MonikerOutcomeStatus,
  PaynowStatus,
  ProactiveMonikerOutcomeStatus,
  ReportStatus,
} from './StatusComponent';
import { reportStatusMapping } from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';

type EditLabelDropdownProps = {
  field: FormItemInterface;
  data: any;
  callback?: () => void;
  isEditable?: boolean;
};

const EditLabelDropdown: React.FC<EditLabelDropdownProps> = ({
  field,
  data,
  callback,
  isEditable,
}) => {
  const [selectedOption, setSelectedOption] = useState(field.value);
  const { control, formState, setValue, getValues } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [updateScamEnablerV2] = useUpdateScamEnablerV2Mutation();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(async () => {
    let currentOutcome = getValues(field.id);
    let response: any;
    console.log('currentOutcome', currentOutcome);
    console.log('selectedOption', selectedOption);

    // if (currentOutcome !== selectedOption) {
    //   setIsEditing(false);
    //   return true;
    // }
    setIsLoading(true);
    if (field.extras.updateAPI === 'updateScamEnablerV2') {
      //handling for boolean field
      let updatedParam = currentOutcome;
      if (updatedParam === 'Yes') {
        updatedParam = true;
      } else if (updatedParam === 'No') {
        updatedParam = false;
      }
      let tempUpdateParams = [
        {
          scamEnablerId: data._id,
          [field.id]: updatedParam,
        },
      ];
      response = await updateScamEnablerV2(tempUpdateParams);
    }

    if (response && !response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Update Successful`,
        }),
      );
      setIsEditing(false);
      if (callback) {
        callback();
      }
    }
    setIsLoading(false);
  }, [field, selectedOption, getValues, data, callback]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    setSelectedOption(field.value);
  }, [field.value]);

  const handleEdit = useCallback(() => setIsEditing(true), []);

  useEffect(() => {
    let value = field.value === true ? 'Yes' : field.value === false ? 'No' : field.value;
    setSelectedOption(value);
  }, [field.value]);
  return (
    <div className="flex flex-row flex-grow gap-2 items-center justify-start ">
      {isEditing ? (
        <form className="flex flex-row flex-grow gap-2 items-center justify-center">
          <Dropdown
            id={field.id}
            control={control}
            rules={field.rules}
            placeholder={field.placeholder}
            formState={formState}
            dropdownData={field.extras.dropdownData}
            searchable={true}
            setValue={setValue}
            revert={true}
            onChange={(e: any) => {
              setSelectedOption(e);
            }}
            originalValue={field.value}
          >
            <IconButton
              isDisabled={false}
              icon="tick"
              type="button"
              onClick={handleSubmit}
              isLoading={isLoading}
            />
          </Dropdown>
          <IconButton
            isDisabled={false}
            icon="cross"
            onClick={handleCancel}
            isLoading={false}
            type="button"
          />
        </form>
      ) : (
        <div className="flex flex-row gap-2 items-center justify-center">
          {field.id === 'outcome' && field?.extras?.type === 'proactive' && (
            <ProactiveMonikerOutcomeStatus outcome={selectedOption} />
          )}
          {field.id === 'outcome' && !field?.extras?.type && (
            <MonikerOutcomeStatus outcome={selectedOption} />
          )}
          {field.id == 'outcome' && field?.extras?.type == 'bank' && (
            <BankStatus outcome={selectedOption} />
          )}
          {field.id == 'outcome' && field?.extras?.type == 'paynow' && (
            <PaynowStatus outcome={selectedOption} />
          )}
          {field.id === 'reportStatus' && <ReportStatus reportStatus={selectedOption} />}
          {field.id !== 'outcome' && field.id !== 'reportStatus' && <p>{selectedOption}</p>}

          {isEditable && (
            <IconButton isDisabled={false} icon="edit" onClick={handleEdit} isLoading={false} />
          )}
        </div>
      )}
    </div>
  );
};

export default EditLabelDropdown;
