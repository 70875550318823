import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';
import { Controller } from 'react-hook-form';
import { getNestedError } from '@utils/utils';
import { CircularProgress } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

const TextInput = ({
  id,
  control,
  placeholder,
  prefix,
  prefill = '',
  formState,
  rules,
  disabled,
  rows,
  maxRows,
  multiline,
  keyup,
  isLoading,
  isVerified,
  textInputType = 'text',
  autocomplete,
}: {
  id: string;
  control: any;
  placeholder?: string;
  prefix?: string;
  prefill?: string;
  formState?: any;
  rules?: any;
  disabled?: boolean;
  rows?: number;
  maxRows?: number;
  multiline?: boolean;
  keyup?: any;
  isLoading?: boolean;
  isVerified?: boolean;
  textInputType?: string;
  autocomplete?: string;
}) => {
  const errors = formState?.errors;

  const handleKeyUp = (e: any) => {
    if (keyup) {
      return keyup(e.target.value);
    } else {
      return e.target.value;
    }
  };

  return (
    <div className="w-full">
      <Controller
        control={control}
        name={id}
        rules={rules}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState, formState }) => (
          <TextField
            onBlur={onBlur}
            multiline={multiline || rows ? true : false}
            rows={rows ? rows : ''}
            maxRows={maxRows}
            type={textInputType}
            onChange={(e) => {
              const newValue = e.target.value;
              onChange(prefix ? `${prefix}${newValue}` : newValue);
              onChange(handleKeyUp(e));
            }}
            onFocus={(e) =>
              e.target.addEventListener(
                'wheel',
                function (e) {
                  e.preventDefault();
                },
                { passive: false },
              )
            }
            value={
              value && textInputType === 'text'
                ? value
                : value && textInputType === 'number'
                  ? value
                  : prefill
            }
            inputRef={ref}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: !rows || rows == 1 ? 0 : 2,
                '&:hover fieldset': {
                  borderColor:
                    errors && getNestedError(errors, id)
                      ? 'var(--red)'
                      : disabled
                        ? 'rgb(189, 189, 189, 0.9)'
                        : 'var(--grey-400)',
                },
                '&.Mui-focused fieldset': {
                  borderColor:
                    errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--blue-400)',
                },
              },
              '& fieldset': {
                borderColor:
                  errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--grey-400)',
                opacity: (theme: any) => (theme.disabled ? 0.8 : 1),
              },
              '.MuiInputBase-input': {
                fontSize: '14px',
              },
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                display: 'none',
              },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete={autocomplete}
            fullWidth
            InputLabelProps={{ shrink: false }}
            InputProps={{
              startAdornment: prefix && (
                <InputAdornment
                  sx={{
                    padding: '27px 14px',
                    backgroundColor: 'var(--grey-200)',
                    borderRight: '1px solid var(--grey-400)',
                    borderTopLeftRadius: (theme) => theme.shape.borderRadius + 'px',
                    borderBottomLeftRadius: (theme) => theme.shape.borderRadius + 'px',
                  }}
                  position="start"
                >
                  <span className="text-sm">{prefix}</span>
                </InputAdornment>
              ),
              endAdornment: isLoading ? (
                <CircularProgress size={20} /> // Step 3a
              ) : isVerified ? (
                <CheckCircle style={{ color: 'green' }} /> // Step 3b
              ) : errors && getNestedError(errors, id) ? (
                <InputAdornment position="end">
                  <Warning />
                </InputAdornment>
              ) : null,
              sx: { borderRadius: '6px' },
            }}
          />
        )}
      />
      {errors && getNestedError(errors, id) && (
        <span style={{ color: 'var(--red)' }} role="alert">
          {getNestedError(errors, id)?.message}
        </span>
      )}
    </div>
  );
};

export default TextInput;
