import { checkPermissions } from '@utils/utils';
import ErrorPage from './ErrorPage';

const AccessControl = ({
  type,
  allowedPermissions = [],
  children,
  renderNoAccess = () => <></>,
}: {
  type: string;
  allowedPermissions?: string[];
  children?: any;
  renderNoAccess?: any;
}) => {

  const permitted = checkPermissions(allowedPermissions);
  if (type === 'page' && !permitted) {
    return <ErrorPage></ErrorPage>;
  } else if (!permitted) {
    return renderNoAccess();
  }
  return children;
};

export default AccessControl;
