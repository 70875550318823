import { useFieldArray, Controller } from 'react-hook-form';
import TelephoneInput from './TelephoneInput'; // Adjust the import path as necessary
import { IconButton } from '../CommonButton';

function MultiTelephoneInput({
  id,
  control,
  rules,
  formState,
  disabled,
  alignment = 'row',
}: {
  id: string;
  control: any;
  rules?: any;
  formState?: any;
  disabled?: boolean;
  alignment?: string;
}) {
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: id,
    rules: rules,
  });

  return (
    <div className={`${alignment === 'row' ? ' flex-col' : ' flex-row'} gap-4`}>
      {alignment === 'column' && (
        <p className="text-xs pb-2 text-grey-400">
          You may paste the full number with the country code into the field.
        </p>
      )}
      {fields.map((field, index) => (
        <div key={field.id} className={`w-full flex flex-row gap-4`}>
          <div className="flex-grow pb-2">
            <Controller
              name={`${id}[${index}]`}
              control={control}
              render={({ field }) => (
                <TelephoneInput
                  id={field.name}
                  control={control}
                  rules={rules}
                  formState={formState}
                  alignment={alignment}
                  hideMessage={true}
                  isMultiInput={true}
                />
              )}
            />
          </div>
          <IconButton
            isDisabled={false}
            icon="delete"
            onClick={() => remove(index)}
            type="button"
          />
        </div>
      ))}
      {alignment === 'row' && (
        <p className="text-xs pt-2 text-grey-400">
          You may paste the full number with the country code into the field.
        </p>
      )}
      <IconButton
        isDisabled={false}
        icon="add"
        onClick={() => append('+65')}
        isLoading={false}
        type="button"
      />
    </div>
  );
}

export default MultiTelephoneInput;
