import { InfoSection } from '../components/InfoSection';
import { TransactionDetails } from '../components/TransactionDetails';
import { RelatedReports } from '../components/RelatedReports';
import { AccountDetails } from './AccountDetails';

export function PaynowSection({ data }: { data: any }) {
  return (
    <>
      {/* Account details */}
      <InfoSection title="Account Details" inner={<AccountDetails data={data} />} />

      {/* Transaction details */}
      <InfoSection
        title="Transaction Details (For this report only)"
        inner={
          <TransactionDetails
            amtEarmarked={data?.updatedTransactions[0]?.amountEarmarked || 0}
            transactionHistory={data.updatedTransactions[0]}
          />
        }
      />

      {/* Related Reports */}
      <InfoSection
        title="Related Reports"
        inner={<RelatedReports relatedReports={['12345', '2348728745']} />}
      />
    </>
  );
}
