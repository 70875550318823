import FileUploadXls from '@components/common/FileUploadXls';
import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';
import { CommonButton } from '@components/common/CommonButton';

const UploadExcelModal = ({
  id,
  isOpen,
  onClose,
  formMethods,
  append,
}: {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  formMethods: any;
  append?: any;
}) => {
  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/assets/template/website_template.xlsx';
    link.download = 'SATIS+ Website Upload Template.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalLayout
        title="Upload Excel List"
        handleCloseModal={onClose}
        alignment="center"
        size="small"
      >
        <div>
          <CommonButton
            className="pb-3"
            title="Download Excel Template"
            variant="text-blue"
            onClick={handleDownloadTemplate}
            type="button"
          />
        </div>
        <FileUploadXls
          id={id}
          control={formMethods.control}
          setValue={formMethods.setValue}
          getValues={formMethods.getValues}
          onClose={onClose}
          append={append}
          setError={formMethods.setError}
        />
      </ModalLayout>
    </Modal>
  );
};

export default UploadExcelModal;
