import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import ModalLayout from '@components/layouts/ModalLayout';
import DynamicForm from '@components/common/DynamicForm';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { ReactComponent as LeftArrow } from '@assets/icons/left_arrow_blue.svg';
import { ReactComponent as RightArrow } from '@assets/icons/right_arrow_blue.svg';
import clsx from 'clsx';
import { convertMsToDDMMYYYYHHmm } from '@utils/utils';
import cloneDeep from 'lodash.clonedeep';
import { useGetReportDetailsByIdV2Query } from '@api/reportsSlice';

interface RelatedReportModalProps {
  isOpen: boolean;
  handleClose: () => void;
  reportId: string;
  scamEnablerId?: string;
  platform?: string;
}

export const overviewItems: Array<FormItemInterface> = [
  {
    label: 'Date Received',
    id: 'createdAt',
    type: 'label',
    nestedField: ['relatedReportData'],
  },
  {
    label: 'Police Station/Unit',
    id: 'policeStation',
    type: 'label',
    nestedField: ['relatedReportData'],
  },
  {
    label: 'Submitted By',
    id: 'spfEmail',
    type: 'label',
    nestedField: ['relatedReportData'],
  },
  {
    label: 'Scam Type',
    id: 'scamType',
    type: 'label',
    nestedField: ['relatedReportData'],
  },
];

export const monikerItems: Array<FormItemInterface> = [
  {
    label: 'platform',
    id: 'platform',
    type: 'label',
  },
  {
    label: 'User Id',
    id: 'userId',
    type: 'label',
  },
  {
    label: 'URL',
    id: 'url',
    type: 'label',
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'scamEnablerId',
    extras: {
      updateAPI: 'uploadScamEnablerAttachmentV2',
      attachmentPath: '/v1/report/attachment/',
    },
  },
  {
    label: 'justification',
    id: 'useOfScamEnabler',
    type: 'label',
  },
  {
    label: 'Victim Report',
    id: 'victimReported',
    type: 'label',
  },
  {
    label: 'Brief Facts',
    id: 'explanation',
    type: 'label',
  },
  // {
  //   label: 'Interaction to any post/ads',
  //   id: 'interactionPostAds',
  //   type: 'label',
  // },
  {
    label: 'No. of victims',
    id: 'numberOfVictims',
    type: 'label',
  },
  {
    label: "Scammer's contact No.",
    id: 'scammerContactNumber',
    type: 'label',
  },
];

const RelatedReportModal: React.FC<RelatedReportModalProps> = ({
  isOpen,
  handleClose,
  reportId,
}) => {
  const [relatedScamEnablerData, setRelatedScamEnablerData] = useState<any>({});
  const [currentReportHistoryPage, setReportHistoryPage] = useState(0);

  const { data, error, isLoading, isSuccess, refetch } = useGetReportDetailsByIdV2Query(
    {
      id: reportId,
    },
    {
      refetchOnFocus: true,
      skip: reportId === '',
    },
  );

  const handlePreviousSubmission = () => {
    if (currentReportHistoryPage !== 0) {
      setReportHistoryPage(currentReportHistoryPage - 1);
    }
  };

  const handleNextSubmission = () => {
    if (currentReportHistoryPage + 1 < data!.report.reportHistory.length) {
      setReportHistoryPage(currentReportHistoryPage + 1);
    }
  };

  useEffect(() => {
    if (error) {
      console.error('An error occurred:', error);
    }

    if (!isLoading && data && isSuccess) {
      let relatedReportData = cloneDeep(data?.report.reportHistory[currentReportHistoryPage]);

      relatedReportData.createdAt = convertMsToDDMMYYYYHHmm(relatedReportData.createdAt);
      let submissionData = {
        relatedReportData: relatedReportData,
        relatedScamEnablers: relatedReportData.scamEnablerSubmissions,
      };

      setRelatedScamEnablerData(submissionData);
    }
  }, [data, currentReportHistoryPage]);

  return (
    <>
      {data && (
        <Modal open={isOpen} onClose={handleClose}>
          <ModalLayout
            title={data?.report.reportNumber}
            handleCloseModal={() => {
              handleClose();
            }}
            alignment="right"
            size="full"
            link={`/admin/reports/${data.report._id}`}
            subTitle=""
            linkText="Go to Report Details Page"
          >
            {data.report.reportHistory.length > 0 && (
              <div className="flex flex-row justify-between">
                <h3 className="font-bold text-blue-500">Submissions</h3>
                <div className="flex flex-row bottom-0 justify-between items-center">
                  <button
                    onClick={handlePreviousSubmission}
                    className={clsx(
                      'flex flex-row items-center gap-2 font-bold text-blue-500 bg-blue-200 rounded-md p-1 mr-3',
                      {
                        'opacity-30': currentReportHistoryPage === 0,
                      },
                    )}
                  >
                    <LeftArrow></LeftArrow>
                  </button>
                  <p className="text-center text-grey-400">
                    <span className="font-bold">{currentReportHistoryPage + 1}</span>
                    {` of ${data.report.reportHistory.length}`}
                  </p>
                  <button
                    onClick={handleNextSubmission}
                    className={clsx(
                      'flex flex-row items-center gap-2 font-bold text-blue-500 bg-blue-200 rounded-md p-1 ml-3',
                      {
                        'opacity-30':
                          currentReportHistoryPage === data.report.reportHistory.length - 1,
                      },
                    )}
                  >
                    <RightArrow></RightArrow>
                  </button>
                </div>
              </div>
            )}
            <h3 className="text-blue-400 font-bold pt-4">General Information</h3>
            {relatedScamEnablerData?.relatedReportData && (
              <DynamicForm
                formFields={overviewItems}
                data={relatedScamEnablerData}
                key="overview"
              />
            )}
            <h3 className="text-blue-400 font-bold pt-4 flex flex-row align-bottom gap-2">
              Moniker Details
            </h3>
            {relatedScamEnablerData?.relatedScamEnablers &&
              relatedScamEnablerData.relatedScamEnablers.map(
                (relatedScamEnabler: any, index: number) => {
                  return (
                    <div key={index}>
                      <p className="text-grey-400 font-bold pt-4 pb-1">
                        {`Scam Enabler ${index + 1}`}
                      </p>
                      <DynamicForm
                        formFields={monikerItems}
                        data={relatedScamEnabler}
                        key={index}
                      />
                    </div>
                  );
                },
              )}
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RelatedReportModal;
