const date = new Date();

export const carousellColumns = [
  {
    title: 'Date of Request',
    key: 'dateOfRequest',
    render: (text: string, record: any, index: number) => {
      const date = new Date();
      const formattedDate = date.toLocaleDateString('en-GB');
      return <p>{formattedDate}</p>;
    },
  },
  {
    title: 'Month',
    key: 'month',
    render: (text: string, record: any, index: number) => <p>{date.getMonth()}</p>,
  },
  {
    title: 'Week',
    key: 'week',
    render: (text: string, record: any, index: number) => <p>{Math.ceil(date.getDate() / 7)}</p>,
  },
  {
    title: 'Year',
    key: 'year',
    value: date.getFullYear(), // Get the year (4 digits)
    render: (text: string, record: any, index: number) => <p>{date.getFullYear()}</p>,
  },
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
  },
  {
    title: 'Brief facts of case',
    dataIndex: 'explanation',
    key: 'explanation',
  },
];
