import { useCheckReportExistMutation } from '@api/reportsSlice';
import { CommonButton } from '@components/common/CommonButton';
import ModalLayout from '@components/layouts/ModalLayout';
import { Modal, TextField } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

const CheckReportsModal = ({
  isOpen,
  onModalClose,
}: {
  isOpen: boolean;
  onModalClose: () => void;
}) => {
  const { register, handleSubmit, setValue, getValues } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [checkReportExist] = useCheckReportExistMutation();
  const onSubmitReports = async () => {
    let reportList = getValues('reportlist');
    let newReportList = reportList.split('\n').map((report: any) => report.trim());
    setIsLoading(true);
    let response = await checkReportExist({ reportNumbers: newReportList });

    setIsLoading(false);
  };

  const closeModal = () => {
    setValue('reportlist', '');
    onModalClose();
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <ModalLayout
        title={`Check Reports`}
        handleCloseModal={closeModal}
        alignment="center"
        size="small"
      >
        <form className="flex flex-col w-full" onSubmit={handleSubmit(onSubmitReports)}>
          <p className="py-1 font-semibold text-sm  pb-2">
            Copy list of reportIds to check and paste into text area
          </p>
          <TextField
            {...register('reportlist')}
            defaultValue={''}
            placeholder={'M/20240308/001'}
            multiline
            rows={15}
            className="bg-white"
            InputProps={{
              sx: {
                fontSize: '14px',
              },
            }}
          ></TextField>
          <div className="box-border w-full flex justify-end bottom-0 items-center pt-2 gap-2">
            <CommonButton isDisabled={false} variant="text" title={`Cancel`} onClick={closeModal} />

            <CommonButton
              isDisabled={false}
              variant="text-blue"
              title={`Submit`}
              isLoading={isLoading}
              onClick={async (event: any) => {
                handleSubmit && (await handleSubmit(event));
              }}
            />
          </div>
        </form>
      </ModalLayout>
    </Modal>
  );
};

export default CheckReportsModal;
