import {
  financialExchangePlatform,
  bankOutcomesUpdatable,
  accountTypes,
  transactionTypes,
} from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { bankOutcomes } from '@interface/constant';
import { debounce } from 'lodash';

export const bankFilterGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Account Status',
    type: 'dropdownMulti',
    id: 'outcome',
    placeholder: 'Please Select',
    extras: { dropdownData: bankOutcomes },
    alignment: 'row',
  },
  {
    label: 'Name of Bank',
    type: 'dropdownMulti',
    id: 'platform',
    placeholder: 'Please Select',
    extras: { dropdownData: financialExchangePlatform },
    alignment: 'row',
  },
  {
    label: 'DO Assigned',
    type: 'dropdownUser',
    id: 'officer_assigned',
    // TODO: replace usergroup
    extras: { userGroup: 'scamEnablers' },
    alignment: 'row',
  },
  {
    label: 'Date of Latest Transfer',
    type: 'calendarRange',
    id: 'created_date_from,created_date_to',
    placeholder: 'dd/mm/yyyy',
    alignment: 'row',
  },
];

export const overviewInformationItemsGeneral: Array<FormItemInterface> = [
  {
    label: 'Account No.',
    id: 'accountNumber',
    type: 'label',
  },
  {
    label: 'Name of Bank',
    id: 'platform',
    type: 'label',
  },

  {
    label: "Account Holder's Name",
    id: 'accountHolderName',
    type: 'label',
  },
  {
    label: "Account Holder's NRIC/ID",
    id: 'accountHolderId',
    type: 'label',
  },
  {
    label: "Account Holder's Contact",
    id: 'accountHolderContact',
    type: 'label',
  },
  {
    label: 'Account Type',
    id: 'accountType',
    type: 'label',
  },
  {
    label: 'DO Assigned',
    id: 'dutyOfficer',
    type: 'labelEditDropdownUser',
  },
  {
    label: 'Account Status',
    id: 'outcome',
    type: 'labelEditDropdown',
    extras: {
      dropdownData: bankOutcomes,
      type: 'bank',
    },
  },
];

export const overviewFormItemsGeneral: Array<FormItemInterface> = [
  {
    label: 'Account No.',
    id: 'accountNumber',
    type: 'textInput',
    disabled: true,
    rules: {
      required: 'Account No is required',
    },
  },
  {
    label: 'Name of Bank',
    id: 'platform',
    type: 'textInput',
    disabled: true,
  },
  {
    label: "Account Holder's Name",
    id: 'accountHolderName',
    type: 'textInput',
  },
  {
    label: "Account Holder's NRIC/ID",
    id: 'accountHolderId',
    type: 'textInput',
  },
  {
    label: "Account Holder's Contact",
    id: 'accountHolderContact',
    type: 'textInput',
  },
  {
    label: 'Account Type',
    id: 'accountType',
    type: 'dropdown',
    extras: {
      dropdownData: accountTypes,
    },
  },
  // {
  //   label: 'Transaction Type',
  //   id: 'transactionType',
  //   type: 'dropdown',
  //   extras: {
  //     dropdownData: transactionTypes,
  //   },
  // },
  // {
  //   label: 'DO Assigned',
  //   id: 'dutyOfficer',
  //   type: 'dropdownUser',
  //   extras: { userGroup: 'scamEnablers' },
  // },
  {
    label: 'Account Status',
    id: 'outcome',
    type: 'dropdown',
    extras: {
      dropdownData: bankOutcomes,
    },
  },
];

export const overviewInformationItemsDates: Array<FormItemInterface> = [
  {
    label: 'Date of Creation',
    id: 'createdAt',
    type: 'labelEditDateTime',
  },
  {
    label: 'Date of Referral',
    id: 'referralDate',
    type: 'labelEditDateTime',
    extras: {
      tooltip: {
        content:
          'This refers to the date when the bank account was first referred to ASC. It will follow Date of Creation by default but it can be edited accordingly if required.',
      },
    },
  },
  {
    label: 'Date of PO Sent',
    id: 'productionOrderSentDate',
    type: 'labelEditDateTime',
  },
  {
    label: 'Date of Bank Account Frozen',
    id: 'bankAccountFrozenDate',
    type: 'labelEditDateTime',
    extras: {
      tooltip: {
        content:
          'This refers to the date when the bank provides an update of the account outcome. \ne.g. Frozen, CENTUM - Not Frozen, Earmarked Funds, etc.',
      },
    },
  },
  {
    label: 'Date of Bank Details Received',
    id: 'bankDetailsRetrievedDate',
    type: 'labelEditDateTime',
  },
  {
    label: 'Review TAT',
    id: 'reviewTAT',
    type: 'label',
    extras: {
      tooltip: {
        content: 'Review TAT = Date of PO Sent - Date of Referral',
      },
    },
  },
  {
    label: 'Return TAT (Account Frozen)',
    id: 'returnTATFrozen',
    type: 'label',
    extras: {
      tooltip: {
        content: 'Return TAT (Account Frozen) = Date of PO Sent - Date of Bank Account Frozen',
      },
    },
  },
  {
    label: 'Return TAT (Bank Details)',
    id: 'returnTATDetails',
    type: 'label',
    extras: {
      tooltip: {
        content: 'Return TAT (Bank Details) = Date of PO Sent - Date of Bank Details Received',
      },
    },
  },
];

export const overviewFormItemsDates: Array<FormItemInterface> = [
  {
    label: 'Date of Referral',
    id: 'referralDate',
    extras: {
      removePopupContainer: true,
    },
    type: 'dateTime',
  },
  {
    label: 'Date of PO Sent',
    id: 'productionOrderSentDate',
    extras: {
      removePopupContainer: true,
    },
    type: 'dateTime',
  },
  {
    label: 'Date of Bank Account Frozen',
    id: 'bankAccountFrozenDate',
    extras: {
      removePopupContainer: true,
    },
    type: 'dateTime',
  },
  {
    label: 'Date of Bank Details Received',
    id: 'bankDetailsRetrievedDate',
    extras: {
      removePopupContainer: true,
    },
    type: 'dateTime',
  },
  // {
  //   label: 'Review TAT',
  //   id: 'reviewTAT',
  //   type: 'label',
  //   disabled: true,
  // },
  // {
  //   label: 'Return TAT (Account Frozen)',
  //   id: 'returnTATFrozen',
  //   type: 'label',
  //   disabled: true,
  // },
  // {
  //   label: 'Return TAT (Bank Details)',
  //   id: 'returnTATDetails',
  //   type: 'label',
  //   disabled: true,
  // },
];

export const overviewInformationItemsFir: Array<FormItemInterface> = [
  {
    label: 'Person of Interest Centric (POIC)',
    id: 'personOfInterestCentric',
    type: 'checkbox',
  },
  {
    label: 'Report No.',
    id: 'fir',
    type: 'link',
  },
];

export const overviewEditFormItemsFir = (
  reportDropDownData: any,
  getReportFunction: any,
  isPOIC: boolean,
) => {
  let firField: FormItemInterface = {
    label: 'Report No.',
    id: 'fir',
    type: 'dropdown',
    extras: {
      dropdownData: reportDropDownData,
      onInputChange: false,
    },
  };
  if (isPOIC === true) {
    firField.extras['onInputChange'] = async (input: string) => {
      const pattern = /^(?=.{13,15}$)([A-Z]{1,3}\/\d{4,8}\/\d+|ASC\/\d{4}\/\d{2}\/\d{2})$/;
      // debounce(async () => {
      if (input.length > 4) {
        let response = await getReportFunction({
          params: {
            search: input,
            page: 1,
            size: 10,
            sort_by: 'createdAt',
            order_by: 'DESC',
            search_fields: 'reportNumber',
            projection: 'reportNumber,_id',
          },
        });

        if (!response.error && response?.data?.reports) {
          return response.data.reports.map((item: any) => {
            return {
              id: item._id,
              label: item.reportNumber,
            };
          });
        }
      }
      // }, 300);

      return [];
    };
  }
  return [
    {
      label: 'Person of Interest Centric (POIC)',
      id: 'personOfInterestCentric',
      type: 'checkbox',
    },
    firField,
  ];
};

export const reportInformationForAccount: Array<FormItemInterface> = [
  {
    label: 'Total Amount Scammed (SGD)',
    id: 'amountScammed',
    type: 'label',
    extras: {
      format: {
        type: 'currency',
      },
    },
  },
  {
    label: 'Transactions',
    id: 'transactions',
    type: 'dynamicTable',
    extras: {
      tableFields: [
        {
          label: 'Date of Transfer',
          type: 'label',
          id: 'dateOfTransfer',
        },
        {
          label: 'Amount Loss',
          type: 'label',
          id: 'amountScammed',
        },
        {
          label: 'Transaction Type',
          id: 'transactionType',
          type: 'label',
        },
      ],
    },
    alignment: 'column',
  },
  {
    label: 'Additional Info for Email PO',
    id: 'additionalInformation',
    type: 'label',
    alignment: 'column',
  },
];

export const editReportInformationForAccount: Array<FormItemInterface> = [
  {
    label: 'Total Amount Scammed (SGD)',
    id: 'amountScammed',
    type: 'label',
  },
  {
    label: 'Transactions',
    id: 'transactions',
    type: 'dynamicTable',
    extras: {
      defaultRow: {
        dateOfTransfer: '',
        amountLoss: '',
        currency: 'SGD',
        transactionType: 'L-L',
      },
      tableFields: [
        {
          label: 'Date of Transfer',
          type: 'calendar',
          id: 'dateOfTransfer',
          extras: {
            removePopupContainer: true,
          },
        },
        {
          label: 'Amount Loss',
          type: 'textInputSelect',
          id: 'amountLoss',
          extras: {
            idCurrency: 'currency',
          },
        },
        {
          label: 'Transaction Type',
          id: 'transactionType',
          type: 'dropdown',
          alignment: 'column',
          optional: true,
          extras: {
            dropdownData: transactionTypes,
            // defaultValue: 'Local - Local',
          },
        },
      ],
    },
    optional: true,
    alignment: 'column',
  },
  {
    label: 'Additional Info for Email PO',
    id: 'additionalInformation',
    type: 'textInput',
    alignment: 'column',
  },
];

export const bulkUpdateOutcomeForm: Array<FormItemInterface> = [
  {
    label: 'Account Status',
    id: 'outcome',
    type: 'dropdown',
    alignment: 'column',
    extras: {
      dropdownData: bankOutcomesUpdatable,
    },
  },
  {
    label: 'Date of PO Sent',
    id: 'productionOrderSentDate',
    type: 'dateTime',
    extras: {
      removePopupContainer: true,
    },
    alignment: 'column',
  },
  {
    label: 'Date of Bank Account Frozen',
    id: 'bankAccountFrozenDate',
    type: 'dateTime',
    alignment: 'column',
    extras: {
      removePopupContainer: true,
      tooltip: {
        content: `This refers to the date when the bank provides an update of the account outcome. 
e.g. Frozen, CENTUM - Not Frozen, Earmarked Funds, etc.`,
      },
    },
  },
  {
    label: 'Date of Bank Details Received',
    id: 'bankDetailsRetrievedDate',
    type: 'dateTime',
    extras: {
      removePopupContainer: true,
    },
    alignment: 'column',
  },
];

export const editSeizureAmountsItems: Array<FormItemInterface> = [
  {
    label: '',
    id: 'seizedAmount',
    type: 'dynamicTable',
    extras: {
      removeLabel: true,
      defaultRow: {
        bankDetailsRetrievedDate: '',
        amountInSGD: '',
        poReferenceNo: '',
      },
      tableFields: [
        {
          label: 'Date of Bank Details Received',
          type: 'dateTime',
          id: 'bankDetailsRetrievedDate',
          extras: {
            removePopupContainer: true,
          },
        },
        {
          label: 'Amount Seized (SGD)',
          type: 'textInputSelect',
          id: 'amount',
          extras: {
            idCurrency: 'currency',
          },
        },
        {
          label: 'PO Reference No.',
          id: 'poReferenceNo',
          type: 'textInput',
        },
      ],
    },
    alignment: 'column',
  },
];

export const seizureAmountsItems: Array<FormItemInterface> = [
  {
    label: '',
    id: 'seizedAmount',
    type: 'dynamicTable',
    extras: {
      removeLabel: true,
      defaultRow: {
        bankDetailsRetrievedDate: '',
        amountInSGD: '',
        poReferenceNo: '',
      },
      tableFields: [
        {
          label: 'Date of Bank Details Received',
          type: 'label',
          id: 'bankDetailsRetrievedDate',
        },
        {
          label: 'Amount Seized (SGD)',
          type: 'label',
          id: 'amountSeized',
        },
        {
          label: 'PO Reference No.',
          id: 'poReferenceNo',
          type: 'label',
        },
      ],
    },
    alignment: 'column',
  },
];

export const editEarmarkedAmountsItems: Array<FormItemInterface> = [
  {
    label: '',
    id: 'earmarkedAmount',
    type: 'dynamicTable',
    extras: {
      removeLabel: true,
      defaultRow: {
        bankDetailsRetrievedDate: '',
        amountInSGD: '',
        poReferenceNo: '',
      },
      tableFields: [
        {
          label: 'Date of Bank Details Received',
          type: 'dateTime',
          id: 'bankDetailsRetrievedDate',
          extras: {
            removePopupContainer: true,
          },
        },
        {
          label: 'Amount Earmarked (SGD)',
          type: 'textInputSelect',
          id: 'amount',
          extras: {
            idCurrency: 'currency',
          },
        },
        {
          label: 'PO Reference No.',
          id: 'poReferenceNo',
          type: 'textInput',
        },
      ],
    },
    alignment: 'column',
  },
];

export const earmarkedAmountsItems: Array<FormItemInterface> = [
  {
    label: '',
    id: 'earmarkedAmount',
    type: 'dynamicTable',
    extras: {
      removeLabel: true,
      defaultRow: {
        bankDetailsRetrievedDate: '',
        amountInSGD: '',
        poReferenceNo: '',
      },
      tableFields: [
        {
          label: 'Date of Bank Details Received',
          type: 'label',
          id: 'bankDetailsRetrievedDate',
        },
        {
          label: 'Amount Earmarked (SGD)',
          type: 'label',
          id: 'amountSeized',
        },
        {
          label: 'PO Reference No.',
          id: 'poReferenceNo',
          type: 'label',
        },
      ],
    },
    alignment: 'column',
  },
];
