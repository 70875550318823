// TooltipComponent.js
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import styled from '@emotion/styled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useState } from 'react';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 360,
  },
});

const TooltipComponent = ({ content }: { content: string }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const TooltipContent = () => {
    switch (content) {
      case 'onlineMaterial':
        return (
          <div className="text-black p-4 drop-shadow-xl">
            <h1 className="text-black text-sm font-bold">
              Forms the specified offence in Part 2 of the First Schedule of OCHA:
            </h1>
            <ul className="list-disc pl-3">
              <li>
                Offences under Chapters 12 and 18 and sections 383 to 389, 405 to 409, 415 to 420A
                and 424A of the Penal Code 1871.
              </li>
              <li>
                An abetment, or a conspiracy, or an attempt to commit an offence listed in (a).
              </li>
            </ul>
          </div>
        );
      case 'scamChecklist':
        return (
          <div className="text-black p-4 drop-shadow-xl">
            <h1 className="text-black text-sm font-bold">Scam meets the following criteria:</h1>
            <ul className="list-disc pl-3">
              <li>Defined as Scam typology mentioned in CAD SOP 12</li>
              <li>
                Potential harm to Singapore entities or victims involved are local victims (i.e.
                money is lost from local bank account(s) or credit/debit card(s)) who unwittingly
                respond to scammers’ outreach, resulting in the loss of property (usually in the
                form of money); or
              </li>
              <li>
                Scammers reaching out to potential victims via social media, unsolicited phone or
                video calls, emails, mobile applications or the Internet; or
              </li>
              <li>
                Lack of face-to-face meetings between scammers and victims/potential victims
                throughout the entire process; or
              </li>
              <li>No physical danger posed to the victims/potential victims; or</li>
              <li>
                May involve impersonation of a legitimate entity or authority and / or misuse of the
                name of a legitimate entity or authority
              </li>
            </ul>
          </div>
        );
      case 'seizureAmounts':
        return (
          <div className="text-black p-4 drop-shadow-xl rounded-lg">
            <h1 className="text-grey-400 text-sm font-bold">
              Banks provided an updated seized amount after some time:
            </h1>
            <ul className="list-disc pl-3 text-grey-400">
              <li>Edit the previous amount that was tagged to the same PO</li>
              <li>
                The Latest Amount Seized will be updated accordingly if it has the latest date of
                bank details received
              </li>
            </ul>
            <h1 className="text-grey-400 text-sm font-bold pt-2">
              Banks provided amount seized with foreign and local currency (Same Date of Bank
              Received and PO Reference No.)
            </h1>
            <ul className="list-disc pl-3 text-grey-400">
              <li>
                System will add up both amounts in SGD to reflect as the Latest Amount Seized{' '}
              </li>
              <li>
                The Latest Amount Seized will be updated accordingly if it has the latest date of
                bank details received
              </li>
            </ul>
          </div>
        );
        break;
      default:
        return <p className="text-black text-sm drop-shadow-xl p-4">{content}</p>;
    }
  };

  const getTooltipContent = () => {
    return (
      <CustomWidthTooltip
        title={<TooltipContent />}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'common.white',
              fontSize: '12px',
            },
          },
        }}
        placement="bottom"
        open={open}
      >
        <span onClick={handleClick} onMouseLeave={handleClose}>
          <InfoOutlinedIcon />
        </span>
      </CustomWidthTooltip>
    );
  };

  return <span className="pl-1 cursor-pointer">{getTooltipContent()}</span>;
};

export default TooltipComponent;
