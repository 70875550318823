import { Modal } from '@mui/material';
import RadioGroupComponent from '@components/common/RadioGroup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton, LoadingButtonComponent } from '@components/common/CommonButton';
import { getSessionUserFromAccessToken } from '@utils/utils';
import DropdownUser from '@components/common/DropdownUser';
import { assignUserParameters, useScamEnablerAssign } from '@api/scamEnablerSlice';
import AccessControl from '@components/common/AccessControl';

const AssignModal = ({
  selectedScamEnablers,
  showSelectedScamEnablers,
  assignOpen,
  onAssignClose,
  callback,
  isDA2Assign,
}: {
  selectedScamEnablers: Array<any>;
  showSelectedScamEnablers: boolean;
  assignOpen: boolean;
  onAssignClose: () => void;
  callback: () => void;
  isDA2Assign?: boolean;
}) => {
  const radioItems = [
    {
      value: 'da1',
      label: `Decision Authority 1`,
    },
    {
      value: 'da2',
      label: `Decision Authority 2`,
    },
  ];
  const radioItemsDA2Assign = [
    {
      value: 'da1',
      label: `Decision Authority 1`,
      disabled: true,
    },
    {
      value: 'da2',
      label: `Decision Authority 2`,
    },
  ];
  const [role, setRole] = useState('da1');
  const [roleLabel, setRoleLabel] = useState(`Decision Authority 1`);
  const [dropdownUserGroup, setDropdownUserGroup] = useState(`scamEnablersDA1`);
  const [isLoadingAssign, setIsLoadingAssign] = useState(false);
  const [assignToMeAllowedPermission, setAssignToMeAllowedPermission] = useState([
    'customs.ao',
    'customs.sao',
  ]);
  // const [selectedUser, setSelectedUser] = useState<any>();
  const assignUser = useScamEnablerAssign();

  const formMethods = useForm();

  useEffect(() => {
    if (isDA2Assign === true) {
      setRole('da2');
      setRoleLabel('Decision Authority 2');
      setDropdownUserGroup('scamEnablersDA2');
      setAssignToMeAllowedPermission(['customs.sao']);
    } else {
      setRole('da1');
      setRoleLabel('Decision Authority 1');
      setDropdownUserGroup('scamEnablersDA1');
      setAssignToMeAllowedPermission(['customs.ao', 'customs.sao']);
    }
  }, [isDA2Assign]);

  const handleClick = async () => {
    setIsLoadingAssign(true);
    let newAssignParams: Array<assignUserParameters> = [];
    let selectedUser = formMethods.getValues('assignTo');
    if (typeof selectedUser !== 'string') {
      selectedUser = selectedUser.id;
    }
    if (!selectedUser || !selectedScamEnablers) {
      setIsLoadingAssign(false);
    } else {
      selectedScamEnablers.forEach((scamEnabler) => {
        newAssignParams.push({
          scamEnablerId: scamEnabler._id,
          userId: selectedUser,
          role: role,
        });
      });
      let response: any = await assignUser(newAssignParams);

      setIsLoadingAssign(false);
      if (!response.error) {
        onAssignClose();
        callback();
      }
    }
  };

  const handleAssignToMe = async () => {
    const sessionUser = await getSessionUserFromAccessToken();
    formMethods.setValue('assignTo', sessionUser.userId);
  };

  const handleOptionChange = (event: any) => {
    setRole(event.target.value);
    if (event.target.value === 'da1') {
      setRoleLabel('Decision Authority 1');
      setDropdownUserGroup('scamEnablersDA1');
      setAssignToMeAllowedPermission(['customs.ao', 'customs.sao']);
    } else {
      setRoleLabel('Decision Authority 2');
      setDropdownUserGroup('scamEnablersDA2');
      setAssignToMeAllowedPermission(['customs.sao']);
    }
  };

  return (
    <>
      <Modal open={assignOpen} onClose={onAssignClose}>
        <ModalLayout
          title={'Assign'}
          handleCloseModal={onAssignClose}
          alignment="center"
          size="medium"
        >
          <RadioGroupComponent
            radioItems={isDA2Assign ? radioItemsDA2Assign : radioItems}
            onChange={handleOptionChange}
            defaultValue={isDA2Assign ? 'da2' : 'da1'}
          />
          <h5 className="pt-6 font-h4-bold-14 font-bold">{roleLabel}</h5>
          <div className="flex justify-between py-4">
            <h5>Assign the following reports to:</h5>
            <AccessControl type="component" allowedPermissions={assignToMeAllowedPermission}>
              {!isDA2Assign && (
                <button className="text-blue-400 font-bold text-sm" onClick={handleAssignToMe}>
                  Assign to me
                </button>
              )}
            </AccessControl>
          </div>
          {dropdownUserGroup === 'scamEnablersDA1' && (
            <DropdownUser
              id={'assignTo'}
              userGroup={dropdownUserGroup}
              control={formMethods.control}
              setValue={formMethods.setValue}
            />
          )}
          {dropdownUserGroup === 'scamEnablersDA2' && (
            <DropdownUser
              id={'assignTo'}
              userGroup={dropdownUserGroup}
              control={formMethods.control}
              setValue={formMethods.setValue}
            />
          )}

          {showSelectedScamEnablers && (
            <>
              <h5 className="pt-6 font-h4-bold-14 ">Selected Scam Enablers</h5>
              <div className="overflow-auto bg-grey-200 p-4">
                {selectedScamEnablers.map((scamEnabler, index) => (
                  <div key={index} className="grid grid-cols-3">
                    <p className="text-sm">{`${scamEnabler.scamEnablerUserId}`}</p>
                    <p className="text-sm col-span-2">{`${
                      scamEnabler.scamType ? scamEnabler.scamType : ''
                    }`}</p>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="flex justify-end gap-4 pt-4">
            <CommonButton
              isDisabled={false}
              variant="text"
              title={`Cancel`}
              onClick={onAssignClose}
            />
            <LoadingButtonComponent
              isLoading={isLoadingAssign}
              title="Assign"
              onClick={handleClick}
            />
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default AssignModal;
