import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createTheme,
  ThemeProvider,
} from '@mui/material';

import { ReactComponent as ExpandIcon } from '@assets/icons/down_arrow.svg';

export function InfoSection({ title, inner }: { title: string; inner: any }) {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandIcon height={10} width={15} />}>
        <h3 className="font-bold text-blue-400 text-[16px]">{title}</h3>
      </AccordionSummary>
      <AccordionDetails>{inner}</AccordionDetails>
    </Accordion>
  );
}
