import { ReactComponent as DownArrow } from '@assets/icons/down_arrow.svg';
import clsx from 'clsx';
import { useState } from 'react';

export function DropdownContent({
  title,
  content,
  bgGray,
}: {
  title: string;
  content: any;
  bgGray?: boolean;
}) {
  const [isShowing, setIsShowing] = useState(false);

  return (
    <>
      <div className="flex gap-2 align-middle">
        <p>{title}</p>
        <DownArrow
          width={15}
          className={clsx('rotate-0  hover:cursor-pointer', {
            'rotate-180': isShowing,
          })}
          onClick={() => {
            setIsShowing(!isShowing);
          }}
        />
      </div>

      <div
        className={clsx('rounded-lg mt-4 flex flex-col gap-4 transition', {
          'opacity-0 hidden': !isShowing,
          'bg-gray-100': bgGray,
        })}
      >
        {content}
      </div>
    </>
  );
}
