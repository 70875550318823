import { Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton, LoadingButtonComponent } from '@components/common/CommonButton';
import { getSessionUserFromAccessToken } from '@utils/utils';
import DropdownUser from '@components/common/DropdownUser';
import { useState } from 'react';
import { useAssignProactiveScamEnablersV2Mutation } from '@api/scamEnablerSlice';

const AssignProactiveMonikerModal = ({
  selectedScamEnablers,
  showSelectedScamEnablers,
  assignOpen,
  onAssignClose,
  callback,
}: {
  selectedScamEnablers: Array<any>;
  showSelectedScamEnablers: boolean;
  assignOpen: boolean;
  onAssignClose: () => void;
  callback: () => void;
}) => {
  const [isLoadingAssign, setIsLoadingAssign] = useState(false);
  const [assignUser] = useAssignProactiveScamEnablersV2Mutation();

  const formMethods = useForm();

  const handleClick = async () => {
    setIsLoadingAssign(true);
    let newAssignParams: any = [];
    let selectedUser = formMethods.getValues('assignTo');
    if (typeof selectedUser !== 'string') {
      selectedUser = selectedUser.id;
    }
    if (!selectedUser || !selectedScamEnablers) {
      setIsLoadingAssign(false);
    } else {
      selectedScamEnablers.forEach((scamEnabler) => {
        newAssignParams.push({
          proactiveScamEnablerId: scamEnabler._id,
          userId: selectedUser,
        });
      });
      let response: any = await assignUser(newAssignParams);

      setIsLoadingAssign(false);
      if (!response.error) {
        onAssignClose();
        callback();
      }
    }
  };

  const handleAssignToMe = async () => {
    const sessionUser = await getSessionUserFromAccessToken();
    formMethods.setValue('assignTo', sessionUser.userId);
  };

  return (
    <>
      <Modal open={assignOpen} onClose={onAssignClose}>
        <ModalLayout
          title={'Assign'}
          handleCloseModal={onAssignClose}
          alignment="center"
          size="medium"
        >
          <div className="flex justify-between py-4">
            <h5>Assign the following monikers to:</h5>
            <button className="text-blue-400 font-bold text-sm" onClick={handleAssignToMe}>
              Assign to me
            </button>
          </div>
          <DropdownUser
            id={'assignTo'}
            userGroup={'proactiveScamEnablers'}
            control={formMethods.control}
            setValue={formMethods.setValue}
          />
          {showSelectedScamEnablers && (
            <>
              <h5 className="pt-6 font-h4-bold-14 ">Selected Scam Enablers</h5>
              <div className="overflow-auto bg-grey-200 p-4">
                {selectedScamEnablers.map((scamEnabler, index) => (
                  <div key={index} className="grid grid-cols-3">
                    <p className="text-sm">{`${scamEnabler.scamEnablerUserId}`}</p>
                    <p className="text-sm col-span-2">{`${
                      scamEnabler.scamType ? scamEnabler.scamType : ''
                    }`}</p>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="flex justify-end gap-4 pt-4">
            <CommonButton
              isDisabled={false}
              variant="text"
              title={`Cancel`}
              onClick={onAssignClose}
            />
            <LoadingButtonComponent
              isLoading={isLoadingAssign}
              title="Assign"
              onClick={handleClick}
            />
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default AssignProactiveMonikerModal;
