import React from 'react';
import Breadcrumbs from '@components/common/Breadcrumbs';

interface BreadcrumbsAndButtonsProps {
  data: any;
  params: any;
  refetch: () => void;
  currentRole: string;
  handleOpenQueuePOModal: () => void;
  handleCloseQueuePOModal: () => void;
  poQueueModalOpen: boolean;
  handleOpenPOModal: () => void;
  handleClosePOModal: () => void;
  poModalOpen: boolean;
}

const BreadcrumbsAndButtons: React.FC<BreadcrumbsAndButtonsProps> = ({
  data,
  params,
  refetch,
  currentRole,
  handleOpenQueuePOModal,
  handleCloseQueuePOModal,
  poQueueModalOpen,
  handleOpenPOModal,
  handleClosePOModal,
  poModalOpen,
}) => {
  return (
    <div className="w-100 bg-grey-200 p-4 flex flex-row justify-between items-center">
      <Breadcrumbs
        breadcrumbs={[
          { label: 'Reports', href: '/admin/reports' },
          {
            label: data?.report.reportNumber,
            href: `/admin/reports/${params.id}`,
            active: true,
          },
        ]}
      />
      {/* <div className="flex flex-row gap-4"> */}
      {/* {
          <AssignButton
            selectedReports={[data.report]}
            callback={refetch}
            showSelectedReports={false}
          />
        } */}
      {/* {(data.report.da2 || data.report.da1) && (
          <CommonButton
            isDisabled={currentRole !== 'da2' && currentRole !== 'da1'}
            variant="secondary"
            title={`Send to Directions Queue`}
            onClick={handleOpenQueuePOModal}
          />
        )}
        <ProductionQueueModal
          data={data}
          poOpen={poQueueModalOpen}
          onPOClose={handleCloseQueuePOModal}
        />

        {(data.report.da2 || data.report.da1) && (
          <CommonButton
            isDisabled={currentRole !== 'da2' && currentRole !== 'da1'}
            variant="primary"
            title={` Generate Production Order`}
            onClick={handleOpenPOModal}
          />
        )}
        <ProductionOrderModal
          data={data}
          poOpen={poModalOpen}
          onPOClose={handleClosePOModal}
          callback={refetch}
        /> */}
      {/* </div> */}
    </div>
  );
};

export default BreadcrumbsAndButtons;
