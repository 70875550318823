import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import DynamicForm from '@components/common/DynamicForm';
import LoginHeader from '@components/common/LoginHeader';
import { ChangePasswordFormGeneralItems } from './ChangePasswordFormConfig';
import { useChangePasswordMutation } from '@api/userSlice';
import { getSessionUserFromAccessToken } from '@utils/utils';
import cloneDeep from 'lodash.clonedeep';
import { CommonButton } from '@components/common/CommonButton';

export default function LoginComponent() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formItems, setFormItems] = useState(ChangePasswordFormGeneralItems);
  const [changePassword] = useChangePasswordMutation();

  const formMethods = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onSubmit',
  });

  useEffect(() => {
    let formattedFormItems = cloneDeep(ChangePasswordFormGeneralItems);
    let newPasswordField = formattedFormItems.find((item) => item.id === 'newPassword');
    let confirmPasswordField = formattedFormItems.find((item) => item.id === 'confirmPassword');

    if (newPasswordField) {
      newPasswordField.rules.validate = {
        samePassword: () => {
          const formValues: any = formMethods.getValues();
          return (
            !(formValues.newPassword && formValues.newPassword === formValues.currentPassword) ||
            'New Password should not be the same as current password!'
          );
        },
        passwordStrength: () => {
          const formValues: any = formMethods.getValues();
          const newPassword = formValues.newPassword;
          const atLeastMinimumLength = new RegExp(/(?=.{8,})/).test(newPassword);
          const atLeastOneLowercaseLetter = new RegExp(/(?=.*?[a-z])/).test(newPassword);
          const atLeastOneUppercaseLetter = new RegExp(/(?=.*?[A-Z])/).test(newPassword);
          const atLeastOneNumber = new RegExp(/(?=.*?[0-9])/).test(newPassword);
          const atLeastOneSpecialChar = new RegExp(/(?=.*?[`~!@#$%^&*_()\-+={}[\]:;"',.?/<>])/).test(
            newPassword,
          );

          return (
            (atLeastMinimumLength &&
              atLeastOneLowercaseLetter &&
              atLeastOneUppercaseLetter &&
              atLeastOneNumber &&
              atLeastOneSpecialChar) ||
            'Invalid password strength'
          );
        },
      };
    }
    if (confirmPasswordField) {
      confirmPasswordField.rules.validate = {
        confirmPassword: () => {
          const formValues: any = formMethods.getValues();
          return formValues.newPassword === formValues.confirmPassword || 'Passwords should match!';
        },
      };
    }
    setFormItems(formattedFormItems);
  }, []);

  const onSubmit = async (event: any) => {
    setIsSubmitting(true);
    let user = await getSessionUserFromAccessToken();
    const formValues: any = formMethods.getValues();
    let params = {
      pathParameters: {
        user_id: user.userId,
      },
      body: {
        currentPassword: formValues.currentPassword,
        newPassword: formValues.newPassword,
        confirmPassword: formValues.confirmPassword,
      },
    };

    const response: any = await changePassword(params);

    setIsSubmitting(false);

    if (!response.error) {
      let route = searchParams.get('route') || '';

      if (route) {
        route = `?route=${route}`;
      }
      return navigate(`/login-setup-totp${route}`);
    }
  };

  return (
    <div className="md:flex flex-col items-center justify-center min-h-screen bg-white md:bg-gradient-to-b md:from-blue-300 md:to-blue-400">
      <div className="flex flex-col w-full h-full md:w-1/2 xl:w-5/12 gap-2 bg-white py-16 px-10 md:px-16 rounded-lg">
        <LoginHeader />

        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <h1 className="mb-4 ml-1">Set new password</h1>
            <DynamicForm formFields={formItems} formMethods={formMethods}></DynamicForm>
          </div>
          <div className="flex flex-row flex-col pt-4 text-left">
            <CommonButton
              className="ml-0 w-full flex justify-center"
              variant="primary"
              type="submit"
              title={isSubmitting ? 'Submitting...' : 'Submit'}
              isLoading={isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
