import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';
import { User } from '@interface/UsersInterface';
import { CommonButton } from '@components/common/CommonButton';

const ConfirmResetPasswordModal = ({
  user,
  isOpen,
  onConfirmResetPasswordModalClose,
}: {
  user: User;
  isOpen: boolean;
  onConfirmResetPasswordModalClose: (confirm: boolean) => void;
}) => {
  const handleOnClick = (confirm: boolean) => {
    onConfirmResetPasswordModalClose(confirm);
  };
  return (
    <Modal open={isOpen} onClose={onConfirmResetPasswordModalClose}>
      <ModalLayout
        title={`Confirm`}
        handleCloseModal={onConfirmResetPasswordModalClose}
        alignment="center"
        size="small"
      >
        <p>
          Confirm reset password for <b>{user.email}</b>?
        </p>
        <CommonButton
          className="ml-0 mt-5 w-full flex justify-center"
          variant="secondary"
          type="button"
          title="Cancel"
          onClick={() => handleOnClick(false)}
        />
        <CommonButton
          className="ml-0 mt-5 w-full flex justify-center"
          variant="primary"
          type="button"
          title="Confirm"
          onClick={() => handleOnClick(true)}
        />
      </ModalLayout>
    </Modal>
  );
};

export default ConfirmResetPasswordModal;
