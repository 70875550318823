import { LoadingButtonComponent } from '@components/common/CommonButton';
import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';

const CommunityGuidelineConfirmationModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalLayout
        title={'Confirmation'}
        handleCloseModal={onClose}
        alignment="center"
        size="medium"
      >
        <p className="py-3">
          You have <b>DO NOT</b> approve the channel submission to be via Community Guideline.
        </p>
        <p className="py-2">
          By saving this, this moniker will be reassigned back to DA1 for reassessment.
        </p>
        <p className="py-2">Are you sure you want to proceed?</p>
        <p>You cannot undo this action.</p>
        <div className="flex justify-end gap-4 pt-4">
          <button onClick={onClose}>Cancel</button>
          <LoadingButtonComponent
            isLoading={false}
            title="Proceed with Reassessment"
            variant="red"
            onClick={() => console.log('proceed')}
          />
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default CommunityGuidelineConfirmationModal;
