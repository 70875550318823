import { FormItemInterface } from '@interface/dynamicFormInterface';

export const loginFormGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Email',
    type: 'textInput',
    id: 'email',
    placeholder: 'e.g user@spf.gov.sg',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid SPF email address',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@(spf|gt\.tech|tech).gov.sg$/i,
        message: 'A valid SPF email address is required eg. user@spf.gov.sg.',
      },
    },
  },
  {
    label: 'Password',
    type: 'password',
    id: 'password',
    placeholder: 'Password',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid Password',
      minLength: {
        value: 8,
        message: 'Please enter at least 8 characters',
      },
    },
  },
];
