import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';
import AdminUpdateUserComponent from './AdminUpdateUserComponent';
import { User } from '@interface/UsersInterface';

const AdminUpdateUserModal = ({
  user,
  isOpen,
  onAdminUpdateUserModalClose,
}: {
  user: User;
  isOpen: boolean;
  onAdminUpdateUserModalClose: (updatedUser: boolean) => void;
}) => {
  return (
    <Modal open={isOpen} onClose={onAdminUpdateUserModalClose}>
      <ModalLayout
        title={`Update User`}
        handleCloseModal={onAdminUpdateUserModalClose}
        alignment="center"
        size="small"
      >
        <AdminUpdateUserComponent user={user} onAdminUpdateUserModalClose={(updatedUser: boolean) => onAdminUpdateUserModalClose(updatedUser)}/>
      </ModalLayout>
    </Modal>
  );
};

export default AdminUpdateUserModal;
