import {
  platform,
  proactiveProfileType,
  proactiveScamEnablerOutcomes,
  scamType,
} from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { getFileMimeTypes, validateIdNotNil } from '@utils/utils';
import validator from 'validator';

const urlItems: Array<FormItemInterface> = [
  {
    label: 'Website URL',
    type: 'tableInput',
    id: 'url',
    alignment: 'column',
    placeholder: 'Please enter',
    rules: {
      required: 'Website URL is required',
      validate: {
        isURL: (value: string) => {
          if (!validator.isURL(value)) {
            return 'Invalid website URL. Please provide URL in the correct format e.g. https://website.com or http://website.com';
          }
        },
        isNotMoniker: (value: string) => {
          if (
            value.toLowerCase().includes('www.facebook.com') ||
            value.toLowerCase().includes('www.instagram.com') ||
            value.toLowerCase().includes('t.me/') ||
            value.toLowerCase().includes('www.carousell.') ||
            value.toLowerCase().includes('www.lazada.') ||
            value.toLowerCase().includes('www.shopee.sg') ||
            value.toLowerCase().includes('www.line.me') ||
            value.toLowerCase().includes('www.wechat.com') ||
            value.toLowerCase().includes('https://wa.me/') ||
            value.toLowerCase().includes('www.tiktok.com')
          ) {
            return 'Moniker URL is not permitted. Please enter under the appropriate scam enablers.';
          }
        },
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'fileUpload',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
    },
    alignment: 'column',
    optional: true,
  },
];

export const proactiveMonikerfilterGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Platform',
    type: 'dropdownMulti',
    id: 'platform',
    placeholder: 'Please Select',
    extras: { dropdownData: platform },
    alignment: 'row',
  },
  {
    label: 'Scam Type',
    type: 'dropdownMulti',
    id: 'scam_type',
    placeholder: 'Please Select',
    extras: { dropdownData: scamType },
    alignment: 'row',
  },
  {
    label: 'Date Added',
    type: 'calendarRange',
    id: 'created_date_from,created_date_to',
    placeholder: 'dd/mm/yyyy',
    alignment: 'row',
  },
  {
    label: 'Officer Assigned',
    type: 'dropdownUser',
    id: 'officer_assigned',
    extras: { userGroup: 'proactiveScamEnablers' },
    alignment: 'row',
  },
  {
    label: 'Outcome of Moniker',
    type: 'dropdownMulti',
    id: 'outcome',
    extras: { dropdownData: proactiveScamEnablerOutcomes },
    placeholder: 'Plese select',
    alignment: 'row',
  },
  {
    label: 'Tags',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true },
    alignment: 'row',
  },
];

export const overviewEditItems: Array<FormItemInterface> = [
  {
    label: 'Officer Assigned',
    id: 'assigned',
    type: 'dropdownUser',
    extras: { userGroup: 'proactiveScamEnablers' },
  },
  {
    label: 'Outcome',
    type: 'dropdown',
    id: 'outcome',
    extras: {
      dropdownData: proactiveScamEnablerOutcomes,
    },
  },
  {
    label: 'Date Submitted',
    id: 'submittedAt',
    type: 'dateTime',
  },
  {
    label: 'Date of Reply',
    id: 'closedAt',
    type: 'dateTime',
  },
  {
    label: 'Reference No.',
    id: 'referenceNo',
    type: 'textInput',
  },
  {
    label: 'Review TAT',
    id: 'reviewTAT',
    type: 'label',
  },
  {
    label: 'Return TAT',
    id: 'returnTAT',
    type: 'label',
  },
];

export const overviewItems: Array<FormItemInterface> = [
  {
    label: 'Officer Assigned',
    id: 'assigned',
    type: 'labelEditDropdownUser',
    extras: { updateAPI: 'assignScamEnablerV2', userGroup: 'scamEnablers' },
  },
  {
    label: 'Outcome',
    type: 'labelEditDropdown',
    id: 'outcome',
    extras: {
      type: 'proactive',
      updateAPI: 'updateScamEnablerV2',
      dropdownData: proactiveScamEnablerOutcomes,
    },
  },
  {
    label: 'Date Submitted',
    id: 'submittedAt',
    type: 'labelEditDateTime',
  },
  {
    label: 'Date of Reply',
    id: 'closedAt',
    type: 'labelEditDateTime',
  },
  {
    label: 'Reference No.',
    id: 'referenceNo',
    type: 'label',
  },
  {
    label: 'Review TAT',
    id: 'reviewTAT',
    type: 'label',
  },
  {
    label: 'Return TAT',
    id: 'returnTAT',
    type: 'label',
  },
];

export const monikerItems: Array<FormItemInterface> = [
  {
    label: 'Platform',
    id: 'platform',
    type: 'label',
  },
  {
    label: 'URL',
    id: 'url',
    type: 'label',
    extras: {
      isLoading: false,
      isVerified: false,
    },
  },
  {
    label: 'User ID',
    id: 'scamEnablerUserId',
    type: 'label',
  },
  {
    label: 'Profile Name',
    id: 'profilename',
    type: 'label',
  },
  {
    label: 'Verified Profile',
    type: 'boolean',
    id: 'verifiedProfile',
    extras: {
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
  },
  {
    label: 'Tags',
    id: 'tags',
    type: 'label',
  },
  {
    label: 'Facebook Creation Date',
    id: 'facebookCreatedAt',
    type: 'date',
    extras: {
      format: 'DD MMMM YYYY HH:ss',
    },
  },
  {
    label: 'Screenshot File Name',
    id: 'attachmentFileNames',
    type: 'label',
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'proactiveScamEnablerId',
    extras: {
      attachmentPath: '/v2/proactivescamenablers/attachments/',
      updateAPI: 'uploadProactiveScamEnablerAttachmentV2',
      allowPaste: true,
    },
  },
  {
    label: 'Scam Type',
    id: 'scamType',
    type: 'label',
  },
  {
    label: 'Profile Type',
    id: 'profileType',
    type: 'label',
  },
  {
    label: 'No of Ads',
    id: 'numberOfAds',
    type: 'label',
  },
  {
    label: 'Phone No',
    id: 'scammerContactNumber',
    type: 'label',
  },
  {
    label: 'Redirection URL(s)',
    id: 'redirectionUrls',
    type: 'dynamicTable',
    extras: {
      tableFields: [
        {
          label: 'Website URL',
          type: 'label',
          id: 'url',
        },
        {
          label: 'Screenshots',
          type: 'screenshot',
          id: 'attachments',
          extras: {
            attachmentPath: '/v2/proactivescamenablers/attachments/',
          },
        },
      ],
    },
    alignment: 'column',
  },
  {
    label: 'Remarks',
    id: 'remarks',
    type: 'label',
  },
];

export const monikerEditItems: Array<FormItemInterface> = [
  {
    label: 'Platform',
    type: 'dropdown',
    id: 'platform',
    extras: { dropdownData: platform },
  },
  {
    label: 'URL',
    id: 'url',
    type: 'textInput',
    placeholder: 'e.g. https://www.facebook.com/profile.php?id=111111111111111',
    rules: {
      pattern: {},
      required: 'Please enter a url',
    },
  },
  {
    label: 'User ID',
    id: 'scamEnablerUserId',
    type: 'label',
    rules: {
      pattern: {},
      required: 'Please enter a userID',
    },
  },
  {
    label: 'Profile Name',
    id: 'profilename',
    type: 'textInput',
    rules: {
      pattern: {},
      required: 'Please enter a Profile Name',
    },
  },
  {
    label: 'Verified Profile',
    type: 'boolean',
    id: 'verifiedProfile',
    extras: {
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
  },
  {
    label: 'Tags',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: {
      allowFreeSolo: true,
      dropdownData: [],
      isUpperCase: true,
    },
    rules: {
      pattern: {},
    },
  },
  {
    label: 'Facebook Creation Date',
    id: 'facebookCreatedAt',
    type: 'dateTime',
    extras: {
      showTime: false,
      format: ['DD MMMM YYYY', 'DD MMMM'],
    },
  },
  {
    label: 'Screenshot File Name',
    id: 'attachmentFileNames',
    type: 'multiTextInput',
    extras: {
      tooltip: {
        content:
          'This field is manually editable and does not automatically update with the file name of the screenshot you upload',
      },
    },
  },
  {
    label: 'Screenshot(s)',
    id: 'attachments',
    type: 'screenshot',
    extras: {
      attachmentPath: '/v2/proactivescamenablers/attachments/',
      updateAPI: 'uploadProactiveScamEnablerAttachmentV2',
    },
  },
  {
    label: 'Scam Type',
    id: 'scamType',
    type: 'dropdown',
    extras: {
      dropdownData: scamType,
    },
  },
  {
    label: 'Profile Type',
    id: 'profileType',
    type: 'dropdown',
    extras: {
      dropdownData: proactiveProfileType,
    },
  },
  {
    label: 'No of Ads',
    id: 'numberOfAds',
    type: 'textInput',
    rules: {
      pattern: {
        value: /^[0-9]*$/,
        message: 'Please enter a valid number',
      },
    },
    extras: {
      textInputType: 'number',
    },
  },
  {
    label: 'Phone No.',
    id: 'scammerContactNumber',
    type: 'multiTelephoneInput',
  },
  {
    label: 'Redirection URL(s)',
    id: 'redirectionUrls',
    type: 'dynamicTable',
    optional: true,
    extras: {
      defaultRow: {
        url: '',
        attachments: [],
      },
      tableFields: [
        {
          label: 'Website URL',
          type: 'textInput',
          id: 'url',
          rules: {
            required: 'Please enter a URL',
          },
        },
        {
          label: 'Screenshots',
          type: 'screenshot',
          id: 'attachments',
          extras: {
            attachmentPath: '/v2/proactivescamenablers/attachments/',
          },
        },
      ],
    },
    alignment: 'column',
  },
  {
    label: 'Remarks',
    id: 'remarks',
    type: 'textInput',
    extras: {
      rows: 4,
    },
  },
];

export const createProactiveItems: Array<FormItemInterface> = [
  {
    label: 'Platform',
    type: 'dropdown',
    id: 'platform',
    alignment: 'column',
    extras: { dropdownData: platform },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    placeholder: 'e.g. https://www.facebook.com/profile.php?id=111111111111111',
    rules: {
      pattern: {},
      required: 'Please enter a URL',
      // validate: {
      //   startsWithFacebookProfileUrl: (value: string) =>
      //     (value.startsWith('https://www.facebook.com/profile.php?id=') && value.length > 40) ||
      //     'URL must start with https://www.facebook.com/profile.php?id=',
      // },
    },
  },
  {
    label: 'Moniker ID',
    type: 'textInput',
    id: 'scamEnablerUserId',
    alignment: 'column',
    // helperTextBottom:
    //   'This moniker ID is new in the system. Please fill in the following information to proceed with adding this moniker to the system.',
    rules: {
      pattern: {},
      required: 'Please enter a Moniker ID',
      validate: {
        isNotNil: (value: string) => {
          return validateIdNotNil(value);
        },
      },
    },
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profilename',
    alignment: 'column',
    rules: {
      pattern: {},
      required: 'Please enter a Profile Name',
    },
  },
  {
    label: 'Verified Profile',
    type: 'boolean',
    id: 'verifiedProfile',
    extras: {
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
    alignment: 'column',
  },
  {
    label: 'Tags',
    type: 'dropdownMulti',
    id: 'tags',
    alignment: 'column',
    placeholder: 'Type a tag and press Enter…',
    optional: true,
    extras: {
      allowFreeSolo: true,
      dropdownData: [],
      isUpperCase: true,
    },
    rules: {
      pattern: {},
    },
  },
  {
    label: 'Facebook Creation Date',
    id: 'facebookCreatedAt',
    alignment: 'column',
    type: 'calendar',
    optional: true,
  },
  {
    label: 'Scam Type',
    type: 'dropdown',
    id: 'scamType',
    alignment: 'column',
    extras: {
      dropdownData: scamType,
    },
    optional: true,
  },
  {
    label: 'Profile Type',
    type: 'dropdown',
    id: 'profileType',
    alignment: 'column',
    optional: true,
    extras: {
      dropdownData: proactiveProfileType,
    },
  },
  {
    label: 'No. of Ads',
    type: 'textInput',
    id: 'numberOfAds',
    alignment: 'column',
    optional: true,
    rules: {
      pattern: {
        value: /^[0-9]+$/,
        message: 'Please enter a valid number',
      },
    },
    extras: {
      textInputType: 'number',
    },
  },
  {
    label: 'Phone No.',
    type: 'multiTelephoneInput',
    id: 'scammerContactNumber',
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Screenshots',
    type: 'fileUpload',
    alignment: 'column',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    optional: true,
  },
  {
    label: 'Redirection URL(s)',
    id: 'redirectionUrls',
    type: 'dynamicTable',
    optional: true,
    extras: {
      showCounter: true,

      defaultRow: {
        url: '',
        attachments: [],
      },
      tableFields: [
        {
          label: 'Website URL',
          type: 'textInput',
          id: 'url',
        },
        {
          label: 'Screenshots',
          type: 'screenshot',
          id: 'attachments',
          extras: {
            attachmentPath: '/v2/proactivescamenablers/attachments/',
          },
        },
      ],
    },
    alignment: 'column',
  },
  {
    label: 'Remarks',
    id: 'remarks',
    alignment: 'column',
    type: 'textInput',
    extras: {
      rows: 4,
    },
    optional: true,
  },
];

//=======================================================================================================
//UPDATE PROACTIVE MONIKER MODAL

export const validColumns = [
  {
    title: 'Row',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
  },
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '90%',
  },
];

export const invalidColumns = [
  {
    title: 'Row',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
  },
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '45%',
  },
  {
    title: 'Reason',
    dataIndex: 'errorMessage',
    key: 'errorMessage',
    width: '45%',
  },
];
export const monikerIdConfig = [
  // {
  //   label: 'Platform',
  //   type: 'dropdown',
  //   id: 'platform',
  //   alignment: 'column',
  //   extras: {
  //     dropdownData: platform,
  //   },
  //   rules: {
  //     required: 'Please select an option',
  //   },
  // },
  {
    label: 'Input the moniker IDs below',
    helperText: 'Fill in one user ID/phone no./full URL(FB and IG only) per line',
    type: 'textInput',
    id: 'monikerId',
    alignment: 'column',
    rules: {
      required: 'Please enter a Moniker ID',
    },
    extras: {
      multiline: true,
      rows: 10,
    },
  },
];

export const updateProactiveItems: Array<FormItemInterface> = [
  {
    label: 'Outcome',
    type: 'dropdown',
    id: 'outcome',
    alignment: 'column',
    extras: {
      dropdownData: proactiveScamEnablerOutcomes,
    },
  },
  // {
  //   label: 'Facebook Creation Date',
  //   id: 'facebookCreatedAt',
  //   alignment: 'column',
  //   type: 'calendar',
  // },
  // {
  //   label: 'Attachment File Names',
  //   id: 'attachmentFileNames',
  //   type: 'multiTextInput',
  //   alignment: 'column',
  //   extras: {
  //     tooltip: {
  //       content:
  //         'This field is manually editable and does not automatically update with the file name of the screenshot you upload',
  //     },
  //   },
  // },
  {
    label: 'Date Submitted',
    id: 'submittedAt',
    type: 'dateTime',
    alignment: 'column',
  },
  {
    label: 'Date of Reply',
    id: 'closedAt',
    type: 'dateTime',
    alignment: 'column',
  },
  // {
  //   label: 'Scam Type',
  //   type: 'dropdown',
  //   id: 'scamType',
  //   alignment: 'column',
  //   extras: {
  //     dropdownData: scamType,
  //   },
  // },
  // {
  //   label: 'Profile Type',
  //   type: 'dropdown',
  //   id: 'profileType',
  //   alignment: 'column',
  //   extras: {
  //     dropdownData: proactiveProfileType,
  //   },
  // },
  {
    label: 'Reference No.',
    id: 'referenceNo',
    alignment: 'column',
    type: 'textInput',
  },

  // {
  //   label: 'No of Ads',
  //   type: 'textInput',
  //   id: 'numberOfAds',
  //   alignment: 'column',
  //   rules: {
  //     pattern: {
  //       value: /^[0-9]+$/,
  //       message: 'Please enter a valid number',
  //     },
  //   },
  //   extras: {
  //     textInputType: 'number',
  //   },
  // },
  // {
  //   label: 'Phone Number',
  //   type: 'multiTelephoneInput',
  //   id: 'scammerContactNumber',
  //   alignment: 'column',
  // },
];
