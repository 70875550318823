import ModalLayout from '@components/layouts/ModalLayout';
import { Modal } from '@mui/material';
import AdminCreateUserComponent from './AdminCreateUserComponent';

const AdminCreateUserModal = ({
  isOpen,
  onAdminCreateUserModalClose,
}: {
  isOpen: boolean;
  onAdminCreateUserModalClose: (createdUser: boolean) => void;
}) => {
  return (
    <Modal open={isOpen} onClose={onAdminCreateUserModalClose}>
      <ModalLayout
        title={`Create User`}
        handleCloseModal={onAdminCreateUserModalClose}
        alignment="center"
        size="small"
      >
        <AdminCreateUserComponent onAdminCreateUserModalClose={(createdUser: boolean) => onAdminCreateUserModalClose(createdUser)}/>
      </ModalLayout>
    </Modal>
  );
};

export default AdminCreateUserModal;

