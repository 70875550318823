import { Alert, Modal } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton } from '@components/common/CommonButton';
import { convertUnixToSingaporeTime } from '@utils/utils';
import DynamicForm from '@components/common/DynamicForm';
import { bulkUpdateOutcomeForm } from '../BankFormConfig';
import { DropdownContent } from '@components/common/DropdownContent';
import { DataTable } from '@components/common/DataTable';
import { BankStatus } from '@components/common/StatusComponent';
import { useUpdateFinancialInstitutesMutation } from '@api/financialInstituteSlice';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';

const BulkUpdateModal = ({
  selectedBanks,
  modalOpen,
  setModalOpen,
  callback,
}: {
  selectedBanks: Array<any>;
  modalOpen: boolean;
  setModalOpen: any;
  callback: () => void;
}) => {
  const defaultValues = {
    outcome: '',
    productionOrderSentDate: '',
    bankAccountFrozenDate: '',
    bankDetailsRetrievedDate: '',
  };
  const formMethods = useForm({
    defaultValues,
  });
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updateFinancialInstitutes] = useUpdateFinancialInstitutesMutation();

  const formatValues = (id: string) => {
    const values: any = formMethods.getValues();
    let dirtyValues = formMethods.formState.dirtyFields;
    if (!dirtyValues) {
      dispatch(
        showAlert({
          type: 'error',
          message: 'No Fields to update',
        }),
      );
    }
    let params: any = { financialInstituteId: id };
    for (let key in dirtyValues) {
      if (
        key === 'productionOrderSentDate' ||
        key === 'bankAccountFrozenDate' ||
        key === 'bankDetailsRetrievedDate'
      ) {
        params[key] = values[key] ? new Date(values[key]).getTime() : null;
      } else {
        params[key] = values[key];
      }
    }
    return params;
  };

  const handleClick = async () => {
    setIsSubmitting(true);

    let params: any = [];
    selectedBanks.forEach((bank: any) => {
      params.push(formatValues(bank._id));
    });
    let r: any = await updateFinancialInstitutes(params);

    if (!r.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: 'Outcome of bank account(s) updated successfully.',
        }),
      );

      await callback();
    }

    setIsSubmitting(false);
    formMethods.reset(defaultValues);
    setModalOpen(false);
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <ModalLayout
          title={'Update Outcome'}
          handleCloseModal={() => {
            setModalOpen(false);
          }}
          alignment="center"
          size="medium"
        >
          {new Set(selectedBanks.map((bank: any) => bank.outcome)).size > 1 && (
            <Alert title="Alert" severity="warning">
              You have selected multiple bank accounts with different outcomes. By proceeding with
              this update, all existing outcomes will be overwritten. Please ensure this is what you
              intend to do.
            </Alert>
          )}

          <div>
            <DynamicForm
              formFields={bulkUpdateOutcomeForm}
              formMethods={formMethods}
              isEditable={true}
            />

            <div className="mt-4">
              <DropdownContent
                title={`Selected moniker(s): ${selectedBanks.length}`}
                bgGray={false}
                content={
                  <DataTable
                    data={selectedBanks.map((bank: any) => ({
                      accountNumber: bank.accountNumber,
                      outcome: <BankStatus outcome={bank.outcome} />,
                      productionOrderSentDate: convertUnixToSingaporeTime(
                        bank.productionOrderSentDate,
                      ),
                      bankAccountFrozenDate: convertUnixToSingaporeTime(bank.bankAccountFrozenDate),
                      bankDetailsRetrievedDate: convertUnixToSingaporeTime(
                        bank.bankDetailsRetrievedDate,
                      ),
                    }))}
                    keyMapping={{
                      accountNumber: 'Bank Account No.',
                      outcome: 'Outcome',
                      productionOrderSentDate: 'PO Sent Date',
                      bankAccountFrozenDate: 'Bank Account Frozen Date',
                      bankDetailsRetrievedDate: 'Bank Details Retrieved Date',
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className="mr-0 ml-auto flex gap-4">
            <CommonButton
              variant={'text'}
              title={'Cancel'}
              onClick={() => {
                setModalOpen(false);
                formMethods.reset(defaultValues);
              }}
            />
            <CommonButton
              variant={'primary'}
              title={'Update'}
              onClick={formMethods.handleSubmit(handleClick)}
              isLoading={isSubmitting}
            />
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default BulkUpdateModal;
