import { TableContainer, Table, TableBody } from '@mui/material';
import TableChecklistRow from './TableChecklistRow';

const TableChecklist = ({
  id,
  control,
  getValues,
  rules,
  rows,
  setValue,
  watch,
  isEditable,
}: {
  id?: string;
  control?: any;
  getValues?: any;
  rules?: any;
  rows: any;
  setValue?: any;
  watch?: any;
  isEditable?: boolean;
}) => {
  return (
    <TableContainer id={id}>
      <Table className="border border-black" sx={{ borderRadius: '25px' }}>
        <TableBody>
          {rows.map((row: any, index: number) => (
            <TableChecklistRow
              key={index}
              control={control}
              row={row}
              index={index}
              getValues={getValues}
              id={id}
              isEditable={isEditable}
              rules={rules}
              rows={rows}
              watch={watch}
              setValue={setValue}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableChecklist;
