import AccessControl from '@components/common/AccessControl';
import MonikerTable from './DirectionsQueueMonikerTable';

import HeaderComponent from '@components/common/HeaderComponent';
import { useParams } from 'react-router-dom';

const DirectionsQueuePage = () => {
  const { type } = useParams<{ type: string }>();
  return (
    <div>
      <HeaderComponent title={'Directions Queue'} />
      <AccessControl type="page" allowedPermissions={["directionsQueue.productionorders.view"]}></AccessControl>
      {!type || (type === 'monikers' && <MonikerTable />)}

        <div className="mt-5 flex w-full justify-center"></div>
    </div>
  );
};

export default DirectionsQueuePage;
