import Checkbox from '@mui/material/Checkbox';
import { Controller } from 'react-hook-form';

const CheckboxTable = ({
  control,
  id,
  rules,
  row,
  disabled,
  validate,
}: {
  control?: any;
  id: string;
  rules?: any;
  row?: any;
  disabled?: boolean;
  validate?: any;
}) => {
  const validationRules = {
    ...rules,
    validate: validate,
  };

  return (
    <Controller
      control={control}
      name={id}
      rules={validationRules}
      defaultValue={false}
      render={({ field: { onChange, onBlur, value, name, ref }, fieldState, formState }) => (
        <Checkbox
          id={id}
          checked={typeof value === 'boolean' ? value : false}
          disabled={disabled}
          sx={{
            padding: '3px',
            color: disabled ? 'var(--grey-400)' : 'var(--blue-500)',
            opacity: disabled ? 0.6 : 1,
            '&.Mui-checked': {
              color: disabled ? 'var(--grey-400)' : 'var(--blue-500)',
              opacity: disabled ? 0.6 : 1,
            },
          }}
          value={typeof value === 'boolean' ? value : false}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
      )}
    />
  );
};

export default CheckboxTable;
