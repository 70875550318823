import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  accessToken: string | null;
  sessionUser: any; // Add sessionUser to the state
  [key: string]: any; // Add index signature
}

const initialState: AuthState = {
  accessToken: null,
  sessionUser: null, // Initialize sessionUser as null
};

export const AuthSlice = createSlice({
  name: 'auth',
  reducerPath: 'auth',
  initialState,
  reducers: {
    setKeyValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const { key, value } = action.payload;
      state[key] = value; // No error now
    },
    setSessionUser: (state, action: PayloadAction<any>) => {
      state.sessionUser = action.payload; // New reducer for setting session user
    },
  },
});

export const { setKeyValue, setSessionUser } = AuthSlice.actions;
