import Switch from '@mui/material/Switch';
import { Controller } from 'react-hook-form';
import { getNestedError } from '@utils/utils';

const Toggle = ({
  control,
  id,
  rules,
  formState,
}: {
  control: any;
  id: string;
  rules?: any;
  formState?: any;
}) => {
  const errors = formState?.errors;

  return (
    <div>
      <Controller
        name={id}
        control={control}
        render={({ field }) => <Switch {...field} checked={field.value} />}
      />
      {errors && getNestedError(errors, id) && (
        <span style={{ color: 'var(--red)' }} role="alert">
          {getNestedError(errors, id)?.message}
        </span>
      )}
    </div>
  );
};

export default Toggle;
