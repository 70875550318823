import { getAmountLoss } from '@utils/utils';

export const facebookColumns = () => [
  {
    title: 'Moniker URL',
    key: 'url',
    render: (text: string, record: any, index: number) => (
      <p>
        {record.platform === 'Facebook'
          ? `https://www.facebook.com/${record.scamEnablerUserId}`
          : record.platform === 'Instagram'
            ? `https://www.instagram.com/${record.scamEnablerUserId}`
            : ''}
      </p>
    ),
  },
  {
    title: 'Victim Report',
    dataIndex: 'victimReported',
    key: 'victimReported',
    render: (text: string, record: any, index: number) => (
      <p>{record.victimReported === true ? 'Yes' : 'No'}</p>
    ),
  },
  {
    title: 'Brief facts of case',
    dataIndex: 'explanation',
    key: 'explanation',
  },
  {
    title: 'Interactions to any post/ads',
    dataIndex: 'interactionPostAds',
    key: 'interactionPostAds',
    render: (text: string, record: any, index: number) => (
      <p>{record.interactionPostAds === true ? 'Yes' : 'No'}</p>
    ),
  },
  {
    title: 'Amount of Losses',
    key: 'amountLoss',
    render: (text: string, record: any, index: number) => {
      return <p>{getAmountLoss(record) ?? 0}</p>;
    },
  },
];
