import Modal from '@mui/material/Modal';
import ModalLayout from '@components/layouts/ModalLayout';
import { ScamEnablerInterface } from '@interface/ScamEnablerInterface';
import { useGetProductionQueueDetailsByIdV2Query } from '@api/productionOrderSlice';
import PortfoliosMonikerDetailsOverviewComponent from '@pages/portfolios/monikers/MonikersDetailedPage/PortfoliosMonikerDetailsOverviewComponent';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import PortfoliosMonikerDetailsComponent from '@pages/portfolios/monikers/MonikersDetailedPage/PortfolioMonikerDetailsComponent';

interface enablerInterface {
  title: string;
  content: Array<FormItemInterface>;
  data: ScamEnablerInterface;
}

const ProductionOrderMonikerModal = ({
  poQueueId,
  isOpen,
  onClose,
}: {
  poQueueId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { refetch, isLoading, data } = useGetProductionQueueDetailsByIdV2Query(
    {
      poQueueId: poQueueId,
    },
    {
      skip: poQueueId === '' && !isOpen,
    },
  );

  const handleCallback = () => {
    refetch();
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <ModalLayout
          title={data ? data.scamEnablerUserId : ''}
          handleCloseModal={onClose}
          alignment="right"
          size="full"
          link={data?._id ? `/admin/portfolios/monikers/${data._id}` : ''}
          subTitle="Overview"
          linkText="Go to Moniker Details Page"
          overflowClass="overflow-hidden"
        >
          <div className="max-h-[80vh] overflow-auto">
            <PortfoliosMonikerDetailsOverviewComponent
              data={{ scamEnabler: { ...data } }}
              refetch={() => {}}
              isLoading={isLoading}
              isEditable={false}
              isDirectionQueueMonikerModal={true}
            />
            <PortfoliosMonikerDetailsComponent
              data={{ scamEnabler: { ...data } }}
              refetch={() => {}}
              isLoading={isLoading}
              isEditable={false}
              isDirectionQueueMonikerModal={true}
            />
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default ProductionOrderMonikerModal;
