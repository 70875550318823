import { Badge, Box, Modal, Tab, Tabs, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton } from '@components/common/CommonButton';
import {
  useGetScamEnablersExistV2Mutation,
  useUpdateScamEnablerV2Mutation,
} from '@api/scamEnablerSlice';
import DynamicForm from '@components/common/DynamicForm';
import { platform, scamEnablerOutcomes } from '@interface/constant';
import { convertMsToDDMMYYYYHHmm, extractUserIdFromUrl } from '@utils/utils';
import CustomizedStepperForm from '@components/common/Stepper';
import { ErrorOutline } from '@mui/icons-material';
import cloneDeep from 'lodash.clonedeep';
import { Table } from 'antd';
import { MonikerOutcomeStatus } from '@components/common/StatusComponent';
import { ReactComponent as ExpandMoreIcon } from '@assets/icons/down_arrow.svg';
import { ReactComponent as ExpandLessIcon } from '@assets/icons/up_arrow.svg';

export const validColumns = [
  {
    title: 'Row',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
  },
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '90%',
  },
];

export const invalidColumns = [
  {
    title: 'Row',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
  },
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '45%',
  },
  {
    title: 'Reason',
    dataIndex: 'errorMessage',
    key: 'errorMessage',
    width: '45%',
  },
];

const updateSelectScamEnablerConfig = [
  {
    label: 'Platform',
    type: 'dropdown',
    id: 'platform',
    extras: { dropdownData: platform },
    placeholder: 'Plese select',
    alignment: 'column',
  },
  {
    label: 'Input the moniker IDs below',
    helperText: 'Fill in one user ID/phone no./full URL(FB and IG only) per line',
    id: 'scamEnablerIds',
    type: 'textInput',
    extras: {
      rows: 15,
    },
    alignment: 'column',
  },
];

const updateConfig = [
  {
    label: 'Outcome of Moniker',
    type: 'dropdown',
    id: 'outcome',
    extras: { dropdownData: scamEnablerOutcomes },
    placeholder: 'Plese select',
    alignment: 'column',
  },
  {
    label: 'Production Order Sent Date',
    type: 'dateTime',
    id: 'productionOrderSentDate',
    extras: { removePopupContainer: true },
    alignment: 'column',
  },
  {
    label: 'Date of Reply',
    type: 'dateTime',
    id: 'closedAt',
    extras: { removePopupContainer: true },
    alignment: 'column',
  },
  {
    label: 'Platform Reference Number',
    type: 'textInput',
    id: 'platformReferenceNo',
    extras: { removePopupContainer: true },
    alignment: 'column',
  },
];

const UpdateMonikerModal = ({
  isOpen,
  handleClose,
  selectedScamEnablers,
  showSelectedScamEnablers,
  callback,
}: {
  isOpen: boolean;
  handleClose: () => void;
  selectedScamEnablers?: any[];
  showSelectedScamEnablers?: boolean;
  callback: () => void;
}) => {
  const step1FormMethods = useForm({
    mode: 'onChange',
  });
  const formSubmitMethods = useForm({
    mode: 'onChange',
  });
  const steps = ['Input Moniker IDs', 'System Verification', 'Update Fields'];
  const [checkUserId] = useGetScamEnablersExistV2Mutation();
  const [updateScamEnablerV2] = useUpdateScamEnablerV2Mutation();
  const [stepCompletionStatus, setStepCompletionStatus] = useState(
    new Array(steps.length).fill(false),
  );
  const [activeStep, setActiveStep] = useState(0);
  const [tabValue, setTabValue] = useState('valid');

  const isButtonDisabled = false;
  const [validMonikerIds, setValidMonikerIds] = useState<any[]>([]);
  const [invalidMonikerIds, setInvalidMonikerIds] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => setIsExpanded(!isExpanded);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      setIsLoading(true);
      const formValues = step1FormMethods.getValues();
      if (formValues.scamEnablerIds) {
        let scamEnablerUserIds = formValues.scamEnablerIds.split('\n');
        scamEnablerUserIds = scamEnablerUserIds.map((item: string) => {
          return extractUserIdFromUrl(item, formValues.platform).userId || item;
        });
        const platform = formValues.platform;
        let response = await checkUserId({
          scamEnablerUserIds,
          platform,
        }).unwrap();
        let tempValidMonikerIds: any[] = [];
        let tempInvalidMonikerIds: any[] = [];
        response.result.forEach((item: any) => {
          if (item.exists === true) {
            tempValidMonikerIds.push(item);
          } else {
            let newItem = { ...item, errorMessage: 'Moniker Id does not exist in the system.' };
            tempInvalidMonikerIds.push(newItem);
          }
        });
        setValidMonikerIds(tempValidMonikerIds);
        setInvalidMonikerIds(tempInvalidMonikerIds);
        console.log(response);
        setIsLoading(false);
        setStepCompletionStatus((prev) => {
          const newStatus = [...prev];
          newStatus[activeStep] = true;
          return newStatus;
        });
        setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (validMonikerIds && validMonikerIds.length > 0) {
        setStepCompletionStatus((prev) => {
          const newStatus = [...prev];
          newStatus[activeStep] = true;
          return newStatus;
        });
        setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
      }
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    // const allPreviousCompleted = stepCompletionStatus.slice(0, step).every((status) => status);
    // if (allPreviousCompleted) {
    //   setActiveStep(step);
    // }
  };

  const formatValules = (id: string) => {
    const submitData: any = formSubmitMethods.getValues();
    let dirtyFields = formSubmitMethods.formState.dirtyFields;
    let tempParams: any = {};
    for (let key in dirtyFields) {
      if (submitData[key] === '' || submitData[key] === undefined) continue;
      if (typeof submitData[key] === 'string') {
        tempParams[key] = submitData[key].trim();
      } else {
        tempParams[key] = submitData[key];
      }
      if (key === 'productionOrderSentDate' || key === 'closedAt') {
        tempParams[key] = new Date(submitData[key]).getTime();
      }
    }
    tempParams['scamEnablerId'] = id;
    return tempParams;
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    let tempRequest: any = [];

    if (validMonikerIds?.length > 0) {
      validMonikerIds.forEach((item) => {
        tempRequest.push(formatValules(item._id));
      });
    } else if (selectedScamEnablers && selectedScamEnablers?.length > 0) {
      selectedScamEnablers.forEach((item) => {
        tempRequest.push(formatValules(item._id));
      });
    }

    let response: any = await updateScamEnablerV2(tempRequest);

    if (!response.error) {
      handleClose();
      if (callback) callback();
    }
    setIsLoading(false);
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="flex flex-col gap-2">
            <h2 className="bold">General Information</h2>
            <DynamicForm
              formFields={updateSelectScamEnablerConfig}
              formMethods={step1FormMethods}
            ></DynamicForm>
          </div>
        );
      case 1:
        return (
          <>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { backgroundColor: '#255662' } }}
            >
              <Tab
                label={
                  <div className="flex items-center gap-4">
                    <p
                      className={`font-bold ${tabValue === 'valid' ? 'text-blue-500' : 'text-blue-400'}`}
                      style={{ textTransform: 'none' }}
                    >
                      Successful
                    </p>
                    <Badge color="success" badgeContent={validMonikerIds.length || 0} max={999} />
                  </div>
                }
                value="valid"
              />
              <Tab
                label={
                  <div className="flex items-center gap-4">
                    <p
                      className={`font-bold ${tabValue === 'invalid' ? 'text-blue-500' : 'text-blue-400'}`}
                      style={{ textTransform: 'none' }}
                    >
                      Error
                    </p>
                    <Badge color="error" badgeContent={invalidMonikerIds.length || 0} max={999} />
                  </div>
                }
                value="invalid"
              />
            </Tabs>
            {tabValue === 'valid' && (
              <div className="h-[40vh] overflow-y-auto">
                <Table
                  rowKey="scamEnablerUserId"
                  dataSource={cloneDeep(validMonikerIds)}
                  columns={validColumns}
                  pagination={false}
                  sticky
                />
              </div>
            )}
            {tabValue === 'invalid' && (
              <div className="h-[40vh] overflow-y-auto">
                <Table
                  rowKey="scamEnablerUserId"
                  dataSource={cloneDeep(invalidMonikerIds)}
                  columns={invalidColumns}
                  pagination={false}
                  sticky
                />
              </div>
            )}
          </>
        );

      case 2:
        return (
          <form onSubmit={handleSubmit}>
            <DynamicForm formFields={updateConfig} formMethods={formSubmitMethods} />
          </form>
        );
      default:
        return 'Unknown stepIndex';
    }
  };
  const tooltipContent = (
    <div className="flex text-black bg-white">
      <ErrorOutline className="mr-2" />
      <span>All required fields need to be filled before proceeding to the next section.</span>
    </div>
  );

  const checkIsButtonEnabled = () => {
    if (activeStep === 0) {
      return step1FormMethods.formState.isValid;
    } else if (activeStep === 1) {
      return validMonikerIds?.length > 0;
    } else if (activeStep === 2) {
      return formSubmitMethods.formState.isDirty && formSubmitMethods.formState.isValid;
    }
    return false;
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalLayout
        title={'Update Details'}
        handleCloseModal={handleClose}
        alignment="center"
        size="large"
        overflowClass=""
      >
        <>
          {(!selectedScamEnablers || selectedScamEnablers.length === 0) && (
            <CustomizedStepperForm
              steps={steps}
              handleStepClick={handleStep}
              activeStep={activeStep}
              stepCompletionStatus={stepCompletionStatus}
            >
              <Box sx={{ mb: 2 }}>
                <div className="flex-1 overflow-auto pb-10">{getStepContent(activeStep)}</div>
                <div className="pt-4 flex justify-end sticky gap-5 bottom-0 pb-4 bg-white">
                  <CommonButton variant="text" title="Cancel" onClick={handleClose} type="button" />
                  {activeStep !== 0 && (
                    <CommonButton
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleBack();
                      }}
                      type="button"
                      variant="secondary"
                      title="Previous"
                    />
                  )}

                  <Tooltip
                    title={tooltipContent}
                    disableHoverListener={!isButtonDisabled}
                    placement="bottom-end"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.white',
                          fontSize: '12px',
                        },
                      },
                    }}
                  >
                    <span>
                      <CommonButton
                        variant="primary"
                        title={activeStep !== steps.length - 1 ? 'Next' : 'Submit'}
                        type="submit"
                        isDisabled={!checkIsButtonEnabled()}
                        onClick={handleNext}
                        isLoading={isLoading}
                      />
                    </span>
                  </Tooltip>
                </div>
              </Box>
            </CustomizedStepperForm>
          )}
          {selectedScamEnablers && selectedScamEnablers.length > 0 && (
            <div className="flex flex-col">
              <form>
                <DynamicForm formFields={updateConfig} formMethods={formSubmitMethods} />
              </form>
              {showSelectedScamEnablers && (
                <>
                  <div className="flex flex-row gap-4 items-center">
                    <h5 className="font-h4-bold-14">{`Selected monikers: ${selectedScamEnablers.length}`}</h5>
                    <button
                      className="py-2 px-4 text-white font-bold rounded-lg my-4 flex items-center justify-center"
                      onClick={toggleExpansion}
                    >
                      {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </button>
                  </div>
                  {isExpanded && (
                    <div className="overflow-auto bg-grey-200 ">
                      <div className="grid grid-cols-4 py-4 px-2 bg-grey-300">
                        <p className="text-sm font-bold">Scam Enabler User ID</p>
                        <p className="text-sm font-bold">Outcome</p>
                        <p className="text-sm font-bold">Sent Date</p>
                        <p className="text-sm font-bold">Date of Reply</p>
                      </div>
                      {selectedScamEnablers.map((scamEnabler, index) => (
                        <div
                          key={index}
                          className="grid grid-cols-4 p-2 zebra-stripe gap-6 rounded-lg items-center"
                        >
                          <p className="text-sm">{scamEnabler.scamEnablerUserId}</p>
                          <MonikerOutcomeStatus outcome={scamEnabler.outcome} />
                          <p className="text-sm">
                            {scamEnabler.productionOrderSentDate
                              ? convertMsToDDMMYYYYHHmm(scamEnabler.productionOrderSentDate)
                              : '-'}
                          </p>
                          <p className="text-sm">
                            {scamEnabler.closedAt
                              ? convertMsToDDMMYYYYHHmm(scamEnabler.closedAt)
                              : '-'}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
              <div className="pt-4 flex justify-end  gap-5 bottom-0 pb-4 bg-white">
                <CommonButton variant="text" title="Cancel" onClick={handleClose} type="button" />
                <CommonButton
                  isLoading={isLoading}
                  variant="primary"
                  title="Submit"
                  isDisabled={!formSubmitMethods.formState.isDirty}
                  onClick={handleSubmit}
                  type="submit"
                />
              </div>
            </div>
          )}
        </>
      </ModalLayout>
    </Modal>
  );
};

export default UpdateMonikerModal;
