import { Link } from 'react-router-dom';

export function LinkedPaynow({ paynowData }: { paynowData: any }) {
  return (
    <>
      {paynowData.length == 0 && (
        <div className=" text-left">
          <p className="italic text-xs text-gray-500">No PayNow numbers linked to this account</p>
        </div>
      )}

      <div className="flex flex-col gap-3">
        {paynowData.map((paynow: any) => (
          <Link
            key={paynow.paynowId}
            // To change this link
            to={`/admin/portfolios/paynow/${paynow.paynowId}`}
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: '15px',
            }}
          >
            {paynow.paynowNumber}
          </Link>
        ))}
      </div>
    </>
  );
}
