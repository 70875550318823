export const shopeeColumns = [
  {
    title: 'Moniker Link',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
  },
];
