import { PaynowAddToReportButton } from '@pages/portfolios/Buttons';
import { convertUnixToSingaporeTime } from '@utils/utils';
import { useEffect, useState } from 'react';
import { ReactComponent as NoRecordsIcon } from '@assets/icons/no_records.svg';
import AddToReportConfirmationModal from '../modals/AddToReportConfirmationModal';

export function PaynowDetailsScreening({ data }: { data: any }) {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [addToReportOpen, setAddToReportOpen] = useState(false);

  const handleCheckboxChange = (selected: any, isChecked: boolean) => {
    if (isChecked) {
      setSelectedRows([...selectedRows, selected]);
    } else {
      setSelectedRows(selectedRows.filter((item) => item !== selected));
    }
    console.log(selectedRows);
  };

  const handleSelectAllChange = (isChecked: boolean) => {
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedRows(data?.history);
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-between align-middle">
        <div className="w-100 flex flex-row items-center align-middle gap-4">
          <h1 className="font-bold text-lg text-blue-500">Screening Records</h1>
        </div>
        {data?.history && (
          <PaynowAddToReportButton
            onClick={() => {
              setAddToReportOpen(true);
            }}
            selectedRows={selectedRows}
            isDisabled={selectedRows.length === 0}
          />
        )}
        <AddToReportConfirmationModal
          selectedBankAccounts={selectedRows}
          addToReportOpen={addToReportOpen}
          data={data}
          onAddToReportClose={() => setAddToReportOpen(false)}
        />
      </div>

      <div className="max-h-[75vh] pb-4 overflow-auto mt-5">
        {data?.history && (
          <table className="w-full border-collapse rounded-lg overflow-hidden">
            <thead className="text-left">
              <tr className="bg-grey-300">
                <th className="p-4 font-bold text-grey-500">Name of Bank</th>
                <th className="p-4 font-bold text-grey-500">Bank Account Number</th>
                <th className="p-4 font-bold text-grey-500">Bank Account Display Name</th>
                <th className="p-4 font-bold text-grey-500">Date of Activation</th>
                <th className="p-4 font-bold text-grey-500">Date of Deactivation</th>
                <th className="p-4 font-bold text-grey-500">Date of Request</th>
                <th className="p-4 font-bold text-grey-500">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={(e) => handleSelectAllChange(e.target.checked)}
                  />
                </th>
              </tr>
            </thead>
            <tbody className="text-left">
              {data?.history &&
                data?.history.map((history: any, index: number) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-grey-100'}>
                    <td className="p-4 text-black">{history.bank}</td>
                    <td className="p-4 text-black">{history.bankAccountNumber}</td>
                    <td className="p-4 text-black">{history.bankDisplayName}</td>
                    <td className="p-4 text-black">
                      {history.activationDate
                        ? convertUnixToSingaporeTime(history.activationDate)
                        : '-'}
                    </td>
                    <td className="p-4 text-black">
                      {history.deactivationDate
                        ? convertUnixToSingaporeTime(history.deactivationDate)
                        : '-'}
                    </td>
                    <td className="p-4 text-black">
                      {history.screeningAt && convertUnixToSingaporeTime(history.screeningAt)}
                    </td>
                    <td className="p-4 text-black">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(history)}
                        onChange={(e) => handleCheckboxChange(history, e.target.checked)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {!data?.history && (
          <div className="flex justify-center items-center align-middle h-full">
            <NoRecordsIcon className="h-full" />
          </div>
        )}
      </div>
    </div>
  );
}

export default PaynowDetailsScreening;
