import { Middleware, configureStore } from '@reduxjs/toolkit';
import { apiSlice, errorMiddleware } from '../api/apiSlice';
import { setupListeners } from '@reduxjs/toolkit/query';
import { AuthSlice } from './authSlice';
import { AlertSlice } from './alertSlice';
import { createBrowserHistory } from 'history';

// import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync'

// Create the syncMiddleware with your desired options
// const syncMiddleware = createStateSyncMiddleware({
//     // Define which parts of your state you want to sync across tabs
//     // For example, if you want to sync the 'common' and 'auth' slices:
//     whitelist: ['auth/setCredentials'],
// })

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [AuthSlice.reducerPath]: AuthSlice.reducer,
    [AlertSlice.reducerPath]: AlertSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, errorMiddleware),
  devTools: false,
});
// initMessageListener(store)

setupListeners(store.dispatch);
