import { IconButton } from '@components/common/CommonButton';
import DynamicForm from '@components/common/DynamicForm';
import {
  LazadaItems,
  ShopeeItems,
  carousellItems,
  createTransactionItems,
  defaultItems,
  emailItems,
  faceBookItems,
  iMessageItems,
  instagramItems,
  phoneNumberItems,
  phoneNumberUserIdItems,
  rcsItems,
  telegramItems,
  twitterItems,
  urlItems,
  viewFormGeneraMonikerItems,
  viewFormGeneralItems,
  whatsappItems,
} from './CreateReportFormConfig';
import cloneDeep from 'lodash.clonedeep';
import { financialExchangePlatform } from '@interface/constant';
import { ScamEnablerPaymentChannelForm } from '../ReportFormConfig';

const remarks: any = [
  {
    label: 'Remarks',
    type: 'label',
    id: 'remarks',
    alignment: 'row',
    optional: true,
  },
];

const CreateReportConfirmation = ({
  formMethod,
  formValues,
  setActiveStep,
}: {
  formMethod: any;
  formValues: any;
  setActiveStep: (index: number) => void;
}) => {
  // const getTransactionForm = (transaction: any) => {
  //   let tempItems = cloneDeep(createTransactionItems);
  //   tempItems.forEach((item) => {
  //     item.type = `label`;
  //     item.alignment = `row`;
  //   });
  //   return tempItems;
  // };

  const getScamEnablerForm = (scamEnabler: any) => {
    let tempItems = cloneDeep(viewFormGeneraMonikerItems);
    switch (scamEnabler.platform) {
      case 'Facebook':
        tempItems = tempItems.concat(cloneDeep(faceBookItems));
        break;
      case 'Instagram':
        tempItems = tempItems.concat(cloneDeep(instagramItems));
        break;
      case 'Telegram':
        tempItems = tempItems.concat(cloneDeep(telegramItems));
        break;
      case 'X':
        tempItems = tempItems.concat(cloneDeep(twitterItems));
        break;
      case 'Carousell':
        tempItems = tempItems.concat(cloneDeep(carousellItems));
        break;
      case 'Lazada':
        tempItems = tempItems.concat(cloneDeep(LazadaItems));
        break;
      case 'Shopee':
        tempItems = tempItems.concat(cloneDeep(ShopeeItems));
        break;
      case 'Website':
        break;
      case 'Whatsapp':
        tempItems = tempItems.concat(cloneDeep(whatsappItems));
        break;
      case 'Email':
        tempItems = tempItems.concat(cloneDeep(emailItems));
        break;
      case 'LINE App':
      case 'WeChat':
        tempItems = tempItems.concat(cloneDeep(phoneNumberUserIdItems));
        break;
      // case 'On a call':
      case 'SMS':
        tempItems = tempItems.concat(cloneDeep(phoneNumberItems));
        break;
      case 'RCS':
        tempItems = tempItems.concat(cloneDeep(rcsItems));
        break;
      case 'iMessage':
        tempItems = tempItems.concat(cloneDeep(iMessageItems));
        break;
      default:
        if (financialExchangePlatform.includes(scamEnabler.platform)) {
          tempItems = tempItems.concat(
            cloneDeep(ScamEnablerPaymentChannelForm(scamEnabler.platform)),
          );
        } else {
          tempItems = tempItems.concat(cloneDeep(defaultItems));
        }
        break;
    }

    tempItems.forEach((item, index) => {
      item.optional = false;
      if (item.type === `fileUpload`) {
        item.type = 'fileLabel';
        item.alignment = `row`;
      } else if (item.type === `dynamicTable`) {
        item.alignment = `column`;
        let values = formMethod.getValues();
        if (item?.extras?.tableFields?.length > 0) {
          item.extras.tableFields.map((field: any) => {
            field.type = `label`;
          });
        }
      } else if (item.type === 'radioToggle') {
        const newItem = cloneDeep(item.extras.nestedFieldTypes[0]);
        if (item.extras.confirmationLabel) newItem.label = item.extras.confirmationLabel;
        newItem.type = 'label';
        newItem.alignment = 'row';
        tempItems[index] = newItem; // Update the item in the array
      } else {
        item.type = `label`;
        item.alignment = `row`;
      }
    });

    return tempItems;
  };

  return (
    <div className="flex flex-col gap-1">
      <h2 className="bold">Confirmation</h2>
      <div className="flex flex-row gap-2 items-center">
        <h3 className="py-2">General Information</h3>
        <IconButton
          isDisabled={false}
          icon="edit"
          onClick={() => setActiveStep(0)}
          isLoading={false}
          type="button"
        />
      </div>
      <DynamicForm formFields={viewFormGeneralItems} data={formValues} />
      <div className="flex flex-row gap-2 items-center">
        <h3 className="py-2">Scam Enablers</h3>
        <IconButton
          isDisabled={false}
          icon="edit"
          onClick={() => setActiveStep(1)}
          isLoading={false}
          type="button"
        />
      </div>
      {formValues.monetaryLossInvolved === 'true' && (
        <DynamicForm
          formFields={[
            {
              label: 'Amount of Losses(SGD)',
              type: 'label',
              id: 'amountLoss',
              extras: {
                textInputType: 'number',
              },
            },
          ]}
          data={formValues}
        />
      )}
      {formValues.scamEnablers.map((scamEnabler: any, index: number) => {
        const scanEnablerItems = getScamEnablerForm(scamEnabler);
        return (
          <div key={`scamEnabler-${index}`}>
            <p className="font-bold pt-2">{`Scam Enablers ${index + 1}`}</p>
            {scamEnabler.platform === 'Website' ? (
              <>
                <DynamicForm formFields={scanEnablerItems} data={scamEnabler} />
                {scamEnabler.websites.map((website: any, websiteIndex: number) => {
                  let tempItems = cloneDeep(urlItems);
                  tempItems.forEach((item) => {
                    if (item.type === `fileUpload`) {
                      item.type = 'fileLabel';
                    } else {
                      item.type = `label`;
                    }
                    item.label += ` ${websiteIndex + 1}`;
                    item.alignment = `row`;
                  });
                  return <DynamicForm key={websiteIndex} formFields={tempItems} data={website} />;
                })}
                <DynamicForm formFields={remarks} data={scamEnabler} />
              </>
            ) : (
              <DynamicForm formFields={scanEnablerItems} data={scamEnabler} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CreateReportConfirmation;
