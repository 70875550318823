import { useCallback, useState } from 'react';
import { IconButton } from './CommonButton';
import TextInput from './TextInput';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { useForm } from 'react-hook-form';
import { useUpdateScamEnablerV2Mutation } from '@api/scamEnablerSlice';
import { showAlert } from '@store/alertSlice';
import { useDispatch } from 'react-redux';

interface EditLabelTextProps {
  field: FormItemInterface;
  data: any;
  callback?: any;
  isEditable?: boolean;
}

export const EditLabelText: React.FC<EditLabelTextProps> = ({
  field,
  data,
  callback,
  isEditable,
}) => {
  const { control, formState, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [updateScamEnablerV2] = useUpdateScamEnablerV2Mutation();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(async () => {
    let newValue = getValues(field.id);
    let response: any;
    // if (currentValue !== newValue) {
    //   setIsEditing(false);
    //   return true;
    // }

    setIsLoading(true);
    if (field.extras.updateAPI === 'updateScamEnablerV2') {
      let tempUpdateParams = [
        {
          scamEnablerId: data._id,
          [field.id]: newValue,
        },
      ];
      response = await updateScamEnablerV2(tempUpdateParams);
    }

    if (!response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Update Successful`,
        }),
      );
      setIsEditing(false);
      if (callback) {
        callback();
      }
      // setCurrentValue(newValue);
    }
    setIsLoading(false);
  }, [field, data, callback]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
  }, [field.value]);

  const handleEdit = useCallback(() => setIsEditing(true), []);
  return (
    <div>
      {isEditing ? (
        <form className="flex flex-row flex-grow gap-2 items-center justify-center">
          <TextInput
            id={field.id}
            control={control}
            rules={field.rules}
            placeholder={field.placeholder}
            formState={formState}
            prefix={field?.extras?.prefix ? field.extras.prefix : ''}
            disabled={field.disabled}
            rows={field?.extras?.rows}
            prefill={field?.extras?.prefill}
            keyup={field?.extras?.keyup}
          ></TextInput>

          <IconButton isDisabled={false} icon="cross" onClick={handleCancel} isLoading={false} />
          {formState.isValid && (
            <IconButton
              isDisabled={false}
              icon="tick"
              onClick={handleSubmit}
              isLoading={isLoading}
            />
          )}
        </form>
      ) : (
        <div className="flex flex-row items-center gap-2">
          {field?.extras?.showLink ? (
            <a
              href={field.value}
              target="_blank"
              rel="noopener noreferrer"
              className={`${field?.extras.istextBreakAll ? 'break-all' : 'break-words'} text-sm hover:underline hover:text-blue-500`}
            >
              {field.value}
            </a>
          ) : (
            <p className={field?.extras.istextBreakAll ? 'break-all' : 'break-words'}>
              {field.value}
            </p>
          )}
          {isEditable && (
            <IconButton
              isDisabled={false}
              icon="edit"
              onClick={() => setIsEditing(true)}
              isLoading={false}
            />
          )}
        </div>
      )}
    </div>
  );
};
