// EditLabelDateTime.tsx
import { useState } from 'react';
import { IconButton } from './CommonButton';
import { DateTimeForm } from './DateTimeForm';
import { convertMsToDDMMYYYYHHmm } from '@utils/utils';
import { useUpdateScamEnablerV2Mutation } from '@api/scamEnablerSlice';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';

interface EditLabelDateTimeProps {
  field: FormItemInterface;
  data: any;
  callback?: any;
  isEditable?: boolean;
}

export const EditLabelDateTime: React.FC<EditLabelDateTimeProps> = ({
  field,
  data,
  callback,
  isEditable,
}) => {
  const [dateTime, setDateTime] = useState(field.value);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [updateScamEnablerV2] = useUpdateScamEnablerV2Mutation();
  const dispatch = useDispatch();

  const handleSubmit = async (values: any) => {
    let response: any;
    setIsLoading(true);
    const newValue = new Date(values[field.id]).getTime();

    if (field.extras.updateAPI === 'updateScamEnablerV2') {
      let tempUpdateParams = [
        {
          scamEnablerId: data._id,
          [field.id]: newValue,
        },
      ];
      response = await updateScamEnablerV2(tempUpdateParams);
    }

    if (response && !response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Update Successful`,
        }),
      );
      if (callback) {
        callback();
      }
      setIsEditing(false);
    }
    setDateTime(newValue);
    setIsLoading(false);
  };

  return (
    <div className="flex flex-row gap-2 items-center justify-start">
      {isEditing ? (
        <DateTimeForm
          id={field.id}
          onSubmit={handleSubmit}
          onCancel={() => setIsEditing(false)}
          isLoading={isLoading}
          rules={field.rules}
        />
      ) : (
        <div className="flex flex-row gap-2 items-center justify-center">
          <p>{dateTime && dateTime !== '-' ? convertMsToDDMMYYYYHHmm(dateTime) : '-'}</p>
          {isEditable && (
            <IconButton
              isDisabled={false}
              icon="edit"
              onClick={() => setIsEditing(true)}
              isLoading={false}
            />
          )}
        </div>
      )}
    </div>
  );
};
