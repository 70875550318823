import Modal from '@mui/material/Modal';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import ModalLayout from '@components/layouts/ModalLayout';
import { useCreateScamEnablerCommentMutation } from '@api/scamEnablerSlice';
import TextInput from '@components/common/TextInput';
import { CommonButton } from '@components/common/CommonButton';
import { ReactComponent as NoCommentIcon } from '@assets/icons/no_comment.svg';
import { convertUnixToSingaporeTime } from '@utils/utils';
import React from 'react';
import { List, ListItemText, Typography, Box } from '@mui/material';
import { clsx } from 'clsx';

interface CommentModalProps {
  isOpen: boolean;
  handleClose: () => void;
  comments: [];
  scamEnablerId: string | undefined;
  refetchComments: () => void;
  currentUser: any;
  currentRole: string;
}

const CommentModal: React.FC<CommentModalProps> = ({
  isOpen,
  handleClose,
  comments,
  scamEnablerId,
  refetchComments,
  currentUser,
  currentRole,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const formMethods = useForm({
    mode: 'onSubmit',
  });

  const [createComment] = useCreateScamEnablerCommentMutation();

  const onSubmit = async (event: any) => {
    setIsSubmitting(true);
    const formValues: any = formMethods.getValues();
    let params = {
      scamEnablerId: scamEnablerId,
      comment: formValues.comment,
    };

    let createCommentResult: any = await createComment(params);

    setIsSubmitting(false);
    if (!createCommentResult.error) {
      formMethods.reset({ comment: '' });
      dispatch(
        showAlert({
          severity: 'success',
          message: `Comment added successfully.`,
        }),
      );
      refetchComments();
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalLayout
          title="Comments"
          handleCloseModal={() => {
            handleClose();
          }}
          alignment="right"
          size="full"
        >
          <div className="overflow-hidden h-[84vh] flex flex-col -mt-6">
            <div className="overflow-auto h-[78vh] mb-4">
              {comments && comments?.length > 0 && (
                <List>
                  {comments.map((comment: any, index: any) => (
                    <div key={index} className="pb-2">
                      <ListItemText
                        primary={
                          <Box display="flex" alignItems="center">
                            <div className={clsx('w-8 h-8 rounded-full text-white flex justify-center items-center font-bold mr-2', currentUser.userId === comment.createdBy ? 'bg-blue-400' : 'bg-blue-600')}>
                              {comment.initials}
                            </div>
                            <div>{comment.name}</div>
                            {!comment.readAt &&
                              comment.createdBy !== currentUser.userId &&
                              (currentRole === 'da1' || currentRole === 'da2') && (
                                <Box
                                  component="span"
                                  sx={{
                                    ml: 1,
                                    width: 8,
                                    height: 8,
                                    bgcolor: '#D96363',
                                    borderRadius: '50%',
                                  }}
                                />
                              )}
                          </Box>
                        }
                        secondary={
                          <>
                            <div className="mt-2">
                              <Typography variant="body2" color="textSecondary">
                                {comment.comment}
                              </Typography>
                            </div>
                            <div className="mt-2">
                              <Typography variant="caption" color="textSecondary" display="block">
                                {convertUnixToSingaporeTime(comment.createdAt)}
                              </Typography>
                            </div>
                          </>
                        }
                      />
                    </div>
                  ))}
                </List>
              )}
              {(!comments || comments?.length <= 0) && (
                <div className="flex justify-center items-center h-full">
                  <NoCommentIcon />
                </div>
              )}
            </div>
            {currentRole && (
              <div>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                  <TextInput
                    id={'comment'}
                    rules={{ required: 'Please enter a comment' }}
                    control={formMethods.control}
                    placeholder={'Type your comment here'}
                    formState={formMethods.formState}
                    rows={3}
                  ></TextInput>
                  <div className="flex flex-col pt-4 items-end pb-2 pr-1">
                    <CommonButton
                      className="ml-0 w-half flex justify-center"
                      variant="primary"
                      type="submit"
                      title="Post Comment"
                      isLoading={isSubmitting}
                      isDisabled={!formMethods.formState.isValid}
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default CommentModal;
