import { useGetScamEnablersV2Query } from '@api/scamEnablerSlice';
import { Table, Pagination, ConfigProvider } from 'antd';
import { DebounceInput } from 'react-debounce-input';
import cloneDeep from 'lodash.clonedeep';
import { getMonikerColumns } from '../getColumns';
import useTableParams from '@components/common/useTableParams';
import { useEffect, useRef, useState } from 'react';
import FilterAssets from '@components/common/FilterAssets';
import FilterModal from '@components/common/FilterModal';
import { CommonButton } from '@components/common/CommonButton';
import { useForm } from 'react-hook-form';
import { monikerfilterGeneralItems } from './MonikerFormConfig';
import { monikerOutcomeStatusMapping } from '@interface/constant';
import AccessControl from '@components/common/AccessControl';
import {
  MonikerTableGroupButtonPrimary,
  MonikerTableGroupButtonSecondary,
  DownloadButton,
} from '../Buttons';

export default function MonikerTable({ platforms }: { platforms?: string }) {
  const [searchTerm, setSearchTerm] = useState('');

  const defaultFilterValues = {
    outcome: ['Pending Review', 'Under Review', 'Pending Decision'],
    // outcome: [],
    platforms: [],
    scam_type: [],
    'created_date_from,created_date_to': [],
    'po_sent_date_from,po_sent_date_to': [],
    created_date_from: '',
    created_date_to: '',
    po_sent_date_from: '',
    po_sent_date_to: '',
    channel: '',
    direction: '',
    da1: '',
    da2: '',
  };

  const initialParams = {
    sort: {
      sortId: 'createdAt',
      sortOrder: 'desc' as const, // Update the sortOrder property to have a value of 'asc' as a string literal
    },
    pagination: {
      current: 1,
      pageSize: 50,
    },
    filters: defaultFilterValues,
  };

  const formMethods = useForm({
    defaultValues: defaultFilterValues,
    mode: 'onChange',
  });

  const { tableParams, setTableParams, handlePageChange, handleTableChange } =
    useTableParams(initialParams);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
    });
    setSearchTerm(e.target.value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRows(selectedRows);
    },
  };

  const handleFiltersChange = (filters: any) => {
    if (filters.outcome) {
      let newOutcomeStatusFilters: Array<string> = [];
      filters.outcome.forEach((outcome: string) => {
        let statusCode = Object.keys(monikerOutcomeStatusMapping).find(
          (key) => monikerOutcomeStatusMapping[key] === outcome,
        );

        if (statusCode) newOutcomeStatusFilters.push(statusCode);
      });
    }
    if (filters.platform) {
      filters.platform = filters.platform.join(',');
    }
    if (filters.scam_type) {
      filters.scam_type = filters.scam_type.join(',');
    }
    if (filters.created_date_from) {
      filters.created_date_from = Date.parse(filters.created_date_from);
    }
    if (filters.created_date_to) {
      filters.created_date_to = Date.parse(filters.created_date_to);
    }
    if (filters.po_sent_date_from) {
      filters.po_sent_date_from = Date.parse(filters.po_sent_date_from);
    }
    if (filters.po_sent_date_to) {
      filters.po_sent_date_to = Date.parse(filters.po_sent_date_to);
    }
    if (filters.channel) {
      filters.channel = filters.channel.join(',');
    }
    if (filters.direction) {
      filters.direction = filters.direction.join(',');
    }
    filters.da1 = filters?.da1?.id ? filters.da1.id : '';
    filters.da2 = filters?.da2?.id ? filters.da2.id : '';

    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
      filters,
    });
  };
  const { refetch, data, isSuccess, isError, isLoading, isFetching } = useGetScamEnablersV2Query({
    params: {
      platforms: platforms,
      search: searchTerm,
      sort_by: tableParams.sort.sortId,
      order_by: tableParams.sort.sortOrder,
      page: tableParams.pagination.current,
      size: tableParams.pagination.pageSize,
      filters: tableParams.filters,
    },
  });
  useEffect(() => {
    refetch();
  }, []);

  const tableRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
  }, [data]);

  const columns = getMonikerColumns(refetch);

  return (
    <>
      <AccessControl type="page" allowedPermissions={['portfolios.scamenablers.view']}>
        <div className="rounded-lg bg-white p-2 md:pt-0 overflow-y-auto">
          <div className="flex items-center justify-between gap-2 py-3">
            <div className="flex flex-row gap-4  items-center">
              <div className="w-64 ">
                <DebounceInput
                  className="w-64 h-12 p-4 bg-grey-300 rounded-[8px] border-none text-grey-500 text-sm"
                  minLength={2}
                  debounceTimeout={300}
                  placeholder="Search for moniker ID..."
                  onChange={handleSearch}
                />
              </div>
              <CommonButton
                isDisabled={false}
                variant="secondary"
                title={`Filters`}
                onClick={() => setFilterOpen(true)}
              />
            </div>

            <div className="flex flex-row gap-2 mr-4">
              <DownloadButton selectedScamEnablers={selectedRows} />
              <MonikerTableGroupButtonSecondary
                selectedScamEnablers={selectedRows}
                showSelectedScamEnablers={true}
                callback={refetch}
              />
              <MonikerTableGroupButtonPrimary callback={() => setTimeout(refetch, 3000)} />
            </div>
          </div>
          <FilterAssets
            defaultValues={defaultFilterValues}
            formMethods={formMethods}
            handleFiltersChange={handleFiltersChange}
          />
          <div className="max-h-[calc(100vh-300px)] overflow-auto" ref={tableRef}>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: '#D0DEE0',
                  },
                },
              }}
            >
              <Table
                rowSelection={{ ...rowSelection }}
                dataSource={cloneDeep(data?.reports)}
                columns={columns}
                rowKey="_id"
                pagination={false}
                onChange={handleTableChange}
                loading={isLoading || isFetching}
                // expandable={{
                //   expandRowByClick: true,
                //   expandedRowRender: (record) => (
                //     <div
                //       title={record.productionOrders
                //         .sort((a: any, b: any) => {
                //           // Assuming createdAt is a Date object
                //           return b.createdAt - a.createdAt;
                //           // If createdAt is a timestamp (number), the above line works directly.
                //         })
                //         .map((order: any) => order.directionReferenceNo || order.productionOrderId)
                //         .join(',\n')}
                //     >
                //       {record.productionOrders
                //         .sort((a: any, b: any) => {
                //           // Assuming createdAt is a Date object
                //           return b.createdAt - a.createdAt;
                //           // If createdAt is a timestamp (number), the above line works directly.
                //         })
                //         .map((order: any, index: number) => (
                //           <div key={index}>
                //             <span>{order.directionReferenceNo || order.productionOrderId}</span>
                //           </div>
                //         ))}
                //     </div>
                //   ),
                // }}
                sticky
              />
            </ConfigProvider>
          </div>

          <Pagination
            className="pt-2 flex justify-end"
            defaultCurrent={1}
            current={tableParams.pagination.current}
            total={data?.totalNumberOfRecords}
            onChange={handlePageChange}
            showSizeChanger={true}
            defaultPageSize={50}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          />
          <FilterModal
            filterOpen={filterOpen}
            formItems={monikerfilterGeneralItems}
            formMethods={formMethods}
            onClearFilters={() => formMethods.reset(defaultFilterValues)}
            onFilterClose={() => setFilterOpen(false)}
            onSubmitFilter={(filters: any) => {
              handleFiltersChange(filters);
            }}
          />
        </div>
      </AccessControl>
    </>
  );
}
