import { useDispatch } from 'react-redux';
import { apiSlice } from './apiSlice';
import { showAlert } from '@store/alertSlice';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { BACKEND_URL } from '@environment/AppEnvironment';
import { getFileNameFromContentDisposition } from '@utils/utils';

export const productionOrderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createProductionOrder: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `/v1/productionorder`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    // getProductionOrder: builder.query({
    //   query: (params) => {
    //     return {
    //       method: 'GET',
    //       url: `/v1/productionorder/${params.productionOrderId}`,
    //     };
    //   },
    // }),
    sendProductionOrderQueue: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `/v1/productionorder/send`,
        };
      },
    }),
    deleteProductionOrderQueue: builder.mutation({
      query: (params) => {
        return {
          method: 'DELETE',
          body: params,
          url: `/v1/productionorder/queue`,
        };
      },
    }),
    getProductionOrderQueue: builder.query({
      query: (request) => {
        return {
          method: 'GET',
          params: {
            page: request.params.page,
            size: request.params.size,
            search: request.params.search,
            sort_by: request.params.sort_by,
            order_by: request.params.order_by,
            scam_type: request.params.filters?.scam_type,
            created_date_from: request.params.filters?.created_date_from,
            created_date_to: request.params.filters?.created_date_to,
            platform: request.params.filters?.platform,
            outcome: request.params.filters?.outcome,
          },
          url: `/v1/productionorder/queue`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    //V2 ================
    createProductionOrderV2: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          body: params,
          url: `/v2/productionorder`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getProductionOrderQueueV2: builder.query({
      query: (request) => {
        console.log('request', request);
        return {
          method: 'GET',
          params: {
            page: request.params.page,
            size: request.params.size,
            search: request.params.search,
            sort_by: request.params.sort_by,
            order_by: request.params.order_by,
            scam_type: request.params.filters?.scam_type,
            created_date_from: request.params.filters?.created_date_from,
            created_date_to: request.params.filters?.created_date_to,
            platform: request.params.filters?.platform,
            outcome: request.params.filters?.outcome,
            direction: request.params.filters?.direction,
            channel: request.params.filters?.channel,
          },
          url: `/v2/productionorder/queue`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getProductionQueueDetailsByIdV2: builder.query({
      query: (params) => {
        return {
          url: `/v2/productionorder/queue/${params.poQueueId}`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const useSendProductionOrderQueue = () => {
  const [sendProductionOrderQueue] = useSendProductionOrderQueueMutation();
  const dispatch = useDispatch();

  const update = async (scamEnablerIds: Array<string>, view: string) => {
    const response: any = await sendProductionOrderQueue({
      scamEnablerIds,
      view,
    });

    if (!response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `${scamEnablerIds.length} Monikers sent to PO queue successfully.`,
        }),
      );
    }
    return response;
  };

  return update;
};

export const useDownloadProductionOrders = () => {
  const dispatch = useDispatch();

  const update = async (
    productionOrderIds: Array<string>,
    version: string,
    requireAdditionalFiles: boolean,
  ) => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}/${version}/productionorder/download`,
        { productionOrderIds, requireAdditionalFiles: requireAdditionalFiles },
        {
          responseType: 'blob', // Tell axios to get response data as Blob
          withCredentials: true, // Include credentials
        },
      );

      const contentDisposition = response.headers['content-disposition'];
      const fileName = getFileNameFromContentDisposition(contentDisposition);
      saveAs(response.data, fileName);
      dispatch(
        showAlert({
          type: 'success',
          message: `Production Orders downloaded successfully. Open the file from your downloads folder.`,
        }),
      );
    } catch (error: any) {
      console.error('Error downloading file:', error);
      dispatch(
        showAlert({
          type: 'error',
          message: `An error occurred: ${error}`,
        }),
      );
      return error;
    }
  };
  return update;
};

export const useDownloadDailyReturns = () => {
  const dispatch = useDispatch();

  const update = async (date_from: number, date_to: number, type: string = 'OCHA', email?: string) => {
    try {
      const response = await axios.get(
        `${BACKEND_URL}/v1/statistics/dailyreturns?date_from=${date_from}&date_to=${date_to}&type=${type}${email ? `&email=${email}` : ''}`,
        {
          responseType: 'blob', // Tell axios to get response data as Blob
          withCredentials: true, // Include credentials
        },
      );

      const contentDisposition = response.headers['content-disposition'];
      const fileName = getFileNameFromContentDisposition(contentDisposition);
      saveAs(response.data, fileName);
      dispatch(
        showAlert({
          type: 'success',
          message: `Daily Returns downloaded successfully. Open the file from your downloads folder.`,
        }),
      );
    } catch (error: any) {
      console.error('Error downloading file:', error);
      dispatch(
        showAlert({
          type: 'error',
          message: `An error occurred: ${error}`,
        }),
      );
      return error;
    }
  };
  return update;
};

export const useDownloadProductionOrder = () => {
  const dispatch = useDispatch();

  const update = async (
    productionOrderId: string,
    version: string,
    require_additional_files: boolean,
  ) => {
    try {
      const response = await axios.get(
        `${BACKEND_URL}/${version}/productionorder/download/${productionOrderId}${require_additional_files ? '?require_additional_files=true' : '?require_additional_files=false'}`,
        {
          responseType: 'blob',
          withCredentials: true,
        },
      );
      const contentDisposition = response.headers['content-disposition'];
      const fileName = getFileNameFromContentDisposition(contentDisposition);
      saveAs(response.data, fileName);
    } catch (error: any) {
      console.error('Error downloading file:', error);
      dispatch(
        showAlert({
          type: 'error',
          message: `An error occurred: ${error}`,
        }),
      );
      return error;
    }
  };
  return update;
};

export const {
  useCreateProductionOrderMutation,
  useCreateProductionOrderV2Mutation,
  // useGetProductionOrderQuery,
  useGetProductionOrderQueueV2Query,
  useSendProductionOrderQueueMutation,
  useGetProductionOrderQueueQuery,
  useGetProductionQueueDetailsByIdV2Query,
  useDeleteProductionOrderQueueMutation,
} = productionOrderApiSlice;
