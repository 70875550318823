// AlertComponent.js
import { Alert } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlert } from '@store/alertSlice';

const AlertComponent = () => {
  const dispatch = useDispatch();

  const alert = useSelector((state: any) => state.alerts);

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        dispatch(hideAlert());
      }, 6000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert, dispatch]);

  return (
    alert && (
      <Alert
        className="fixed top-0 right-0 m-4 z-[10000]"
        severity={alert.type}
        onClose={() => {
          dispatch(hideAlert());
        }}
      >
        {alert.message}
      </Alert>
    )
  );
};

export default AlertComponent;
