import { convertUnixToSingaporeTime } from '@utils/utils';
import { Link } from 'react-router-dom';

export function AssociatedReportsSection({ record }: { record: any }) {
  return (
    <div>
      <p
        className="text-gray-400 text-xs absolute left-1/2 bg-white px-2 text-center"
        style={{
          top: -10,
          backgroundColor: 'white',
        }}
      >
        Reports
      </p>
      <div className="px-20 flex flex-col">
        <div className="flex flex-col items-center">
          <table className="table-fixed">
            <thead>
              <tr className="">
                <th className="font-bold text-gray-400 text-left">Report No.</th>
              </tr>
            </thead>

            <tbody className="">
              {record.reports.map((report: any) => (
                <tr className="">
                  <td className="text-left pb-1">
                    <Link
                      className="underline text-blue-500"
                      to={`/admin/reports/${report.reportId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {report.reportNumber}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
