import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import { getNestedError } from '@utils/utils';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import { is } from 'bluebird';

const DropdownMulti = ({
  id,
  control,
  rules,
  placeholder,
  formState,
  dropdownData,
  onChange,
  setValue,
  children,
  allowFreeSolo,
  isUpperCase,
}: {
  id: string;
  control: any;
  rules?: any;
  placeholder?: string;
  formState?: any;
  dropdownData: Array<any>;
  onChange?: any;
  setValue?: any;
  children?: any;
  allowFreeSolo?: boolean;
  isUpperCase?: boolean;
}) => {
  const errors = formState?.errors;

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& fieldset': {
              border:
                errors && getNestedError(errors, id)
                  ? 'solid 1px var(--red)'
                  : 'solid 1px var(--grey-400)',
              borderRadius: '6px',
            },
          },
        },
      },
    },
  });

  const handleChange = (event: any, value: any) => {
    if (onChange) onChange(value);
  };

  const CustomPopper = function (props: any) {
    return (
      <Popper {...props} transition>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Box>{props.children}</Box>
          </Grow>
        )}
      </Popper>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Controller
        name={id}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Autocomplete
            {...field}
            id="select"
            disableCloseOnSelect
            multiple
            freeSolo={allowFreeSolo}
            onChange={(event: any, newValue: any) => {
              if (isUpperCase) {
                newValue = newValue.map((value: string) => value.toUpperCase());
              }
              field.onChange(newValue);
              handleChange(event, newValue);
            }}
            // renderTags={(value: readonly string[], getTagProps) =>
            //   value.map((option: string, index: number) => {
            //     const { key, ...tagProps } = getTagProps({ index });
            //     return (
            //       <Chip
            //         variant="filled"
            //         style={{ backgroundColor: '#DFECF0', borderColor: '#DFECF0' }}
            //         label={isUpperCase ? String(option).toUpperCase() : String(option)}
            //         key={key}
            //         {...tagProps}
            //       />
            //     );
            //   })
            // }
            options={dropdownData}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: false,
                }}
                placeholder={field && field.value && field.value.length > 0 ? '' : placeholder}
                sx={{ fontSize: '14px' }}
              />
            )}
            renderOption={(props, option: any, { selected }) => (
              <Box
                component="li"
                sx={{
                  '&:hover': {
                    backgroundColor: 'var(--blue-200) !important',
                  },
                  ...(selected && {
                    backgroundColor: 'var(--blue-300) !important',
                  }),
                  fontSize: '14px',
                }}
                {...props}
                key={typeof option === 'object' ? option.id : option}
              >
                {typeof option === 'object' ? (
                  <>
                    <Typography variant="body2">{option.label}</Typography>
                    {option.subLabel && (
                      <Typography variant="body2" color="#484848" sx={{ paddingLeft: '15px' }}>
                        {option.subLabel}
                      </Typography>
                    )}
                  </>
                ) : (
                  option
                )}
              </Box>
            )}
            isOptionEqualToValue={(option: any, value: any) =>
              typeof option === 'object' ? option.id === value.id : option === value
            }
            sx={{
              width: '100%',
              '& .MuiAutocomplete-inputRoot': {
                backgroundColor: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor:
                    errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--grey-400)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--grey-400)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor:
                    errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--blue-400)',
                },
                fontSize: '14px',
              },
            }}
            value={field.value || []}
            PopperComponent={CustomPopper}
          />
        )}
      />

      {errors && getNestedError(errors, id) && (
        <span style={{ color: 'var(--red)' }} role="alert">
          {getNestedError(errors, id)?.message}
        </span>
      )}
    </ThemeProvider>
  );
};

export default DropdownMulti;
