import {
  useListUsersQuery,
  useGetProactiveScamEnablerAssignUsersQuery,
  useGetScamEnablerAssignUsersQuery,
} from '@api/userSlice';
import Dropdown from './dropdown/Dropdown';
import DropdownMulti from './DropdownMulti';
import { User } from '@interface/UsersInterface';

const DropdownUser = ({
  id,
  control,
  setValue,
  userGroup,
  multi,
  children,
}: {
  id: string;
  control: any;
  setValue: any;
  userGroup: any;
  multi?: boolean;
  children?: any;
}) => {
  let scamEnablerAssignUsersPermissions =
    userGroup === 'scamEnablersDA2' ? 'customs.sao' : 'customs.ao,customs.sao';

  const { data: paymentDailyReturnsModalUsers } = useListUsersQuery(
    {
      params: {
        active: true,
      },
    },
    { skip: userGroup !== 'paymentDailyReturnsModalUsers' },
  );
  const { data: scamEnablerUsersData } = useGetScamEnablerAssignUsersQuery(
    {
      params: {
        permissions: scamEnablerAssignUsersPermissions,
      },
    },
    { skip: userGroup !== 'scamEnablersDA1' &&  userGroup !== 'scamEnablersDA2' },
  );
  const { data: proactiveScamEnablerUsersData } = useGetProactiveScamEnablerAssignUsersQuery(
    {
      params: {},
    },
    { skip: userGroup !== 'proactiveScamEnablers' },
  );

  return (
    <div className="w-full">
      {paymentDailyReturnsModalUsers?.users &&
        userGroup === 'paymentDailyReturnsModalUsers' &&
        multi && (
          <DropdownMulti
            id={id}
            control={control}
            dropdownData={paymentDailyReturnsModalUsers?.users?.map((user: User) => {
              return {
                id: user?.email,
                label: `${user?.firstName} ${user?.lastName}`,
                subLabel: user?.email,
              };
            })}
            setValue={setValue}
          >
            {children}
          </DropdownMulti>
        )}
      {paymentDailyReturnsModalUsers?.users &&
        userGroup === 'paymentDailyReturnsModalUsers' &&
        !multi && (
          <Dropdown
            id={id}
            control={control}
            dropdownData={paymentDailyReturnsModalUsers.users.map((user: User) => {
              return {
                id: user?.email,
                label: `${user?.firstName} ${user?.lastName}`,
                subLabel: user?.email,
              };
            })}
            setValue={setValue}
          >
            {children}
          </Dropdown>
        )}
      {scamEnablerUsersData?.users && (userGroup === 'scamEnablersDA1' || userGroup === 'scamEnablersDA2') && (
        <Dropdown
          id={id}
          control={control}
          dropdownData={scamEnablerUsersData.users.map((user: User) => {
            return {
              id: user.userId,
              label: `${user.firstName} ${user.lastName}`,
              subLabel: user.email,
            };
          })}
          setValue={setValue}
        >
          {children}
        </Dropdown>
      )}
      {proactiveScamEnablerUsersData?.users && userGroup === 'proactiveScamEnablers' && (
        <Dropdown
          id={id}
          control={control}
          dropdownData={proactiveScamEnablerUsersData.users.map((user: User) => {
            return {
              id: user.userId,
              label: `${user.firstName} ${user.lastName}`,
              subLabel: user.email,
            };
          })}
          setValue={setValue}
        >
          {children}
        </Dropdown>
      )}
    </div>
  );
};

export default DropdownUser;
