import DynamicForm from '@components/common/DynamicForm';
import { paynowOverviewForm } from './paynowSectionFormConfig';

export function AccountDetails({ data }: { data: any }) {
  return (
    <div>
      {/* <DataRow name="HP/NRIC/FIN/UEN" value={id} />
      <DataRow
        name="PayNow Account Status"
        value={outcome}
        badge={<PaynowStatus outcome={outcome} />}
      />
      <DataRow name="Bank Display Name (Submitted)" value={bankDisplayName} /> */}
      <DynamicForm formFields={paynowOverviewForm} data={data} />
    </div>
  );
}
