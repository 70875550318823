import { Link } from 'react-router-dom';

export function RelatedReports({ relatedReports }: { relatedReports: Array<any> }) {
  return (
    <div>
      <p className="italic text-gray-400">The following reports also invovles this scam enabler.</p>
      {relatedReports.map((reportEntry: any) => {
        return (
          <div className="mt-3">
            <Link
              to={`/admin/reports/${reportEntry.reportId}`}
              className="underline text-sm font-medium text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              {reportEntry.reportNumber}
            </Link>
          </div>
        );
      })}
    </div>
  );
}
