import { FormItemInterface } from '@interface/dynamicFormInterface';
import { useEffect, useState } from 'react';
import DynamicForm, { getField } from './DynamicForm';
import RadioInput from './Radio';

const RadioToggleField = ({
  field,
  formMethods,
  nestedFields,
}: {
  field: FormItemInterface;
  formMethods: any;
  nestedFields: any;
}) => {
  const [selectedType, setSelectedType] = useState(
    formMethods.getValues(field.id) === 'false' ? nestedFields[1].type : nestedFields[0].type,
  );

  const handleChange = (value: string) => {
    setSelectedType(value === 'true' ? field.extras.options[0].type : field.extras.options[1].type);
  };

  const getNestedField = (selectedType: string) => {
    const nestedField = nestedFields.find((nestedField: any) => nestedField.type === selectedType);
    if (nestedField) {
      return <DynamicForm formFields={[nestedField]} formMethods={formMethods} />;
    }
    return null;
  };

  useEffect(() => {
    if (formMethods.getValues(field.id) !== 'false') {
      formMethods.setValue(field.id, 'true');
    }
  }, []);

  return (
    <div>
      <RadioInput
        control={formMethods.control}
        setValue={formMethods.setValue}
        id={field.id}
        rules={field.rules}
        formState={formMethods.formState}
        defaultValue="true"
        handleTrueClick={() => handleChange('true')}
        handleFalseClick={() => handleChange('false')}
        radioItems={field.extras.options}
        isEditable={true}
      />
      {getNestedField(selectedType)}
    </div>
  );
};

export default RadioToggleField;
