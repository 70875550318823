import FormHeaderComponent from '../../../components/common/FormHeaderComponent';
import CreateReportComponent from './CreateReportComponent';

const FormCreateReportPage = () => {
  return (
    <div className="md:flex flex-col h-full items-center justify-center min-h-screen bg-grey-100">
      <div className="flex flex-col w-full h-full xl:w-2/3 gap-2 bg-white xl:my-8 pb-1 rounded-lg">
        <FormHeaderComponent title={'Police Scam Reporting'} />
        <div className="px-8">
          <CreateReportComponent showHeader={true} isFormCreateReport={true} />
        </div>
      </div>
    </div>
  );
};

export default FormCreateReportPage;
