import { Badge, Box, Modal, Tab, Tabs, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton } from '@components/common/CommonButton';
import {
  useGetProactiveScamEnablersExistV2Mutation,
  useUpdateProactiveScamEnablersV2Mutation,
} from '@api/scamEnablerSlice';
import DynamicForm from '@components/common/DynamicForm';

import CustomizedStepperForm from '@components/common/Stepper';
import { ErrorOutline } from '@mui/icons-material';
import { Table } from 'antd';
import { cloneDeep } from 'lodash';
import {
  invalidColumns,
  monikerIdConfig,
  updateProactiveItems,
  validColumns,
} from '../ProactiveMonikersFormConfig';
import { extractUserIdFromUrl } from '@utils/utils';

const UpdateProactiveMonikerModal = ({
  isOpen,
  handleClose,
  selectedScamEnablers,
  callback,
}: {
  isOpen: boolean;
  handleClose: () => void;
  selectedScamEnablers?: any[];
  callback: () => void;
}) => {
  const step1FormMethods = useForm({
    defaultValues: {
      platform: 'Facebook',
      monikerId: '',
    },
    mode: 'onChange',
  });
  const formSubmitMethods = useForm({
    mode: 'onChange',
  });
  const steps = ['Input Moniker IDs', 'System Verification', 'Update Fields'];
  const [updateProactiveScamEnablers] = useUpdateProactiveScamEnablersV2Mutation();
  const [stepCompletionStatus, setStepCompletionStatus] = useState(
    new Array(steps.length).fill(false),
  );
  const [activeStep, setActiveStep] = useState(0);
  const [tabValue, setTabValue] = useState('valid');

  const isButtonDisabled = false;
  const [validMonikerIds, setValidMonikerIds] = useState<any[]>([]);
  const [invalidMonikerIds, setInvalidMonikerIds] = useState<any[]>([]);

  const [checkUserId] = useGetProactiveScamEnablersExistV2Mutation();
  const [isLoading, setIsLoading] = useState(false);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      setIsLoading(true);
      const monikerIds = step1FormMethods.getValues();
      if (monikerIds.monikerId) {
        const monikerIdArray = monikerIds.monikerId.split('\n');
        const monikerIdSet = new Set(monikerIdArray);
        let tempRequest: any = [];
        monikerIdSet.forEach((monikerId) => {
          if (monikerId !== '') {
            let scamEnablerUserId = monikerId;
            if (monikerIds.platform === 'Facebook') {
              if (extractUserIdFromUrl(monikerId, monikerIds.platform).isValidUrl) {
                scamEnablerUserId = extractUserIdFromUrl(monikerId, monikerIds.platform).userId;
              }
            }
            tempRequest.push({
              scamEnablerUserId: scamEnablerUserId,
              platform: monikerIds.platform,
            });
          }
        });
        let response = await checkUserId({
          proactiveScamEnablers: tempRequest,
        }).unwrap();
        let tempValidMonikerIds: any[] = [];
        let tempInvalidMonikerIds: any[] = [];
        response.result.forEach((item: any) => {
          if (item.exists === true) {
            tempValidMonikerIds.push(item);
          } else {
            let newItem = { ...item, errorMessage: 'Moniker Id does not exist in the system.' };
            tempInvalidMonikerIds.push(newItem);
          }
        });
        setValidMonikerIds(tempValidMonikerIds);
        setInvalidMonikerIds(tempInvalidMonikerIds);
        console.log(response);
        setIsLoading(false);
        setStepCompletionStatus((prev) => {
          const newStatus = [...prev];
          newStatus[activeStep] = true;
          return newStatus;
        });
        setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (validMonikerIds && validMonikerIds.length > 0) {
        setStepCompletionStatus((prev) => {
          const newStatus = [...prev];
          newStatus[activeStep] = true;
          return newStatus;
        });
        setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
      }
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    // const allPreviousCompleted = stepCompletionStatus.slice(0, step).every((status) => status);
    // if (allPreviousCompleted) {
    //   setActiveStep(step);
    // }
  };

  const formatValules = (id: string) => {
    const submitData: any = formSubmitMethods.getValues();
    let dirtyFields = formSubmitMethods.formState.dirtyFields;
    let tempParams: any = {};
    for (let key in dirtyFields) {
      tempParams[key] = submitData[key];
      if (key === 'url' && extractUserIdFromUrl(submitData[key], 'Facebook').isValidUrl) {
        tempParams['scamEnablerUserId'] = extractUserIdFromUrl(submitData[key], 'Facebook').userId;
      } else if (key === 'submittedAt' || key === 'closedAt') {
        tempParams[key] = new Date(submitData[key]).getTime();
      }
      // else if (key === 'facebookCreatedAt') {
      //   tempParams[key] = new Date(submitData[key]).setHours(0, 0, 0, 0);
      // } else if (key === 'numberOfAds') {
      //   tempParams[key] = parseInt(submitData[key]);
      // } else if (key === 'attachmentFileNames' && submitData[key]?.length > 0) {
      //   tempParams[key] = submitData[key]
      //     .map((row: string) => (row ? row.trim() : ''))
      //     .filter((row: string) => row !== '');

      //   if (tempParams[key].length === 0) {
      //     delete tempParams[key];
      //   }
      // } else if (key === 'scammerContactNumber' && submitData[key]?.length > 0) {
      //   tempParams[key] = submitData[key]
      //     .map((row: string) => (row ? row.trim() : '+65'))
      //     .filter((row: string) => row && row !== '+65');

      //   if (tempParams[key].length === 0) {
      //     delete tempParams[key];
      //   }
      // }
    }
    tempParams['_id'] = id;
    return tempParams;
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    let tempRequest: any = [];

    if (validMonikerIds?.length > 0) {
      validMonikerIds.forEach((item) => {
        tempRequest.push(formatValules(item._id));
      });
    } else if (selectedScamEnablers && selectedScamEnablers?.length > 0) {
      selectedScamEnablers.forEach((item) => {
        tempRequest.push(formatValules(item._id));
      });
    }

    let response: any = await updateProactiveScamEnablers(tempRequest);

    if (!response.error) {
      if (callback) callback();
      handleClose();
    }
    setIsLoading(false);
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="flex flex-col gap-2">
            <h2 className="bold">Input Moniker IDs</h2>
            <DynamicForm formFields={monikerIdConfig} formMethods={step1FormMethods}></DynamicForm>
          </div>
        );
      case 1:
        return (
          <>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { backgroundColor: '#255662' } }}
            >
              <Tab
                label={
                  <div className="flex items-center gap-4">
                    <p
                      className={`font-bold ${tabValue === 'valid' ? 'text-blue-500' : 'text-blue-400'}`}
                      style={{ textTransform: 'none' }}
                    >
                      Successful
                    </p>
                    <Badge color="success" badgeContent={validMonikerIds.length || 0} max={999} />
                  </div>
                }
                value="valid"
              />
              <Tab
                label={
                  <div className="flex items-center gap-4">
                    <p
                      className={`font-bold ${tabValue === 'invalid' ? 'text-blue-500' : 'text-blue-400'}`}
                      style={{ textTransform: 'none' }}
                    >
                      Error
                    </p>
                    <Badge color="error" badgeContent={invalidMonikerIds.length || 0} max={999} />
                  </div>
                }
                value="invalid"
              />
            </Tabs>
            {tabValue === 'valid' && (
              <div className="h-[40vh] overflow-y-auto">
                <Table
                  rowKey="scamEnablerUserId"
                  dataSource={cloneDeep(validMonikerIds)}
                  columns={validColumns}
                  pagination={false}
                  sticky
                />
              </div>
            )}
            {tabValue === 'invalid' && (
              <div className="h-[40vh] overflow-y-auto">
                <Table
                  rowKey="scamEnablerUserId"
                  dataSource={cloneDeep(invalidMonikerIds)}
                  columns={invalidColumns}
                  pagination={false}
                  sticky
                />
              </div>
            )}
          </>
        );

      case 2:
        return (
          <form onSubmit={handleSubmit}>
            <DynamicForm formFields={updateProactiveItems} formMethods={formSubmitMethods} />
          </form>
        );
      default:
        return 'Unknown stepIndex';
    }
  };
  const tooltipContent = (
    <div className="flex text-black bg-white">
      <ErrorOutline className="mr-2" />
      <span>All required fields need to be filled before proceeding to the next section.</span>
    </div>
  );

  const checkIsButtonEnabled = () => {
    if (activeStep === 0) {
      return step1FormMethods.formState.isValid;
    } else if (activeStep === 1) {
      return validMonikerIds?.length > 0;
    } else if (activeStep === 2) {
      return formSubmitMethods.formState.isDirty && formSubmitMethods.formState.isValid;
    }
    return false;
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalLayout
        title={'Update Details'}
        handleCloseModal={handleClose}
        alignment="center"
        size="large"
        overflowClass=""
      >
        <>
          {(!selectedScamEnablers || selectedScamEnablers.length === 0) && (
            <CustomizedStepperForm
              steps={steps}
              handleStepClick={handleStep}
              activeStep={activeStep}
              stepCompletionStatus={stepCompletionStatus}
            >
              <Box sx={{ mb: 2 }}>
                <div className="flex-1 overflow-auto pb-10">{getStepContent(activeStep)}</div>
                <div className="pt-4 flex justify-end sticky gap-5 bottom-0 pb-4 bg-white">
                  <CommonButton variant="text" title="Cancel" onClick={handleClose} type="button" />
                  {activeStep !== 0 && (
                    <CommonButton
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleBack();
                      }}
                      type="button"
                      variant="secondary"
                      title="Previous"
                    />
                  )}

                  <Tooltip
                    title={tooltipContent}
                    disableHoverListener={!isButtonDisabled}
                    placement="bottom-end"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.white',
                          fontSize: '12px',
                        },
                      },
                    }}
                  >
                    <span>
                      <CommonButton
                        variant="primary"
                        title={activeStep !== steps.length - 1 ? 'Next' : 'Submit'}
                        type="submit"
                        isDisabled={!checkIsButtonEnabled()}
                        onClick={handleNext}
                        isLoading={isLoading}
                      />
                    </span>
                  </Tooltip>
                </div>
              </Box>
            </CustomizedStepperForm>
          )}
          {selectedScamEnablers && selectedScamEnablers.length > 0 && (
            <div className="flex flex-col">
              <form>
                <DynamicForm formFields={updateProactiveItems} formMethods={formSubmitMethods} />
              </form>
              <h5 className="pt-6 font-h4-bold-14 ">Selected Scam Enablers</h5>
              <div className="overflow-auto bg-grey-200 p-4 min-h-36">
                <div className="grid grid-cols-4 pb-2">
                  <p className="text-sm font-bold">Scam Enabler User ID</p>
                </div>
                {selectedScamEnablers.map((scamEnabler, index) => (
                  <div key={index} className="grid grid-cols-4">
                    <p className="text-sm">{scamEnabler.scamEnablerUserId}</p>
                  </div>
                ))}
              </div>
              <div className="pt-4 flex justify-end  gap-5 bottom-0 pb-4 bg-white">
                <CommonButton variant="text" title="Cancel" onClick={handleClose} type="button" />
                <CommonButton
                  isLoading={isLoading}
                  variant="primary"
                  title="Submit"
                  isDisabled={!formSubmitMethods.formState.isDirty}
                  onClick={handleSubmit}
                  type="submit"
                />
              </div>
            </div>
          )}
        </>
      </ModalLayout>
    </Modal>
  );
};

export default UpdateProactiveMonikerModal;
