import { InfoSection } from '../components/InfoSection';
import { RelatedReports } from '../components/RelatedReports';
import { LinkedPaynow } from './LinkedPaynow';
import { Overview } from './Overview';
import { ProductionOrdersIssued } from './ProductionOrdersIssued';
import { TransactionDetails } from '../components/TransactionDetails';

export function BankSection({ data, reportNumber }: { data: any; reportNumber: string }) {
  const getUpdatedTransaction = () => {
    let reportedTransaction;
    if (data?.updatedTransactions?.length > 0) {
      reportedTransaction = data.updatedTransactions.find((transaction: any) => {
        return transaction.reportNumber === reportNumber;
      });
    }

    return reportedTransaction;
  };
  return (
    <div>
      {/* Overview */}
      <InfoSection title="Overview" inner={<Overview data={data} />} />

      {/* Transaction details */}
      <InfoSection
        title="Transaction Details (For this report only)"
        inner={
          <TransactionDetails
            amtEarmarked={getUpdatedTransaction()?.amountEarmarked || 0}
            transactionHistory={getUpdatedTransaction()}
          />
        }
      />

      {/* Linked Paynow */}
      <InfoSection
        title="List of PayNow Linked"
        inner={<LinkedPaynow paynowData={data?.paynowNumbers} />}
      />

      <InfoSection
        title="Production Orders Issued"
        inner={<ProductionOrdersIssued orders={data?.productionOrders} />}
      />

      <InfoSection
        title="Related Reports"
        inner={<RelatedReports relatedReports={data?.relatedReports} />}
      />
    </div>
  );
}
