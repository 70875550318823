import { paynowOutcomes } from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';

export const paynowOverviewForm: Array<FormItemInterface> = [
  {
    label: 'HP/NRIC/FIN/UEN',
    id: 'paynowNumber',
    type: 'label',
    alignment: 'half-row',
  },
  {
    label: 'PayNow Account Status',
    id: 'outcome',
    type: 'labelEditDropdown',
    alignment: 'half-row',
    extras: {
      dropdownData: paynowOutcomes,
      type: 'paynow',
    },
  },
  {
    label: 'Bank Display Name (Submitted)',
    id: 'bankDisplayName',
    type: 'label',
    alignment: 'half-row',
  },
];
