import { useFieldArray, Controller } from 'react-hook-form';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import TextInput from './TextInput';
import { IconButton } from './CommonButton';

function MultiTextInput({
  fieldConfig,
  control,
  formState,
  parentExtras,
}: {
  fieldConfig: FormItemInterface;
  control: any;
  formState: any;
  parentExtras: any;
}) {
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: fieldConfig.id,
    rules: fieldConfig.rules,
  });

  return (
    <div className={`${fieldConfig.alignment === 'row' ? ' flex-col' : ' flex-row'} gap-4 w-full`}>
      {fields.map((field, index) => (
        <div key={index} className={` flex flex-row gap-4`}>
          <div className="flex-grow pb-2">
            <Controller
              name={`${fieldConfig.id}[${index}]`}
              control={control}
              render={({ field }) => (
                <TextInput
                  id={field.name}
                  control={control}
                  rules={fieldConfig.rules}
                  placeholder={fieldConfig.placeholder}
                  formState={formState}
                  prefix={fieldConfig?.extras?.prefix ? fieldConfig.extras.prefix : ''}
                  disabled={field.disabled}
                  rows={fieldConfig?.extras?.rows}
                  prefill={fieldConfig?.extras?.prefill}
                  keyup={fieldConfig?.extras?.keyup}
                  isLoading={parentExtras && parentExtras[fieldConfig.id]?.isLoading}
                  isVerified={parentExtras && parentExtras[fieldConfig.id]?.isVerified}
                  textInputType={fieldConfig?.extras?.textInputType}
                ></TextInput>
              )}
            />
          </div>
          <IconButton
            isDisabled={false}
            icon="delete"
            onClick={() => remove(index)}
            type="button"
          />
        </div>
      ))}
      <IconButton
        isDisabled={false}
        icon="add"
        onClick={() => append('')}
        isLoading={false}
        type="button"
      />
    </div>
  );
}

export default MultiTextInput;
