import { useEffect, useMemo, useState } from 'react';
import DynamicForm from '@components/common/DynamicForm';
import {
  overviewUpdateItemsGeneral,
  overviewInformationItemsGeneral,
  overviewUpdateItemsGeneralWithRecords,
} from '../PaynowFormConfig';
import { useForm } from 'react-hook-form';
import { useUpdatePaynowMutation } from '@api/paynowSlice';
import { showAlert } from '@store/alertSlice';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import { CommonButton, IconButton } from '@components/common/CommonButton';
import ActivityLog from '@pages/portfolios/monikers/MonikersDetailedPage/ActivityLog/ActivityLog';

export function PaynowDetailsOverview({ data, refetch }: { data: any; refetch: any }) {
  const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [updatePaynow] = useUpdatePaynowMutation();

  const dispatch = useDispatch();

  const defaultValues = useMemo(() => {
    if (data) {
      let newData = cloneDeep(data);
      return newData;
    }
  }, [data]);

  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (data) {
      formMethods.reset(defaultValues);
      setIsLoading(false);
    }
  }, [data, defaultValues, formMethods]);

  const handleSubmit = async () => {
    let dirtyFields = formMethods.formState.dirtyFields;
    let values = formMethods.getValues();
    let params: any = { paynowId: data._id };
    for (let key in dirtyFields) {
      params[key] = values[key];
    }

    console.log(params);
    let r: any = await updatePaynow(params);

    if (!r.error) {
      await refetch();

      dispatch(
        showAlert({
          type: 'success',
          title: 'Success',
          message: `Overview updated successfully.`,
        }),
      );
    }
    setIsEditing(false);
    setIsSubmitting(false);
  };

  return (
    <div>
      <div className="flex flex-row justify-between align-middle">
        <div className="w-100 flex flex-row items-center align-middle gap-4">
          <h1 className="font-bold text-lg text-blue-500">Overview</h1>
          {
            <>
              <IconButton
                isDisabled={false}
                icon="activity_log"
                type="button"
                onClick={() => setIsActivityLogModalOpen(true)}
                isLoading={false}
              />
              {data?.accountNumber && isActivityLogModalOpen && (
                <ActivityLog
                  isOpen={isActivityLogModalOpen}
                  handleClose={() => setIsActivityLogModalOpen(false)}
                  id={data.account?.accountNum}
                />
              )}
            </>
          }
        </div>
        {!isLoading && data?.outcome !== 'Records Found' && (
          <div className="flex flex-row gap-4">
            {isEditing && (
              <>
                <CommonButton
                  isDisabled={false}
                  variant="text"
                  title={`Cancel`}
                  onClick={() => setIsEditing(false)}
                />
                <CommonButton
                  isDisabled={!formMethods.formState.isDirty}
                  variant="secondary"
                  title={`Save`}
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                />
              </>
            )}
            {!isEditing && (
              <CommonButton
                isDisabled={false}
                variant="primary"
                title={`Edit`}
                isLoading={isSubmitting}
                onClick={() => setIsEditing(true)}
              />
            )}
          </div>
        )}
      </div>

      <div className="max-h-[75vh] pb-4 overflow-auto mt-5">
        {data && (
          <>
            {!isEditing && (
              <DynamicForm
                formFields={overviewInformationItemsGeneral}
                data={data}
                callback={refetch}
                isEditable={false}
              />
            )}
            {isEditing && (
              <form>
                <DynamicForm
                  formFields={
                    data?.history && data?.history.length > 0
                      ? overviewUpdateItemsGeneralWithRecords
                      : overviewUpdateItemsGeneral
                  }
                  formMethods={formMethods}
                  data={data}
                  callback={refetch}
                  isEditable={true}
                />
              </form>
            )}
          </>
        )}
        {!data && <DynamicForm formFields={overviewInformationItemsGeneral} isLoading={true} />}
      </div>
    </div>
  );
}
