import { useFieldArray } from 'react-hook-form';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton } from '@components/common/CommonButton';
import CreateReportMonikers from './CreateReportMonikers';
import RadioInput from '@components/common/Radio';
import DynamicForm from '@components/common/DynamicForm';

const CreateReportScamEnablers = ({
  formMethods,
  scamEnablerFormState,
  setScamEnablerFormState,
  isShowPaymentChannel,
  isFormCreateReport,
}: {
  formMethods: any;
  scamEnablerFormState: Array<Array<FormItemInterface>>;
  setScamEnablerFormState: any;
  isShowPaymentChannel: boolean;
  isFormCreateReport: boolean;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currScamEnabler, setCurrScamEnabler] = useState<number>(0);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [showTransactions, setShowTransactions] = useState(false);

  const monetaryLossItems: FormItemInterface = {
    label: 'Was there any monetary loss involved?',
    type: 'boolean',
    id: 'monetaryLossInvolved',
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  };

  const { fields, append, remove, update } = useFieldArray({
    control: formMethods.control,
    name: 'scamEnablers',
  });

  const handleCloseDeleteModal = () => {
    setCurrScamEnabler(0);
    setShowDeleteModal(false);
  };

  useEffect(() => {
    sectionRefs.current = sectionRefs.current.slice(0, fields.length);
  }, [fields]);

  return (
    <div>
      <div className="flex flex-col">
        <h2 className="bold">Scam Enablers</h2>

        {isFormCreateReport && (
          <div className="flex flex-col pb-6 pt-2">
            <label className="font-bold mr-4" htmlFor={'monetaryLossInvolved'}>
              {'Was there any monetary loss involved?'}:{' '}
            </label>
            <RadioInput
              control={formMethods.control}
              id="monetaryLossInvolved"
              rules={monetaryLossItems.rules}
              formState={formMethods.formState}
              handleTrueClick={() => {
                setShowTransactions(true);
                formMethods.setValue('monetaryLossInvolved', 'true');
              }}
              handleFalseClick={() => {
                setShowTransactions(false);
                formMethods.setValue('monetaryLossInvolved', 'false');
              }}
            />
            {(showTransactions || formMethods.getValues('monetaryLossInvolved') === 'true') && (
              <DynamicForm
                formFields={[
                  {
                    label: 'Amount of Losses(SGD)',
                    type: 'textInput',
                    id: 'amountLoss',
                    alignment: 'column',
                    extras: {
                      textInputType: 'number',
                    },
                    rules: {
                      required: 'Please indicate the amount of losses in SGD',
                      pattern: {
                        value: /^[0-9]*$/,
                        message: 'Please enter a valid amount',
                      },
                    },
                  },
                ]}
                formMethods={formMethods}
              />
            )}
          </div>
        )}

        {fields.map((field: any, index: number) => (
          <div key={index}>
            <CreateReportMonikers
              formMethods={formMethods}
              scamEnablerFormState={scamEnablerFormState}
              setScamEnablerFormState={setScamEnablerFormState}
              setCurrScamEnabler={setCurrScamEnabler}
              setShowDeleteModal={setShowDeleteModal}
              field={field}
              index={index}
              update={update}
              sectionRefs={sectionRefs}
              isShowPaymentChannel={isShowPaymentChannel}
              isFormCreateReport={isFormCreateReport}
            />
          </div>
        ))}
      </div>
      <CommonButton
        className="pt-5"
        title="Add Another Scam Enabler"
        variant={'text'}
        onClick={async () => {
          await append({ useOfScamEnabler: '', platform: '', type: 'Moniker' });
          setTimeout(() => {
            const lastFormSection = sectionRefs.current[fields.length];
            if (lastFormSection) {
              lastFormSection.scrollIntoView({ behavior: 'smooth' });
            }
          }, 100);
        }}
        type="button"
      />
      <Modal open={showDeleteModal} onClose={handleCloseDeleteModal}>
        <ModalLayout
          title="Delete Scam Enabler"
          handleCloseModal={handleCloseDeleteModal}
          alignment="center"
          size="small"
        >
          <p className="pb-4">
            You are deleting
            <span className="font-bold">
              {' '}
              Scam Enabler {currScamEnabler != null && currScamEnabler + 1}.
            </span>
          </p>
          <p className="pt-2 pb-2">Are you sure you want to proceed?</p>
          <p className="pb-3">You cannot undo this action.</p>
          <div className="pt-4 flex justify-end gap-4 b-4 bg-white">
            <CommonButton title="Cancel" variant="text" onClick={handleCloseDeleteModal} />
            <CommonButton
              className="ml-5"
              title="Delete"
              variant="warning"
              onClick={() => {
                let newDynamicFormState = cloneDeep(scamEnablerFormState);
                newDynamicFormState.splice(currScamEnabler, 1);
                setScamEnablerFormState(newDynamicFormState);
                remove(currScamEnabler);
                handleCloseDeleteModal();
              }}
            />
          </div>
        </ModalLayout>
      </Modal>
    </div>
  );
};

export default CreateReportScamEnablers;
