import DynamicForm from '@components/common/DynamicForm';
import { convertMsToHoursMinutes } from '@utils/utils';
import cloneDeep from 'lodash.clonedeep';
import {
  monikerUpdateDetailsKeyInformation,
  monikerDetailsItems,
  monikerDetailsKeyInformation,
  monikerUpdateDetailsAdditionalInformation,
  monikerDetailsAdditionalInformation,
} from '../MonikerFormConfig';
import { CommonButton } from '@components/common/CommonButton';
import { useEffect, useMemo, useState } from 'react';
import { useUpdateScamEnablerV2Mutation } from '@api/scamEnablerSlice';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

const PortfoliosMonikerDetailsComponent = ({
  data,
  refetch,
  isLoading,
  isEditable,
  isDirectionQueueMonikerModal,
}: {
  data: any;
  refetch: any;
  isLoading: boolean;
  isEditable: boolean;
  isDirectionQueueMonikerModal: boolean;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updateScamEnablerV2] = useUpdateScamEnablerV2Mutation();

  const defaultValues = useMemo(() => {
    const initialValues: any = {};

    if (data?.scamEnabler) {
      monikerUpdateDetailsKeyInformation.forEach((item) => {
        if (item.type === 'dateTime' && data.scamEnabler[item.id]) {
          initialValues[item.id] = dayjs(data.scamEnabler[item.id]).format('YYYY-MM-DDTHH:mm');
        } else {
          initialValues[item.id] = data?.scamEnabler[item.id] || ''; // Fallback to empty string if data[item.id] is undefined
        }
      });

      monikerUpdateDetailsAdditionalInformation.forEach((item) => {
        if (item.type === 'dateTime' && data.scamEnabler[item.id]) {
          initialValues[item.id] = dayjs(data.scamEnabler[item.id]).format('YYYY-MM-DDTHH:mm');
        } else {
          initialValues[item.id] = data?.scamEnabler[item.id] || ''; // Fallback to empty string if data[item.id] is undefined
        }
      });
    }
    return initialValues;
  }, [data]);

  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    formMethods.reset(defaultValues); // This will reset the form with new default values
  }, [defaultValues, formMethods, data]);

  const filterUpdateDetailsKeyInformation = () => {
    let tempItemsConfig = cloneDeep(monikerUpdateDetailsKeyInformation);
    if (data?.scamEnabler?.productionOrders?.length > 0) {
      tempItemsConfig = tempItemsConfig.filter(
        (item) => item.id !== 'scamEnablerUserId' && item.id !== 'platform',
      );
    }
    return tempItemsConfig;
  };

  const filterUpdateDetailsAdditionalInformation = () => {
    let tempItemsConfig = cloneDeep(monikerUpdateDetailsAdditionalInformation);
    if (data?.scamEnabler?.productionOrders?.length > 0) {
      tempItemsConfig = tempItemsConfig.filter(
        (item) => item.id !== 'scamEnablerUserId' && item.id !== 'platform',
      );
    }
    return tempItemsConfig;
  };

  const processReport = (data: any) => {
    if (!data) return {};
    let tempData = cloneDeep(data);
    let productionOrderSentDate =
      tempData?.productionOrderSentDate || tempData?.generateProductionOrderAt;

    tempData.productionOrderSentDate = productionOrderSentDate;

    if (productionOrderSentDate) {
      let reviewTATObject = convertMsToHoursMinutes(productionOrderSentDate - tempData.createdAt);
      tempData.reviewTAT = reviewTATObject.hours + ' hours ' + reviewTATObject.minutes + ' minutes';

      if (tempData.closedAt) {
        let returnTATObject = convertMsToHoursMinutes(tempData.closedAt - productionOrderSentDate);
        tempData.returnTAT =
          returnTATObject.hours + ' hours ' + returnTATObject.minutes + ' minutes';
      }
    }
    if (tempData?.assignment) {
      tempData.assignment.forEach((assign: any) => {
        if (assign.role === 'da1') {
          tempData.da1 = assign.userName;
        } else if (assign.role === 'da2') {
          tempData.da2 = assign.userName;
        }
      });
    }

    return tempData;
  };

  const handleSubmit = async () => {
    if (isEditing) {
      setIsSubmitting(true);
      let values = formMethods.getValues();
      let dirtyFields = formMethods.formState.dirtyFields;
      let tempParams: any = {};

      for (const key in dirtyFields) {
        if (key === 'productionOrderSentDate' || key === 'closedAt') {
          tempParams[key] = new Date(values[key]).getTime();
        }
        if (key === 'victimReported') {
          tempParams[key] = values[key] === 'Yes' ? true : false;
        } else if (key === 'amountLoss') {
          tempParams[key] = parseInt(values[key]);
        } else {
          if (typeof values[key] === 'string') {
            tempParams[key] = values[key].trim();
          } else {
            tempParams[key] = values[key];
          }
        }
      }
      tempParams['scamEnablerId'] = data.scamEnabler._id;
      let response: any = await updateScamEnablerV2([tempParams]);

      if (!response.error) {
        await refetch();
        formMethods.reset(defaultValues);
      }
      setIsSubmitting(false);
    }
    setIsEditing(!isEditing);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        {!isDirectionQueueMonikerModal ? (
          <>
            <h1 className="font-bold text-lg text-blue-500">Moniker Details</h1>
            <div className="flex flex-row gap-4">
              {isEditing && (
                <>
                  <CommonButton
                    isDisabled={false}
                    variant="text"
                    title={`Cancel`}
                    onClick={() => setIsEditing(false)}
                  />
                  <CommonButton
                    isDisabled={!formMethods.formState.isDirty}
                    variant="secondary"
                    title={`Save`}
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </>
              )}
              {!isEditing && (
                <CommonButton
                  isDisabled={!isEditable}
                  variant="primary"
                  title={`Edit`}
                  isLoading={isSubmitting}
                  onClick={() => setIsEditing(true)}
                />
              )}
            </div>
          </>
        ) : (
          <h1 className="font-semibold text-sm pb-1 text-blue-400">Moniker Details</h1>
        )}
      </div>
      <p className="italic text-xs pb-4 text-grey-400">
        {isDirectionQueueMonikerModal
          ? 'When PO is issued, details are populated based on the time when PO is generated'
          : 'Details populated based on latest submission'}
      </p>
      <div className="max-h-[75vh] overflow-auto pb-8">
        <h1 className="font-semibold text-sm pb-1 text-blue-400">
          Key Information (To review before processing)
        </h1>
        {data && data.scamEnabler && Object.keys(data.scamEnabler).length > 0 ? (
          <>
            {!isEditing && (
              <DynamicForm
                formFields={monikerDetailsKeyInformation}
                data={processReport(data.scamEnabler)}
                callback={refetch}
                isEditable={isEditable}
              />
            )}
            {isEditing && (
              <form>
                <DynamicForm
                  formFields={filterUpdateDetailsKeyInformation()}
                  formMethods={formMethods}
                  data={processReport(data.scamEnabler)}
                  callback={refetch}
                />
              </form>
            )}
          </>
        ) : (
          <DynamicForm formFields={monikerDetailsKeyInformation} isLoading={true} />
        )}
        <h1 className="font-semibold text-sm pb-1 pt-5 text-blue-400">Additional Information</h1>
        {data && data.scamEnabler && Object.keys(data.scamEnabler).length > 0 ? (
          <>
            {!isEditing && (
              <DynamicForm
                formFields={monikerDetailsAdditionalInformation}
                data={processReport(data.scamEnabler)}
                callback={refetch}
                isEditable={isEditable}
              />
            )}
            {isEditing && (
              <form>
                <DynamicForm
                  formFields={filterUpdateDetailsAdditionalInformation()}
                  formMethods={formMethods}
                  data={processReport(data.scamEnabler)}
                  callback={refetch}
                />
              </form>
            )}
          </>
        ) : (
          <DynamicForm formFields={monikerDetailsAdditionalInformation} isLoading={true} />
        )}
      </div>
    </div>
  );
};

export default PortfoliosMonikerDetailsComponent;
