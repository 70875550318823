import NavLinks from './NavLinks';
import '../../App.css';
import { useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as LeftArrow } from '@assets/icons/left_arrow.svg';
import { ReactComponent as RightArrow } from '@assets/icons/right_arrow.svg';
import { ReactComponent as LeftArrowBlue } from '@assets/icons/left_arrow_blue.svg';
import { ReactComponent as RightArrowBlue } from '@assets/icons/right_arrow_blue.svg';
import logoImage from '@assets/icons/satis_plus_logo_graphic.png';
import logoImageName from '@assets/icons/satis_plus_logo_graphic_name.png';

export default function SideNav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="flex h-full flex-col pt-6 bg-blue-200 "
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={clsx(
          'flex h-[48px] grow items-center justify-center gap-6 md:flex-none  px-4 pb-4 mt-4 mb-8 ',
          {
            'p-0': !isOpen,
            'flex md:justify-start mx-4': isOpen,
          },
        )}
      >
        {!isOpen && <img src={logoImage} alt="Logo" className="h-8 w-8 " />}
        {isOpen && <img src={logoImageName} alt="Logo" className="h-8" />}
        {/* <p
          className={clsx('text-3xl font-bold', {
            'hidden md:block ': isOpen,
            hidden: !isOpen,
          })}
        >
          {'SATIS +'}
        </p> */}
      </div>
      <div className="flex grow justify-between  flex-col space-x-0 space-y-2 px-4 ">
        <NavLinks isOpen={isOpen} />
        <div className="hidden h-auto w-full grow rounded-md md:block"></div>
      </div>
      <button
        className="flex h-[48px] grow items-center justify-center bg-blue-400 hover:bg-blue-300 hover:text-blue-500 md:flex-none p-0"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          isHovered ? (
            <LeftArrowBlue />
          ) : (
            <LeftArrow />
          )
        ) : isHovered ? (
          <RightArrowBlue />
        ) : (
          <RightArrow />
        )}
      </button>
    </div>
  );
}
