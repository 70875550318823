import React, { useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { countries } from './countryData';

interface CountrySelectorProps {
  onSelect: (country: any) => void;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({ onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredCountries = countries.filter(
    (country: any) =>
      country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      country.dialCode.includes(searchTerm) ||
      country.code.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div>
      <TextField
        variant="outlined"
        label="Search country"
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
        margin="normal"
      />
      {filteredCountries.map((country: any) => (
        <MenuItem
          key={country.code}
          onClick={() => {
            onSelect(country);
          }}
          className="flex items-center space-x-2"
        >
          <img src={country.flag} alt={country.name} className="w-6 h-6" />
          <span>
            {country.name} ({country.dialCode})
          </span>
        </MenuItem>
      ))}
    </div>
  );
};

export default CountrySelector;
